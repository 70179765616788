import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const closingRoomReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_SIGN_SALE_SUCCESS:
      return { ...state, addSignSaleRes: action.payload }
    case types.ADD_SIGN_SALE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, addSignSaleRes: action.payload }

      case types.GET_SIGN_SUCCESS:
      return { ...state, getSignRes: action.payload }
    case types.GET_SIGN_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, getSignRes: action.payload }

      case types.GET_SIGN_SALE_SUCCESS:
        return { ...state, getSignSaleRes: action.payload }
      case types.GET_SIGN_SALE_FAILURE:
        openNotificationWithIcon(
          'error',
          'Error',
          "Please Try Again"
        );
        return { ...state, getSignSaleRes: action.payload }

        case types.GET_ASSET_ID_SALE_SUCCESS:
          return { ...state, getAssetIdSaleRes: action.payload }
        case types.GET_ASSET_ID_SALE_FAILURE:
          openNotificationWithIcon(
            'error',
            'Error',
            "Please Try Again"
          );
          return { ...state, getAssetIdSaleRes: action.payload }
          
    default:
      return state
  }
}

   
