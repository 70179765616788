import React from 'react';
import { Button } from 'antd';
import {lockIcon} from './../../../Common/Icons';
import { Link } from 'react-router-dom';
import {getUrlSecondLastId} from "../../../Common/reUseFunctions";

const index = ({ onChangeSelections, location }) => {
  // const onChangeInventory = (value) => {
  //   console.log("NEGOTIATIONS VALUE", value)
  // }

    const locationUrl = location.pathname;
    const tradingId = getUrlSecondLastId(locationUrl);
  return (
    <div className="my-market-inventory ">
      <div><h3>Closing Room {lockIcon}</h3></div>
      <div className="header-btn-groups import-asset-menu">
        <Button className="mr20" type="link"><Link >Select Rights</Link></Button>
        <Button className="mr20" type="link"><Link to={`/trading-task/${tradingId}`}>Request Proposal</Link></Button>
        <Button className="mr20" type="link">Negotiation Room</Button>
        <Button className="mr20 active" type="link">Closing Room</Button>
      </div>
    </div>
  )
}

export default index;
