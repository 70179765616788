import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Avatar, Button } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getUrlSecondLastId,
  getLocalCountrytime,
  getCountrytime,
} from "./../../Common/reUseFunctions";
import { CloseCircleTwoTone } from "@ant-design/icons";

import LicenseAssetCollapse from "./LicenseAssetCollapse";
import LicenceAgreementSec from "./LicenceAgreementSec";

const RoomPersons = ({
  showModal,
  sellingAsset,
  getAllTradingTaskRes,
  signConfirmingModal,
  loading,
  logoImage,
  cancelNegotiationRes,
  onCancelNegotiation,
  history,
  getOrganizationProfileRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  location,
  leftOrganizationData,
  signedAndUnSignedAssets,
}) => {
  const [tradingTaskId, setTradingTaskId] = useState();
  const [disableSignBtn, setDisableSignBtn] = useState(false);

  let tradingAggregationId = "";
  let createdByCompanyId = "";
  let tradingtaskcreater = "";
  let tradingtaskacceptor = "";
  let selectionType = "";
  let companyId = "";
  let company_name = "";

  if (getOrganizationProfileRes && getAllTradingTaskRes) {
    tradingAggregationId =
      getOrganizationProfileRes.trading_aggregation_organization_id;
    createdByCompanyId =
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.created_by_company_id;
    tradingtaskcreater =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.creator_signed;
    tradingtaskacceptor =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.acceptor_signed;
    selectionType = getAllTradingTaskRes.data && getAllTradingTaskRes.data.type;
    companyId =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_id;
  }

  const signNowButton = () => {
    if (selectionType === "sale") {
      if (tradingAggregationId === createdByCompanyId) {
        if (tradingtaskcreater === false && tradingtaskacceptor === true) {
          return (
            <Button
              loading={loading}
              type="primary"
              onClick={() => signConfirmingModal()}
            >
              SIGN NOW
            </Button>
          );
        } else {
          return (
            <Button disabled={true} type="primary">
              SIGN NOW
            </Button>
          );
        }
      } else {
        // if (tradingtaskcreater === false && tradingtaskacceptor === false) {
        if (tradingtaskacceptor === false) {
          return (
            <Button
              loading={loading}
              type="primary"
              onClick={() => signConfirmingModal()}
            >
              SIGN NOW
            </Button>
          );
        } else {
          return (
            <Button disabled={true} type="primary">
              SIGN NOW
            </Button>
          );
        }
      }
    }

    // commented below code to remove condition for sale and purchase

    if (selectionType === "purchase") {
      if (tradingAggregationId === createdByCompanyId) {
        if (tradingtaskacceptor === false && tradingtaskcreater === false) {
          return (
            <Button
              loading={loading}
              type="primary"
              onClick={() => signConfirmingModal()}
            >
              SIGN NOW
            </Button>
          );
        } else {
          return (
            <Button disabled={true} type="primary">
              SIGN NOW
            </Button>
          );
        }
      } else {
        if (tradingtaskcreater === true) {
          return (
            <Button
              loading={loading}
              type="primary"
              onClick={() => signConfirmingModal()}
            >
              SIGN NOW
            </Button>
          );
        } else {
          return (
            <Button disabled={true} type="primary">
              SIGN NOW
            </Button>
          );
        }
      }
    }

    // if (selectionType === "purchase") {
    //   if (tradingAggregationId === companyId) {
    //     if (tradingtaskcreater === true && tradingtaskacceptor === false) {
    //       return (
    //         <Button
    //           loading={loading}
    //           type="primary"
    //           onClick={() => signConfirmingModal()}
    //         >
    //           SIGN NOW
    //         </Button>
    //       );
    //     } else {
    //       return (
    //         <Button disabled={true} type="primary">
    //           SIGN NOW
    //         </Button>
    //       );
    //     }
    //   } else {
    //     if (tradingtaskacceptor === false) {
    //       return (
    //         <Button
    //           loading={loading}
    //           type="primary"
    //           onClick={() => signConfirmingModal()}
    //         >
    //           SIGN NOW
    //         </Button>
    //       );
    //     } else {
    //       return (
    //         <Button disabled={true} type="primary">
    //           SIGN NOW
    //         </Button>
    //       );
    //     }
    //   }

    // if (tradingAggregationId === createdByCompanyId) {
    //   if (tradingtaskcreater === true && tradingtaskacceptor === false) {
    //     return (
    //       <Button
    //         loading={loading}
    //         type="primary"
    //         onClick={() => signConfirmingModal()}
    //       >
    //         SIGN NOW
    //       </Button>
    //     );
    //   } else {
    //     return (
    //       <Button disabled={true} type="primary">
    //         SIGN NOW
    //       </Button>
    //     );
    //   }
    // } else {
    //   if (tradingtaskacceptor === false) {
    //     return (
    //       <Button
    //         loading={loading}
    //         type="primary"
    //         onClick={() => signConfirmingModal()}
    //       >
    //         SIGN NOW
    //       </Button>
    //     );
    //   } else {
    //     return (
    //       <Button disabled={true} type="primary">
    //         SIGN NOW
    //       </Button>
    //     );
    //   }
    // }
    // }
  };

  useEffect(() => {
    const locationUrl = location.pathname;
    const id = getUrlSecondLastId(locationUrl);
    setTradingTaskId(id);
    if (cancelNegotiationRes && cancelNegotiationRes.status === true) {
      history.push("/trading-tasks");
    }
  }, [cancelNegotiationRes]);

  const onCancelNego = () => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlSecondLastId(locationUrl);
    onCancelNegotiation(getUrlPath);
  };

  return (
    <Fragment>
      <div className="license-contract">
        <div className="contract-header">
          <Row gutter={16}>
            <Col span={10}>
              <div className="contract-asset-profile">
                <Avatar src={logoImage} size={60} />
                <div>
                  <h3>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.assigned_to_object &&
                      getAllTradingTaskRes.data.assigned_to_object.name}
                  </h3>
                  <p>
                    {leftOrganizationData.created_by_company_title ||
                      leftOrganizationData.company_title}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="contract-time" span={6}>
              <p>Your Local Time {getLocalCountrytime()}</p>
            </Col>
            <Col className="contract-time" span={6}>
              <p>
                {getOrganizationProfileRes && getOrganizationProfileRes.country}
                , Local Time {getCountrytime(getOrganizationProfileRes)}
              </p>
            </Col>
          </Row>
        </div>
        {/* <div className="contract-upload">
          <h3 className="mb20">LICENSE CONTRACT</h3>
          <div style={{ maxWidth: "400px" }}>
            <Upload {...fileUploadProps}>
              <Button>
                <UploadOutlined /> Upload the file...
                </Button>
            </Upload>
          </div>
          <Button className="upload-history" type="link" onClick={() => showModal('file_history')}><HistoryOutlined /> File history</Button>
        </div> */}
        <div>
          {/* <RoomPersonsCom showModal={showModal} /> */}
          <LicenceAgreementSec
            getAllTradingTaskRes={getAllTradingTaskRes}
            showModal={showModal}
          />
          <LicenseAssetCollapse
            getAllTradingTaskRes={getAllTradingTaskRes}
            showModal={showModal}
            sellingAsset={sellingAsset}
            getFormalLicenseRes={getFormalLicenseRes}
            onGetFormalLicense={onGetFormalLicense}
            location={location}
          />
        </div>
        <div className="signature-btn-sec">
          <h5>SIGNATORIES</h5>
          <div className="signature-agreement-btns">
            {/* <div>
              <div className="signature-select">
                <Select defaultValue="Steve Baker" style={{ width: 180 }} disabled>
                  <Option value="Steve Baker">Steve Baker</Option>
                </Select>
                <Button type="default">INVITE SENT</Button>
              </div>
              <div className="signature-select">
                <Select defaultValue="Select Person" style={{ width: 180 }} onChange={handleChange}>
                  <Option value="Select Person">Select Person</Option>
                  <Option value="Last Invite Users">Last Invite Person</Option>
                  <Option value="New Invite Users">New Invite Person</Option>
                </Select>
                <Button type="primary">SEND INVITE</Button>
              </div>
            </div> */}
            <div>{signNowButton()}</div>
            {signedAndUnSignedAssets &&
              signedAndUnSignedAssets.length > 0 &&
              signedAndUnSignedAssets.map((data) => {
                return (
                  <div className="sign-text-icon">
                    <span className="icon-style">
                      {" "}
                      <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    </span>
                    <span className="sign-text">{data.title}</span>
                  </div>
                );
              })}
          </div>
          <div className="close-agreement-btns">
            <div>
              <Link to={`/trading-task/${tradingTaskId}/proposal`}>
                <Button type="link">
                  <ArrowLeftOutlined /> Back to Negotiation
                </Button>
              </Link>
              {/* <Button onClick={() => onCancelNego()} type="link">
                <CloseOutlined /> CANCEL NEGOTIATION
              </Button> */}
            </div>
          </div>
        </div>

        {/* <div className="close-agreement-btns">
           <Button type="primary" onClick={()=> setVisibleSignature(!visibleSignature)}>CLOSE AGREEMENT</Button>
           <div>
           <Link to={`/selections/5f4f71666e3c8ee2126379e0/proposal`}><Button type="link"><ArrowLeftOutlined /> Back to Negotiation</Button></Link>
           <Link to={`!#`}><Button type="link"><CloseOutlined /> CANCEL NEGOTIATIONS</Button></Link>
           </div>
         </div> */}
      </div>
    </Fragment>
  );
};

export default RoomPersons;
