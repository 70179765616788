import React from 'react';

import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor,
  onChangeSelectionType,
  sessionRes,
  setStatusFilter,
  statusFilter,
  setBlockedFilter,
  blockedFilter,
  setUnBlockedFilter,
  unBlockedFilter,
  setPendingFilter,
  pendingFilter,
  setSelectionTypeChange,
  onSearch,
}) => {
  return (
    <div>
      <TopTitleSection
        sessionRes={sessionRes}
      />
      <InventoryFilters
        showTableEditor={showTableEditor}
        onChangeSelectionType={onChangeSelectionType}

        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setBlockedFilter={setBlockedFilter}
        blockedFilter={blockedFilter}
        setUnBlockedFilter={setUnBlockedFilter}
        unBlockedFilter={unBlockedFilter}
        setPendingFilter={setPendingFilter}
        pendingFilter={pendingFilter}
        setSelectionTypeChange={setSelectionTypeChange}
        onSearch={onSearch}
      />
    </div>
  )
}

export default HeaderFilter;