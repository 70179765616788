import React from "react";
import { Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const MetadataShare = ({
  publishMetadata,
  unPublishMetadata,
  loading,
  assetDetail,
  mainUserRes,
}) => {
  return assetDetail && !assetDetail.shared_at ? (
    getRolesPermissions(mainUserRes, "asset.sync") === false ? (
      <Button style={{color: "#FF246E"}} type="link" onClick={publishMetadata} loading={loading}>
        <DoubleRightOutlined />
        Publish in marketplace
      </Button>
    ) : (
      ""
    )
  ) : getRolesPermissions(mainUserRes, "asset.unsync") === false ? (
    <Button style={{color: "#FF246E"}} type="link" onClick={unPublishMetadata} loading={loading}>
      <DoubleRightOutlined />
      Unpublish from marketplace
    </Button>
  ) : (
    ""
  );
};

export default MetadataShare;
