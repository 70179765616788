import React from 'react';
import { Input } from 'antd'

import { SearchOutlined } from '@ant-design/icons';

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  showTableEditor
}) => {

  return (
    <div className="d-flex">
      <div className="inventory-search-bar">
        <Input placeholder="Search" prefix={<SearchOutlined />} />
      </div>
    </div>
  )
}

export default InventorySearchDropdown;