import React, { useState, Fragment } from "react";
import UploadingCsvFile from './UploadingCsvFiledd';
import UploadCsvFile from './UploadCsvFile';
import HeaderFilter from './HeaderFilter';

const ImportAssetsFile = () => {
  const [fileList, setFileList] = useState([]);
 
  return (
    <Fragment>
      <HeaderFilter />
      {
        fileList && fileList[0] ?
          <UploadingCsvFile
            fileList={fileList}
          />
          :
          <UploadCsvFile
            fileList={fileList}
            setFileList={setFileList}
          />
      }
    </Fragment>
  );
};

export default ImportAssetsFile;
