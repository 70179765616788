import React from 'react';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';


const PresetsListView = ({
  list,
}) => {

  return (
    <div className="d-flex align-item-center justify-between full-width saved-search-dell">
      {list.name}
      <Popconfirm title="Are you sure？" icon={<QuestionCircleOutlined />}>
        <u>Delete</u>
      </Popconfirm>
    </div>
  )
}

export default PresetsListView;
