import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MemeberProfile from '../Components/ContentArea/CataloguesMemberProfile/CataloguesMemberProfile';

import {
  getSession
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    sessionRes: state.userReducer.sessionRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSession: bindActionCreators(getSession, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemeberProfile);