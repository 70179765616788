import React from 'react';
import { Button } from 'antd';

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory">
      <div><h3>Import Assets File</h3></div>
      <div className="header-btn-groups import-asset-menu">
        <Button className="mr20 active" type="link">Uploading File</Button>
        <Button className="mr20" type="link">Columns Mapping</Button>
        <Button className="mr20" type="link">Import Assets</Button>
      </div>
    </div>
  )
}

export default TopTitleSection;