import React, { useState } from 'react';
import { Form, Input, Row, Col, Select } from 'antd';

import { multiLanguages } from './../../../../../statics/data/multiLanguages'

const Titles = () => {
  const [selectedLang, setSelectedLang] = useState([]);

  const onChangeLanguage = value => {
    setSelectedLang(value);

  };

  return (
    <Row gutter={16} className="mb5">
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name"><h3 className="required"><span>Title</span></h3></div>
          <div className="metadata-filed">
            <div className="d-flex align-item-center">
              <Form.Item
                name="name"
                className="full-width mr20 mb0"
              >
                <Input placeholder="Title name" className="full-width" />
              </Form.Item>

              <Form.Item
                name="language"
                className="full-width mr20 mb0"
                rules={[
                  {
                    pattern: new RegExp("[a-z]{2}(\-[A-Z]{2})?"),
                    message: "Please enter the correct language!"
                  }
                ]}
              >
                <Select
                  placeholder="Select language"
                  value={selectedLang}
                  onChange={onChangeLanguage}
                  style={{ width: '100%' }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={trigger => trigger.parentElement}
                >
                  {multiLanguages.map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Titles;