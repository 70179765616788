import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UsersManagementComp from "../Components/ContentArea/Administration/UserManagement";

import {
  getUsersList,
  createNewUser,
  getSpecificUserDetail,
  getBlockSpecificUser,
  getUnblockSpecificUser,
  getRolesList,
  updateUseInformation,
  updateProfileStatus,
  userDelete,
  getDepartmentList,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    createNewUserRes: state.userManagementReducer.createNewUserRes,
    userManagementList: state.userManagementReducer.userManagementList,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    blockSpecificUser: state.userManagementReducer.blockSpecificUser,
    unBlockSpecificUser: state.userManagementReducer.unBlockSpecificUser,
    profileStatusSpecificUser:
      state.userManagementReducer.profileStatusSpecificUser,
    rolesList: state.rolesReducer.rolesList,
    updateUserInfoRes: state.userManagementReducer.updateUserInfoRes,
    userDelete: state.userManagementReducer.userDelete,
    departmentList: state.departmentReducer.departmentList,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewUser: bindActionCreators(createNewUser, dispatch),
    onGetUserManagementList: bindActionCreators(getUsersList, dispatch),
    onGetSpecificUserDetail: bindActionCreators(
      getSpecificUserDetail,
      dispatch
    ),
    onGetBlockSpecificUser: bindActionCreators(getBlockSpecificUser, dispatch),
    onGetUnblockSpecificUser: bindActionCreators(
      getUnblockSpecificUser,
      dispatch
    ),
    onGetRolesList: bindActionCreators(getRolesList, dispatch),
    onUpdateUserInfo: bindActionCreators(updateUseInformation, dispatch),
    onProfileStatus: bindActionCreators(updateProfileStatus, dispatch),
    onUserDelete: bindActionCreators(userDelete, dispatch),
    onGetDepartmentList: bindActionCreators(getDepartmentList, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersManagementComp);
