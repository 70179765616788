import React, { useState } from "react";
import { Modal } from "antd";
import Filter from "./Filter";
import List from "./List";
import PublishedAssetForm from "./PublishedAssetsForm";

const inventoryData = [
  {
    name: "Lion King 1",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 2",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 4",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 5",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 6",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 7",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 8",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 9",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 10",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 11",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 12",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 13",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 14",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 15",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
  {
    name: "Lion King 16",
    address: "0x1683199880b3218A8F47E269D56bD730b269f032",
    created_at: "2020-06-05T14:20:51.236Z",
    created_by: "john.doe@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    updated_by: "john.doe@bbc.co.uk",
  },
];

const PublishedAssets = ({
  onGetAssetsList,
  assetsList,

  onGetAssetDetail,
  assetDetail,

  onCreateAsset,
  createAsset,

  onUpdateAsset,
  updateAsset,

  onDeleteAsset,
  deleteAsset,
}) => {
  const [search, setSearch] = useState("");
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);

  const showModal = (modalType) => {
    setVisible(true);
    setModalType(modalType);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <Filter onSearch={onSearch} search={search} />

      <List data={inventoryData} showModal={showModal} />

      <Modal
        title={modalType === "edit" ? "Update Asset" : "New Asset"}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        {visible && (
          <PublishedAssetForm
            handleCancel={handleCancel}
            modalType={modalType}
          />
        )}
      </Modal>
    </div>
  );
};

export default PublishedAssets;
