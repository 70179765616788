import React from "react";
import { Button, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";

const TopTitleSection = ({
  assetDetail,

  titleEdit,
  setTitleEdit,

  assetTitle,
  setAssetTitle,

  updatingAssetTitle,

  userPermissions,
}) => {
  const showTitleEdit = () => {
    setTitleEdit(true);
    setAssetTitle(assetDetail && assetDetail.title && assetDetail.title.value);
  };

  return (
    <div
      className="my-market-inventory"
      style={{ position: "fixed", width: "100%", minHeight: "70px" }}
    >
      <div>
        {assetDetail && (
          <h3>
            {titleEdit && (
              <div className="d-flex align-item-center">
                <Input
                  placeholder="Asset Title"
                  className="width-300 mr10"
                  value={assetTitle}
                  onChange={(e) => setAssetTitle(e.target.value)}
                />
                <Button
                  className="mr5"
                  disabled={assetTitle.length > 0 ? false : true}
                  type="primary"
                  onClick={updatingAssetTitle}
                >
                  Save
                </Button>
                <Button onClick={() => setTitleEdit(false)}>Cancel</Button>
              </div>
            )}
            {!titleEdit && (
              <div className="d-flex align-item-center">
                {assetDetail && assetDetail.title && assetDetail.title.value}
                <EditOutlined
                  className="ml20 pencil-edit"
                  onClick={showTitleEdit}
                />
              </div>
            )}
            {/* {!titleEdit && (
              <div className="d-flex align-item-center">
                {assetDetail && assetDetail.title && assetDetail.title.value}
                {userPermissions &&
                  userPermissions.roles &&
                  userPermissions.roles &&
                  userPermissions.roles[0] &&
                  userPermissions.roles[0].permissions &&
                  userPermissions.roles[0].permissions.includes(
                    "ASSET_METADATA_EDIT"
                  ) && (
                    <EditOutlined
                      className="ml20 pencil-edit"
                      onClick={showTitleEdit}
                    />
                  )}
              </div>
            )} */}
          </h3>
        )}
      </div>
      {/* <div><p className="add-new-selection update-asset"><Button type="primary" className="p0 border-0" >UPDATE ASSET</Button></p></div> */}
    </div>
  );
};

export default TopTitleSection;
