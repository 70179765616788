import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ImportCSV from "../Components/ContentArea/Administration/ImportCSV";

import { uploadCSV, uploadXML } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    csvList: state.uploadCSVReducer.csvList,
    xmlList: state.uploadCSVReducer.xmlList,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadCSV: bindActionCreators(uploadCSV, dispatch),
    onUploadXML: bindActionCreators(uploadXML, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportCSV);
