import React from "react";
import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  onSearch,
  showModal,
  showTableEditor,
  mainUserRes,
}) => {
  return (
    <div>
      <TopTitleSection showModal={showModal} mainUserRes={mainUserRes} />
      <InventoryFilters showTableEditor={showTableEditor} onSearch={onSearch} />
    </div>
  );
};

export default HeaderFilter;
