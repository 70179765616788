import React from "react";

import TopTitleSection from "./TopTitleSection";

const HeaderFilter = ({
  showModal,
  sessionRes,

  organizationRes
}) => {
  return (
    <div>
      <TopTitleSection
        showModal={showModal}
        sessionRes={sessionRes}

        organizationRes={organizationRes}
      />
    </div>
  );
};

export default HeaderFilter;
