import React, { useState } from "react";
import { Form, Button } from "antd";
import { pluseFilledIcon } from "./../Icons";

import CascadingFilterItem from "./CascadingFilterItem";

const selectSeachItem = [
  "Title",
  "Length",
  "Director",
  "Language",
  "Production Company",
  "Production Year",
];

const CascadingSelectFilter = ({
  onFinish,
  onGetInventoryHeader,
  getInventoryHeaderRes,
}) => {
  const [dropdownResult, setDropdownResult] = useState("");
  const [selectedArr, setSelectedArr] = useState([]);
  const [dropdownItems, setDropdownItems] = useState(selectSeachItem);

  const onAddingFilter = (add) => {
    add();
    removeDropDownItems();
  };

  const removeDropDownItems = () => {
    let array = [...dropdownItems];
    if (array && array.length > 0 && dropdownResult) {
      let innerIndex = array.indexOf(dropdownResult);

      array.splice(innerIndex, 1);
      setDropdownItems(array);
      let arr = [...selectedArr];
      arr.push(dropdownResult);
      setSelectedArr(arr);
    }
  };

  const onRemovingFilter = (remove, fieldName, removeSelected) => {
    let array = [...selectedArr];
    if (array && array.length > 0 && removeSelected) {
      let innerIndex = array.indexOf(removeSelected);

      array.splice(innerIndex, 1);
      setSelectedArr(array);
    }

    remove(fieldName);
  };

  return (
    <div className="inventory-select-filter cascading-filter">
      <Form name="cascading_sort_filter" onFinish={onFinish} autoComplete="off">
        <Form.List name="cascading_sort_filter">
          {(fields, { add, remove }) => {
            return (
              <div key={fields.key}>
                {fields.map((field) => (
                  <CascadingFilterItem
                    field={field}
                    remove={remove}
                    key={field.key}
                    dropdownItems={dropdownItems}
                    setDropdownResult={setDropdownResult}
                    selectedArr={selectedArr}
                    onRemovingFilter={onRemovingFilter}
                    onGetInventoryHeader={onGetInventoryHeader}
                    getInventoryHeaderRes={getInventoryHeaderRes}
                  />
                ))}

                <Form.Item>
                  <div className="d-flex">
                    {selectedArr &&
                    selectedArr.length === selectSeachItem.length ? (
                      ""
                    ) : (
                      <p className="add-selection-btn mr60">
                        <Button
                          type="link"
                          className="p0 border-0"
                          onClick={() => onAddingFilter(add)}
                        >
                          {pluseFilledIcon} Add New Level
                        </Button>
                      </p>
                    )}
                    {/* <p className="add-selection-btn"><Button type="link" className="p0 border-0" onClick={() => onAddingFilter(add)} >X Delete Level</Button></p> */}
                  </div>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <div className="cascading-btn">
          <Form.Item>
            <Button type="secondry" htmlType="submit">
              CANCLE
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              APPLY
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default CascadingSelectFilter;
