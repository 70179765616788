import React, { useState, useEffect, Fragment } from "react";
import { exportIcon } from "./../../../../Common/Icons";
import { Radio, Button, Form, Input, message } from "antd";

const SelectionList = ({
  list,
  onGetSelectionDetail,
  selectionDetail,
  onUpdateSelection,
  onGetSelectionsListModal,
}) => {
  const [form] = Form.useForm();
  const [showSelectionForm, setShowSelectionForm] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: selectionDetail && selectionDetail.name,
      type: selectionDetail && selectionDetail.type,
    });
  });

  const onCloseSelectionEdit = (e, value) => {
    e.stopPropagation();
    setShowSelectionForm(value);
  };

  const onShowSelectionedit = (e, value) => {
    e.stopPropagation();
    setShowSelectionForm(value);
    onGetSelectionDetail(list && list._id);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    onUpdateSelection(list && list._id, values).then(() =>
      onGetSelectionsListModal()
    );
    message.success("Successfully Updated");
    setShowSelectionForm(false);
  };

  return (
    <Fragment>
      {showSelectionForm === false && (
        <div className="radio-slection">
          <span className="radion-s-icon">
            {exportIcon} {list.name}{" "}
          </span>
          <Button onClick={(e) => onShowSelectionedit(e, true)} type="link">
            <u>Edit</u>
          </Button>
        </div>
      )}
      {showSelectionForm === true && (
        <div className="edit-selection selection-edit-main">
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="selection-edit-modal">
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Selection name!",
                  },
                ]}
              >
                <Input placeholder="Selection name..." />
              </Form.Item>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please Selection Type!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="sale">Sale</Radio>
                  <Radio value="purchase">Purchase</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="add-select-update">
              <Form.Item>
                <Button type="link" htmlType="submit">
                  Update
                </Button>
                <Button
                  type="link"
                  onClick={(e) => onCloseSelectionEdit(e, false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Fragment>
  );
};

export default SelectionList;
