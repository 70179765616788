import React from "react";
import { List } from "antd";
import { useHistory } from "react-router";
import { getOnlyHoursMinutes } from "./../Common/reUseFunctions";
import moment from "moment";

const Notifications = ({
  notificationFilter,
  onGetNotifications,
  getNotificationsRes,
  onPutNotifications,
}) => {
    let filteredNotRes =   getNotificationsRes.sort(function compare(a, b) {
        let dateA = new Date(a.created_at);
        let dateB = new Date(b.created_at);
        return dateB - dateA;
    });
    const getUnreadNotifications = (filteredNotRes) => {
        let _unreadNotifications = [];
        filteredNotRes && filteredNotRes.map((data, index) => {
            if(data.seen === false)
            {
                _unreadNotifications.push(data)
            }
            else
            {
                //donothing
            }
        } )
        return _unreadNotifications
    }
    const history = useHistory();
  const getNotificationsId = (item) => {
      onPutNotifications(item._id, true).then(() => {
          onGetNotifications();
      });
      if(item.type === "trading-task")
      {
        let _tradingTaskId;
          item && item.ids && item.ids.map((data, index) => {
             if(data.name === "trading_tasks")
             {
                 _tradingTaskId = data.id
             }
             else
             {
                 _tradingTaskId = ""
             }
          })
          let redirectURL = `/trading-task/${_tradingTaskId}`;
          history.push(redirectURL);
          setTimeout(() => {
              document.location.reload()
          }, 1000);
      }
      else if(item.type === "chat")
      {
          let _tradingTaskId;
          item && item.ids && item.ids.map((data, index) => {
              if(data.name === "chat")
              {
                  _tradingTaskId = data.id
              }
              else
              {
                  _tradingTaskId = ""
              }
          })
          let redirectURL = `/trading-task/${_tradingTaskId}/proposal`;
          history.push(redirectURL);
          setTimeout(() => {
              document.location.reload()
          }, 1000);
      }
      else
      {
          history.push('/dashboard')
      }
  };

  return (
    <div className="notifications-list-style">
      <List
        itemLayout="horizontal"
        dataSource={notificationFilter === "All" ? filteredNotRes : getUnreadNotifications(filteredNotRes)}
        renderItem={(item) => (
          <div onClick={() => getNotificationsId(item)}>
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className="notification-avatar">{item.avatar}</div>
                }
                title={<a>{item.message}</a>}
                description={moment(item.created_at).fromNow()}
              />
            </List.Item>
          </div>
        )}
      />
    </div>
  );
};

export default Notifications;
