import React, { Fragment, useState } from 'react';
import {  Radio  } from 'antd';

import AddSelectionList from './SelectionList';

const selectionsList = [
  { name: "Oner" },
  { name: "Internal Identification" },
  { name: "Title" },
  { name: "Alternative Title" },
  { name: "Oner 2" },
  { name: "Internal Identification 2" },
  { name: "Title 2" },
  { name: "Alternative Title 2" },
]

const SelectionList = ({
  addSelectionsList,
  // selectionsList,
  onCreateSelection,
  onGetSelectionsList,
  onUpdateSelection,

  handleCloseSelection,
  setSelectiveColumns,
  handleCancel
}) => {

  const [selectedSelection, setSelectedSelection] = useState("");

  const onChangeSelection = e => {
    setSelectedSelection(e.target.value)
    setSelectiveColumns(e.target.value);
    handleCancel()
  };

  return (
    <Fragment>
      <ul className="selection-radio column-tasks-area slecting-columns"> 

        {
          (selectionsList && selectionsList.length > 0) &&
          selectionsList.map((list, index) => {
            return (
              <li key={index}>
                <Radio.Group onChange={onChangeSelection} value={selectedSelection}>
                <Radio.Button value={list.name}>
                    <AddSelectionList
                      list={list}
                      index={index}
                      onGetSelectionsList={onGetSelectionsList}
                      onUpdateSelection={onUpdateSelection}
                    />
                  </Radio.Button>
                </Radio.Group>
              </li>

            )
          })
        }

      </ul>
    </Fragment>
  )
}

export default SelectionList;
