import React, { useState } from "react";
import { Row, Col, Button, message } from "antd";
import DataMaintenanceFormCom from "./DataMaintenanceForm";
import {
  bigPluse,
  importIcon,
  syncIcon,
  syncIconDisabled,
} from "../../Common/Icons";
import { Link } from "react-router-dom";
import { getRolesPermissions } from "./../../Common/reUseFunctions";

const DataMaintenanceForm = ({
  history,
  search,
  currentPage,
  mainUserRes,
  handleCancel,
  modalType,
  selectedData,
  getAssestsList,
  onCreateAsset,
  createAsset,
  onGetAssetDetail,
  assetDetail,
  onUpdateAsset,
}) => {
  const [showSelectionForm, setShowSelectionForm] = useState(false);

  const showMessage = () => {
    message.error("You Don't Have Permission");
  };
  const onCloseAsset = (value) => {
    setShowSelectionForm(value);
  };
  const onAddAssetForm = (value) => {
    setShowSelectionForm(value);
  };

  return (
    <div>
      {modalType !== "edit" && showSelectionForm === false && (
        <Row gutter={16}>
          {getRolesPermissions(mainUserRes, "asset.create") === false ? (
            <Col span="8">
              <span onClick={() => onAddAssetForm(true)}>
                <div className="add-asset-box">
                  <div>
                    <div className="add-asset-icon">{bigPluse}</div>
                    <p>Add Manually</p>
                  </div>
                </div>
              </span>
            </Col>
          ) : (
            ""
          )}
          <Col span="8">
            <Link to={{ pathname: `/import-assets` }}>
              <div className="add-asset-box">
                <div>
                  <div className="add-asset-icon">{importIcon}</div>
                  <p>Import</p>
                </div>
              </div>
            </Link>
          </Col>
          <Col span="8">
            <span>
              <div className="add-asset-box" style={{ pointerEvents: "none" }}>
                <div>
                  <div className="add-asset-icon">{syncIconDisabled}</div>
                  <p>Synchronize</p>
                </div>
              </div>
            </span>
          </Col>
        </Row>
      )}
      {(showSelectionForm === true || modalType === "edit") && (
        <DataMaintenanceFormCom
          handleCancel={handleCancel}
          modalType={modalType}
          selectedData={selectedData}
          getAssestsList={getAssestsList}
          onCreateAsset={onCreateAsset}
          createAsset={createAsset}
          onGetAssetDetail={onGetAssetDetail}
          assetDetail={assetDetail}
          onUpdateAsset={onUpdateAsset}
          history={history}
          search={search}
          currentPage={currentPage}
          onCloseAsset={onCloseAsset}
        />
      )}
      {modalType !== "edit" && (
        <Row gutter={16}>
          <Col span="24">
            <Button
              onClick={handleCancel}
              className="add-asset-box-btn"
              type="default"
            >
              CANCEL
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DataMaintenanceForm;
