import React, { Fragment } from 'react';
import { Button, Form, DatePicker, TimePicker, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const NegotiationInvitationModal = ({ modelType, handleCancel }) => {


  // const onChangeUser = (value) => {
  //   console.log(`selected ${value}`);
  // }

  // const onSelectUser = (value) => {
  //   console.log(`selected ${value}`);
  // }

  const onFinish = values => {
    console.log('Received values of form: ', values);
  };

  return (
    <div className="license-edit-model request-p-modal negotiation-modal">
      <h3>Negotiation Invitation</h3>
      <h4 className="mb0">TOONZ MEDIA GROUP</h4>
      <div className="negotiation-time">
        <span>Tokyo, Japan (GMT+9)</span>
        <span>Tokyo's' Local Time 2:32AM</span>
        <span>Mon-Fri: 9AM to 5PM</span>
      </div>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
      >
        <div className="slection-asset-view mb0">
          <div className="formal-license-view">

            <Fragment>
              <h5>TIME SLOT 1</h5>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="r-p-bg">
                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>SET TIME</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>SET DATE</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="r-p-bg">
                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>TOONZ MEDIA TIME</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>TOONZ MEDIA DATE</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Fragment>

            <Fragment>
              <h5>TIME SLOT 2</h5>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="r-p-bg">
                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>SET TIME</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>SET DATE</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="r-p-bg">
                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>TOONZ MEDIA TIME</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="formal-license-row">
                      <div className="formal-license-name">
                        <span>TOONZ MEDIA DATE</span>
                      </div>
                      <div className="formal-license-data">
                        <Form.Item
                          name='first'
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

            </Fragment>

            <Form.List name="users">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map(field => (
                      <Fragment>
                        <h5>TIME SLOT 1</h5>
                        <Row gutter={16}>
                          <Col span={12}>
                            <div className="r-p-bg">
                              <div className="formal-license-row">
                                <div className="formal-license-name">
                                  <span>SET TIME</span>
                                </div>
                                <div className="formal-license-data">
                                  <Form.Item
                                    name={[field.name, 'first']}
                                    fieldKey={[field.fieldKey, 'first']}
                                    rules={[{ required: true, message: 'Missing first name' }]}
                                  >
                                    <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="formal-license-row">
                                <div className="formal-license-name">
                                  <span>SET DATE</span>
                                </div>
                                <div className="formal-license-data">
                                  <Form.Item
                                    name={[field.name, 'first']}
                                    fieldKey={[field.fieldKey, 'first']}
                                    rules={[{ required: true, message: 'Missing first name' }]}
                                  >
                                    <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className="r-p-bg">
                              <div className="formal-license-row">
                                <div className="formal-license-name">
                                  <span>TOONZ MEDIA TIME</span>
                                </div>
                                <div className="formal-license-data">
                                  <Form.Item
                                    name={[field.name, 'first']}
                                    fieldKey={[field.fieldKey, 'first']}
                                    rules={[{ required: true, message: 'Missing first name' }]}
                                  >
                                    <TimePicker defaultValue={moment('12:08:23', 'HH:mm:ss')} />
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="formal-license-row">
                                <div className="formal-license-name">
                                  <span>TOONZ MEDIA DATE</span>
                                </div>
                                <div className="formal-license-data">
                                  <Form.Item
                                    name={[field.name, 'first']}
                                    fieldKey={[field.fieldKey, 'first']}
                                    rules={[{ required: true, message: 'Missing first name' }]}
                                  >
                                    <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <MinusCircleOutlined
                          className="negotiation-close"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Fragment>
                    ))}

                    <Form.Item
                      className="mb0"
                    >

                      <p className="add-selection-btn">
                        <Button
                          type="link"
                          className="p0 border-0"
                          onClick={() => {
                            add();
                          }} ><PlusOutlined /> Add New Time Slot</Button>
                      </p>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
        </div>
        <div className="invitation-party-text">
          <p>
            We will let you know once the other party accept the invitation.
          </p>
        </div>
        <div className="formal-license-btn">
          <div className="r-p-btn">
            <Button
              onClick={handleCancel}
            >
              CANCEL
              </Button>

            <Button
              type="primary"
              onClick={() => handleCancel("show_chat")}
            >
              SEND INVITATION
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default NegotiationInvitationModal;