export const multiLanguages = [
  {
    value: 'non-linguistic',
    name: 'Non Linguistic'
  },
  {
    value: "ab",
    name: "Abkhaz"
  },
  {
    value: "aa",
    name: "Afar"
  },
  {
    value: "af",
    name: "Afrikaans"
  },
  {
    value: "ak",
    name: "Akan"
  },
  {
    value: "sq",
    name: "Albanian"
  },
  {
    value: "am",
    name: "Amharic"
  },
  {
    value: "ar",
    name: "Arabic"
  },
  {
    value: "an",
    name: "Aragonese"
  },
  {
    value: "hy",
    name: "Armenian"
  },
  {
    value: "as",
    name: "Assamese"
  },
  {
    value: "av",
    name: "Avaric"
  },
  {
    value: "ae",
    name: "Avestan"
  },
  {
    value: "ay",
    name: "Aymara"
  },
  {
    value: "az",
    name: "Azerbaijani"
  },
  {
    value: "bm",
    name: "Bambara"
  },
  {
    value: "ba",
    name: "Bashkir"
  },
  {
    value: "eu",
    name: "Basque"
  },
  {
    value: "be",
    name: "Belarusian"
  },
  {
    value: "bn",
    name: "Bengali; Bangla"
  },
  {
    value: "bh",
    name: "Bihari"
  },
  {
    value: "bi",
    name: "Bislama"
  },
  {
    value: "bs",
    name: "Bosnian"
  },
  {
    value: "br",
    name: "Breton"
  },
  {
    value: "bg",
    name: "Bulgarian"
  },
  {
    value: "my",
    name: "Burmese"
  },
  {
    value: "ca",
    name: "Catalan; Valencian"
  },
  {
    value: "ch",
    name: "Chamorro"
  },
  {
    value: "ce",
    name: "Chechen"
  },
  {
    value: "ny",
    name: "Chichewa; Chewa; Nyanja"
  },
  {
    value: "zh",
    name: "Chinese"
  },
  {
    value: "cv",
    name: "Chuvash"
  },
  {
    value: "kw",
    name: "Cornish"
  },
  {
    value: "co",
    name: "Corsican"
  },
  {
    value: "cr",
    name: "Cree"
  },
  {
    value: "hr",
    name: "Croatian"
  },
  {
    value: "cs",
    name: "Czech"
  },
  {
    value: "da",
    name: "Danish"
  },
  {
    value: "dv",
    name: "Divehi; Dhivehi; Maldivian;"
  },
  {
    value: "nl",
    name: "Dutch"
  },
  {
    value: "dz",
    name: "Dzongkha"
  },
  {
    value: "en",
    name: "English"
  },
  {
    value: "eo",
    name: "Esperanto"
  },
  {
    value: "et",
    name: "Estonian"
  },
  {
    value: "ee",
    name: "Ewe"
  },
  {
    value: "fo",
    name: "Faroese"
  },
  {
    value: "fj",
    name: "Fijian"
  },
  {
    value: "fi",
    name: "Finnish"
  },
  {
    value: "fr",
    name: "French"
  },
  {
    value: "ff",
    name: "Fula; Fulah; Pulaar; Pular"
  },
  {
    value: "gl",
    name: "Galician"
  },
  {
    value: "ka",
    name: "Georgian"
  },
  {
    value: "de",
    name: "German"
  },
  {
    value: "el",
    name: "Greek, Modern"
  },
  {
    value: "gn",
    name: "GuaranÃ­"
  },
  {
    value: "gu",
    name: "Gujarati"
  },
  {
    value: "ht",
    name: "Haitian; Haitian Creole"
  },
  {
    value: "ha",
    name: "Hausa"
  },
  {
    value: "he",
    name: "Hebrew (modern)"
  },
  {
    value: "hz",
    name: "Herero"
  },
  {
    value: "hi",
    name: "Hindi"
  },
  {
    value: "ho",
    name: "Hiri Motu"
  },
  {
    value: "hu",
    name: "Hungarian"
  },
  {
    value: "ia",
    name: "Interlingua"
  },
  {
    value: "id",
    name: "Indonesian"
  },
  {
    value: "ie",
    name: "Interlingue"
  },
  {
    value: "ga",
    name: "Irish"
  },
  {
    value: "ig",
    name: "Igbo"
  },
  {
    value: "ik",
    name: "Inupiaq"
  },
  {
    value: "io",
    name: "Ido"
  },
  {
    value: "is",
    name: "Icelandic"
  },
  {
    value: "it",
    name: "Italian"
  },
  {
    value: "iu",
    name: "Inuktitut"
  },
  {
    value: "ja",
    name: "Japanese"
  },
  {
    value: "jv",
    name: "Javanese"
  },
  {
    value: "kl",
    name: "Kalaallisut, Greenlandic"
  },
  {
    value: "kn",
    name: "Kannada"
  },
  {
    value: "kr",
    name: "Kanuri"
  },
  {
    value: "ks",
    name: "Kashmiri"
  },
  {
    value: "kk",
    name: "Kazakh"
  },
  {
    value: "km",
    name: "Khmer"
  },
  {
    value: "ki",
    name: "Kikuyu, Gikuyu"
  },
  {
    value: "rw",
    name: "Kinyarwanda"
  },
  {
    value: "ky",
    name: "Kyrgyz"
  },
  {
    value: "kv",
    name: "Komi"
  },
  {
    value: "kg",
    name: "Kongo"
  },
  {
    value: "ko",
    name: "Korean"
  },
  {
    value: "ku",
    name: "Kurdish"
  },
  {
    value: "kj",
    name: "Kwanyama, Kuanyama"
  },
  {
    value: "la",
    name: "Latin"
  },
  {
    value: "lb",
    name: "Luxembourgish, Letzeburgesch"
  },
  {
    value: "lg",
    name: "Ganda"
  },
  {
    value: "li",
    name: "Limburgish, Limburgan, Limburger"
  },
  {
    value: "ln",
    name: "Lingala"
  },
  {
    value: "lo",
    name: "Lao"
  },
  {
    value: "lt",
    name: "Lithuanian"
  },
  {
    value: "lu",
    name: "Luba-Katanga"
  },
  {
    value: "lv",
    name: "Latvian"
  },
  {
    value: "gv",
    name: "Manx"
  },
  {
    value: "mk",
    name: "Macedonian"
  },
  {
    value: "mg",
    name: "Malagasy"
  },
  {
    value: "ms",
    name: "Malay"
  },
  {
    value: "ml",
    name: "Malayalam"
  },
  {
    value: "mt",
    name: "Maltese"
  },
  {
    value: "mi",
    name: "MÄori"
  },
  {
    value: "mr",
    name: "Marathi (MarÄá¹­hÄ«)"
  },
  {
    value: "mh",
    name: "Marshallese"
  },
  {
    value: "mn",
    name: "Mongolian"
  },
  {
    value: "na",
    name: "Nauru"
  },
  {
    value: "nv",
    name: "Navajo, Navaho"
  },
  {
    value: "nb",
    name: "Norwegian BokmÃ¥l"
  },
  {
    value: "nd",
    name: "North Ndebele"
  },
  {
    value: "ne",
    name: "Nepali"
  },
  {
    value: "ng",
    name: "Ndonga"
  },
  {
    value: "nn",
    name: "Norwegian Nynorsk"
  },
  {
    value: "no",
    name: "Norwegian"
  },
  {
    value: "ii",
    name: "Nuosu"
  },
  {
    value: "nr",
    name: "South Ndebele"
  },
  {
    value: "oc",
    name: "Occitan"
  },
  {
    value: "oj",
    name: "Ojibwe, Ojibwa"
  },
  {
    value: "cu",
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic"
  },
  {
    value: "om",
    name: "Oromo"
  },
  {
    value: "or",
    name: "Oriya"
  },
  {
    value: "os",
    name: "Ossetian, Ossetic"
  },
  {
    value: "pa",
    name: "Panjabi, Punjabi"
  },
  {
    value: "pi",
    name: "PÄli"
  },
  {
    value: "fa",
    name: "Persian (Farsi)"
  },
  {
    value: "pl",
    name: "Polish"
  },
  {
    value: "ps",
    name: "Pashto, Pushto"
  },
  {
    value: "pt",
    name: "Portuguese"
  },
  {
    value: "qu",
    name: "Quechua"
  },
  {
    value: "rm",
    name: "Romansh"
  },
  {
    value: "rn",
    name: "Kirundi"
  },
  {
    value: "ro",
    name: "Romanian, [])"
  },
  {
    value: "ru",
    name: "Russian"
  },
  {
    value: "sa",
    name: "Sanskrit (Saá¹ská¹›ta)"
  },
  {
    value: "sc",
    name: "Sardinian"
  },
  {
    value: "sd",
    name: "Sindhi"
  },
  {
    value: "se",
    name: "Northern Sami"
  },
  {
    value: "sm",
    name: "Samoan"
  },
  {
    value: "sg",
    name: "Sango"
  },
  {
    value: "sr",
    name: "Serbian"
  },
  {
    value: "gd",
    name: "Scottish Gaelic; Gaelic"
  },
  {
    value: "sn",
    name: "Shona"
  },
  {
    value: "si",
    name: "Sinhala, Sinhalese"
  },
  {
    value: "sk",
    name: "Slovak"
  },
  {
    value: "sl",
    name: "Slovene"
  },
  {
    value: "so",
    name: "Somali"
  },
  {
    value: "st",
    name: "Southern Sotho"
  },
  {
    value: "az",
    name: "South Azerbaijani"
  },
  {
    value: "es",
    name: "Spanish; Castilian"
  },
  {
    value: "su",
    name: "Sundanese"
  },
  {
    value: "sw",
    name: "Swahili"
  },
  {
    value: "ss",
    name: "Swati"
  },
  {
    value: "sv",
    name: "Swedish"
  },
  {
    value: "ta",
    name: "Tamil"
  },
  {
    value: "te",
    name: "Telugu"
  },
  {
    value: "tg",
    name: "Tajik"
  },
  {
    value: "th",
    name: "Thai"
  },
  {
    value: "ti",
    name: "Tigrinya"
  },
  {
    value: "bo",
    name: "Tibetan Standard, Tibetan, Central"
  },
  {
    value: "tk",
    name: "Turkmen"
  },
  {
    value: "tl",
    name: "Tagalog"
  },
  {
    value: "tn",
    name: "Tswana"
  },
  {
    value: "to",
    name: "Tonga (Tonga Islands)"
  },
  {
    value: "tr",
    name: "Turkish"
  },
  {
    value: "ts",
    name: "Tsonga"
  },
  {
    value: "tt",
    name: "Tatar"
  },
  {
    value: "tw",
    name: "Twi"
  },
  {
    value: "ty",
    name: "Tahitian"
  },
  {
    value: "ug",
    name: "Uyghur, Uighur"
  },
  {
    value: "uk",
    name: "Ukrainian"
  },
  {
    value: "ur",
    name: "Urdu"
  },
  {
    value: "uz",
    name: "Uzbek"
  },
  {
    value: "ve",
    name: "Venda"
  },
  {
    value: "vi",
    name: "Vietnamese"
  },
  {
    value: "vo",
    name: "VolapÃ¼k"
  },
  {
    value: "wa",
    name: "Walloon"
  },
  {
    value: "cy",
    name: "Welsh"
  },
  {
    value: "wo",
    name: "Wolof"
  },
  {
    value: "fy",
    name: "Western Frisian"
  },
  {
    value: "xh",
    name: "Xhosa"
  },
  {
    value: "yi",
    name: "Yiddish"
  },
  {
    value: "yo",
    name: "Yoruba"
  },
  {
    value: "za",
    name: "Zhuang, Chuang"
  },
  {
    value: "zu",
    name: "Zulu"
  }
]