import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  TimePicker,
  Input,
  Button,
  message,
} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { TextArea } = Input;

const RequestProposalForm = ({
  onCreateTradingList,
  selectionAssetsListRes,
  selectionDetail,
  data,
  history,
  userManagementList,
  getUserListRes,
  mainUserRes,
  departmentsWithUsersRes,
  organizationRes,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [approvals, setApprovals] = useState([]);
  const [posted, setPosted] = useState(false);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const handleApprovals = (approval, index) => {
    const newApprovals = [...approvals];
    newApprovals[index] = approval;
    setApprovals([...newApprovals]);
  };

  const showPermissionMessage = () => {
    message.error("You Don't Have the Permission");
  };

  const assetDataFunc = () => {
    let assetArray = [];
    data.assets.map((items) =>
      assetArray.push({
        id: items.id,
        title: items.name,
        formal_license_definitions: items.licenses,
      })
    );
    return assetArray;
  };

  const onFinish = (values) => {
    const tradingbody = {
      name: selectionDetail && selectionDetail.name,
      selection_created_by:
        selectionDetail &&
        selectionDetail.created_by &&
        selectionDetail.created_by.username,
      type: selectionDetail && selectionDetail.type,
      company_title: data && data.name,
      company_id: data && data.tas_id,
      comments: values && values.comments,
      assets: assetDataFunc(),
      assigned_to: values && values.assign_task,
      created_by_company_id:
        organizationRes && organizationRes.trading_aggregation_organization_id,
      approvals,
    };

    if (values.date && values.time) {
      let newDate = new Date(values.date);
      let newTime = new Date(values.time);
      let hours = newTime.getHours(); // => 9
      let mints = newTime.getMinutes(); // =>  30
      let seconds = newTime.getSeconds(); // => 51

      newDate.setHours(hours);
      newDate.setMinutes(mints);
      newDate.setSeconds(seconds);

      // newDate.setTime(newTime.getTime());

      tradingbody.deadline = newDate;
    }

    if (getRolesPermissions(mainUserRes, "trading-task.create") === false) {
      onCreateTradingList(tradingbody).then((res) => {
        message.success(
          res && res.payload && res.payload.data && res.payload.data.message
        );
        setPosted(true);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (time, timeString) => {
    console.log(time, timeString);
  };

  return (
    <div className="request-proposal-form">
      <Form
        name="request_proposal_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <div>
              <h4 className="mb0">ASSIGN TASK</h4>
            </div>
            <div className="request-proposal-row">
              <div className="proposal-field-name">
                <h3 className="">
                  <span>SELECT USER</span>
                </h3>
              </div>
              <div className="request-proposal-field">
                <Form.Item
                  name="assign_task"
                  rules={[
                    { required: true, message: "Please select your user!" },
                  ]}
                >
                  <Select
                    placeholder="Select..."
                    value={selectedItems}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    {getUserListRes &&
                      getUserListRes.users &&
                      getUserListRes.users.length > 0 &&
                      getUserListRes.users.map((item) => (
                        <Select.Option
                          key={item.tas_user_id}
                          disabled={!item.tas_user_id ? true : false}
                          value={item.tas_user_id}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <h4 className="mb0">SET DEADLINE</h4>
            </div>
            <div className="request-proposal-row dead-line">
              <div className="proposal-field-name proposal-date-field">
                <Form.Item
                  name="date"
                  rules={[
                    { required: true, message: "Please select your date!" },
                  ]}
                >
                  <DatePicker format={dateFormatList} />
                </Form.Item>
              </div>
              <div className="request-proposal-field">
                <Form.Item
                  name="time"
                  rules={[
                    { required: true, message: "Please select your time!" },
                  ]}
                >
                  <TimePicker use12Hours format="h:mm A" onChange={onChange} />
                </Form.Item>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="request-proposal-row">
              <Form.Item
                name="comments"
                rules={[
                  { required: true, message: "Please select your comments!" },
                ]}
                className="full-width"
              >
                <TextArea
                  // onChange={onChangeTextArea}
                  autoSize={{ minRows: 4, maxRows: 4 }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <div>
              <h4 className="mb0">APPROVALS</h4>
            </div>
            {departmentsWithUsersRes &&
              departmentsWithUsersRes.departments &&
              departmentsWithUsersRes.departments.map((d, index) => (
                <div className="request-proposal-row" key={`item${d._id}`}>
                  <div className="proposal-field-name">
                    <h3 className="">
                      <span>{d.name}</span>
                    </h3>
                  </div>
                  <div className="request-proposal-field">
                    <Form.Item
                      key={d._id}
                      rules={[
                        {
                          required: false,
                          message: "Please select your user!",
                        },
                      ]}
                    >
                      <Select
                        key={`select-${d._id}`}
                        placeholder="Select..."
                        onChange={(id) => {
                          const user = d.users.filter(
                            (user) => user._id.toString() === id.toString()
                          )[0];

                          handleApprovals(
                            {
                              department: d.name,
                              contact_id: user._id,
                              user: `${user.first_name} ${user.last_name}`,
                            },
                            index
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        {d.users &&
                          d.users.map((user) => (
                            <Select.Option key={user._id} value={user._id}>
                              {`${user.first_name} ${user.last_name}`}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              ))}

            {/* <div className="request-proposal-row">
              <div className="proposal-field-name"><h3 className=""><span>FINANCE</span></h3></div>
              <div className="request-proposal-field">
                <Form.Item
                  name="assign_task"
                  rules={[{ required: false, message: 'Please select your user!' }]}
                >
                  <Select
                    placeholder="Select..."
                    value={selectedItems}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    getPopupContainer={trigger => trigger.parentElement}
                  >
                    {Options.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>

          <div className="request-proposal-row">
              <div className="proposal-field-name"><h3 className=""><span>STRATEGY</span></h3></div>
              <div className="request-proposal-field">
                <Form.Item
                  name="assign_task"
                  rules={[{ required: false, message: 'Please select your user!' }]}
                >
                  <Select
                    placeholder="Select..."
                    value={selectedItems}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                    getPopupContainer={trigger => trigger.parentElement}
                  >
                    {Options.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div> */}
          </Col>
          {/* <Col span={12}>
            {JSON.stringify(approvals)}
          </Col> */}
          {/* <Col span={12}>
            <div className="request-approvals-btns">
              <div>
                <Button type="primary">REQUEST APPROVALS</Button>
                <Button className="req" disabled type="secondary">REQUESTED</Button>
                <Button className="apr" type="secondary">APPROVED</Button>
                
              </div>
            </div>
          </Col> */}
          {getRolesPermissions(mainUserRes, "trading-task.create") === false ? (
            <Col span={24}>
              <div className="proceed-btns">
                <Button htmlType="submit" type="secondary" disabled={posted}>
                  PROCEED <ArrowRightOutlined />
                </Button>
              </div>
            </Col>
          ) : (
            <Col span={24}>
              <div className="proceed-btns">
                <Button
                  type="secondary"
                  onClick={() => showPermissionMessage()}
                >
                  PROCEED <ArrowRightOutlined />
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default RequestProposalForm;
