import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InventorySearchComp from "../Components/ContentArea/LicenseTrading/InventorySearch";

import {
  getAssetsList,
  getAssetDetail,
  getMarketReady,
  getInventoryList,
  addAssetInSelection,
  updateInventoryHeader,
  getInventoryHeader,
  getInventorySeachDetail,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    assetsList: state.assetReducer.assetsList,
    assetDetail: state.assetReducer.assetDetail,
    marketList: state.inventorySearchReducer.marketList,
    sessionRes: state.userReducer.sessionRes,
    inventoryListRes: state.inventorySearchReducer.inventoryListRes,
    addAssetInSelectionRes: state.inventorySearchReducer.addAssetInSelectionRes,
    updateInventoryHeaderRes:
      state.inventorySearchReducer.updateInventoryHeaderRes,
    getInventoryHeaderRes: state.inventorySearchReducer.getInventoryHeaderRes,
    getInventorySeachDetailRes:
      state.inventorySearchReducer.getInventorySeachDetailRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAssetsList: bindActionCreators(getAssetsList, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onGetMarketReady: bindActionCreators(getMarketReady, dispatch),
    onGetInventoryList: bindActionCreators(getInventoryList, dispatch),
    onAddAssetInSelection: bindActionCreators(addAssetInSelection, dispatch),
    onUpdateInventoryHeader: bindActionCreators(
      updateInventoryHeader,
      dispatch
    ),
    onGetInventoryHeader: bindActionCreators(getInventoryHeader, dispatch),
    onGetInventorySeachDetail: bindActionCreators(
      getInventorySeachDetail,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventorySearchComp);
