import React from 'react';
import { Button } from 'antd';
import { ImportOutlined, ExportOutlined } from '@ant-design/icons';

const TopTitleSection = ({
  onChangeSelections,
  selection,
  showModal,
  selectionsList,
  selectionDetail
}) => {

  return (
    <div className="my-market-inventory" style={{ position: "fixed", width: "calc(100% - 300px)" }}>
      {
        selection === "selection-sales" ?
        <div><h3><ImportOutlined />{selectionDetail && selectionDetail.name}</h3></div> :
        <div><h3><ExportOutlined />{selectionDetail && selectionDetail.name}</h3></div>
      }
      {
        selection === "selection-sales" ?
        selectionDetail && selectionDetail.assets && selectionDetail.assets.length > 0 ?
          <div className="selection-header-btn">
            <Button onClick={showModal} type="default">STRAIGHT SALES</Button>
            {/* <Button type="default">AUCTION SALES</Button>
            <Button type="default">BIDDING PROGRESS SALES</Button> */}
          </div> : ""
           :
          <div className="header-btn-groups import-asset-menu">
          <Button className="mr20 active" type="link">Select Rights</Button>
          <Button className="mr20" type="link">Request Proposal</Button>
          <Button className="mr20" type="link">Negotiation Room</Button>
          <Button className="mr20" type="link">Closing Room</Button>
        </div>

      }
    </div>
  )
}

export default TopTitleSection;