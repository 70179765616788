import React, { Component } from "react";
import { Button } from "antd";
import { DragDropContext } from "react-beautiful-dnd";

import Column from "./Columns";

import initialData from "../../../../statics/data/initialTableEditorData.js";

class TableDragDrop extends Component {
  state = initialData;

  componentDidMount() {
    this.props.onGetInventoryHeader()
    // this.props.onUpdateInventoryHeader
  }

  onDragStart = (result) => {
    console.log(result);
  };

  onDragUpdate = (result) => {
    console.log(result);
  };

  onDragEnd = (result) => {
    const { draggableId, source, destination, type } = result;
    const { columns, columnOrder } = this.state;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === "columns") {
      const newColumnOrder = Array.from(columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...this.state,
        columnOrder: newColumnOrder,
      };

      this.setState(newState);
      // message.success('Column Reordered Successfully');
      return;
    }

    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    // Moving tasks in same columns
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...columns,
          [newColumn.id]: newColumn,
        },
      };

      this.setState(newState);
      // message.success('Tasks Reordered Successfully');
      return;
    }

    // Moving tasks from one column to other columns
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);

    const newStart = {
      ...start,
      taskIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    this.setState(newState);
    // message.success('Tasks Reordered Successfully');
  };

  resetColumns = () => {
    this.setState(initialData);
  };

  render() {
    const { tasks, columns, columnOrder } = this.state;

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        <div className="table-edit-row">
          <Button
            type="link"
            className="reset-default-btn"
            onClick={this.resetColumns}
          >
            <u>Reset to Default</u>
          </Button>
          {columnOrder.map((columnId, index) => {
            const column = columns[columnId];
            const task = column.taskIds.map((taskId) => tasks[taskId]);
            return (
              <Column
                key={column.id}
                column={column}
                task={task}
                index={index}
              />
            );
          })}
        </div>
      </DragDropContext>
    );
  }
}

export default TableDragDrop;
