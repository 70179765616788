import React, { useState } from 'react';
import Filter from './Filter';
import SelectionList from './List';
import DeletComfirmationmodal from './../Components/DeleteConfirmation';

const ColumnsMappingPreset = ({
  onGetSelectionsList,
  selectionsList,

  onGetSelectionDetail,
  selectionDetail,

  onCreateSelection,
  createSelection,

  onUpdateSelection,
  updateSelection,

  onDeleteSelection,
  deleteSelection,

  selectionType,
  onClickSelection,

  handleCancel,
  ViewSearchPreset

}) => {
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);

  const onShowModal = (e) => {
    setVisible(true);
  }

  const handleModalCancel = (e) => {
    setVisible(false);
  }

  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}
        ViewSearchPreset={ViewSearchPreset}
      />
      <SelectionList
        selectionsList={selectionsList}
        onCreateSelection={onCreateSelection}
        onGetSelectionsList={onGetSelectionsList}
        onUpdateSelection={onUpdateSelection}

        handleCancel={handleCancel}
        onShowModal={onShowModal}
      />
      <DeletComfirmationmodal visible={visible} handleModalCancel={handleModalCancel} />
    </div>
  )
}

export default ColumnsMappingPreset;