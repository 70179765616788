import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Modal, Spin, message } from "antd";
import HeaderFilter from "./HeaderFilter";
import AddEditCatalogue from "../SpecialCatalogues/AddEditCatalogue";
import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";

import HeaderComponent from "./../../../../Containers/Header";
import ProfileSection from "./ProfileSection";
import SpecialAndGeneralCatalogues from "./SpecialAndGeneralCatalogues";

import { ArrowLeftOutlined } from "@ant-design/icons";

const SpecialCataloguesDetail = ({
  onGetSpecialCatalogue,
  onDeleteSpecialCatalogue,
  onUpdateSpecialCatalogue,
  getSpecificUserDetail,
  onGetSpecificUserDetail,
  specificUserImage,
  onGetSpecificUserImage,
  mainUserRes,
  onGetMainUserDetail,
  onLogout,
  onGetInventory,
  onAddAssetToSpecialCatalogue,
  onDeleteAssetFromSpecialCatalogue,
  onGetNotifications,
  getNotificationsRes,
}) => {
  const [catalogue, setCatalogue] = useState({});
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ visible: false });
  const { id } = useParams();
  const history = useHistory();
  const [visibleCatalogue, setVisibleCatalogue] = useState(false);

  const showCatalogue = (value, id) => {
    setVisibleCatalogue(true);
  };

  const cancelCatalogue = (e) => {
    setVisibleCatalogue(false);
  };

  useEffect(() => {
    onGet();
  }, [id]);

  const onGet = () => {
    setLoading(true);
    onGetSpecialCatalogue(id)
      .then(({ payload }) => {
        const specialAssets = payload.special_catalogue.assets;

        setCatalogue(payload.special_catalogue);
        setAssets(specialAssets);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // const onCreate = (data) => {
  //   setLoading(true)

  //   onCreateSpecialCatalogue(data).then((response) => {
  //     setLoading(false);
  //     setModal({visible: false});
  //     onGet(skip, 9);
  //     message.success(response.payload.message);
  //   }).catch((err) => {
  //     setLoading(false);
  //     message.error('Try again.');
  //   })
  // }

  const onUpdate = (data, id) => {
    setLoading(true);

    onUpdateSpecialCatalogue(id, data)
      .then((response) => {
        setLoading(false);
        setModal({ visible: false });
        onGet(id);
        message.success(response.payload.message);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Try again.");
      });
  };

  const onDelete = (id) => {
    setLoading(true);

    onDeleteSpecialCatalogue(id)
      .then((response) => {
        setLoading(false);
        setModal({ visible: false });
        message.success(response.payload.message);

        history.push("/special-catalogues");
      })
      .catch((err) => {
        setLoading(false);
        message.error("Try again.");
      });
  };

  const handleCancel = () => {
    setModal({ visible: false });
  };

  return (
    <div>
      <HeaderFilter
        mainUserRes={mainUserRes}
        onShowModal={setModal}
        catalogue={catalogue}
      />

      <Spin size="large" spinning={loading}>
        {catalogue && (
          <ProfileSection
            mainUserRes={mainUserRes}
            catalogue={catalogue}
            showCatalogue={showCatalogue}
          />
        )}
      </Spin>

      {modal.visible &&
        (modal.type && modal.type === "delete-catalogue" ? (
          <DeleteConfirmation
            visible={modal.visible}
            handleCancel={handleCancel}
            selectedData={modal.payload}
            onDelete={() => {}}
            onDeleteItem={onDelete}
          />
        ) : (
          <AddEditCatalogue
            loading={loading}
            onGet={onGet}
            onUpdate={onUpdate}
            onDelete={onDelete}
            modal={modal}
            handleCancel={handleCancel}
          />
        ))}
      {visibleCatalogue && (
        <Modal
          visible={visibleCatalogue}
          onCancel={cancelCatalogue}
          footer={false}
          className="modal-trading"
          width="100%"
        >
          <div className="app-header-trading-task">
            <HeaderComponent
              getSpecificUserDetail={getSpecificUserDetail}
              onGetSpecificUserDetail={onGetSpecificUserDetail}
              specificUserImage={specificUserImage}
              onGetSpecificUserImage={onGetSpecificUserImage}
              mainUserRes={mainUserRes}
              onGetMainUserDetail={onGetMainUserDetail}
              onLogout={onLogout}
              onGetNotifications={onGetNotifications}
              getNotificationsRes={getNotificationsRes}
            />
          </div>
          <div className="trading-task-detail ">
            <Button
              type="link"
              className="back-button m0"
              onClick={cancelCatalogue}
            >
              <ArrowLeftOutlined /> Back to {catalogue.name} Catalogue
            </Button>
            <SpecialAndGeneralCatalogues
              assets={assets}
              onGetInventory={onGetInventory}
              onGetSpecialCatalogue={onGet}
              onAddAssetToSpecialCatalogue={onAddAssetToSpecialCatalogue}
              onDeleteAssetFromSpecialCatalogue={
                onDeleteAssetFromSpecialCatalogue
              }
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SpecialCataloguesDetail;
