import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { multiLanguages } from "./../../../../../statics/data/multiLanguages";

const PromotionalMaterial = ({ helperText, requiredField }) => {
  const [selectedLang, setSelectedLang] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const onChangeLanguage = (value) => {
    setSelectedLang(value);
  };

  return (
    <Row
      gutter={16}
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >
      <Col className="pl0_important" span={24}>
        <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Promotional Material</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>
              Provide press reviews or other references and images that promote
              your asset.
            </p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <div className="metadata-field-box">
            <Row gutter={16} className="mb20">
              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name">
                    <h3 className="">
                      <span>Reviews</span>
                    </h3>
                  </div>
                  <div className="metadata-filed">
                    <Form.List name="reviews">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field) => (
                              <div
                                key={field.key}
                                className="d-flex align-item-center mb15"
                              >
                                <Form.Item
                                  {...field}
                                  name={[field.name, "value"]}
                                  fieldKey={[field.fieldKey, "value"]}
                                  className="full-width mr20 mb0"
                                >
                                  <Input
                                    placeholder="Review"
                                    className="full-width"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, "language"]}
                                  fieldKey={[field.fieldKey, "language"]}
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        "[a-z]{2}(-[A-Z]{2})?"
                                      ),
                                      message:
                                        "Please enter the correct language!",
                                    },
                                  ]}
                                  className="full-width mr20 mb0"
                                >
                                  <Select
                                    placeholder="Select language"
                                    value={selectedLang}
                                    onChange={onChangeLanguage}
                                    style={{ width: "100%" }}
                                    showSearch
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                  >
                                    {multiLanguages.map((item, index) => (
                                      <Select.Option
                                        key={index}
                                        value={item.value}
                                      >
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>

                                <MinusCircleOutlined
                                  className="ml10 remove-circle-tow"
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              </div>
                            ))}

                            <Form.Item>
                              <Button
                                type="link"
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> Add Reviews
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name">
                    <h3 className="">
                      <span>Press Clip</span>
                    </h3>
                  </div>
                  <div className="metadata-filed">
                    <Form.List name="pressClip">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field) => (
                              <div
                                key={field.key}
                                className="d-flex align-item-center mb15"
                              >
                                <Form.Item
                                  {...field}
                                  name={[field.name, "title"]}
                                  fieldKey={[field.fieldKey, "title"]}
                                  className="full-width mr20 mb0"
                                >
                                  <Input
                                    placeholder="Title"
                                    className="full-width"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, "value"]}
                                  validateTrigger={["onChange", "onBlur"]}
                                  className="full-width mr20 mb0"
                                >
                                  <Input placeholder="Press Clip" />
                                </Form.Item>

                                <MinusCircleOutlined
                                  className="ml10 remove-circle-tow"
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              </div>
                            ))}

                            <Form.Item>
                              <Button
                                type="link"
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> Add Press Clip
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name">
                    <h3 className="">
                      <span>Title Images</span>
                    </h3>
                    <p className="mb0">
                      First image will be picked as a main image
                    </p>
                  </div>
                  <div className="metadata-filed">
                    <Form.List name="titleImage">
                      {(fields, { add, remove }) => {
                        return (
                          <div>
                            {fields.map((field) => (
                              <div
                                key={field.key}
                                className="d-flex align-item-center mb15"
                              >
                                <Form.Item
                                  {...field}
                                  name={[field.name, "title"]}
                                  fieldKey={[field.fieldKey, "title"]}
                                  className="full-width mr20 mb0"
                                >
                                  <Input
                                    placeholder="Title"
                                    className="full-width"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, "value"]}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      pattern: new RegExp("https?://.+"),
                                      message: "Please enter the https://!",
                                    },
                                  ]}
                                  className="full-width mr20 mb0"
                                >
                                  <Input placeholder="Image Link" />
                                </Form.Item>

                                <MinusCircleOutlined
                                  className="ml10 remove-circle-tow"
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              </div>
                            ))}

                            <Form.Item>
                              <Button
                                type="link"
                                onClick={() => {
                                  add();
                                }}
                                block
                              >
                                <PlusOutlined /> Add Image
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PromotionalMaterial;
