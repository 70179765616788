import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const taskAssigmentReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ASSIGNED_TASKS_SUCCESS:
      return { ...state, assignedTaskRes: action.payload };
    case types.GET_ASSIGNED_TASKS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, catalogueRes: action.payload };

    case types.CREATE_ASSIGNED_TASK_SUCCESS:
      return { ...state, assignedTaskRes: action.payload };
    case types.CREATE_ASSIGNED_TASK_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, assignedTaskRes: action.payload };

    case types.UPDATE_ASSIGNED_TASK_SUCCESS:
      return { ...state, assignedTaskRes: action.payload };

    case types.UPDATE_ASSIGNED_TASK_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, assignedTaskRes: action.payload };

    case types.DELETE_ASSIGNED_TASK_SUCCESS:
      return { ...state, assignedTaskRes: action.payload };

    case types.DELETE_ASSIGNED_TASK_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, assignedTaskRes: action.payload };

    case types.GET_ALL_USERS_SUCCESS:
      return { ...state, allUsersRes: action.payload };
    case types.GET_ALL_USERS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, allUsersRes: action.payload };

    case types.GET_ALL_ORGANIZATIONS_SUCCESS:
      return { ...state, allOrganizationsRes: action.payload };
    case types.GET_ALL_ORGANIZATIONS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, allOrganizationsRes: action.payload };

    default:
      return state;
  }
};
