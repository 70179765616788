import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const OPTIONS = [
  {key: 'lt50k', value: '< 50 000 EUR'},
  {key: '50-250k', value: '50 000 - 250 000 EUR'},
  {key: '250-500k', value: '250 000 - 500 000 EUR'},
  {key: '500k-1M', value: '500 000 - 1 000 000 EUR'},
  {key: 'gt1M', value: '> 1 000 000 EUR'},
];

const Budget = ({ helperText, validationField, requiredField }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "film_genres")) {
      setTabsChange(false)
    }
  }, [validationField])

  const handleChange = selectedItems => {
    setSelectedItems(selectedItems);

    console.log(selectedItems)
  };

  const filteredOptions = OPTIONS.filter(o => !selectedItems.includes(o));
  return (
    <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Budget</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Indicate the budget for this asset's production.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <div className="metadata-field-box">
            <Row gutter={16}>
              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className=""><span>Budget</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="budget"
                    >
                      <Select
                        placeholder="Select budget"
                        value={selectedItems}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        getPopupContainer={trigger => trigger.parentElement}
                      >
                        {filteredOptions.map(item => (
                          <Select.Option key={item.key} value={item.key}>
                            {item.value}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Budget;