import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import SelectionVendorList from "./VendorAssetList";
import SelectionVendorView from "./VendorAssetListView";
import FormalLicenseEdit from "./FormalLicenseEdit";
import RequestProposalModal from "./RequestProposalModal";
import PermissionsModal from "./../../../../Common/Components/PermissionsModal";
import {
  getUrlLastId,
  getRolesPermissions,
} from "./../../../../Common/reUseFunctions";
import NegotiationInvitationModal from "./../../../../Common/SendInvitationModal/NegotiationInvitationModal";

const VendorAssets = ({
  selectionId,
  location,
  modalType,
  onGetAllTradingTask,
  getAllTradingTaskRes,
  assetDetail,
  onGetAssetDetail,
  getInventorySeachDetailRes,
  onGetInventorySeachDetail,
  onGetOrganizationProfile,
  getOrganizationProfileRes,
  onsendInvitation,
  sendInvitationRes,
  mainUserRes,
}) => {
  const [visible, setVisible] = useState(false);
  const [visiblePe, setVisiblePe] = useState(false);
  const [modelType, setModelType] = useState("");
  const [loading, setLoading] = useState(false);
  const [tradingTaskId, setTradingTaskId] = useState("");
  const [licenseDefinitions, setLicenseDefinitions] = useState([]);

  useEffect(() => {
    const locationUrl = location.pathname;
    const tradingTaskId = getUrlLastId(locationUrl);
    if (getRolesPermissions(mainUserRes, "trading-task.show") === false) {
      setLoading(true);
      onGetAllTradingTask(tradingTaskId).then((res) => {
        setLoading(false);
      });
    }
    setTradingTaskId(tradingTaskId);
  }, [mainUserRes]);

  useEffect(() => {
    onGetOrganizationProfile();
  }, []);

  const showModal = (e) => {
    setVisible(true);
    setModelType(e);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const showModalPe = (e) => {
    setVisiblePe(true);
    setModelType(e);
  };

  const handleCancelPe = (e) => {
    setVisiblePe(false);
  };

  const getAssetFormalLicenseDefinitions = (data) => {
    setLicenseDefinitions(data)
  }

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <div className="trading-task-detail">
          <Link to={`/trading-tasks`} type="link" className="back-button">
            <ArrowLeftOutlined /> Back to Trading Tasks
          </Link>
        </div>
        <Row gutter="16" style={{ marginTop: "20px" }}>
          <Col span={12}>
            <SelectionVendorList
              getAssetFormalLicenseDefinitions={getAssetFormalLicenseDefinitions}
              selectionId={selectionId}
              showModal={showModal}
              modalType={modalType}
              getAllTradingTaskRes={getAllTradingTaskRes}
              tradingTaskId={tradingTaskId}
              assetDetail={assetDetail}
              onGetAssetDetail={onGetAssetDetail}
              getInventorySeachDetailRes={getInventorySeachDetailRes}
              onGetInventorySeachDetail={onGetInventorySeachDetail}
              mainUserRes={mainUserRes}
            />
          </Col>
          <Col span={12}>
            <SelectionVendorView
              licenseDefinitions={licenseDefinitions}
              showModal={showModal}
              showModalPe={showModalPe}
              modalType={modalType}
              getAllTradingTaskRes={getAllTradingTaskRes}
              onGetAllTradingTask={onGetAllTradingTask}
            />
          </Col>
        </Row>
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={modelType === "request" ? 550 : 610}
        >
          {(modelType === "edit" || modelType === "add") && (
            <FormalLicenseEdit modelType={modelType} showModal={showModal} />
          )}
          {modelType === "request" && (
            <RequestProposalModal
              modelType={modelType}
              handleCancel={handleCancel}
            />
          )}
          {modelType === "Trading Detail" && (
            <NegotiationInvitationModal
              handleCancel={handleCancel}
              onsendInvitation={onsendInvitation}
              sendInvitationRes={sendInvitationRes}
              location={location}
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              tradingTaskId={tradingTaskId}
            />
          )}
        </Modal>
        <PermissionsModal visible={visiblePe} handleCancel={handleCancelPe} />
      </Spin>
    </Fragment>
  );
};

export default VendorAssets;
