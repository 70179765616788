import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select, Radio } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { multiLanguages } from "./../../../../../../statics/data/multiLanguages";

const OPTIONS = [
  "Mono",
  "Stereo",
  "Dolby Surround 5.1",
  "Dolby Surround 5.2",
  "Dolby Atmos/DTS:X",
  "MPEG-H",
  "other",
];

const Index = ({ helperText, requiredField }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const onChangeLanguage = (value) => {
    setSelectedLang(value);
  };

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  return (
    <Row gutter={16} className="mb5">
      <Col className="pl0_important" span={24}>
        <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Audio Tracks</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>Indicate the specifications of the asset's audio track.</p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="audioTrack">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <div>
                      <Row gutter={16} className="mb20">
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Language</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "language"]}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    pattern: new RegExp("[a-z]{2}(-[A-Z]{2})?"),
                                  },
                                ]}
                                className="full-width mr20 mb0"
                              >
                                <Select
                                  placeholder="Select language"
                                  value={selectedLang}
                                  onChange={onChangeLanguage}
                                  style={{ width: "100%" }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {multiLanguages.map((item, index) => (
                                    <Select.Option
                                      key={index}
                                      value={item.value}
                                    >
                                      {item.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Channels</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "channels"]}
                                validateTrigger={["onChange", "onBlur"]}
                                style={{ width: "100%" }}
                                className="full-width mr20 mb0"
                              >
                                <Select
                                  placeholder="Select channels"
                                  value={selectedItems}
                                  onChange={handleChange}
                                  style={{ width: "100%" }}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Gapless Play</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "gapless_play"]}
                                defaultValue="yes"
                              >
                                <Radio.Group>
                                  <Radio value="yes">Yes</Radio>
                                  <Radio value="no">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          className={
                            requiredField === true
                              ? "metadata-tab-body_hide"
                              : "mb5"
                          }
                        >
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Audio Track Id</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item {...field} name={[field.name, "id"]}>
                                <Input placeholder="Id" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Audio Track
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;
