export const licenseFlags = [
  { name: 'All Rights', value: 'l' },
  { name: 'Audio only', value: 'a' },
  { name: 'Download on Demand (DOD)', value: 'd' },
  { name: 'Download to Own (DTO)', value: 'o' },
  { name: 'DVD', value: 'v' },
  { name: 'Educational', value: 'e' },
  { name: 'Film Festival', value: 'f' },
  { name: 'Free Tv', value: 't' },
  { name: 'Hotels', value: 'h' },
  { name: 'in-flight', value: 'i' },
  { name: 'non-commercial', value: 'n' },
  { name: 'Pay Tv', value: 'p' },
  { name: 'Public Viewing', value: 'b' },
  { name: 'Ships', value: 's' },
  { name: 'Stream on Demand (SOD)', value: 'w' },
  { name: 'Theatrical', value: 'r' },
];