import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { playIcon } from "../../../Common/Icons";
import screener1 from "../../../../statics/images/screener1.png";
import { toDateString, getOnlyMinutes } from "../../../Common/reUseFunctions";
import VideoPlayer from "./VideoPlayer";
import { Background } from "victory";

const AssetTrilerScreener = ({ getInventorySeachDetailRes }) => {
  const [visible, setVisible] = useState(false);
  const [traliersId, setTraliersId] = useState();

  const showModal = (id) => {
    setVisible(true);
    setTraliersId(id);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <div className="asset-detial-video">
      <h2>Trailers and Screener</h2>
      <Row gutter={16}>
        {getInventorySeachDetailRes &&
          getInventorySeachDetailRes.trailers &&
          getInventorySeachDetailRes.trailers.length > 0 &&
          getInventorySeachDetailRes.trailers.map((data, index) => {
            return (
              <Col key={index} span={12} className="inventory-grid">
                <div type="primary" onClick={() => showModal(data.url)}>
                  {data && (
                    <div className="inventory-grid-video">{playIcon}</div>
                  )}
                </div>
                <div>
                  <h3>{data.name}</h3>
                </div>
                <div className="asset-grid-info">
                  <div className="w35-per">
                    <h4>Launch Date</h4>
                    <div className="mb10">
                      <span>{toDateString(data && data.launch_date)}</span>
                    </div>
                  </div>
                  <div className="w30-per">
                    <h4>Length</h4>
                    <div className="mb10">
                      <span>{getOnlyMinutes(data && data.length)}</span>
                    </div>
                  </div>
                  <div className="w35-per">
                    <h4>Director</h4>
                    <div className="mb10">
                      <span>{data && data.director}</span>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
      {visible && (
        <Modal
          visible={visible}
          onCancel={handleCancel}
          className="detail-model video-modal"
          footer={false}
        >
          <VideoPlayer url={traliersId} />
        </Modal>
      )}
    </div>
  );
};

export default AssetTrilerScreener;
