import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from "react-router-dom";

const SpecialCatalogue = ({ assets, removeAsset }) => {

  return (
    <div className="special-cat">
      <h2>{assets.name}</h2>
      <Row gutter={16} >
        { assets && assets.map((asset, index) => <Col className="mb20" span={8} key={index}>
            <Link to="#!">
              <div className="catalogues-banner catalogue-b-grid">
                <img className="catalogue-bg-img" src={asset.preview_image} alt={asset.title.value} />
              </div>
            </Link>
            <div className="new-catalogues-info highlights-catalogue s-catalogues-text">
              <h3>{asset.title.value}</h3>
              <div className="catalouge-btns">
                <Button type="link" className="p0 border-0" onClick={() => removeAsset(asset._id)}> Remove</Button>
              </div>
            </div>
          </Col>)
        }
      </Row>
    </div>
  )
}

export default SpecialCatalogue;