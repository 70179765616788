import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';


import DashboardPage from "./DashboardPage"
import AssetList from "./AssetList"
import SelectionDetailCom from './../../Containers/SelectionsDetail';
// import RequestProposal from './RequestProposal';
// import SelectionProposal from './SelectionProposal/SelectionProposalIndex';
// import SelectionProposalClosing from './SelectionProposalClosing';
import Organization from './Organization';
import OrganizationDetail from './Organization/Detail';
import Auction from './Auction';
import PersonalAuction from './Auction';
import PublishedAuction from './Auction';
import AuctionDetail from './Auction/Detail';
import LicenseManagement from './LicenseManagement';
import TradingTasks from './../../Containers/TradingTask';

import MembersCatalogues from './../../Containers/MembersCatalogues';
import CataloguesMemberProfile from './../../Containers/MemberProfile';
import PremiumCatalogue from './PremiumCatalogue';
// import PremiumProfile from './CataloguesPremiumProfile';
import PremiumProfile from './../../Containers/CataloguesPremiumProfile';

import AudioVideoCall from './AudioVideoCall';
import HelpComponent from './HelpComp';

import UserManagement from './../../Containers/UserManagement';
import OrganizationProfile from './../../Containers/Organization';
import RoleManagement from './../../Containers/Roles';
import AutomatedTaskAssignment from './../../Containers/TaskAssignment';
import ProposalsManagement from './Administration/ProposalsManagement';
import CataloguesAssetsView from './CataloguesAssetsView';
import SpecialCatalogues from './../../Containers/SpecialCatalogues';
import SpecialCataloguesDetail from './../../Containers/SpecialCatalogueDetail';
import TradingTaskDetail from './../../Containers/TradingTaskDetail';

import AssetImport from './Administration/AssetImports';
import ImportAssetsFile from './Administration/ImportAssetsFile';

import ColumnsMapping from './../../Containers/ColumnMapping';

import ImportAssetsSuccessfully from './Administration/ImportAssetsSuccessfully';

import DepartmentsManagement from '../../Containers/DepartmentsManagement';

// import SelectRight from './../ContentArea/SelectionDetailCom/'

import {
  Wallet,
  WalletDetail,
  Inventory,
  AssetsMetadata,
  PublishedAssets,
  UnpublishedAssets,
  Selections,
  InventorySearch,
  AssetDetail,
  RequestProposal,
  SelectionProposal,
  ImportCSV,
  SelectionProposalClosing,
} from '../../Containers';

const ContentArea = ({
  sessionRes,
  history
}) => (
  <Switch>
    <Redirect exact from='/' to='/dashboard' />
    <Route path='/dashboard' component={DashboardPage} />
    <Route path='/wallet/:id' component={WalletDetail} />
    <Route exact path='/wallet' component={Wallet} />

    <Route exact path='/marketplace' component={AssetList} />
    {/* <Route exact path='/inventory-search/:id' component={AssetDetail} /> */}
    <Route exact path='/inventory-search/:id' component={(props) => <AssetDetail InventoryDetail={true} {...props} />} />

    <Route exact path='/organizations' component={Organization} />
    <Route exact path='/organizations/:id' component={OrganizationDetail} />

    <Route exact path='/auction' component={Auction} />
    <Route path='/auction/personal-auction' component={PersonalAuction} />
    <Route path='/auction/subscribed-auction' component={PublishedAuction} />
    <Route path='/auction/:id' component={AuctionDetail} />

    <Route exact path='/asset-management' component={() => <Inventory history={history} sessionRes={sessionRes} />} />
    <Route exact path='/import-assets' component={() => <ImportCSV history={history} sessionRes={sessionRes} />} />
    <Route path='/license-management' component={LicenseManagement} />
    <Route path='/inventory/published-assets' component={PublishedAssets} />
    <Route path='/inventory/unpublished-assets' component={UnpublishedAssets} />
    <Route path='/asset-management/:id' render={(props) => <AssetsMetadata sessionRes={sessionRes} {...props} />} />

    <Route exact path='/selections' component={Selections} />

    <Route exact path='/selections-purchase/:id' component={SelectionDetailCom} />
    <Route exact path='/request-proposal' component={RequestProposal} />
    <Route path='/selections-sales/:id' render={(props) => <SelectionDetailCom selection={`selection-sales`} {...props} />} />

    <Route path='/selections/:id/proposal-closing' component={SelectionProposalClosing} />

    <Route path='/inventory-search' component={InventorySearch} />
    <Route path='/trading-tasks' component={TradingTasks} />
    <Route path='/trading-task/:id/proposal' component={SelectionProposal} />
    <Route exact path='/trading-task/:id' component={TradingTaskDetail} />

    <Route path='/member-catalogues' component={MembersCatalogues} />
    <Route path='/member-profile' component={CataloguesMemberProfile} />
    <Route path='/premium-catalogue' component={PremiumCatalogue} />
    <Route path='/premium-profile/:id?' component={PremiumProfile} />
    <Route path='/catalogue-assets' component={CataloguesAssetsView} />

    <Route path='/call' component={AudioVideoCall} />

    <Route path='/help' component={HelpComponent} />

    <Route path='/user-management' component={UserManagement} />

    <Route path='/organization-profile' component={OrganizationProfile} />

    <Route path='/role-management' component={() => <RoleManagement history={history} sessionRes={sessionRes} />} />

    <Route path='/automated-task-assignment' component={AutomatedTaskAssignment} />

    <Route path='/proposals-management' component={ProposalsManagement} />

    <Route path='/special-catalogues' component={SpecialCatalogues} />

    <Route path='/special-catalogues-detail/:id' component={SpecialCataloguesDetail} />

    <Route path='/asset-import' component={AssetImport} />

    <Route path='/import-asset-file' component={ImportAssetsFile} />

    <Route path='/columns-mapping' component={ColumnsMapping} />
    <Route path='/import-asset-successfully' component={ImportAssetsSuccessfully} />

    <Route path='/departments-management' component={DepartmentsManagement} />

    <Redirect to='/dashboard' />
  </Switch>
);

export default ContentArea;
