import React from "react";
import { Row, Col } from "antd";
import OrganizationDetail from "./OrgazinerDetail";
import OrgazinerContact from "./OrgazinerContact";

const Detail = () => {
  return (
    <Row gutter={16}>
      <Col span={24}>
        <OrganizationDetail />
      </Col>
      <Col span={24}>
        <OrgazinerContact />
      </Col>
    </Row>
  );
};

export default Detail;
