import React from 'react';

import InventoryFilterBar from "./InventoryFiltersBar"

const MyMarketInventory = ({
  onChangeLayout,
  changeLayout,
  showTableEditor,
  changeSelectionView
}) => {
 
  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        showTableEditor={showTableEditor}
        changeSelectionView={changeSelectionView}
      />
    </div>
  )
}

export default MyMarketInventory;