import React from 'react';
import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showModal,
  onSearch,
}) => {
  return (
    <div>
      <TopTitleSection
        showModal={showModal}
      />
      <InventoryFilters
        onSearch={onSearch}
      />
    </div>
  )
}

export default HeaderFilter;