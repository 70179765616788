import React from "react";
import { Button } from "antd";
import { pluseFilledIcon } from "./../../../../Common/Icons";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const TopTitleSection = ({ showModal, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Role Management</h3>
      </div>
      <div>
        <p className="add-selection-btn add-new-selection">
          {getRolesPermissions(mainUserRes, "role.create") === false ? (
            <Button
              type="default"
              className="p0 border-0"
              onClick={() => showModal("create_role")}
            >
              {pluseFilledIcon}
              CREATE NEW ROLE
            </Button>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
};

export default TopTitleSection;
