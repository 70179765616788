import React from "react";
import { Button, Modal } from "antd";

const DeleteConfirmation = ({ handleCancel, visible, onDelete, onDeleteItem, loading, selectedData, handleModalCancel, modalType, text }) => {
  return (
    <Modal
      title={false}
      visible={visible}
      onCancel={handleModalCancel ? handleModalCancel : handleCancel}
      footer={false}
      className="modal-style"
      width={420}
      centered
    >
      <div className="delete-confirm-style">
        <h3>Are you sure?</h3>
        <p>
          {text}
        </p>

        <div className="text-right asset-action-btn">
          <Button type="default" onClick={handleModalCancel ? handleModalCancel : handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="ml10"
            onClick={() => {
              onDelete("delete")
              onDeleteItem && onDeleteItem(selectedData)
            }}
            loading={loading}
          >
           {(modalType === "Sign Now") || (modalType === "Sign Sale") ? "Confirm" : "Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
