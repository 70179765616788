import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const getWalletList = (skip, limit, search) => {
  const options = {
    url: `wallets?skip=${skip}&limit=${limit}&order=asc&username=${search}`
  };

  options.types = [
    types.GET_WALLET_LIST_SUCCESS,
    types.GET_WALLET_LIST_FAILURE
  ];

  return apiNew.get(options);
}

export const getWalletDetail = (id) => {
  const options = { url: `wallets/${id}` };

  options.types = [
    types.GET_WALLET_DETAIL_SUCCESS,
    types.GET_WALLET_DETAIL_FAILURE
  ];

  return apiNew.get(options);
}

export const getWalletBalance = (id) => {
  const options = { url: `wallets/${id}/balance` };

  options.types = [
    types.GET_WALLET_BALANCE_SUCCESS,
    types.GET_WALLET_BALANCE_FAILURE
  ];

  return apiNew.get(options);
}

export const createWallet = (data) => {
  const options = { url: `wallets` };

  options.types = [
    types.CREATE_WALLET_SUCCESS,
    types.CREATE_WALLET_FAILURE
  ];

  return apiNew.post(options, data);
}

export const updateWallet = (id, data) => {
  const options = { url: `wallets/${id}` };

  options.types = [
    types.UPDATE_WALLET_SUCCESS,
    types.UPDATE_WALLET_FAILURE
  ];

  return apiNew.put(options, data);
}

export const deleteWallet = (id) => {
  const options = { url: `wallets/${id}` };

  options.types = [
    types.DELETE_WALLET_SUCCESS,
    types.DELETE_WALLET_FAILURE
  ];

  return apiNew.delete(options);
}