import React, { useState, useEffect } from "react";
import { Button, Form, Spin, Checkbox, Input, message, Popover } from "antd";
import { getRolesPermissions } from "../../../Common/reUseFunctions";
import datetimeStringAbbrevaite from "@antv/g2plot/lib/util/responsive/rules/datetime-string-abbrevaite";
import { QuestionCircleFilled } from "@ant-design/icons";

const CreateNewRole = ({
  search,
  permissionsRes,
  onGetPermissions,
  currentPage,
  onGetMainUserDetail,
  mainUserRes,
  modalType,
  handleCancel,
  onCreateRoles,
  onGetSpecificRole,
  selectedData,
  onUpdateRole,
  onGetRolesList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [groupedPermissions, setGroupedPermissions] = useState({});

  useEffect(() => {
    setLoading(true);
    if (modalType === "edit_role") {
      onGetSpecificRole(selectedData && selectedData).then((res) => {
        form.setFieldsValue({
          name: res.payload.name,
          permissions: res.payload.permissions,
        });
        setLoading(false);
      });
    }
    onGetPermissions().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (permissionsRes && permissionsRes.permissions) {
      const grouped = {};

      permissionsRes.permissions.map((permission) => {
        if (!Array.isArray(grouped[permission.group_name]))
          grouped[permission.group_name] = [];

        grouped[permission.group_name].push(permission);
      });

      setGroupedPermissions(grouped);
    }
  }, [permissionsRes]);

  const onFinish = (values) => {
    setLoading(true);
    if (modalType === "create_role") {
      const permissionsName = values && values.permissions;
      const rolesBody = {
        name: values && values.name,
        permissions: permissionsName,
      };
      onCreateRoles(rolesBody)
        .then((res) => {
          const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
          setLoading(false);
          onGetRolesList(skip, 9, search);
          handleCancel();
          message.success(res && res.payload && res.payload.message);
        })
        .catch(() => {
          const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
          setLoading(false);
          onGetRolesList(skip, 9, search);
        });
    }

    if (modalType === "edit_role") {
      if (getRolesPermissions(mainUserRes, "role.edit") === false) {
        onUpdateRole(selectedData && selectedData, values)
          .then((res) => {
            const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
            setLoading(false);
            onGetRolesList(skip, 9, search);
            handleCancel();
            message.success(res && res.payload && res.payload.message);
            onGetMainUserDetail();
          })
          .catch(() => {
            const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
            setLoading(false);
            onGetRolesList(skip, 9, search);
          });
      }
    }
  };

  return (
    <div className="license-edit-model">
      <h3>{modalType === "edit_role" ? "Edit Role" : "Create New Role"}</h3>
      <Spin size="large" spinning={loading}>
        <Form name="validate_other" onFinish={onFinish} form={form}>
          <div className="slection-asset-view mb20-imp">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span> ROLE NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="name"
                    rules={[
                      { required: true, message: "Please enter role name!" },
                    ]}
                    required
                  >
                    <Input placeholder="Role Name" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <h4 className="select-permission">SELECT PERMISSIONS</h4>
          <div className="available-permissions-list preview-tabslist">
            <Form.Item
              name="permissions"
              rules={[
                { required: true, message: "Please select the permissions!" },
              ]}
            >
              <Checkbox.Group style={{ width: "100%" }}>
                {groupedPermissions &&
                  Object.keys(groupedPermissions).length > 0 &&
                  Object.keys(groupedPermissions).map((labelKey, index) => {
                    return (
                      <div>
                        <div className="permissions-data-label" key={index}>
                          {labelKey}
                        </div>
                        {groupedPermissions[labelKey].map((data, index) => {
                          return (
                            <div className="permissions-data">
                              <Checkbox key={index} value={data.name}>
                                <div className="permissions-data-value">
                                  {data.title}
                                  <Popover
                                    // style={{ maxWidth: 200 }}
                                    overlayStyle={{
                                      width: "14rem",
                                      textAlign: "center",
                                    }}
                                    content={<div>{data.description}</div>}
                                    trigger="click"
                                  >
                                    <div className="permissions-data-help">
                                      <QuestionCircleFilled />
                                    </div>
                                  </Popover>
                                </div>
                              </Checkbox>
                            </div>
                          );
                        })}
                        {/* <Checkbox key={index} value={data.name}>
                          <div className="permissions-data-value">
                            <div>{data.description}</div>
                            <Popover
                              // style={{ maxWidth: 200 }}
                              overlayStyle={{
                                width: "15vw"
                              }}
                              content={<div>Descriptions to be added</div>} trigger="click">
                              <div className="permissions-data-help"><QuestionCircleFilled /></div>
                            </Popover>
                          </div>
                        </Checkbox> */}
                      </div>
                    );
                  })}
              </Checkbox.Group>
            </Form.Item>
          </div>
          <div className="formal-license-btn">
            <Button loading={loading} htmlType="submit" type="primary">
              {modalType === "edit_role" ? "UPDATE ROLE" : "CREATE ROLE"}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default CreateNewRole;
