import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "../../Common/reUseFunctions";

const TopTitleSection = ({ showModal, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Wallets</h3>
      </div>
      {getRolesPermissions(mainUserRes, "wallet.create") === false ? (
        <div>
          <p className="add-selection-btn add-new-selection">
            <Button type="default" className="p0 border-0" onClick={showModal}>
              <PlusOutlined /> CREATE NEW WALLET
            </Button>
          </p>
        </div>
      ) : (
        <div>
          <p className="add-selection-btn add-new-selection">
            <Button type="default" className="p0 border-0">
              <PlusOutlined /> Add New Wallet
            </Button>
          </p>
        </div>
      )}
    </div>
  );
};

export default TopTitleSection;
