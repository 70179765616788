import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const CataloguesByLogo = ({ data, mainUserRes }) => {
  return (
    <Row gutter={24}>
      {getRolesPermissions(mainUserRes, "organization.show") === false
        ? data.map((catalogue) => (
            <Col className="mb25" span={4} key={catalogue._id}>
              <Link to={`premium-profile/${catalogue._id}`}>
                <div className="catalogues-by-logo">
                  <img
                    preview="false"
                    src={`${process.env.REACT_APP_API_BASE_URL}organizations/${catalogue.logo}/image`}
                    alt={catalogue.name}
                  />
                </div>
              </Link>
            </Col>
          ))
        : data.map((catalogue) => (
            <Col className="mb25" span={4} key={catalogue._id}>
              <Link to="#">
                <div className="catalogues-by-logo">
                  <img
                    preview="false"
                    src={`${process.env.REACT_APP_API_BASE_URL}organizations/${catalogue.logo}/image`}
                    alt={catalogue.name}
                  />
                </div>
              </Link>
            </Col>
          ))}
    </Row>
  );
};

export default CataloguesByLogo;
