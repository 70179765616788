import React, { useEffect, useState, Fragment } from 'react';
import { Form, Button, Row, Col, Spin, message, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {exclamationIcon} from './../../../../Common/Icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import OwnerForm from './OwnerForm';
import InternalIdentification from './InternalIdentification';
// import ISCCGenerate from './ISCCGenerate';
// import FilmGenres from './FilmGenres';
// import MusicDanceGenres from './MusicDanceGenres';
// import CommentsForm from './CommentsForm';
// import Series from './Series';
// import Chapters from './Chapters';
// import AgeRestrictions from './AgeRestrictions';
// import TrailerAndScreeners from './TrailerAndScreeners';

import MultiLanguageTitles from './MultiLanguageTitles';
// import ISCCFilePrints from './ISCCFilePrints';
import Titles from './Titles';
// import ShortSummaries from './ShortSummaries';
// import LongSummaries from './LongSummaries';
// import Trailer from './Trailer';
// import Screener from './Screener';
// import Awards from './Awards';
// import Festivals from './Festivals';
// import PromotionalMaterial from './PromotionalMaterial';
// import ProductionCompany from './ProductionCompany';
// import CoProductionCompany from './CoProductionCompanies';
// import Director from './Directors';
// import Contributors from './Contributors';
// import Status from './Status';
// import FilmSetting from './FilmSetting';
// import ProductionReleaseDate from './ProductionReleaseDate';
// import RightOffered from './RightsOffered';
// import VideoTrack from './VideoTracks';
// import AudioTrack from './AudioTracks';
// import SubtitleTrack from './SubtitleTracks';
// import Snippets from './Snippets';
// import DurationForm from './DurationForm';
// import BudgetForm from './Budget';

import SelectColumnModal from '../../../../Common/SelectColumnModal';
import ColumnsMappingPreset from '../../../../Common/ColumnsMappingPreset';

import {
	newYearDate,
	getDateByYear,
	hhmmssFormate,
	getOnlyYear,
	setTiming
} from '../../../../Common/reUseFunctions';

const AssetsDetailOptions = ({
	onIsccCodeUpdating,
	isccUpdating,

	onGetAssetDetail,
	assetDetail,

	onUpdateAsset,

	onIsccCodeGeneration,
	isccGeneration,

	onIsccGenerationProcess,
	isccStatus,
	assetID,

}) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	// const [titleEdit, setTitleEdit] = useState(false);
	// const [assetTitle, setAssetTitle] = useState(false);
	const [tabsChange, setTabsChange] = useState(true);
	// const [helperText, setHelperText] = useState(false);
	// const [requiredField, setRequiredField] = useState(false);
	// const [validationField, setValidationField] = useState([]);
	// const [allMetadata, setAllMetadata] = useState(true);
	const [visible, setVisible] = useState(false);
	const [modalType, setModalType] = useState("");
	// const [trailerMetadata, setTrailerMetadata] = useState(false);
	// const [trailerMetadataKey, setTrailerMetadataKey] = useState('');
	const [selectiveColumns, setSelectiveColumns] = useState("");

	useEffect(() => {
		if (assetID) {
			onGetAssetDetail(assetID).then((res) => {
			})
		}

	}, []);

	const showModal = ( type) => {
		setVisible(true);
		setModalType(type);
	};
	const handleCancel = value => {
		setVisible(false); 
	};

	// const handleCancel = value => {
	// 	setVisible(false);

	// };

	/////////////////////////////   Functions Start   //////////////////////////////////////

	//////////////////////////////////////////////////// Series

	const seriesDataFunc = (obj) => {
		let seriesArr = [];
		seriesArr = [obj];
		let seriesData = [];
		if (seriesArr) {
			seriesArr.map((data, index) => seriesData.push({
				name: data && data.title && data.title.name,
				language: data && data.title && data.title.language,
				multiLanguageTitle: data && data.multiLanguageTitle,
				musicGenre: data && data.musicGenre,
				danceGenre: data && data.danceGenre,
				season: data && data.season,
				episode: data && data.episode,
			}))
		}

		return seriesData;
	}

	//////////////////////////////////////////////////// Comments
	const commentDataFunc = (arr) => {
		let commentData = [];
		if (arr) {
			arr.map((data, index) => commentData.push({
				external: (data.external === false && "no") || (data.external === true && "yes"),
				comment: data.comment
			}))
		}

		return commentData;
	}

	//////////////////////////////////////////////////// Chapters
	/////// Multi Laguage

	const chaptersDataFunc = (arr) => {

		let chaptersData = [];
		if (arr) {

			arr.map((data, index) => chaptersData.push({
				name: data && data.title && data.title.name,
				language: data && data.title && data.title.language,
				multiLanguageTitle: data && data.multiLanguageTitle,
				contributers: data && data.contributers,
				musicGenre: data && data.musicGenre,
				danceGenre: data && data.danceGenre,
				duration: data && new moment(setTiming(data.duration)),
				id: data && data.id,
			}))
		}

		return chaptersData;
	}

	//////////////////////////////////////////////////// Awards
	const awardDataFunc = (arr) => {
		let awardsgData = [];
		if (arr) {
			arr.map((data, index) => awardsgData.push({
				name: data && data.name,
				category: data && data.category,
				year: data && new moment(data.year.toString()),
				position: data && data.position,
				logo: [
					{ logo: data && data.logo },
				]
			}))
		}

		return awardsgData;
	}

	//////////////////////////////////////////////////// Festivals
	const festivalDataFunc = (arr) => {
		let festivalData = [];
		if (arr) {
			arr.map((data, index) => festivalData.push({
				name: data && data.name,
				category: data && data.category,
				year: data && new moment(data.year.toString()),
				url: data && data.url,
				logo: [
					{ logo: data && data.logo },
				]
			}))
		}

		return festivalData;
	}

	//////////////////////////////////////////////////// Festivals
	const directorsDataFunc = (arr) => {
		let directorData = [];
		if (arr) {
			arr.map((data, index) => directorData.push({
				name: data && data,
			}))
		}

		return directorData;
	}

	//////////////////////////////////////////////////// Contributors
	const contributorsDataFunc = (arr) => {

		let contributorsData = [];
		if (arr) {
			arr.map((data, index) => contributorsData.push({
				name: data && data.name,
				country: data && data.country,
				function: data && data.function,
				featured: (data && data.featured === true && "yes") || (data && data.featured === false && "no"),
				agent: [
					{
						name: data && data.agent && data.agent.name,
						seat: data && data.agent && data.agent.seat,
						contact: data && data.agent && data.agent.contact,
					}
				],
				// logo: [
				// 	{ festival_logo: data && data.logo && data.logo[0] },
				// ]
			}))
		}

		return contributorsData;
	}

	//////////////////////////////////////////////////// Film Setting
	const filmsettingDataFunc = (obj) => {
		let seriesArr = [];
		seriesArr = [obj];

		let filmsettingData = [];
		if (seriesArr) {
			seriesArr.map((data, index) => filmsettingData.push({
				date: data && new moment(data.date),
				location: data && data.location,
			}))
		}

		return filmsettingData;
	}
	//////////////////////////////////////////////////// Right Offered
	const territoriesDataFunc = (arr) => {

		let territoryData = [];
		if (arr) {
			arr.map((data, index) => territoryData.push({
				territory: data && data,
			}))
		}

		return territoryData;
	}

	const rigthOffersDataFunc = (arr) => {

		let rightData = [];
		if (arr) {

			arr.map((data, index) => rightData.push({
				types: data && data.types,
				territories: territoriesDataFunc(data && data.territories),
				resale: (data && data.resale === true && "yes") || (data && data.resale === false && "no"),
				type: data && data.type,
				beginDate: data && new moment(data.beginDate),
				endDate: data && new moment(data.endDate),
				exclusive: (data && data.exclusive === true && "yes") || (data && data.exclusive === false && "no"),


			}))
		}

		return rightData;
	}

	//////////////////////////////////////////////////// Video Track
	const videoTrackDataFunc = (arr) => {
		let videoTrackData = [];
		if (arr) {
			arr.map((data, index) => videoTrackData.push({
				resolution: data && data.resolution,
				aspectRatio: data && data.aspectRatio,
				threeD: (data && data.threeD === true && "yes") || (data && data.threeD === false && "no"),
				color: data && data.color,
				videoTrackId: data && data.videoTrackId,
			}))
		}

		return videoTrackData;
	}

	//////////////////////////////////////////////////// Audio Track
	const audioTrackDataFunc = (arr) => {
		let audioTrackData = [];
		if (arr) {
			arr.map((data, index) => audioTrackData.push({
				language: data && data.language,
				channels: data && data.channels,
				gaplessPlay: (data && data.gaplessPlay === true && "yes") || (data && data.gaplessPlay === false && "no"),
				audioTrackId: data && data.audioTrackId,
			}))
		}

		return audioTrackData;
	}

	//////////////////////////////////////////////////// Snippets

	const snippetsDataFunc = (arr) => {

		let snippetsData = [];
		if (arr) {
			arr.map((data, index) => snippetsData.push({
				snippetTitle: data.title.name,
				language: data.title.language,
				version: data.version,
				timeIn: data && new moment(setTiming(data.timeIn)),
				timeOut: data && new moment(setTiming(data.timeOut)),
				multiLanguageTitles: data && data.multiLanguageTitles,
			}))
		}

		return snippetsData;
	}

	//////////////////////////////////////////////////// Trailer

	const trailerDataFunc = (arr) => {

		let trailerDataFunc = [];
		if (arr) {
			arr.map((data, index) => trailerDataFunc.push({
				name: data.name,
				director: data.director,
				length: data && new moment(data.length),
				launchDate: data && new moment(data.launchDate),
				url: data.url
			}))
		}

		return trailerDataFunc;
	}

	//////////////////////////////////////////////////// Preview Image

	const previewMetaDataBodyFunc = (arr) => {

		let previewMetaDataBodyFunc = [];
		if (arr) {
			arr.map((data, index) => previewMetaDataBodyFunc.push({
				preview: data,
			}))
		}

		return previewMetaDataBodyFunc;
	}

	/////////////////////////////   Functions End   //////////////////////////////////////

	useEffect(() => {

		form.setFieldsValue({
			owner: assetDetail && assetDetail.owner,
			internalIdentification: assetDetail && assetDetail.internalIdentification,
			name: assetDetail && assetDetail.title && assetDetail.title.name,
			language: assetDetail && assetDetail.title && assetDetail.title.language,
			alternativeTitle: assetDetail && assetDetail.alternativeTitles,
			// trailerAndScreeners: trailerScreenerDataFunc(assetDetail && assetDetail.trailerAndScreeners),
			filmGenre: assetDetail && assetDetail.filmGenre,
			musicGenre: assetDetail && assetDetail.musicGenre,
			danceGenre: assetDetail && assetDetail.danceGenre,
			comments: commentDataFunc(assetDetail && assetDetail.comments),
			series: seriesDataFunc(assetDetail && assetDetail.series),
			duration: assetDetail && new moment(setTiming(assetDetail.duration)),
			chapter: chaptersDataFunc(assetDetail && assetDetail.chapters),
			ageRestriction: assetDetail && assetDetail.ageRestrictions,
			shortSummaries: assetDetail && assetDetail.shortSummaries,
			longSummaries: assetDetail && assetDetail.longSummaries,
			trailers: trailerDataFunc(assetDetail && assetDetail.trailers),
			previewImages: previewMetaDataBodyFunc(assetDetail && assetDetail.previewImages),
			awards: awardDataFunc(assetDetail && assetDetail.awards),
			festivals: festivalDataFunc(assetDetail && assetDetail.festivals),
			reviews: assetDetail && assetDetail.promotionalMaterial && assetDetail.promotionalMaterial.reviews,
			pressClip: assetDetail && assetDetail.promotionalMaterial && assetDetail.promotionalMaterial.pressClip,
			titleImage: assetDetail && assetDetail.promotionalMaterial && assetDetail.promotionalMaterial.titleImage,
			productionCompanyName: assetDetail && assetDetail.productionCompany && assetDetail.productionCompany.name,
			productionCompanySeat: assetDetail && assetDetail.productionCompany && assetDetail.productionCompany.seat,
			productionCompanyContact: assetDetail && assetDetail.productionCompany && assetDetail.productionCompany.contact,
			coProductionCompanies: assetDetail && assetDetail.coProductionCompanies,
			directors: directorsDataFunc(assetDetail && assetDetail.directors),
			contributors: contributorsDataFunc(assetDetail && assetDetail.contributors),
			status: assetDetail && assetDetail.assetStatus,
			filmSetting: filmsettingDataFunc(assetDetail && assetDetail.filmSetting),
			productionYear: assetDetail && assetDetail.productionYear && new moment(getDateByYear(assetDetail.productionYear)),
			releaseYear: assetDetail && assetDetail.releaseYear && new moment(newYearDate(assetDetail.releaseYear)),
			rightsOffered: rigthOffersDataFunc(assetDetail && assetDetail.rightsOffered),
			videoTrack: videoTrackDataFunc(assetDetail && assetDetail.videoTrack),
			audioTrack: audioTrackDataFunc(assetDetail && assetDetail.audioTrack),
			subtitleTrack: assetDetail && assetDetail.subtitleTrack,
			snippets: snippetsDataFunc(assetDetail && assetDetail.snippets),
		})

	}, [assetDetail]);

	const onFinish = values => {

		const seriesDataBody = (arr) => {

			let seriesData = [];
			if (arr) {
				arr.map((data, index) => seriesData.push({
					title: {
						name: data.name,
						language: data.language
					},
					multiLanguageTitle: data.multiLanguageTitle,
					musicGenre: data.musicGenre,
					danceGenre: data.danceGenre,
					season: data.season,
					episode: data.episode
				}))
			}
			return seriesData && seriesData[0];
		}

		const chapterDataBody = (arr) => {

			let chapterData = [];
			if (arr) {
				arr.map((data, index) => chapterData.push({
					title: {
						name: data.name,
						language: data.language
					},
					multiLanguageTitle: data.multiLanguageTitle,
					musicGenre: data.musicGenre,
					danceGenre: data.danceGenre,
					duration: hhmmssFormate(data.duration),
					id: data.id,
					contributers: data.contributers
				}))
			}
			return chapterData;
		}

		const awardsDataBody = (arr) => {
			let awardData = [];
			if (arr) {
				arr.map((data, index) => awardData.push({
					name: data.name,
					category: data.category,
					position: data.position,
					logo: data.logo[0].logo,
					year: getOnlyYear(data.year)
				}))
			}
			return awardData;
		}

		const FestivalsDataBody = (arr) => {
			let festivalData = [];
			if (arr) {
				arr.map((data, index) => festivalData.push({
					name: data.name,
					category: data.category,
					url: data.url,
					logo: data.logo[0].logo,
					year: getOnlyYear(data.year)
				}))
			}
			return festivalData;
		}

		const contributorsDataBody = (arr) => {
			let contributorData = [];
			if (arr) {
				arr.map((data, index) => contributorData.push({
					name: data.name,
					country: data.country,
					function: data.function,
					agent: {
						name: data.agent[0].name,
						seat: data.agent[0].seat,
						contact: data.agent[0].contact,
					},
					featured: data.featured
				}))
			}
			return contributorData;
		}

		const filmSettingDataBody = (arr) => {
			let filmSettingDataBody = [];
			if (arr) {
				arr.map((data, index) => filmSettingDataBody.push({
					date: data && new moment(data.date),
					location: data && data.location,
				}))
			}
			return filmSettingDataBody && filmSettingDataBody[0];
		}

		const rightOfferedDataBody = (arr) => {

			let rightOfferedData = [];
			if (arr) {
				arr.map((data, index) => rightOfferedData.push({
					types: data && data.types,
					resale: data && data.resale,
					territories: data && data.territories && data.territories.length > 0 && data.territories.map((data) => data.territory),
					exclusive: data && data.exclusive,
					type: data && data.type,
					beginDate: data && new moment(data.beginDate),
					endDate: data && new moment(data.endDate),
				}))
			}

			return rightOfferedData;
		}

		const snippetsDataBody = (arr) => {

			let snippetsDataBody = [];
			if (arr) {
				arr.map((data, index) => snippetsDataBody.push({
					title: {
						name: data.snippetTitle,
						language: data.language
					},
					version: data.version,
					timeIn: data && hhmmssFormate(data.timeIn),
					timeOut: data && hhmmssFormate(data.timeOut),
					multiLanguageTitles: data.multiLanguageTitles

				}))
			}

			return snippetsDataBody;
		}

		const previewMetaDataBody = (arr) => {

			let previewMetaDataBody = [];
			if (arr) {
				arr.map((data, index) => previewMetaDataBody.push(
					data.preview,
				))
			}

			return previewMetaDataBody;
		}

		const directorsData = values && values.directors && values.directors.length > 0 && values.directors.map((data) => data.name)

		const data = {
			owner: values.owner ? values.owner : "",
			internalIdentification: values.internalIdentification ? values.internalIdentification : "",

			title: {
				name: values.name ? values.name : "",
				language: values.language ? values.language : "",
			},
			alternativeTitles: values.alternativeTitle ? values.alternativeTitle : [],

			filmGenre: values.filmGenre ? values.filmGenre : [],
			musicGenre: values.musicGenre ? values.musicGenre : [],
			danceGenre: values.danceGenre ? values.danceGenre : [],
			series: seriesDataBody(values.series) ? seriesDataBody(values.series) : [],
			duration: values.duration ? hhmmssFormate(values.duration) : "",
			chapters: chapterDataBody(values.chapter) ? chapterDataBody(values.chapter) : [],

			ageRestrictions: values.ageRestriction ? values.ageRestriction : [],
			shortSummaries: values.shortSummaries ? values.shortSummaries : [],
			longSummaries: values.longSummaries ? values.longSummaries : [],
			trailers: values.trailers ? values.trailers : [],
			previewImages: previewMetaDataBody(values.previewImages) ? previewMetaDataBody(values.previewImages) : [],
			comments: values.comments ? values.comments : [],
			awards: awardsDataBody(values.awards) ? awardsDataBody(values.awards) : [],
			festivals: FestivalsDataBody(values.festivals) ? FestivalsDataBody(values.festivals) : "",
			productionCompany: {
				name: values.productionCompanyName,
				seat: values.productionCompanySeat,
				contact: values.productionCompanyContact
			},
			coProductionCompanies: values.coProductionCompanies ? values.coProductionCompanies : [],
			contributors: contributorsDataBody(values.contributors) ? contributorsDataBody(values.contributors) : [],
			filmSetting: filmSettingDataBody(values.filmSetting) ? filmSettingDataBody(values.filmSetting) : [],
			releaseYear: values.releaseYear ? getOnlyYear(values.releaseYear) : '',
			productionYear: values.productionYear ? getOnlyYear(values.productionYear) : '',
			assetStatus: values.status,
			promotionalMaterial: {
				reviews: values.reviews,
				pressClip: values.pressClip,
				titleImage: values.titleImage
			},
			directors: directorsData ? directorsData : [],
			rightsOffered: rightOfferedDataBody(values.rightsOffered) ? rightOfferedDataBody(values.rightsOffered) : [],
			snippets: values.snippets && snippetsDataBody(values.snippets),
			videoTrack: values.videoTrack,
			audioTrack: values.audioTrack,
			subtitleTrack: values.subtitleTrack,
		}


		onUpdateAsset(assetID, data)
			.then(() => {
				setLoading(false);
				message.success('Metadata Updated Successfully')
				onGetAssetDetail(assetID)
			})
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
		setTabsChange(false);
		// setValidationField(errorInfo.errorFields)
	};

	return (
		<Fragment>
			<div className="asset-full-form-style">
				<Spin size="large" spinning={loading}>
					{
						<Form
							form={form}
							name="register"
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							scrollToFirstError
						>
							<Fragment>
								<Row gutter={16} className="mb5">

									<Col className="pl0_important" span={24}>
										<Link onClick={() => setTabsChange(!tabsChange)}>
											<div
												className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
											>
												<h3>General</h3>
												<div className="tab-error-sec">
												<div className="tab-error-found">
													<Button type="link" >
													{exclamationIcon} Errors Found
												    </Button>
												</div>
												<DownOutlined />
												</div>
											</div>
										</Link>
									</Col>

									{/* {
										helperText &&
										<Col span={24} className={tabsChange === true && "hide-helper-text"}>
											<div className="heper-text"><p>Header text would go here for this section.</p></div>
										</Col>
									} */}

									<Col span={24}>
										<div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body mb20"}>
											<div className="metadata-field-box general-tab">

												<OwnerForm
												showModal={showModal}
												selectiveColumns={selectiveColumns}
												/>
												{/* <ISCCGenerate
													assetID={assetID}
													assetDetail={assetDetail}
													onUpdateAsset={onUpdateAsset}
													onGetAssetDetail={onGetAssetDetail}
													onIsccGenerationProcess={onIsccGenerationProcess}
													isccStatus={isccStatus}
													onIsccCodeGeneration={onIsccCodeGeneration}
													isccGeneration={isccGeneration}
												showModal={showModal}

												/> */}
												<InternalIdentification
													// requiredField={requiredField}
												showModal={showModal}

												/>
												<Titles 
												showModal={showModal}
												/>
												<MultiLanguageTitles
													formName="multi_language"
													// requiredField={requiredField}
												showModal={showModal}
												/>
												{/* <TrailerAndScreeners
													addMetaData={addMetaData}
													visible={visible}
													setVisible={setVisible}
													handleCancel={handleCancel}
													assetDetail={assetDetail}
													trailerMetadata={trailerMetadata}
													addMetaDataClose={addMetaDataClose}
													trailerMetadataKey={trailerMetadataKey}
												/> */}
											</div>
										</div>
									</Col>
								</Row>
								{/* <Screener
									validationField={validationField}
									helperText={helperText}
								/>
								<Trailer
									validationField={validationField}
									helperText={helperText}
									requiredField={requiredField}
								/>
								<FilmGenres
									helperText={helperText}
									validationField={validationField}
								/>
								<MusicDanceGenres
									helperText={helperText}
									validationField={validationField}
									requiredField={requiredField}
								/>
								<CommentsForm
									helperText={helperText}
									requiredField={requiredField}
								/>

								<Series
									requiredField={requiredField}
									helperText={helperText}
								/>
								<DurationForm
									validationField={validationField}
									helperText={helperText}
								/>
								<Chapters
									helperText={helperText}
									requiredField={requiredField}
								/>
								<AgeRestrictions
									helperText={helperText}
									requiredField={requiredField}
								/>
								<ShortSummaries
									helperText={helperText}
								/>
								<LongSummaries
									helperText={helperText}
									requiredField={requiredField}
								/>

								<Awards
									helperText={helperText}
									requiredField={requiredField}
								/>
								<Festivals
									helperText={helperText}
									requiredField={requiredField}
								/>
								<PromotionalMaterial
									helperText={helperText}
									requiredField={requiredField}
								/>
								<ProductionCompany
									helperText={helperText}
									requiredField={requiredField}
								/>
								<CoProductionCompany
									helperText={helperText}
									requiredField={requiredField}
								/>
								<Director
									helperText={helperText}
								/>
								<Contributors
									helperText={helperText}
								/>
								<Status
									helperText={helperText}
								/>
								<FilmSetting
									requiredField={requiredField}
									helperText={helperText}
								/>
								<ProductionReleaseDate
									validationField={validationField}
									helperText={helperText}
									requiredField={requiredField}
								/>
								<RightOffered
									helperText={helperText}
								/>
								<VideoTrack
									helperText={helperText}
									requiredField={requiredField}
								/>
								<AudioTrack
									helperText={helperText}
								/>
								<SubtitleTrack
									helperText={helperText}
									requiredField={requiredField}
								/>
								<Snippets
									helperText={helperText}
									requiredField={requiredField}
								/>

								<BudgetForm
									requiredField={requiredField}
								/> */}
							</Fragment>

							{/* <Form.Item className="update-asset-btn">
								<Button
									type="primary"
									htmlType="submit"
									loading={loading}
									disabled={assetDetail && assetDetail.shared_at ? true : false}
									className="code-generator"
								>
									UPDATE ASSET
         		</Button>
							</Form.Item> */}

						</Form>
					}
				</Spin>
			</div>
			{
				visible &&
				<Modal
					visible={visible}
					title={false}
					footer={null}
					onCancel={handleCancel}
					width={(modalType === "select-column" && 400) || (modalType === "select-preset" && 450)}
					className="modal-style"
				>
					{
						modalType === "select-column" &&
						<SelectColumnModal 
						setSelectiveColumns={setSelectiveColumns}
						handleCancel={handleCancel}
						/>
					}
					{
						modalType === "select-preset" &&
						<ColumnsMappingPreset />
					}
				</Modal>
			}
		</Fragment>
	)
}

export default AssetsDetailOptions;