import React from "react";
import { Radio } from "antd";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const TopTitleSection = ({ setStep, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Import Assets File</h3>
      </div>
      <div className="radio-btn-style">
        <Radio.Group
          onChange={(e) => setStep(e.target.value)}
          defaultValue="Convert"
          buttonStyle="solid"
        >
          {getRolesPermissions(mainUserRes, "asset.convert-csv-to-xml") ===
          false ? (
            <Radio.Button value="Convert">Convert CSV to XML</Radio.Button>
          ) : (
            ""
          )}
          {getRolesPermissions(mainUserRes, "asset.import-xml") === false ? (
            <Radio.Button value="Import">Import XML</Radio.Button>
          ) : (
            ""
          )}
        </Radio.Group>
      </div>
    </div>
  );
};

export default TopTitleSection;
