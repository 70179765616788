import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const createDepartment = (data) => {
  const options = { url: `departments` };

  options.types = [
    types.CREATE_DEPARTMENT_SUCCESS,
    types.CREATE_DEPARTMENT_FAILURE
  ];

  return apiNew.post(options, data);
}

export const getDepartmentList = (skip, limit, search) => {
  const options = {
    url: `departments?skip=${skip}&limit=${limit}&order=asc&name=${search}`
  };

  options.types = [
    types.GET_DEPARTMENT_LIST_SUCCESS,
    types.GET_DEPARTMENT_LIST_FAILURE
  ];

  return apiNew.get(options);
}

export const getSpecificDepartmentDetail = (id) => {
  const options = { url: `departments/${id}` };

  options.types = [
    types.GET_SPECIFIC_DEPARTMENT_SUCCESS,
    types.GET_SPECIFIC_DEPARTMENT_FAILURE
  ];

  return apiNew.get(options);
}

export const updateDepartment = (id, data) => {
  const options = { url: `departments/${id}` };

  options.types = [
    types.UPDATE_DEPARTMENT_SUCCESS,
    types.UPDATE_DEPARTMENT_FAILURE
  ];

  return apiNew.put(options, data);
}

export const departmentDelete = (id) => {
  const options = { url: `departments/${id}` };

  options.types = [
    types.DELETE_DEPARTMENT_SUCCESS,
    types.DELETE_DEPARTMENT_FAILURE
  ];

  return apiNew.delete(options);
}
