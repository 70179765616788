import React from "react";

const Footer = () => {
  return (
    <div className="footer-sec">
      <div className="imz-footer">
        <ul className="ant-list-item ant-list-items">
            <li className="pr30">&copy; 2022 IMZ Online Services GmbH</li>
           <li className="pr30">
            <a
              href="https://www.contentagent.net/#partners"
              target="_blank"
              rel="noopener noreferrer"
            >
              Partners
            </a>
          </li>
          <li className="pr30">
            <a
              href="https://www.contentagent.net/#contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href="https://www.contentagent.net/#imprint"
              target="_blank"
              rel="noopener noreferrer"
            >
              Imprint
            </a>
          </li>
        </ul>
        {/* <img src={ImzLogo} alt="ImzLogo" />
        <p>INTERNATIONAL</p>
        <p>MUSIC + MEDIA CENTRE</p> */}
      </div>
      {/* <div className="copy-rights">
        <div className="co-funded">
          <div className="co-funded-text">
            <p>Co-funded by the</p>
            <p>Creative Europe <img src={MediaImage} alt="Media" /> Programme</p>
            <p>of the European Union</p>
          </div>
          <img src={CoFunded} alt="Co Funded" />
        </div>
        <div className="red-box">
          <div>
            <p>wirtschafts</p>
            <p>agentur</p>
            <p>wien</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
