import React, { Fragment, useEffect } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import Message from "./Message";
import {
  getUrlSecondLastId,
  getOnlyHoursMints,
  getRolesPermissions,
} from "../../Common/reUseFunctions";

import { sendInivititionIcon } from "./../../Common/Icons";

const MessagesList = ({
  messages,
  showModal,
  showChat,
  onGetAllMessage,
  getAllMessageRes,
  location,
  mainUserRes,
  getAllTradingTaskRes,
}) => {
  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "chat.list") === false) {
      const interval = setInterval(() => {
        const locationUrl = location.pathname;
        const tradingTaskId = getUrlSecondLastId(locationUrl);
        onGetAllMessage(tradingTaskId);
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [mainUserRes]);

  return (getAllMessageRes &&
    getAllMessageRes.data &&
    getAllMessageRes.data.length > 0) ||
    showChat === "show_chat" ? (
    <Fragment>
      {/* <div className="author-time-role">
        <p>
          {getAllMessageRes &&
            getAllMessageRes.data &&
            getAllMessageRes.data.length > 0 &&
            getAllMessageRes.data[0] &&
            getOnlyHoursMints(getAllMessageRes.data[0].created_at)}
        </p>
        <h5>Ruth Marshall Started Video Call</h5>
      </div> */}
      <div className="messages-list">
        {getAllMessageRes &&
          getAllMessageRes.data.length > 0 &&
          getAllMessageRes.data.map((message) => (
            <Message
              key={message._id}
              message={message}
              getAllTradingTaskRes={getAllTradingTaskRes}
            />
          ))}
      </div>
    </Fragment>
  ) : (
    <div className="messages-list d-flex align-item-center justify-center">
      <div className="send-in-icon">
        {sendInivititionIcon}
        <div className="mt30">
          <Button
            onClick={() => showModal("send_invitation")}
            type="primary"
            className="pl30 pr30"
          >
            SEND INVITATION
          </Button>
        </div>
      </div>
    </div>
  );
};

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default MessagesList;
