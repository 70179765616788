import React, { Fragment } from "react";
import AudioVideoCall from "./../../AudioVideoCall";
// import { Button, Tooltip } from 'antd';
// import { AudioOutlined, PhoneOutlined, VideoCameraOutlined } from '@ant-design/icons';

// import VideoCallImg from '../../../../statics/images/video-bg.png';

// import RingTone from './../../../Common/ring/ringtone.mp3';
// import SignalTone from './../../../Common/ring/signaling.mp3';

const ChatVideoCall = ({ videoCall, dropVideoCall, userId, showModal }) => {
  return (
    <Fragment>
      <AudioVideoCall
        userId={userId}
        videoCall={videoCall}
        showModal={showModal}
      />

      {/* <div className="chat-video-call">
        <div>
          {
            videoCall ?
              <div className="video-call-bg">
                <img src={VideoCallImg} alt="video call" />
                <div className="video-call-btn">
                  <Tooltip title="Audio">
                    <Button shape="circle" icon={<AudioOutlined />} />
                  </Tooltip>
                  <Tooltip title="Call">
                    <Button onClick={dropVideoCall} shape="circle" icon={<PhoneOutlined />} />
                  </Tooltip>
                  <Tooltip title="Video">
                    <Button shape="circle" icon={<VideoCameraOutlined />} />
                  </Tooltip>
                </div>
              </div> :
              <Fragment>
                <h3>No Video Signal</h3>
                <div className="video-call-btn">
                  <Tooltip title="Audio">
                    <Button shape="circle" icon={<AudioOutlined />} />
                  </Tooltip>
                  <Tooltip title="Call">
                    <Button shape="circle" icon={<PhoneOutlined />} />
                  </Tooltip>
                  <Tooltip title="Video">
                    <Button shape="circle" icon={<VideoCameraOutlined />} />
                  </Tooltip>
                </div>
              </Fragment>
          }
        </div>
      </div> */}
    </Fragment>
  );
};

export default ChatVideoCall;
