import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const getTimezones = () => {
  const options = {
    url: `timezones`
  };

  options.types = [
    types.GET_TIMEZONES_SUCCESS,
    types.GET_TIMEZONES_FAILURE
  ];

  return apiNew.get(options);
}