import React, { Fragment, useState } from 'react';
import { Form, Input, Button, Row, Col, TimePicker, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { multiLanguages } from './../../../../../../statics/data/multiLanguages'

const Index = ({
  helperText,
  requiredField
}) => {
  const [selectedLang, setSelectedLang] = useState([]);
  const [selectedMultiLang, setSelectedMultiLang] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const onChangeLanguage = value => {
    setSelectedLang(value);

  };

  const onChangeMultiLanguage = value => {
    setSelectedMultiLang(value);

  };

  const onChange = (time, timeString) => {
    console.log(time, timeString);
  }
  return (

    <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Snippets</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Indicate the snippets available for this asset.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="snippets">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field, index) => (
                    <div>
                      <Row key={index} gutter={16} className="mb20">
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Snippet Title</span></h3></div>
                            <div className="metadata-filed">
                              <div className="d-flex align-item-center">
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'snippetTitle']}
                                  className="full-width mr20 mb0"
                                >
                                  <Input placeholder="Title name" className="full-width" />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, 'language']}
                                  className="full-width mr20 mb0"
                                  rules={[
                                    {
                                      required: false,
                                      pattern: new RegExp("[a-z]{2}(\-[A-Z]{2})?"),
                                      message: "Please enter the correct language!"
                                    }
                                  ]}
                                >
                                  <Select
                                    placeholder="Select language"
                                    value={selectedLang}
                                    onChange={onChangeLanguage}
                                    style={{ width: '100%' }}
                                    showSearch
                                    filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    getPopupContainer={trigger => trigger.parentElement}
                                  >
                                    {multiLanguages.map((item, index) => (
                                      <Select.Option key={index} value={item.value}>
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Multi Language Titles</span></h3></div>
                            <div className="metadata-filed">
                              <Form.List name={[field.name, 'multiLanguageTitles']}>
                                {(fields, { add, remove }) => {
                                  return (
                                    <Fragment>
                                      {fields.map(field => (
                                        <div key={field.key} className="d-flex align-item-center mb10">
                                          <Form.Item
                                            {...field}
                                            name={[field.name, 'value']}
                                            fieldKey={[field.fieldKey, 'value']}
                                            className="full-width mr20 mb0"
                                          >
                                            <Input placeholder="Title name" className="full-width" />
                                          </Form.Item>

                                          <Form.Item
                                            {...field}
                                            name={[field.name, "language"]}
                                            fieldKey={[field.fieldKey, "language"]}
                                            rules={[
                                              {
                                                pattern: new RegExp("[a-z]{2}(\-[A-Z]{2})?"),
                                                message: "Please enter the correct language!"
                                              }
                                            ]}
                                            className="full-width mr20 mb0"
                                          >
                                            <Select
                                              placeholder="Select language"
                                              value={selectedMultiLang}
                                              onChange={onChangeMultiLanguage}
                                              style={{ width: '100%' }}
                                              showSearch
                                              filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                              getPopupContainer={trigger => trigger.parentElement}
                                            >
                                              {multiLanguages.map((item, index) => (
                                                <Select.Option key={index} value={item.value}>
                                                  {item.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Form.Item>

                                          <MinusCircleOutlined
                                            className="ml10 remove-circle-tow"
                                            onClick={() => {
                                              remove(field.name);
                                            }}
                                          />
                                        </div>
                                      ))}

                                      <Form.Item>
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            add();
                                          }}
                                          block
                                        >
                                          <PlusOutlined /> Add Multi Language
                                        </Button>
                                      </Form.Item>
                                    </Fragment>
                                  );
                                }}
                              </Form.List>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Version</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'version']}
                                className="full-width mb20"
                              >
                                <Input placeholder="Version" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Time In</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'timeIn']}
                                className="full-width mb20"
                              >
                                <TimePicker
                                  onChange={onChange}
                                  className="full-width"
                                  getPopupContainer={trigger => trigger.parentElement}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Time Out</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'timeOut']}
                                rules={[
                                  {
                                    pattern: new RegExp("[0-9]+:[0-5][0-9]:[0-5][0-9](\.[0-9]{3})?"),
                                    message: "Please enter the correct time out"
                                  }
                                ]}
                                className="full-width mb20"
                              >
                                <TimePicker
                                  onChange={onChange}
                                  className="full-width"
                                  getPopupContainer={trigger => trigger.parentElement}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '100%' }}
                      >
                        <PlusOutlined /> Add Snippets
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;