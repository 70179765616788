import { licenseAPI } from "../../service/licenseAPI";
import * as types from "../Constants";

export const isccCodeUpdating = (id, data) => {
  const options = { url: `assets/${id}${data}` };

  options.types = [
    types.ISCC_CODE_UPDATING_SUCCESS,
    types.ISCC_CODE_UPDATING_FAILURE,
  ];

  return licenseAPI.patch(options);
};

export const isccCodeGeneration = (id, data) => {
  const options = {
    url: `assets/iscc/${id}`,
  };
  options.types = [
    types.ISCC_CODE_GENERATION_SUCCESS,
    types.ISCC_CODE_GENERATION_FAILURE,
  ];

  return licenseAPI.post(options, data);
};

export const isccGenerationProcess = (id) => {
  const options = {
    url: `assets/iscc/${id}/status`,
  };

  options.types = [
    types.ISCC_CODE_GENERATION_PROCESS_SUCCESS,
    types.ISCC_CODE_GENERATION_PROCESS_FAILURE,
  ];

  return licenseAPI.get(options);
};

export const loadSignLicense = (id) => {
  const options = {
    url: `assets/${id}/license`,
  };

  options.types = [
    types.LOAD_SIGN_LICENSE_SUCCESS,
    types.LOAD_SIGN_LICENSE_FAILURE,
  ];

  return licenseAPI.get(options);
};

export const requestLicenseSignature = (id, data) => {
  const options = {
    url: `assets/sign/${id}`,
  };

  options.types = [
    types.REQUEST_SIGNATURE_SUCCESS,
    types.REQUEST_SIGNATURE_FAILURE,
  ];

  return licenseAPI.requestLicenseSignature(options, data);
};

export const signatureLicenseProcess = (id) => {
  const options = {
    url: `assets/sign/${id}/status`,
  };

  options.types = [
    types.LICENSE_REQUEST_SIGNATURE_PROCESS_SUCCESS,
    types.LICENSE_REQUEST_SIGNATURE_PROCESS_FAILURE,
  ];

  return licenseAPI.get(options);
};

export const publishLicenseBlockchain = (id, data) => {
  const options = {
    url: `assets/publish/${id}`,
  };

  options.types = [
    types.PUBLISH_LICENSE_BLOCKCHAIN_SUCCESS,
    types.PUBLISH_LICENSE_BLOCKCHAIN_FAILURE,
  ];

  return licenseAPI.post(options, data);
};

export const publishAssetMetadata = (id) => {
  const options = {
    url: `assets/${id}/sync`,
  };

  options.types = [
    types.PUBLISH_ASSET_METADATA_SUCCESS,
    types.PUBLISH_ASSET_METADATA_FAILURE,
  ];

  return licenseAPI.get(options);
};

export const unpublishAssetMetadata = (id) => {
  const options = {
    url: `assets/${id}/unsync`,
  };

  options.types = [
    types.UNPUBLISH_ASSET_METADATA_SUCCESS,
    types.UNPUBLISH_ASSET_METADATA_FAILURE,
  ];

  return licenseAPI.get(options);
};
