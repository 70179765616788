import React from 'react';
import AuctionDetail from './AuctionDetial';

const Detail = () => {

  return (
    <AuctionDetail />
  )
}

export default Detail;