import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const uploadCSVReducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPLOAD_CSV_FILE_SUCCESS:
      return { ...state, csvList: action.payload }
    case types.UPLOAD_CSV_FILE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );

      return { ...state, csvList: action.payload }

    case types.UPLOAD_XML_FILE_SUCCESS:
      return { ...state, csvList: action.payload }
    case types.UPLOAD_XML_FILE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );

      return { ...state, xmlList: action.payload }
          
    default:
      return state

      
  }

  
}