import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectionsComp from "../Components/ContentArea/Selections";

import {
  getSelectionsList,
  getSelectionDetail,
  createSelection,
  updateSelection,
  deleteSelection,
  getSelectionAssets,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    selectionsList: state.selectionReducer.selectionsList,
    selectionDetail: state.selectionReducer.selectionDetail,
    createSelection: state.selectionReducer.createSelection,
    updateSelection: state.selectionReducer.updateSelection,
    deleteSelection: state.selectionReducer.deleteSelection,
    sessionRes: state.userReducer.sessionRes,
    selectionAssetsListRes: state.selectionReducer.selectionAssetsListRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSelectionsList: bindActionCreators(getSelectionsList, dispatch),
    onGetSelectionDetail: bindActionCreators(getSelectionDetail, dispatch),
    onCreateSelection: bindActionCreators(createSelection, dispatch),
    onUpdateSelection: bindActionCreators(updateSelection, dispatch),
    onDeleteSelection: bindActionCreators(deleteSelection, dispatch),
    onGetSelectionAssets: bindActionCreators(getSelectionAssets, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionsComp);
