import React from 'react'
import { Row, Col, Button, Pagination } from 'antd'
import { Link } from 'react-router-dom'

const GeneralCatalogue = ({ assets, currentPage, setCurrentPage, addAsset }) => {
  return ((assets && assets.length) ? (
      <>
        <Row gutter={16}>
            { assets.map(asset => (
              <Col className="mb20" span={8} key={asset._id}>
                <Link to="#!">
                  <div className="catalogues-banner catalogue-b-grid">
                    <img
                      className="catalogue-bg-img"
                      src={asset.preview_image}
                      alt="Catalogues"
                    />
                  </div>
                </Link>
                <div className="new-catalogues-info highlights-catalogue s-catalogues-text">
                  <h3>{asset.title.value}</h3>
                  <div className="catalouge-btns">
                    <Button onClick={() => addAsset(asset._id)} type="link" className="p0 border-0">
                      Add
                    </Button>
                    {/* <Button onClick={() => onAddAssetToSpecialCatalogue(catalogue._id)} type="link" className="p0 border-0">
                      Add
                    </Button> */}
                  </div>
                </div>
              </Col>))
            }
        </Row>

      
        <Row gutter={16}>
          <Col span={24} className="d-flex justify-center mt30">
            <Pagination
              defaultCurrent={currentPage}
              defaultPageSize={assets && assets.pagination && assets.pagination.limit}
              onChange={setCurrentPage}
              total={assets && assets.pagination && assets.pagination.total}
            />
          </Col>
        </Row>
      </>
    ) : ''
  )
}

export default GeneralCatalogue
