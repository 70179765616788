import React from "react";
import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showModal,
  sessionRes,
  setSearch,
  onSearch,
  mainUserRes,
}) => {
  return (
    <div>
      <TopTitleSection
        showModal={showModal}
        sessionRes={sessionRes}
        mainUserRes={mainUserRes}
      />
      <InventoryFilters
        setSearch={setSearch}
        onSearch={onSearch}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default HeaderFilter;
