import React, { useState, useEffect } from "react";
import { Button, Spin, Radio } from "antd";
import { WalletOutlined } from "@ant-design/icons";

const WalletModal = ({
  handleCancel,
  setWalletAddress,
  handleWalletCancel,
  onGetWalletList,
  walletList,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    onGetWalletList().then(() => {
      setLoading(false);
    });
  }, []);

  const onChange = (e) => {
    setWalletAddress(e.target.value);
    console.log(`radio checked:${e.target.value}`);
  };
  return (
    <div className="wallet-modal-sec">
      <h1>Select Wallet Account</h1>
      <Spin size="large" spinning={loading}>
        <div className="wallet-modal">
          <Radio.Group onChange={onChange}>
            {walletList &&
              walletList.wallets &&
              walletList.wallets.map((data, index) => {
                return (
                  <Radio.Button key={index} value={data._id}>
                    <span>
                      <WalletOutlined /> {data.name}
                    </span>
                    <span>{data.createdBy}</span>
                    {/* <span>
                      {data.updatedAt}
                    </span> */}
                  </Radio.Button>
                );
              })}
          </Radio.Group>
        </div>
        <Button
          className="full-width"
          loading={loading}
          onClick={() => handleWalletCancel()}
          type="primary"
        >
          Save Changes
        </Button>
      </Spin>
    </div>
  );
};

export default WalletModal;
