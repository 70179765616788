import React from "react";
import { Button } from "antd";
import { pluseFilledIcon } from "./../../../Common/Icons";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const TopTitleSection = ({ showModal, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>My Selections</h3>
      </div>
      {getRolesPermissions(mainUserRes, "selection.create") === false ? (
        <div>
          <p className="add-selection-btn add-new-selection">
            <Button
              type="default"
              className="p0 border-0"
              onClick={() => showModal("add-selection")}
            >
              {pluseFilledIcon} Add New Selection
            </Button>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TopTitleSection;
