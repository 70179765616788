import React from 'react';
import InventorySearchDropDown from "./InventorySearchDropdown";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onFinish,
  showTableEditor,
  setSearch
}) => {

  return (
    <div className="inventory-bottom-filter">
      <InventorySearchDropDown
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        showTableEditor={showTableEditor}
        setSearch={setSearch}
        onFinish={onFinish}
      />
    </div>
  )
}

export default InventorySearchDropdown;