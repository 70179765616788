import React, { Fragment, useEffect, useState } from "react";
import { message } from "antd";
import ProgressScale from "./ProgressScales";
import AssetsDetailOptions from "./AssetsDetailOptions";
import HeaderFilter from "./HeaderFilter/TopTitleSection";

import {
  getUrlLastId,
  getSpecificUserId,
} from "../../../Common/reUseFunctions";

const Detail = ({
  onIsccCodeUpdating,
  isccUpdating,

  onGetAssetDetail,
  assetDetail,

  onUpdateAsset,
  updateAsset,

  onDeleteAsset,
  deleteAsset,

  onIsccCodeGeneration,
  isccGeneration,

  onIsccGenerationProcess,
  isccStatus,

  onLoadSignLicense,
  signLicense,

  onSignatureLicenseProcess,
  signatureStatus,

  onRequestLicenseSignature,
  reqSignature,

  onPublishLicenseBlockchain,
  licensePublish,

  onPublishAssetMetadata,
  publishAsset,

  onUnpublishAssetMetadata,
  UnpublishAsset,

  onGetWalletList,
  walletList,

  onGetWalletDetail,
  walletDetail,

  location,
  onGetMainUserDetail,
  mainUserRes,
  getSpecificUserDetail,
  onGetSpecificUserDetail,
  specificUserImage,
  onGetSpecificUserImage,
  onLogout,
  getNotificationsRes,
  onGetNotifications,
  onGetInventorySeachDetail,
  getInventorySeachDetailRes,
  formUrlIsccGenerationRes,
  onPutformUrlIsccGeneration,
}) => {
  const [assetID, setAssetID] = useState("");
  const [titleEdit, setTitleEdit] = useState(false);
  const [assetTitle, setAssetTitle] = useState(false);
  const [idOfAsset, setIdOfAsset] = useState("");

  useEffect(() => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);
    setAssetID(getUrlPath);
    onGetAssetDetail(getUrlPath);
  }, []);

  const updatingAssetTitle = () => {
    const assTitle = assetDetail && assetDetail.assetTitle;
    if (assTitle !== assetTitle) {
      const data = {
        assetTitle: assetTitle,
      };
      onUpdateAsset(assetID, data).then(() => {
        message.success("Title Updated Successfully");
        setTitleEdit(false);
        onGetAssetDetail(assetID);
      });
    } else {
      setTitleEdit(false);
    }
  };
  return (
    <Fragment>
      <HeaderFilter
        assetDetail={assetDetail}
        titleEdit={titleEdit}
        setTitleEdit={setTitleEdit}
        assetTitle={assetTitle}
        setAssetTitle={setAssetTitle}
        updatingAssetTitle={updatingAssetTitle}
        onGetMainUserDetail={onGetMainUserDetail}
        mainUserRes={mainUserRes}
      />
      <div className="asset-detail-style">
        <div className="form-section">
          {assetDetail && (
            <AssetsDetailOptions
              onIsccCodeUpdating={onIsccCodeUpdating}
              isccUpdating={isccUpdating}
              onGetAssetDetail={onGetAssetDetail}
              assetDetail={assetDetail}
              onUpdateAsset={onUpdateAsset}
              onIsccCodeGeneration={onIsccCodeGeneration}
              isccGeneration={isccGeneration}
              onIsccGenerationProcess={onIsccGenerationProcess}
              isccStatus={isccStatus}
              assetID={assetID}
              mainUserRes={mainUserRes}
              setTitleEdit={setTitleEdit}
              onGetMainUserDetail={onGetMainUserDetail}
              getSpecificUserDetail={getSpecificUserDetail}
              onGetSpecificUserDetail={onGetSpecificUserDetail}
              specificUserImage={specificUserImage}
              onGetSpecificUserImage={onGetSpecificUserImage}
              onLogout={onLogout}
              getNotificationsRes={getNotificationsRes}
              onGetNotifications={onGetNotifications}
              onGetInventorySeachDetail={onGetInventorySeachDetail}
              getInventorySeachDetailRes={getInventorySeachDetailRes}
              idOfAsset={idOfAsset}
              formUrlIsccGenerationRes={formUrlIsccGenerationRes}
              onPutformUrlIsccGeneration={onPutformUrlIsccGeneration}
            />
          )}
        </div>
        <div className="right-side-section">
          {assetDetail && (
            <ProgressScale
              assetID={assetID}
              onGetAssetDetail={onGetAssetDetail}
              assetDetail={assetDetail}
              onUpdateAsset={onUpdateAsset}
              onLoadSignLicense={onLoadSignLicense}
              signLicense={signLicense}
              onSignatureLicenseProcess={onSignatureLicenseProcess}
              signatureStatus={signatureStatus}
              onRequestLicenseSignature={onRequestLicenseSignature}
              reqSignature={reqSignature}
              onPublishLicenseBlockchain={onPublishLicenseBlockchain}
              licensePublish={licensePublish}
              onIsccGenerationProcess={onIsccGenerationProcess}
              isccGeneration={isccGeneration}
              isccStatus={isccStatus}
              onPublishAssetMetadata={onPublishAssetMetadata}
              publishAsset={publishAsset}
              onUnpublishAssetMetadata={onUnpublishAssetMetadata}
              UnpublishAsset={UnpublishAsset}
              onGetWalletList={onGetWalletList}
              walletList={walletList}
              onGetWalletDetail={onGetWalletDetail}
              walletDetail={walletDetail}
              mainUserRes={mainUserRes}
              setTitleEdit={setTitleEdit}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Detail;
