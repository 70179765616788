import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const EmailForPasswordReset = ({
  history,
  resetForgotPasswordRes,
  onForgetPassword,
}) => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const email = {
      email: values.email,
    };
    onForgetPassword(email)
      .then((res) => {
        setLoading(false);
        message.success(res.payload.message);
        history.push("/login");
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="auth-page license-edit-model">
      <div className="main-wrap">
        <div className="authentic-heading">
          <h1>Content.Agent</h1>
        </div>
        <Form
          {...layout}
          name="forget_password"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h3 className="form-title mb0">Forgot Password</h3>
          <p className="mb30">
            Enter you email address we will send you password reset link
          </p>
          <div className="slection-asset-view">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name text-uppercase">
                  <span>Email Address</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: "email",
                        message: "Please input valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="johndoe@sample.com" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <Link to="/login" className="d-flex align-item-center">
            <LeftOutlined className="mr5" /> Back to Login
          </Link>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="full-width mt30 p10 h-auto text-uppercase"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EmailForPasswordReset;
