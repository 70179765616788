import React, { useState } from 'react';
import { Input, Row, Col, Collapse, Select, Slider, Button, } from 'antd';
import { FilterOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Option } = Select;

const marks = {
  1960: '1960',
  1970: '1970',
  1980: '1980',
  1990: '1990',
  2000: '2000',
  2010: '2010',
  2020: '2020',
  2030: 'in production'
};

const Filter = ({
  onSearch,
  history
}) => {
  const [search, setSearch] = useState();
  const [filmGenre, setFilmGenre] = useState();
  const [musicGenre, setMusicGenre] = useState();
  const [minYears, setMinYears] = useState(1995);
  const [maxYears, setMaxYears] = useState(2015);

  const onChangeFilmGenre = value => {
    setFilmGenre(value);
  };

  const onChangeSearch = e => {
    setSearch(e.target.value);
  };

  const onChangeYears = value => {
    if (value[0] < value[1]) {
      setMinYears(value[0]);
      setMaxYears(value[1])
    }
  };

  const onChangeMusicGenre = value => {
    setMusicGenre(value);
  };

  

  const onsubmit = (e) => {
    history.push("/marketplace");
  }

  return (
    <Row gutter={16} className="mb15">
      <Col span={24}>
        {/* <div style={{ background: "#fafafa", padding: "10px" }} className="d-flex justify-center align-item-center">
          <span className="mr20">Search Results</span>
          <Input
            value={search}
            onChange={onSearch}
            prefix={<SearchOutlined />}
            placeholder="Search..."
            className="width-300"
          />

        </div> */}
        <Collapse
          bordered={false}
          defaultActiveKey={['0']}
          expandIcon={({ isActive }) => <FilterOutlined />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="Search by Filters" key="1" className="site-collapse-custom-panel">
            <div className="form-card" style={{ margin: "auto", padding: "0" }}>
              <Row gutter={24}>
                <Col span={24}>
                  <h3>FILTERS</h3>
                </Col>

                <Col span={24}>
                  <div className="d-flex align-item-center">
                    <span style={{ width: "190px" }}>SEARCH NAME</span>
                    <Input placeholder="Search" onChange={onChangeSearch} value={search} />
                  </div>
                </Col>

                <Col span={24}>
                  <div className="d-flex align-item-center">
                    <span style={{ width: "190px" }}>FILM GENRE</span>
                    <Select mode="multiple" placeholder="Please select FILM GENRE" defaultValue={filmGenre} onChange={onChangeFilmGenre}>
                      <Option value="Opera">Opera</Option>
                      <Option value="FireFox">FireFox</Option>
                      <Option value="Microsoft">Microsoft</Option>
                    </Select>
                  </div>
                </Col>

                <Col span={24}>
                  <div className="d-flex align-item-center">
                    <span style={{ width: "190px" }}>MUSIC GENRE</span>
                    <Select mode="multiple" placeholder="Please select MUSIC GENRE" defaultValue={musicGenre} onChange={onChangeMusicGenre}>
                      <Option value="classical">Classical</Option>
                      <Option value="pop">Pop</Option>
                    </Select>
                  </div>
                </Col>

                <Col span={24}>
                  <div className="d-flex align-item-center">
                    <span style={{ width: "190px" }}>PRODUCTION DATE</span>
                    <div style={{ width: "100%", padding: " 0 28px 0 0px" }} className="d-flex">
                      <Button type="link">
                        <LeftOutlined />
                      </Button>
                      <Slider
                        onChange={onChangeYears}
                        min={1960}
                        max={2030}
                        range={true}
                        defaultValue={[minYears, maxYears]}
                        value={[minYears, maxYears]}
                        marks={marks}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </Col>

                <Col className="d-flex flex-end mt30" span={24}>
                  <div>
                    <Button type="primary" onClick={onsubmit}>Search <SearchOutlined /></Button>
                  </div>
                </Col>

              </Row>
            </div>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

export default Filter;