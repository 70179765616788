import React from 'react';
import { Radio } from 'antd';

const TopTitleSection = ({
  onChangeTabs,
  currentTab,
}) => {

  return (
    <div className="my-market-inventory">
      <div><h3>Trading Tasks - {
        (currentTab === 'sale' && 'Sales') ||
        (currentTab === 'purchase' && 'Purchasing') ||
        (currentTab === 'both' && 'Both') ||
        (currentTab === 'archive' && 'Archive')
      }</h3></div>
      <div className="radio-btn-style">
        <Radio.Group onChange={(e) => onChangeTabs(e.target.value)} defaultValue="sale" buttonStyle="solid">
          <Radio.Button value="sale">Sales</Radio.Button>
          <Radio.Button value="purchase">Purchasing</Radio.Button>
          <Radio.Button value="both">Both</Radio.Button>
          <Radio.Button value="archive">Archive</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  )
}

export default TopTitleSection;