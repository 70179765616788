import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const catalogueReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_CATALOGUES_SUCCESS:
      return { ...state, catalogueRes: action.payload };
    case types.GET_CATALOGUES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, catalogueRes: action.payload };

    case types.GET_GENERAL_CATALOGUES_SUCCESS:
      return { ...state, generalCatalogueRes: action.payload };
    case types.GET_GENERAL_CATALOGUES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, generalCatalogueRes: action.payload };

    case types.GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS:
      return { ...state, generalCatalogueRes: action.payload };

    case types.GET_HIGHLIGHTS_OF_ORGANIZATION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, generalCatalogueHighlightRes: action.payload };

    case types.UPDATE_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS:
      return { ...state, generalCatalogueRes: action.payload };

    case types.UPDATE_HIGHLIGHTS_OF_ORGANIZATION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, generalCatalogueHighlightRes: action.payload };

    case types.GET_CATALOGUES_SUCCESS:
      return { ...state, cataloguesList: action.payload };

    case types.GET_CATALOGUES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, cataloguesList: action.payload };

    default:
      return state;
  }
};
