import React from "react";
import { Button, Form } from "antd";
import { getUrlLastId, mergeDateAndTime } from "../reUseFunctions";
import ShowDateTime from "./ShowDateTime";
import { PlusOutlined } from "@ant-design/icons";

const NegotiationInvitationModal = ({
  modelType,
  handleCancel,
  onsendInvitation,
  sendInvitationRes,
  location,
  getAllTradingTaskRes,
  getOrganizationProfileRes,
  tradingTaskId,
}) => {
  // const onChangeUser = (value) => {
  //   console.log(`selected ${value}`);
  // }

  // const onSelectUser = (value) => {
  //   console.log(`selected ${value}`);
  // }

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    let datetimeData = [];
    if (values && values.timeslots && values.timeslots.length > 0) {
      values.timeslots.map((data) => {
        datetimeData.push({ datetime: mergeDateAndTime(data) });
      });
    }
    const invitationBody = {
      time_slots: datetimeData,
    };

    onsendInvitation(tradingTaskId, invitationBody);
    handleCancel("show_chat");
  };

  return (
    <div className="license-edit-model request-p-modal negotiation-modal">
      <h3>Negotiation Invitation</h3>
      <h4 className="mb0">
        {getAllTradingTaskRes &&
          getAllTradingTaskRes.data &&
          getAllTradingTaskRes.data.company_title}
      </h4>
      <div className="negotiation-time">
        <span>
          {getOrganizationProfileRes && getOrganizationProfileRes.city},{" "}
          {getOrganizationProfileRes && getOrganizationProfileRes.country}{" "}
          {getOrganizationProfileRes && getOrganizationProfileRes.timezone}
        </span>
        <span>
          {getOrganizationProfileRes && getOrganizationProfileRes.city}, Local
          Time 2:32AM
        </span>
        <span>Mon-Fri: 9AM to 5PM</span>
      </div>
      <Form name="dynamic_form_nest_item" onFinish={onFinish}>
        <div className="slection-asset-view mb0">
          <div className="formal-license-view">
            <Form.List name="timeslots">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div key={index}>
                        <ShowDateTime
                          getAllTradingTaskRes={getAllTradingTaskRes}
                          getOrganizationProfileRes={getOrganizationProfileRes}
                          field={field}
                          remove={remove}
                        />
                      </div>
                    ))}
                    <Form.Item className="mb0">
                      <p className="add-selection-btn">
                        <Button
                          type="link"
                          className="p0 border-0"
                          onClick={() => {
                            add();
                          }}
                        >
                          <PlusOutlined /> Add New Time Slot
                        </Button>
                      </p>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
        </div>
        <div className="invitation-party-text">
          <p>
            We will let you know once the other party accept the invitation.
          </p>
        </div>
        <div className="formal-license-btn">
          <div className="r-p-btn">
            <Button onClick={handleCancel}>CANCEL</Button>

            <Button
              type="primary"
              htmlType="submit"
              // onClick={() => handleCancel("show_chat")}
            >
              SEND INVITATION
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default NegotiationInvitationModal;
