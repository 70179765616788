import React from "react";
import { Table, Space, Tooltip, Button, Spin, Pagination } from "antd";
import { Link } from "react-router-dom";

const dataSource = [
  {
    status: "Pending",
    fileName: "asset_import.csv",
    noOfAssets: "820",
    columnsMapped: "20 out of 55",
    importDate: "13-09-2020 20:30",
  },
  {
    status: "Pending",
    fileName: "asset_import.csv",
    noOfAssets: "820",
    columnsMapped: "20 out of 55",
    importDate: "13-09-2020 20:30",
  },
  {
    status: "Complete",
    fileName: "asset_import.csv",
    noOfAssets: "820",
    columnsMapped: "20 out of 55",
    importDate: "13-09-2020 20:30",
  },
  {
    status: "Complete",
    fileName: "asset_import.csv",
    noOfAssets: "820",
    columnsMapped: "20 out of 55",
    importDate: "13-09-2020 20:30",
  },
  {
    status: "Complete",
    fileName: "asset_import.csv",
    noOfAssets: "820",
    columnsMapped: "20 out of 55",
    importDate: "13-09-2020 20:30",
  },
];

const UserManagementBox = ({
  loading,
  onClickSelection,
  delConfirmModal,
  userManagementList,
  onChangePagination,
  currentPage,
}) => {
  const columns = [
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "FILE NAME",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "NO OF ASSETS",
      dataIndex: "noOfAssets",
      key: "noOfAssets",
    },
    {
      title: "COLUMNS MAPPED",
      dataIndex: "columnsMapped",
      key: "columnsMapped",
    },
    {
      title: "IMPORT DATE",
      dataIndex: "importDate",
      key: "importDate",
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table usre-management-action">
          {record.status === "Pending" ? (
            <Tooltip title="Open">
              <Link to="/columns-mapping">
                <Button type="link">
                  <u>Open</u>
                </Button>
              </Link>
            </Tooltip>
          ) : (
            " "
          )}

          <Tooltip title="Delete">
            <Button type="link" onClick={() => delConfirmModal(record)}>
              <u>Delete</u>
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        className="table-style  task-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              onClickSelection && onClickSelection(record);
            },
          };
        }}
      />
      <div span={24} className="d-flex justify-center mt50">
        <Pagination
          defaultCurrent={currentPage}
          defaultPageSize={
            userManagementList &&
            userManagementList.pagination &&
            userManagementList.pagination.limit
          }
          onChange={onChangePagination}
          total={
            userManagementList &&
            userManagementList.pagination &&
            userManagementList.pagination.total
          }
        />
      </div>
    </Spin>
  );
};

export default UserManagementBox;
