import React from "react";
import { Switch } from "antd";

const InventorySwitchFilter = ({
  setMarketReady,
  marketReady,
  setNotMarketReady,
  notMarketReady,

  setPublishedAssets,
  publishedAssets,
  assetsList,
}) => {
  const onNonNarketReadyAssets = (checked) => {
    setNotMarketReady(checked);
  };

  const onPublishedAssets = (checked) => {
    setPublishedAssets(checked);
  };

  const onMarketReadyAssets = (checked) => {
    setMarketReady(checked);
  };

  return (
    <div>
      <div className="inventry-switch-btns">
        <span>
          <Switch
            checked={publishedAssets}
            onChange={() => onPublishedAssets(!publishedAssets)}
            size="small"
          />
          Published Assets:{" "}
          {assetsList &&
            assetsList.filter_count &&
            assetsList.filter_count.published}
        </span>
        <span>
          <Switch
            checked={marketReady}
            onChange={() => onMarketReadyAssets(!marketReady)}
            size="small"
          />{" "}
          Market-Ready Assets:{" "}
          {assetsList &&
            assetsList.filter_count &&
            assetsList.filter_count.market_ready}
        </span>
        <span>
          <Switch
            checked={notMarketReady}
            onChange={() => onNonNarketReadyAssets(!notMarketReady)}
            size="small"
          />
          Non Market-Ready Assets:{" "}
          {assetsList &&
            assetsList.filter_count &&
            assetsList.filter_count.not_market_ready}
        </span>
      </div>
    </div>
  );
};

export default InventorySwitchFilter;
