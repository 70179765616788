import React from 'react';

import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./InventorySearchDropdown";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onChangeSelectionType,
  onFinish,
  showTableEditor,
  setStatusFilter,
  statusFilter,
  setBlockedFilter,
  blockedFilter,
  setUnBlockedFilter,
  unBlockedFilter,
  setPendingFilter,
  pendingFilter,
  setSelectionTypeChange,
  onSearch,
}) => {

  return (
    <div className="inventory-bottom-filter">
      <InventorySwitchSec
        onChangeSelectionType={onChangeSelectionType}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setBlockedFilter={setBlockedFilter}
        blockedFilter={blockedFilter}
        setUnBlockedFilter={setUnBlockedFilter}
        unBlockedFilter={unBlockedFilter}
        setPendingFilter={setPendingFilter}
        pendingFilter={pendingFilter}
        setSelectionTypeChange={setSelectionTypeChange}
      />
      <InventorySearchDropDown
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
        onSearch={onSearch}
      />
    </div>
  )
}

export default InventorySearchDropdown;