import React, { useState } from 'react';
import { Button, Form, Input, Spin, message } from 'antd';

const ChangePassword = ({
  handleCancel,
  onChangeUserPassword,
  onLogout,
  history,
}) => {

  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);
    console.log('Received values of form: ', values);

    const data = {
      current_password: values.current_password,
      new_password: values.confirm_password,
    }
    onChangeUserPassword(data).then((res) => {

      setLoading(false);
      message.success(res.payload.message);
      handleCancel();
      if (res.type === "UPDATE_NEW_PASSWORD_SUCCESS") {
        localStorage.clear()
        window.location.reload();
      }
    }).catch((res) => {
      setLoading(false);
      message.success(res.payload.message);
    })
  };

  return (
    <div className="edit-profile-style change-password-sec">
      <h1>Change Password</h1>
      <Spin size="large" spinning={loading}>
        <Form
          name="validate_other"
          onFinish={onFinish}
        >

          <div className="slection-asset-view" style={{ padding: "0px" }}>
            <div className="formal-license-view password-form">

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>CURRENT PASSWORD</span>
                </div>  
                <div className="formal-license-data">
                  <Form.Item
                    name="current_password"
                    rules={[{ required: true, message: 'Please input your current password!' }]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>NEW PASSWORD</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>RETYPE PASSWORD</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="confirm_password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Entered passwords do not match!');
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </div>
              </div>

            </div>
          </div>
          <div className="edit-profile-btn">
            <Button htmlType="submit" type="primary">
              CHANGE PASSWORD
          </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}

export default ChangePassword;