import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

export const getSelectionsList = (
  search = "",
  limit,
  skip,
  selectSales,
  selectPurchase
) => {
  const sales = selectSales === true ? 1 : 0;
  const purchase = selectPurchase === false ? 0 : 1;
  const options = {
    url: `selections?name=${search}&limit=${limit}&skip=${skip}&sale=${sales}&purchase=${purchase}`,
  };

  options.types = [
    types.GET_SELECTIONS_LIST_SUCCESS,
    types.GET_SELECTIONS_LIST_FAILURE,
  ];

  return apiNew.get(options);
};

export const getSelectionDetail = (id) => {
  const options = { url: `selections/${id}` };

  options.types = [
    types.GET_SELECTION_DETAIL_SUCCESS,
    types.GET_SELECTION_DETAIL_FAILURE,
  ];

  return apiNew.get(options);
};

export const createSelection = (data) => {
  const options = { url: `selections` };

  options.types = [
    types.CREATE_SELECTION_SUCCESS,
    types.CREATE_SELECTION_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const updateSelection = (id, data) => {
  const options = { url: `selections/${id}` };

  options.types = [
    types.UPDATE_SELECTION_SUCCESS,
    types.UPDATE_SELECTION_FAILURE,
  ];

  return apiNew.put(options, data);
};

export const deleteSelection = (id) => {
  const options = { url: `selections/${id}` };

  options.types = [
    types.DELETE_SELECTION_SUCCESS,
    types.DELETE_SELECTION_FAILURE,
  ];

  return apiNew.delete(options);
};

export const getSelectionAssets = (id) => {
  const options = { url: `selections/${id}/items` };

  options.types = [
    types.GET_ASSETS_SELECTION_SUCCESS,
    types.GET_ASSETS_SELECTION_FAILURE,
  ];

  return apiNew.get(options);
};

export const delecteSelectionAssets = (selectionId, itemId) => {
  const options = { url: `selections/${selectionId}/items/${itemId}` };

  options.types = [
    types.DELETE_SELECTION_ASSET_SUCCESS,
    types.DELETE_SELECTION_ASSET_FAILURE,
  ];

  return apiNew.delete(options);
};

export const addFormalLicenseDefinition = (selectionId, itemId, data) => {
  const options = { url: `selections/${selectionId}/items/${itemId}/licenses` };

  options.types = [
    types.ADD_FORMAL_LICENSE_SUCCESS,
    types.ADD_FORMAL_LICENSE_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const getFormalLicenseList = (selectionId, itemId) => {
  const options = { url: `selections/${selectionId}/items/${itemId}/licenses` };

  options.types = [
    types.GET_FORMAL_LICENSE_LIST_SUCCESS,
    types.GET_FORMAL_LICENSE_LIST_FAILURE,
  ];

  return apiNew.get(options);
};

export const deleteFormalLicense = (selectionId, itemId, licenseId) => {
  const options = {
    url: `selections/${selectionId}/items/${itemId}/licenses/${licenseId}`,
  };

  options.types = [
    types.DELETE_FORMAL_LICENSE_LIST_SUCCESS,
    types.DELETE_FORMAL_LICENSE_LIST_FAILURE,
  ];

  return apiNew.delete(options);
};

export const updateFormalLicense = (
  selectionId,
  itemId,
  licenseId,
  licenseBody
) => {
  const options = {
    url: `selections/${selectionId}/items/${itemId}/licenses/${licenseId}`,
  };

  options.types = [
    types.UPATE_FORMAL_LICENSE_LIST_SUCCESS,
    types.UPATE_FORMAL_LICENSE_LIST_FAILURE,
  ];

  return apiNew.put(options, licenseBody);
};

export const getAllOrganization = () => {
  const options = { url: `organizations/all` };

  options.types = [
    types.GET_ALL_ORGANIZATION_SUCCESS,
    types.GET_ALL_ORGANIZATION_FAILURE,
  ];

  return apiNew.get(options);
};
