import React from 'react';
import { Input } from 'antd'
import { searchIcon } from './../../../../Common/Icons';

const HeaderFilter = () => {
  return (
    <div className="d-flex flex-end mt30">  
      <div className="inventory-search-bar">
      <Input placeholder="Search" prefix={searchIcon} />
      </div>
    </div>
  )
}

export default HeaderFilter;