import React from "react";
import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({ showModal, onSearch, mainUserRes }) => {
  return (
    <div>
      <TopTitleSection showModal={showModal} mainUserRes={mainUserRes} />
      <InventoryFilters mainUserRes={mainUserRes} onSearch={onSearch} />
    </div>
  );
};

export default HeaderFilter;
