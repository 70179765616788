import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TaskAssignment from "../Components/ContentArea/Administration/TaskAssignment";

import {
  getAssignedTasks,
  createAssignedTask,
  updateAssignedTask,
  deleteAssignedTask,
  getAllOrganizations,
  getAllUsers,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    organizationRes: state.catalogueReducer.generalCatalogueRes,
    usersRes: state.taskAssigmentReducer.userManagementList,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAssignedTasks: bindActionCreators(getAssignedTasks, dispatch),
    onCreateAssignedTask: bindActionCreators(createAssignedTask, dispatch),
    onUpdateAssignedTask: bindActionCreators(updateAssignedTask, dispatch),
    onDeleteAssignedTask: bindActionCreators(deleteAssignedTask, dispatch),
    onGetOrganizations: bindActionCreators(getAllOrganizations, dispatch),
    onGetUsers: bindActionCreators(getAllUsers, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAssignment);
