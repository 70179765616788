import React, { useState, useEffect } from "react";
import { Row, Col, Pagination, Spin, message } from "antd";
import Filter from "./HeaderFilter/TopTitleSection";
import SpecialCatalogue from "./SpecialCataloguesAssets";
import AddEditCatalogue from "./AddEditCatalogue";
import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const SpecialCatalogues = ({
  onGetSpecialCatalogues,
  onCreateSpecialCatalogue,
  onUpdateSpecialCatalogue,
  onDeleteSpecialCatalogue,
  mainUserRes,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ visible: false });

  useEffect(() => {
    onGet();
  }, [currentPage, mainUserRes]);

  const onGet = () => {
    if (getRolesPermissions(mainUserRes, "special-catalogue.list") === false) {
      const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
      setLoading(true);
      onGetSpecialCatalogues(skip, 9)
        .then(({ payload }) => {
          setLoading(false);
          setData(payload);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const onCreate = (data) => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;

    setLoading(true);
    if (
      getRolesPermissions(mainUserRes, "special-catalogue.create") === false
    ) {
      onCreateSpecialCatalogue(data)
        .then((response) => {
          setLoading(false);
          setModal({ visible: false });
          onGet(skip, 9);
          message.success(response.payload.message);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Try again.");
        });
    } else {
      setLoading(false);
    }
  };

  const onUpdate = (data, id) => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;

    setLoading(true);

    onUpdateSpecialCatalogue(id, data)
      .then((response) => {
        setLoading(false);
        setModal({ visible: false });
        onGet(skip, 9);
        message.success(response.payload.message);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Try again.");
      });
  };

  const onDelete = (id) => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;

    setLoading(true);

    onDeleteSpecialCatalogue(id)
      .then((response) => {
        setLoading(false);
        setModal({ visible: false });
        onGet(skip, 9);
        message.success(response.payload.message);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Try again.");
      });
  };

  const handleCancel = () => {
    setModal({ visible: false });
  };

  return (
    <div>
      <Filter mainUserRes={mainUserRes} onShowModal={setModal} />
      <Spin size="large" spinning={loading}>
        <SpecialCatalogue
          data={data.special_catalogues}
          onShowModal={setModal}
          mainUserRes={mainUserRes}
        />
      </Spin>
      {data.special_catalogues && data.special_catalogues.length > 0 && (
        <Row gutter={16}>
          <Col span={24} className="d-flex justify-center mt30">
            <Pagination
              defaultCurrent={currentPage}
              defaultPageSize={data && data.pagination && data.pagination.limit}
              onChange={setCurrentPage}
              total={data && data.pagination && data.pagination.total}
            />
          </Col>
        </Row>
      )}

      {modal.visible &&
        (modal.type && modal.type === "delete-catalogue" ? (
          <DeleteConfirmation
            visible={modal.visible}
            handleCancel={handleCancel}
            selectedData={modal.payload}
            onDelete={() => {}}
            onDeleteItem={onDelete}
          />
        ) : (
          <AddEditCatalogue
            loading={loading}
            onGet={onGet}
            onCreate={onCreate}
            onUpdate={onUpdate}
            onDelete={onDelete}
            modal={modal}
            handleCancel={handleCancel}
          />
        ))}
    </div>
  );
};

export default SpecialCatalogues;
