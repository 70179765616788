import React from 'react';
import { Button, Dropdown, Menu, Badge } from 'antd'
import { settingIconTable } from '../Icons';

const GearButton = ({
  showTableEditor,
  changeLayout
}) => {

  const gearMenu = (
    <Menu className="profile-menu tabe-editor-menu">
      <Menu.Item key="0">
        <p onClick={() => showTableEditor('table_editor')}>
          Data Attributes
        </p>
      </Menu.Item>
      <Menu.Item key="1">
        <p onClick={() => showTableEditor('cascading_sort')} className="drop-dot-badge">
          <Badge dot={true}>
            Cascading Sort
            </Badge>
        </p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={gearMenu} trigger={["click"]} className="dot-badge">
      <Badge dot={true}>
        <Button
          type="default"
          className="editor-gyer"
          disabled={changeLayout && changeLayout === "GRID" ? true : false}
          icon={settingIconTable}

        />
      </Badge>
    </Dropdown>
  )
}

export default GearButton;