import React, { useState } from "react";
import { Upload, message, Button, Progress, Typography } from "antd";
import Filter from "./HeaderFilter";
import {
  UPLOAD_CSV_FILE_SUCCESS,
  UPLOAD_XML_FILE_SUCCESS,
} from "../../../../store/Constants";
import { importIcon, tickIcon } from "../../../Common/Icons";

const { Title } = Typography;

const ImportCSV = ({ onUploadCSV, onUploadXML, mainUserRes }) => {
  const [uploadFileCSV, setUploadFileCSV] = useState(false);
  const [uploadFileXML, setUploadFileXML] = useState(false);
  const [step, setStep] = useState("Convert");
  const { Dragger } = Upload;
  const [percentCSV, setPercentCSV] = useState(0);
  const [percentXML, setPercentXML] = useState(0);
  const [xmlResponse, setXmlResponse] = useState({
    message: "File Uploading",
    count: 0,
  });
  const [file, setFile] = useState(null);

  const uploadServiceCSV = (file) => {
    const formData = new FormData();
    formData.append("csv_file", file);

    const config = {
      onUploadProgress: function (progressEvent) {},
      onDownloadProgress: function (progressEvent) {
        // var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        // setPercentCSV(percentCompleted)
      },
    };

    setUploadFileCSV(true);

    let intervalCSV = setInterval(() => {
      setPercentCSV((percent) => percent + 0.5);
    }, 2000);

    onUploadCSV(formData, config).then((response) => {
      if (response.type === UPLOAD_CSV_FILE_SUCCESS) {
        const blob = new Blob([response.payload], { type: "text/xml" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const filename = new Date().getTime() + "-csv-to-xml.xml";
        link.href = url;

        link.setAttribute("download", filename);

        setFile(link);
        if (percentCSV >= 100) {
          clearInterval(intervalCSV);
          message.success(`File uploaded successfully`);
        }
        setPercentCSV(100);
      }
    });
  };

  const uploadServiceXML = (file) => {
    const formData = new FormData();
    formData.append("xml_file", file);

    const config = {
      onUploadProgress: function (progressEvent) {
        // const progress = progressEvent.progress
        // setPercentXML(progress)
      },
      onDownloadProgress: function (progressEvent) {},
    };

    setUploadFileXML(true);

    let intervalXML = setInterval(() => {
      setPercentXML((percent) => percent + 1);
    }, 300);

    onUploadXML(formData, config).then((response) => {
      setXmlResponse({
        message: response.payload.message,
        count: response.payload.count,
      });
      if (percentXML >= 100) {
        clearInterval(intervalXML);
        message.success(`Assets imported successfully`);
      }
      setPercentXML(100);
    });
  };

  const downloadHandler = () => {
    document.body.appendChild(file);
    file.click();
    file.parentNode.removeChild(file);
  };

  const propsCSV = {
    name: "file",
    action: "//jsonplaceholder.typicode.com/posts/",
    accept: ".csv",
    async customRequest(options) {
      // options.onProgress({ percent: 0 });
      const url = await uploadServiceCSV(options.file);
      // options.onProgress({ percent: 90 });
      options.onSuccess({}, { ...options.file, url });
    },
    onChange(info) {
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const propsXML = {
    name: "file",
    action: "//jsonplaceholder.typicode.com/posts/",
    accept: ".xml",
    async customRequest(options) {
      // options.onProgress({ percent: 0 });
      const url = await uploadServiceXML(options.file);
      // options.onProgress({ percent: 90 });
      options.onSuccess({}, { ...options.file, url });
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="csv-upload">
      <Filter mainUserRes={mainUserRes} setStep={setStep} />
      {step === "Convert" ? (
        <>
          <div
            className="csv-upload-circleprogress"
            style={{ display: uploadFileCSV ? "block" : "none" }}
          >
            <div className="csv-upload-circleprogress-area-parent">
              <div className="csv-upload-circleprogress-area">
                <Progress
                  type="circle"
                  strokeColor={{
                    "0%": "#FF5E5E ",
                    "100%": "#FF246E ",

                  }}
                  percent={percentCSV}
                  strokeWidth={8}
                  width={150}
                  showInfo={true}
                  format={(percent) =>
                    percent < 100 ? `${percent}` : tickIcon
                  }
                  // format={percent => percent < 100 ? `<div dangerouslySetInnerHTML={{__html: '<span>${percent}</span> <span>First &middot; Second</span>'}}></div>` : tickIcon}
                  // format={percent => percent, checkmark}
                />
                {percentCSV < 100 ? (
                  <p className="ant-progress-text2">upload in progress</p>
                ) : (
                  ""
                )}
                <div className="csv-upload-detail">
                  <p className="ant-upload-text">XML successfully created</p>
                  {percentCSV < 100 && (
                    <p className="ant-upload-hint">
                      Please don't refresh or close this browser window.
                    </p>
                  )}
                  <Button
                    onClick={downloadHandler}
                    type="primary"
                    disabled={percentCSV < 100}
                  >
                    DOWNLOAD XML FILE
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="draggerAlign">
            <Dragger
              {...propsCSV}
              style={{ display: uploadFileCSV ? "none" : "block" }}
            >
              <div className="add-asset-icon">{importIcon}</div>
              <div className="csv-upload-detail">
                <p className="ant-upload-text">Upload CSV File</p>
                {/* <Title level={2}>Upload CSV File</Title> */}
                <p className="ant-upload-hint">
                  Drag and drop file here to import the asset in bulk.
                </p>
                <Button type="primary">UPLOAD FILE</Button>
              </div>
            </Dragger>
          </div>
        </>
      ) : (
        <>
          <div
            className="csv-upload-circleprogress"
            style={{ display: uploadFileXML ? "block" : "none" }}
          >
            <div className="csv-upload-circleprogress-area-parent">
              <div className="csv-upload-circleprogress-area">
                <Progress
                  type="circle"
                  strokeColor={{
                    "0%": "#FF5E5E ",
                    "100%": "#FF246E ",

                  }}
                  percent={percentXML}
                  strokeWidth={8}
                  width={150}
                  format={(percent) => (percent < 100 ? percent : tickIcon)}
                />
                <div className="csv-upload-detail">
                  <p className="ant-upload-text">{xmlResponse.message}</p>
                  <p className="ant-upload-hint">
                    {xmlResponse.count
                      ? `${xmlResponse.count} Total Assets`
                      : `Please don't refresh or close this browser window.`}
                  </p>
                  {/* <Button type="primary">Start Mapping</Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="draggerAlign">
            <Dragger
              {...propsXML}
              style={{ display: uploadFileXML ? "none" : "block" }}
            >
              <div className="add-asset-icon">{importIcon}</div>
              <div className="csv-upload-detail">
                <p className="ant-upload-text">Import XML File</p>
                <p className="ant-upload-hint">
                  Drag and drop file here to import the asset in bulk.
                </p>
                <Button type="primary">UPLOAD FILE</Button>
              </div>
            </Dragger>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportCSV;
