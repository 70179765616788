import React from "react";
import { Row, Col, Button, Skeleton, Empty } from "antd";
import { Link } from "react-router-dom";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const SpecialCataloguesAssets = ({ data, onShowModal, mainUserRes }) => {
  return (
    <div>
      <Row gutter={16}>
        {data &&
          data.map((catalogue) => (
            <Col className="mb20" span={8} key={catalogue._id}>
              <Link to={`/special-catalogues-detail/${catalogue._id}`}>
                <div className="new-items-grid catalogues-banner catalogue-b-grid">
                  {catalogue.banner ? (
                    <img
                      className="catalogue-bg-img new-items-grid-img"
                      src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${catalogue.banner}/image`}
                      alt={catalogue.name}
                    />
                  ) : (
                    <Skeleton.Image />
                  )}
                  <div className="catalogues-b-text new-items-grid-img-overlay">
                    <div>
                      <h2 className="new-items-grid-img-overlay-text">
                        {catalogue.name}
                      </h2>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="new-catalogues-info highlights-catalogue s-catalogues-text">
                <h3>{catalogue.name}</h3>
                <h4>
                  <span>No of Assets:</span> {catalogue.assets}
                </h4>
                <div className="catalouge-btns s-catalouge-btns">
                  {getRolesPermissions(
                    mainUserRes,
                    "special-catalogue.edit"
                  ) === false ? (
                    <Button
                      type="link"
                      className="p0 border-0"
                      onClick={() =>
                        onShowModal({
                          visible: true,
                          type: "edit-catalogue",
                          payload: catalogue,
                        })
                      }
                    >
                      Edit
                    </Button>
                  ) : (
                    ""
                  )}
                  {getRolesPermissions(
                    mainUserRes,
                    "special-catalogue.delete"
                  ) === false ? (
                    <Button
                      type="link"
                      className="p0 border-0"
                      onClick={() =>
                        onShowModal({
                          visible: true,
                          type: "delete-catalogue",
                          payload: catalogue._id,
                        })
                      }
                    >
                      Delete
                    </Button>
                  ) : (
                    <div className="d-flex justify-center full-width">
                      <Empty />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          ))}

        {/* <Col className="mb20" span={8}>
          <AddNewCatalogue />
        </Col> */}
      </Row>
    </div>
  );
};

export default SpecialCataloguesAssets;
