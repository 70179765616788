import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const getFormalLicense = (id, assetId) => {
  const options = {
    url: `trading-tasks/${id}/assets/${assetId}/licenses`
  };

  options.types = [
    types.GET_FORMAL_LICENSE_SUCCESS,
    types.GET_FORMAL_LICENSE_FAILURE
  ];

  return apiNew.get(options);
}

export const createFormalLicense = (tradingId, assetId, data) => {
  const options = {
    url: `trading-tasks/${tradingId}/assets/${assetId}/licenses`
  };

  options.types = [
    types.CREATE_FORMAL_LICENSE_SUCCESS,
    types.CREATE_FORMAL_LICENSE_FAILURE
  ];

  return apiNew.post(options, data);
}

export const updateFormalLicenseForm = (tradingId, assetId, licenseId, data) => {
  const options = {
    url: `trading-tasks/${tradingId}/assets/${assetId}/licenses/${licenseId}`
  };

  options.types = [
    types.UPDATE_FORMAL_LICENSE_SUCCESS,
    types.UPDATE_FORMAL_LICENSE_FAILURE
  ];

  return apiNew.put(options, data);
}

export const deleteFormalLicenseForm = (tradingId, assetId, licenseId) => {
  const options = {
    url: `trading-tasks/${tradingId}/assets/${assetId}/licenses/${licenseId}`
  };

  options.types = [
    types.DELETE_FORMAL_LICENSE_SUCCESS,
    types.DELETE_FORMAL_LICENSE_FAILURE
  ];

  return apiNew.delete(options);
}

export const cancelNegotiation = (tradingId) => {
  const options = {
    url: `trading-tasks/${tradingId}/cancel`
  };

  options.types = [
    types.CANCEL_NEGOTIATION_SUCCESS,
    types.CANCEL_NEGOTIATION_FAILURE
  ];

  return apiNew.get(options);
}

export const uploadAgreementFile = (tradingId, data) => {
  const options = {
    url: `trading-tasks/upload-agreement-file/${tradingId}`
  };

  options.types = [
    types.UPLOAD_AGREEMENT_FILE_SUCCESS,
    types.UPLOAD_AGREEMENT_FILE_FAILURE
  ];

  return apiNew.post(options, data);
}

export const getLogsList = (tradingId) => {
  const options = {
    url: `trading-tasks/${tradingId}/logs`
  };

  options.types = [
    types.GET_LOGS_LIST_SUCCESS,
    types.GET_LOGS_LIST_FAILURE
  ];

  return apiNew.get(options);
}

export const sendInvitation = (id, data) => {
  const options = {
    url: `trading-tasks/invite/${id}`
  };

  options.types = [
    types.SEND_INVITATIONS_SUCCESS,
    types.SEND_INVITATIONS_FAILURE
  ];

  return apiNew.post(options, data);
}
