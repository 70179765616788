import React, { useState } from "react";
import InventoryFilterBar from "./InventoryFiltersBar";
import InventorySearchResult from "./InventoryFiltersBar/InventorySearchResult";

const MyMarketInventory = ({
  onChangeLayout,
  changeLayout,
  showTableEditor,
  onChangeSelectionType,
  selectSales,
  setSelectSales,
  selectPurchase,
  setSelectPurchase,
  setSelectionTypeChange,
  onSearch,
  mainUserRes,
}) => {
  const [searchResult, setSearchResult] = useState([]);

  const onFinish = (values) => {
    console.log("Received values of form:", values);
    setSearchResult(values.inventory_search_filter);
  };

  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onChangeSelectionType={onChangeSelectionType}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
        selectSales={selectSales}
        setSelectSales={setSelectSales}
        selectPurchase={selectPurchase}
        setSelectPurchase={setSelectPurchase}
        setSelectionTypeChange={setSelectionTypeChange}
        onSearch={onSearch}
        mainUserRes={mainUserRes}
      />
      {searchResult.length > 0 && (
        <InventorySearchResult
          searchResult={searchResult}
          setSearchResult={setSearchResult}
        />
      )}
    </div>
  );
};

export default MyMarketInventory;
