import React, { useState } from 'react';
import { Form, Space, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import Title from 'antd/lib/skeleton/Title';
import { valueInString } from "./../reUseFunctions";

const orderArray = [
  {
    name: "Smallest to Largest",
    value: "asc"
  },
  {
    name: "Largest to Smallest",
    value: "desc"
  },
]

const { Option } = Select;

const CascadingFilterItem = ({
  field,
  remove,

  dropdownItems,
  setDropdownResult,
  selectedArr,

  onRemovingFilter,
  setDisableAdd,

  getInventoryHeaderRes
}) => {
  const [searchFilterValue, setSearchFilterValue] = useState("");

  const onChangeDropDownValue = (value) => {
    setSearchFilterValue(value);
    setDropdownResult(value);
    setDisableAdd(false);
  }

  return (
    <Space key={field.key} style={{ display: 'flex', marginBottom: 8, alignItems: "baseline", justifyContent: "flex-start" }} align="start">
      <span className="cascading-short">Sort By</span>
      <Form.Item
        {...field}
        name={[field.name, 'sort']}
        fieldKey={[field.fieldKey, 'sort']}
        rules={[{ required: true }]}
        className="select-width"
      >
        <Select
          placeholder="Please select..."
          onChange={onChangeDropDownValue}
        >
          {getInventoryHeaderRes && getInventoryHeaderRes.headers.map((item, index) => (
            <Select.Option key={index} value={item} disabled={
              ((selectedArr && selectedArr.length > 0) && selectedArr.includes(item)) ?
                true : false
            }>
              <span>
                {valueInString(item)}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      
      <Form.Item
        {...field}
        name={[field.name, 'order']}
        fieldKey={[field.fieldKey, 'order']}
        rules={[{ required: true }]}
        className="input-width"
      >
        <Select
        placeholder="Please select..."
        onChange={onChangeDropDownValue}
        >
          {orderArray.map((item, index) => (
            <Select.Option key={index} value={item.value}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <MinusCircleOutlined
        onClick={() => onRemovingFilter(remove, field.name, searchFilterValue)}
      />
    </Space>
  )
}

export default CascadingFilterItem;