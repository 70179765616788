import React from 'react';

import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./InventorySearchDropdown";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  changeInventory,
  showTableEditor,
  changeSelectionView
}) => {

  return (
    <div className="inventory-bottom-filter">
      <div>
        {
          changeSelectionView === "Asset" &&
          <InventorySwitchSec changeInventory={changeInventory} />
        }
      </div>
      <InventorySearchDropDown
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        showTableEditor={showTableEditor}
      />
    </div>
  )
}

export default InventorySearchDropdown;