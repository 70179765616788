import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

export const formUrlIsccGeneration = (id, data) => {
  const options = {
    url: `assets/iscc-from-url/${id}`,
  };
  options.types = [
    types.POST_ISCC_CODE_URL_SUCCESS,
    types.POST_ISCC_CODE_URL_FAILURE,
  ];

  return apiNew.post(options, data);
};
