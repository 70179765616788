import React from 'react';
import InventoryFilterBar from "./InventoryFiltersBar"

const MyMarketInventory = ({
  setSearch,
  onFinish,
  onChangeLayout,
  changeLayout,
  showTableEditor,
}) => {

  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
        setSearch={setSearch}
      />
    </div>
  )
}

export default MyMarketInventory;