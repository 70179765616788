import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SidebarComp from '../Components/Sidebar';

import {
  getAssetsList,
  createAsset,

  getSelectionsList,
  createSelection,

  mianUserDetail
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    assetsList: state.assetReducer.assetsList,
    createAsset: state.assetReducer.createAsset,

    selectionsList: state.selectionReducer.selectionsList,
    createSelection: state.selectionReducer.createSelection,

    mainUserRes: state.userReducer.mainUserRes, 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAssetsList: bindActionCreators(getAssetsList, dispatch),
    onCreateAsset: bindActionCreators(createAsset, dispatch),
    onGetSelectionsList: bindActionCreators(getSelectionsList, dispatch),
    onCreateSelection: bindActionCreators(createSelection, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarComp);
