import React from 'react';
import {  Row, Col  } from 'antd';

import { DownOutlined } from '@ant-design/icons';

const Titles = ({
  showModal
}) => {

  return (
    <Row gutter={16} className="mb5 error-info-pink">
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name"><h3 className=""><span>Title</span></h3></div>
          <div className="map-to-sec space-between">
            <div className="error-message-correction">
              Lion King
            </div>
            <div className="map-to">
              <span>System Values:</span>
              <div className="map-select-colmn" onClick={()=>showModal("select-column") }>
                The Lion Kink
                <DownOutlined />
              </div>
            </div>
            
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Titles;