import React  from 'react';
import {   Row, Col  } from 'antd';

import { DownOutlined } from '@ant-design/icons';

const Titles = ({
  showModal,
  valuesCorrection
}) => {
  
  return (
    <Row gutter={16} className={!valuesCorrection ? "mb5 error-info-pink" : "mb5"}>
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name"><h3 className=""><span>Title</span></h3></div>
          <div className="map-to-sec">
            <div className="map-to">
              <span>Map to:</span>
              <div className="map-select-colmn" onClick={()=>showModal("select-column") }>
              {
                !valuesCorrection ? "Select Column" : "asset_title"
              } 
                <DownOutlined />
              </div>
            </div>
            {
              !valuesCorrection && 
                <div className="error-message">
                Select Data Column
                </div>
            }
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Titles;