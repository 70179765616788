import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";

const WalletForm = ({
  handleCancel,
  modalType,
  onGetWalletDetail,
  walletDetail,
  selectedData,
  onCreateWallet,
  onUpdateWallet,
  onGetWalletList,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalType === "edit") {
      onGetWalletDetail(selectedData && selectedData._id);
    }
  }, []);

  useEffect(() => {
    if (modalType === "edit") {
      form.setFieldsValue({
        name: walletDetail && walletDetail.name,
      });
    }
  }, [walletDetail]);

  const onFinish = (values) => {
    if (modalType === "edit") {
      onUpdateWallet(walletDetail && walletDetail._id, values).then(() => {
        message.success("Successfully Updated");
        handleCancel();
        onGetWalletList();
      });
    } else {
      onCreateWallet(values).then(() => {
        message.success("Successfully Created");
        handleCancel();
        onGetWalletList();
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your Wallet name!",
          },
        ]}
      >
        <Input placeholder="Wallet Name" />
      </Form.Item>

      <Form.Item className="mb5">
        <div className="text-right asset-action-btn">
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="ml10">
            {modalType === "edit" ? "Update" : "Create"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default WalletForm;
