import React, { useEffect } from 'react';
import { Switch } from 'antd';

const InventorySwitchFilter = ({
  selectSales,
  setSelectSales,
  selectPurchase,
  setSelectPurchase,
  setNoLicense,
  setHasLicense,
}) => {

  useEffect(() => {

    if (selectSales === false) {
      setSelectPurchase(true);
    }

    if (selectPurchase === true) {
      setSelectSales(false);
    }
  }, [setSelectPurchase, selectSales, setSelectSales, selectPurchase])

  const onChangeHasLicense = (checked) => {
    setHasLicense(checked)
  }

  const onChangeNoLicense = (checked) => {
    setNoLicense(checked)
  }

  return (
    <div>
      <div className="inventry-switch-btns">
        <span><Switch
          size="small"
          onChange={onChangeHasLicense}
          defaultChecked
        /> Has Defined License
         </span>
        <span><Switch
          defaultChecked
          size="small"
          onChange={onChangeNoLicense}
        /> No Defined License
           </span>
      </div>
    </div>
  )
}

export default InventorySwitchFilter;