import React from "react";
import { Progress, Button } from "antd";
import { successfullyTickIcon } from "./../../../Common/Icons";

import { Link } from "react-router-dom";

const UploadingCsvFile = ({
                            fileList
                          }) => {

  return (
    <div className="promotional-banner uploading-csv-file-sec">
      <div className="import-file-button-sec f-u-success">
        <div>
          <Progress
            type="circle"
            percent={fileList && fileList[0] && fileList[0].percent}
            width={250}
            format={() => successfullyTickIcon}
            strokeColor="#ff2d6c"
            strokeWidth="8"
          />
          {
            fileList && fileList[0] && fileList[0].percent === 100 ?
                <h3>File Uploaded Successfully</h3> :
                <h3>File Uploading</h3>
          }
          {
            fileList && fileList[0] && fileList[0].percent === 100 ?
                <p>820 Total Assets</p> :
                <p>Please don't refresh or close this browser window.</p>
          }
          <Link to="/columns-mapping"><Button disabled={fileList && fileList[0] && fileList[0].percent === 100 ? false : true} type="primary">START MAPPING</Button></Link>

        </div>
      </div>
    </div>
  );
};

export default UploadingCsvFile;
