import React, { useState } from 'react';

import InventoryFilterBar from "./InventoryFiltersBar"
import InventorySearchResult from './InventoryFiltersBar/InventorySearchResult'

const MyMarketInventory = ({
  onChangeLayout,
  changeLayout,
  showTableEditor
}) => {
  // const [changeInventory, setChangeInventory] = useState("")
  const [searchResult, setSearchResult] = useState([])

  // const onChangeInventory = (e) => {
  //   setChangeInventory(e.target.value)
  // }

  const onFinish = values => {
    setSearchResult(values.inventory_search_filter)
  };

  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        // changeInventory={changeInventory}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
      />
      {
        (searchResult.length > 0) &&
        <InventorySearchResult
          searchResult={searchResult}
          setSearchResult={setSearchResult}
        />
      }
    </div>
  )
}

export default MyMarketInventory;