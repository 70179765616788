import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const specialCatalogueReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_SPECIAL_CATALOGUES_SUCCESS:
      return { ...state, specialCatalogueRes: action.payload };
    case types.GET_SPECIAL_CATALOGUES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, specialCatalogueRes: action.payload };

    case types.GET_SPECIAL_CATALOGUE_SUCCESS:
      return { ...state, specialCatalogueRes: action.payload };
    case types.GET_SPECIAL_CATALOGUE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, specialCatalogueRes: action.payload };

    case types.CREATE_SPECIAL_CATALOGUE_SUCCESS:
      return { ...state, specialCatalogueRes: action.payload };
    case types.CREATE_SPECIAL_CATALOGUE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, specialCatalogueRes: action.payload };

    case types.UPDATE_SPECIAL_CATALOGUE_SUCCESS:
      return { ...state, specialCatalogueRes: action.payload };
    case types.UPDATE_SPECIAL_CATALOGUE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, specialCatalogueRes: action.payload };

    case types.DELETE_SPECIAL_CATALOGUE_SUCCESS:
      return { ...state, specialCatalogueRes: action.payload };
    case types.DELETE_SPECIAL_CATALOGUE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, specialCatalogueRes: action.payload };

    case types.ADD_ASSET_TO_SPECIAL_CATALOGUE_SUCCESS:
      return { ...state, specialCatalogueAssetsRes: action.payload };
    case types.ADD_ASSET_TO_SPECIAL_CATALOGUE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.statusText === "Unauthorized"
      ) {
        window.location.assign("/login");
      }
      return { ...state, specialCatalogueRes: action.payload };

    case types.DELETE_ASSET_FROM_SPECIAL_CATALOGUE_SUCCESS:
      return { ...state, specialCatalogueAssetsRes: action.payload };
    case types.DELETE_ASSET_FROM_SPECIAL_CATALOGUE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.statusText === "Unauthorized"
      ) {
        window.location.assign("/login");
      }
      return { ...state, specialCatalogueRes: action.payload };

    default:
      return state;
  }
};
