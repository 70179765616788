import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WalletDetailComp from '../Components/ContentArea/WalletDetail';

import {
  getWalletDetail,
  updateWallet,
  deleteWallet,
  getWalletBalance
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    walletDetail: state.walletReducer.walletDetail,
    updateWallet: state.walletReducer.updateWallet,
    deleteWallet: state.walletReducer.deleteWallet,
    walletBalance: state.walletReducer.walletBalance
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetWalletDetail: bindActionCreators(getWalletDetail, dispatch),
    onUpdateWallet: bindActionCreators(updateWallet, dispatch),
    onDeleteWallet: bindActionCreators(deleteWallet, dispatch),
    onGetWalletBalance: bindActionCreators(getWalletBalance, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetailComp);
