import React from "react";
import { Button } from "antd";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const TopTitleSection = ({ onShowModal, catalogue, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>{catalogue.name}</h3>
      </div>
      <div className="header-btn-groups">
        <Button
          className="mr20"
          type="primary"
          onClick={() =>
            onShowModal({
              visible: true,
              type: "edit-catalogue",
              payload: catalogue,
            })
          }
        >
          UPDATE CATALOGUE
        </Button>
        {getRolesPermissions(mainUserRes, "special-catalogue-item.delete") ===
        false ? (
          <Button
            type="default"
            onClick={() =>
              onShowModal({
                visible: true,
                type: "delete-catalogue",
                payload: catalogue._id,
              })
            }
          >
            DELETE CATALOGUE
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TopTitleSection;
