import React, { useState } from 'react';
import { Input, Row, Col, Form } from 'antd';

const InternalIdentification = ({
  requiredField
}) => {
  const [value, setValue] = useState('');

  const onChangeValue = (e) => {
    setValue(e.target.value);
  }
  return (
    <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name"><h3 className=""><span>Internal Identification</span></h3></div>
          <div className="metadata-filed">
            <Form.Item
              name="internalIdentification"
            >
              <Input placeholder="Type here..." onChange={onChangeValue} value={value} />
            </Form.Item>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default InternalIdentification;