import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./../Containers/App.js";
import { Login, Register } from "../Containers";
import EmailForPasswordReset from "./../Containers/ForgetPassword";
const history = createBrowserHistory();
const MainRoutes = ({
  onGetSession,
  sessionRes,
  logoutRes,
  signInRes,
  history
}) => {
  const [loginUser, setLoginUser] = useState(null);

  useEffect(() => {
    if (signInRes && signInRes.token) {
      setLoginUser(true);
    }

    if (signInRes && !signInRes.token) {
      setLoginUser(false);
    }
  }, [signInRes]);

  useEffect(() => {
    const token = window.localStorage.getItem("AK");
    if (token) {
      setLoginUser(true);
    }
    if (!token) {
      setLoginUser(false);
    }

  }, []);

  return (
    <Router  history={history}>
      {
        (loginUser === true) &&
          <Switch>
            <Route path="/" component={App} />
          </Switch>
      }

      {
        (loginUser === false) &&
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register/:id" component={Register} />
        <Route path="/forgot-password" component={EmailForPasswordReset} />
        <Redirect to="/login" />
      </Switch>
      }
    </Router>
  );
};

export default MainRoutes;
