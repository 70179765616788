import React from 'react';
import { Input } from 'antd'
import { searchIcon } from './../../../../../Common/Icons';

const TopTitleSection = ({onFinish}) => {
  
  return (
    <div className="s-catalogue-modal general-Cate-search-bar">
      <div><h3>General Catalogues</h3></div>
      <div className="inventory-search-bar s-c-filterbar">
        <Input placeholder="Search" prefix={searchIcon} onChange={onFinish} />
      </div>
    </div>
  )
}

export default TopTitleSection;