import React from 'react';
import { Card, List, Popover, Avatar } from 'antd';
import { MailOutlined, FolderOpenOutlined, CaretDownOutlined } from '@ant-design/icons';

const data = [
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <MailOutlined />
  },
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <FolderOpenOutlined />
  },
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <MailOutlined />
  },
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <MailOutlined />
  },
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <FolderOpenOutlined />
  },
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <MailOutlined />
  },
  {
    title: 'Lion King Europe',
    text: 'starts in 2h',
    icon: <MailOutlined />
  }
];

const content = (
  <ul className="capter-list">
    <li><a href="#!">Auction 1</a></li>
    <li><a href="#!">Auction 2</a></li>
    <li><a href="#!">Auction 3</a></li>
  </ul>
);

const Actions = () => {

  return (
    <Card title={
      <div className="d-card-header">
        AUCTIONS SUBSCRIBED
      < Popover placement="bottomLeft" content={content} trigger="click">
          <CaretDownOutlined />
        </Popover>
      </div>
    } bordered={true} hoverable>
      <div className="scroll-sec rep-receive">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src="https://www.nendoworld.com/wp-content/uploads/2020/01/340b4bb83d4956a28289c8227843af14.jpg" />}
                title={<a href="#!">{item.title}</a>}
                description={item.text}
              />
            </List.Item>
          )}
        />
      </div>
    </Card >
  )
}

export default Actions;