import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OrganizationCom from "../Components/ContentArea/Administration/OrganizationProfile";

import {
  getOrganizationDetail,
  createOrganization,
  getOrganizationImage,
  updateOrganizationProfile,
  logout,
  getSpecificUserDetail,
  getSpecificUserImage,
  changeUserPassword,
  updateProfileAndUsername,
  mianUserDetail,
  // getAssetsList,
  getSpecialCatalogues,
  getInventory,
  getHighlights,
  getTimezones,
  updateHighlights,
  getNotifications,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    organizationRes: state.organizationReducer.organizationRes,
    createOrganizationRes: state.organizationReducer.createOrganizationRes,
    organizationImageRes: state.organizationReducer.organizationImageRes,
    organizationProfileRes: state.organizationReducer.organizationProfileRes,

    logoutRes: state.userReducer.logoutRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    specificUserImage: state.userManagementReducer.specificUserImage,
    changePasswordRes: state.userManagementReducer.changePasswordRes,
    updateUserprofileAndUsername:
      state.userManagementReducer.updateUserprofileAndUsername,
    mainUserRes: state.userReducer.mainUserRes,
    assetRes: state.assetReducer.assetList,

    specialCatalogueRes: state.specialCatalogueReducer.specialCatalogueRes,
    inventoryListRes: state.inventorySearchReducer.inventoryListRes,
    generalCatalogueHighlightRes:
      state.catalogueReducer.generalCatalogueHighlightRes,

    timezonesRes: state.timezonesReducer.timezonesList,
    getNotificationsRes: state.notificationsReducer.getNotificationsRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrganization: bindActionCreators(getOrganizationDetail, dispatch),
    onCreateOrganization: bindActionCreators(createOrganization, dispatch),
    onGetOrganizationImage: bindActionCreators(getOrganizationImage, dispatch),
    onUpdateOrganization: bindActionCreators(
      updateOrganizationProfile,
      dispatch
    ),

    onLogout: bindActionCreators(logout, dispatch),
    onGetSpecificUserDetail: bindActionCreators(
      getSpecificUserDetail,
      dispatch
    ),
    onGetSpecificUserImage: bindActionCreators(getSpecificUserImage, dispatch),
    onChangeUserPassword: bindActionCreators(changeUserPassword, dispatch),
    onUpdateProfileAndUsername: bindActionCreators(
      updateProfileAndUsername,
      dispatch
    ),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),

    onGetAssetsList: bindActionCreators(getInventory, dispatch),
    onGetCatalogue: bindActionCreators(getSpecialCatalogues, dispatch),
    onGetHighlights: bindActionCreators(getHighlights, dispatch),
    onUpdateHighlights: bindActionCreators(updateHighlights, dispatch),

    onGetTimezones: bindActionCreators(getTimezones, dispatch),
    onGetNotifications: bindActionCreators(getNotifications, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCom);
