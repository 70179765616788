import React from 'react';
import { Button } from 'antd';

import { StarOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const ProgressScale = ({
  showColumnMapping,
  showSaveMapping,
  valuesCorrection,
}) => {

  return (
    <div className="columns-mapping-right">
      <div className="mapping-right-item">
        <h5>FILE NAME</h5>
        <p>import2021.csv</p>
      </div>
      <div className="mapping-right-item">
        <h5>TOTAL ASSETS</h5>
        <h3>820</h3>
      </div>
      <div className="mapping-right-item">
        <h5>COLUMNS MAPPED</h5>
        <div className="s-m-preset">
        <h3>52 out of 55</h3>
        {
          valuesCorrection ? 
          <span onClick={() => showSaveMapping()}><StarOutlined />Save Mapping Preset</span> :
          <span style={{color: "#bebebe"}}><StarOutlined style={{color: "#bebebe"}}/>Save Mapping Preset</span> 
        }
        
        </div>
      </div>
      {
        !valuesCorrection && 
        <div className="mapping-right-item">
        <h5>ERRORS FOUND</h5>
        <h3>12</h3>
      </div>
      }
      
      <div className="header-btn-groups">
        <Link to="asset-import"><Button  type="default">SAVE PROGRESS</Button></Link>
        {
          !valuesCorrection ? <Button type="primary" onClick={() => showColumnMapping()}>CONTINUE</Button> :
          <Link to="/import-asset-successfully"><Button  type="primary">CONTINUE</Button></Link>
        }
      </div>
    </div>
  )
}

export default ProgressScale;