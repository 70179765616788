import React, {  Fragment } from "react";

import UploadingCsvFile from './UploadingCsvFiledd';

import HeaderFilter from './HeaderFilter';

const ImportAssetsSuccessfully = () => {
  
  return (
    <Fragment>
      <HeaderFilter />
      <UploadingCsvFile />
    </Fragment>
  );
};

export default ImportAssetsSuccessfully;
