import React from "react";

import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor,
  setSearch,
  showModal,
  search,
  onHidePopover,
  setVisiblePopover,
  visiblePopover,
  onSaveSearch,
  setMarketReady,
  marketReady,
  setNotMarketReady,
  notMarketReady,
  setPublishedAssets,
  publishedAssets,
  userPermissions,
  mainUserRes,
  assetsList,
}) => {
  return (
    <div className="position-fixed">
      <TopTitleSection
        mainUserRes={mainUserRes}
        showModal={showModal}
        userPermissions={userPermissions}
      />
      <InventoryFilters
        showTableEditor={showTableEditor}
        setSearch={setSearch}
        onHidePopover={onHidePopover}
        setVisiblePopover={setVisiblePopover}
        visiblePopover={visiblePopover}
        onSaveSearch={onSaveSearch}
        setMarketReady={setMarketReady}
        marketReady={marketReady}
        setNotMarketReady={setNotMarketReady}
        notMarketReady={notMarketReady}
        setPublishedAssets={setPublishedAssets}
        publishedAssets={publishedAssets}
        search={search}
        mainUserRes={mainUserRes}
        assetsList={assetsList}
      />
    </div>
  );
};

export default HeaderFilter;
