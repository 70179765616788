import React, { useEffect, Fragment } from 'react';
import {  Form,  } from 'antd';

const SelectionList = ({
  selectionsList,
  list,
  index,
  onUpdateSelection,
  onGetSelectionsList
}) => {
  // const [selectedSelection, setSelectedSelection] = useState("")
  // const [showSelectionForm, setShowSelectionForm] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: list && list.name
    });
  });

  // const onChangeSelection = e => {
  //   setSelectedSelection(e.target.value)
  // };

  // const onShowSelectionedit = (e, value) => {
  //   e.stopPropagation();
  //   setShowSelectionForm(value)
  // };

  // const onCloseSelectionEdit = (e, value) => {
  //   e.stopPropagation();
  //   setShowSelectionForm(value)
  // };

  // const onFinish = values => {
  //   onUpdateSelection(list && list._id, values)
  //     .then(() => onGetSelectionsList())
  //   message.success('Successfully Updated');
  //   setShowSelectionForm(false)
  // };

  // const onFinishFailed = errorInfo => {
  //   console.log('Failed:', errorInfo);
  // };

  return (
    <Fragment>
      {/* {
        showSelectionForm === false && */}
        <div className="radio-slection">
          <span className="radion-s-icon"> {list.name} </span>
        </div>
      {/* } */}
    </Fragment>
  )
}

export default SelectionList;
