import React from "react";
import InventorySearchDropDown from "./InventorySearchDropdown";
import { getRolesPermissions } from "./../../../../../../Common/reUseFunctions";

const InventorySearchDropdown = ({ onSearch, mainUserRes }) => {
  return (
    <div>
      {getRolesPermissions(mainUserRes, "automated-task-assignment.list") ===
      false ? (
        <div className="inventory-bottom-filter flex-end-imp">
          <InventorySearchDropDown onSearch={onSearch} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySearchDropdown;
