import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../Components/Header";

import {
  logout,
  getSpecificUserDetail,
  getSpecificUserImage,
  changeUserPassword,
  updateProfileAndUsername,
  updateUserPicture,
  mianUserDetail,
  getNotifications,
  putNotifications,
  getOrganizationDetail,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    logoutRes: state.userReducer.logoutRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    specificUserImage: state.userManagementReducer.specificUserImage,
    changePasswordRes: state.userManagementReducer.changePasswordRes,
    updateUserprofileAndUsername:
      state.userManagementReducer.updateUserprofileAndUsername,
    updateUserProfileRes: state.userReducer.updateUserProfileRes,
    mainUserRes: state.userReducer.mainUserRes,
    getNotificationsRes: state.notificationsReducer.getNotificationsRes,
    putNotificationsRes: state.notificationsReducer.putNotificationsRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: bindActionCreators(logout, dispatch),
    onGetSpecificUserDetail: bindActionCreators(
      getSpecificUserDetail,
      dispatch
    ),
    onGetSpecificUserImage: bindActionCreators(getSpecificUserImage, dispatch),
    onChangeUserPassword: bindActionCreators(changeUserPassword, dispatch),
    onUpdateProfileAndUsername: bindActionCreators(
      updateProfileAndUsername,
      dispatch
    ),
    onUpdateUserPicture: bindActionCreators(updateUserPicture, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
    onGetNotifications: bindActionCreators(getNotifications, dispatch),
    onPutNotifications: bindActionCreators(putNotifications, dispatch),
    onGetOrganization: bindActionCreators(getOrganizationDetail, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
