import axios from "axios";

import {
  getAccessToken,
  onTokenExpire,
} from "../Components/Common/reUseFunctions";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const licenseAPI = {
  authLogin:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "POST",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            onTokenExpire(err.response);
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  get: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        headers: {
          Authorization: getAccessToken(),
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          onTokenExpire(err.response);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  getBlobImage: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      return axios({
        method: "GET",
        url: `${baseURL}${options.url}`,
        responseType: "blob",
        headers: {
          Authorization: getAccessToken(),
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          onTokenExpire(err.response);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
  post:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "POST",
          url: `${baseURL}${options.url}`,
          data: params,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            Authorization: getAccessToken(),
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            onTokenExpire(err.response);
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  requestLicenseSignature:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "POST",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            Authorization: getAccessToken(),
            "Content-Type": "text/plain",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            onTokenExpire(err.response);
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  put:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "PUT",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            Authorization: getAccessToken(),
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            onTokenExpire(err.response);
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  patch:
    (options, params = null) =>
    async (dispatch) => {
      const [success, failure] = options.types;
      const promise = (resolve, reject) => {
        axios({
          method: "PATCH",
          url: `${baseURL}${options.url}`,
          data: params,
          headers: {
            Authorization: getAccessToken(),
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            resolve(
              dispatch({
                type: success,
                payload: res.data,
              })
            );
          })
          .catch((err) => {
            onTokenExpire(err.response);
            reject(
              dispatch({
                type: failure,
                payload: err,
              })
            );
          });
      };

      return new Promise(promise);
    },
  delete: (options) => async (dispatch) => {
    const [success, failure] = options.types;
    const promise = (resolve, reject) => {
      axios({
        method: "DELETE",
        url: `${baseURL}${options.url}`,
        headers: {
          Authorization: getAccessToken(),
        },
      })
        .then((res) => {
          resolve(
            dispatch({
              type: success,
              payload: res.data,
            })
          );
        })
        .catch((err) => {
          onTokenExpire(err.response);
          reject(
            dispatch({
              type: failure,
              payload: err,
            })
          );
        });
    };

    return new Promise(promise);
  },
};

export { licenseAPI };
