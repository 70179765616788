import React, { useState } from 'react';
import { Row, Col, Tag, Popover, Pagination } from 'antd';
import AssetTeaser from "./AssetTeaser"


const Asset = ({
  AssetList,
  showModal,
  handleCancel,
  visible,
  selectionType,
  selectionData,
  onClickSelection

}) => {
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(10)

  const onPageChange = (value) => {
    if (value <= 1) {
      setMinValue(0);
      setMaxValue(10);
    } else {
      setMinValue(maxValue);
      setMaxValue(value * 10);
    }
  }

  return (
    <div className="asset-list">
      <Row gutter={16}>
        {
          AssetList.slice(minValue, maxValue).map((data, index) => {
            return (

              <Col key={index} span={12} style={{ marginBottom: "20px" }}>

                <div className="d-flex">

                  <div>
                    <img src={data.poster} alt="poster" />
                  </div>
                  <div>
                    <div className="asset-list-des">
                      <div>
                        <Popover placement="bottomLeft" content={<AssetTeaser onClickSelection={onClickSelection} selectionData={selectionData} selectionType={selectionType} visible={visible} handleCancel={handleCancel} showModal={showModal} data={data} />} trigger="click">
                          <h4><a href="#!">{data.movie_title}</a> <span>{data.watch_time}</span> </h4>
                        </Popover>
                        <h4>{data.producer_by} </h4>

                        {
                          data.genre.map((data, index) => {
                            return (

                              <Tag key={index} color="red">{data}</Tag>

                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

            )
          })
        }
      </Row>
      <Row>
        <Col span={24} className="d-flex flex-end">
          <Pagination
            defaultCurrent={1}
            defaultPageSize={10}
            onChange={onPageChange}
            total={15}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Asset;