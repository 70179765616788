import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Filter from "./HeaderFilter";
import UserManagementBox from "./List";
import AddNewDepartmentModal from "./AddNewDepartment";
import CascadingSort from "../../../Common/CascadingSort";
import TableEditor from "../../../Common/TableEditor";
import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";
import SpecificUserRoles from "./SpecificUserRoles";
import { getRolesPermissions } from "../../../Common/reUseFunctions";

const DepartmentsManagement = ({
  createDepartmentRes,
  onCreateDepartment,
  departmentList,
  onGetDepartmentList,
  getSpecificDepartmentDetail,
  onGetSpecificDepartment,
  updateDepartmentRes,
  onUpdateDepartment,
  deleteDepartmentRes,
  onDeleteDepartment,
  mainUserRes,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleTE, setVisibleTE] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [delConfirm, setDelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "department.list") === false) {
      setLoading(true);
      getDepartmentList(currentPage, 9, search);
    }
  }, [currentPage, search, mainUserRes]);

  const showModal = (modalType, selectedSpecificData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedSpecificData);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showTableEditor = (modalType, selectedSpecificData) => {
    setVisibleTE(true);
    setModalType(modalType);
    setSelectedData(selectedSpecificData);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const delConfirmModal = (data) => {
    setSelectedData(data);
    setDelConfirm(true);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const onDeletingItem = async (modalType) => {
    message.success("Successfully Deleted!");
    setModalType(modalType);
    setDelConfirm(false);
    await onDeleteDepartment(selectedData._id);
    getDepartmentList(currentPage, 9, search);
  };

  const getDepartmentList = (currentPage, limit, search) => {
    if (getRolesPermissions(mainUserRes, "department.list") === false) {
      const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
      onGetDepartmentList(skip, limit, search)
        .then((res) => {
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Filter
        onSearch={onSearch}
        showModal={showModal}
        showTableEditor={showTableEditor}
        mainUserRes={mainUserRes}
      />

      <UserManagementBox
        loading={loading}
        showModal={showModal}
        delConfirmModal={delConfirmModal}
        showTableEditor={showTableEditor}
        departmentList={departmentList}
        mainUserRes={mainUserRes}
        onChangePagination={onChangePagination}
        currentPage={currentPage}
      />

      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={520}
        >
          <AddNewDepartmentModal
            handleCancel={handleCancel}
            modalType={modalType}
            selectedData={selectedData}
            currentPage={currentPage}
            search={search}
            onCreateDepartment={onCreateDepartment}
            getSpecificDepartmentDetail={getSpecificDepartmentDetail}
            onGetSpecificDepartment={onGetSpecificDepartment}
            updateDepartmentRes={updateDepartmentRes}
            onUpdateDepartment={onUpdateDepartment}
            onGetDepartmentList={onGetDepartmentList}
          />
        </Modal>
      )}

      {visibleTE && (
        <Modal
          visible={visibleTE}
          onCancel={cancelTableEditor}
          footer={false}
          className="modal-style"
          width={
            (modalType === "user-roles" && 420) ||
            (modalType === "block-user" && 520) ||
            (modalType === "table_editor" && 740) ||
            (modalType === "cascading_sort" && 500)
          }
        >
          {modalType === "user-roles" && (
            <SpecificUserRoles selectedData={selectedData} />
          )}

          {modalType === "table_editor" && <TableEditor />}

          {modalType === "cascading_sort" && <CascadingSort />}
        </Modal>
      )}

      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onDeletingItem}
        text="You will delete this department for good. This action cannot be undone"
      />
    </div>
  );
};

export default DepartmentsManagement;
