import React from 'react';
import { Table, Card, Avatar } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const Auction = ({
  data
}) => {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <Link to={`/auction/${record.address}`}>
            <Meta
              className="meta-avatar-align meta-link-avatar"
              avatar={<Avatar src="https://images-na.ssl-images-amazon.com/images/I/41m4T-nEFiL._SY498_BO1,204,203,200_.jpg" />}
              title={text}
            />
          </Link>
        )
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'auction_start',
      key: 'auction_start',
      render: (text) => text
    },
    {
      title: 'License Valid',
      dataIndex: 'license_valid',
      key: 'license_valid',
      render: (text) => text
    },
    {
      title: 'Minimum Price',
      dataIndex: 'price',
      key: 'price',
      render: (text) => text
    }
  ];

  return (
    <Table columns={columns} dataSource={data} />
  )
}

export default Auction;
