import React, { Fragment, useState, useEffect } from 'react';
import { Form, Row, Col, DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const ProductionReleaseDate = ({
  validationField,
  helperText,
  requiredField
}) => {
  const [tabsChange, setTabsChange] = useState(true);
  const [tabsChangeTwo, setTabsChangeTwo] = useState(true);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "production_date")) {
      setTabsChange(false)
    }
  }, [validationField])

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "release_date")) {
      setTabsChangeTwo(false)
    }
  }, [validationField])

  return (
    <Fragment>
      <Row gutter={16} className="mb5">

        <Col className="pl0_important" span={24}>
          <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
            <div
              className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
            >
              <h3>Production Year</h3>
              <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
            </div>
          </Link>
        </Col>

        {
          helperText &&
          <Col span={24} className={tabsChange === true && "hide-helper-text"}>
            <div className="heper-text"><p>Indicate the year this asset was produced.</p></div>
          </Col>
        }

        <Col span={24}>
          <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
            <Row spna={24}>
              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className="required"><span>Production Year</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="productionYear"
                      rules={[
                        {
                          pattern: new RegExp("[0-9]{4}(\-[0-1][0-9]\-[0-3][0-9])?"),
                          message: "Please enter the correct"
                        }
                      ]}
                      className="full-width mr10 mb20"
                    >
                      <DatePicker
                        picker="year"
                        className="full-width"
                        getPopupContainer={trigger => trigger.parentElement}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>

        <Col className="pl0_important" span={24}>
          <Link to='#' onClick={() => setTabsChangeTwo(!tabsChangeTwo)}>
            <div
              className={tabsChangeTwo === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
            >
              <h3>Release Year</h3>
              <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
            </div>
          </Link>
        </Col>

        {
          helperText &&
          <Col span={24} className={tabsChangeTwo === true && "hide-helper-text"}>
            <div className="heper-text"><p>Indicate the year this asset was released.</p></div>
          </Col>
        }

        <Col span={24}>
          <div className={tabsChangeTwo === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
            <Row spna={24}>
              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className=""><span>Release Year</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="releaseYear"
                      rules={[
                        {
                          pattern: new RegExp("[0-9]{4}(\-[0-1][0-9]\-[0-3][0-9])?"),
                          message: "Please enter the correct"
                        }
                      ]}
                      className="full-width mr10 mb20"
                    >
                      <DatePicker
                        picker="year"
                        className="full-width"
                        getPopupContainer={trigger => trigger.parentElement}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

    </Fragment>
  );
};

export default ProductionReleaseDate; 