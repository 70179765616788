import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PermissionsCom from '../Components/Common/PermissionsComponent';

import {

  mianUserDetail,

} from '../store/Actions';

const mapStateToProps = state => {
  return {
    mainUserRes: state.userReducer.mainUserRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsCom);
