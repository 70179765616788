import React from 'react';
import { Table, Tag, Space, Tooltip, Card, Avatar, message, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { toDateString } from '../../Common/reUseFunctions';

const { Meta } = Card;

const IntentoryList = ({
  data,
  showModal
}) => {

  const onConfirmDelete = () => {
    message.success('Deleted Successfully');
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <Link to={`/inventory/${record.address}`}>
            <Meta
              className="meta-avatar-align meta-link-avatar"
              avatar={<Avatar src="https://images-na.ssl-images-amazon.com/images/I/41m4T-nEFiL._SY498_BO1,204,203,200_.jpg" />}
              title={text}
            />
          </Link>
        )
      },
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (text) => <Tag color="cyan">{text}</Tag>
    },
    {
      title: 'Created Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => <span>{toDateString(text)}</span>
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space size="middle" className="action-table">
          <Link to={`/inventory/${record.address}`}>
            <Tooltip title="Edit">
              <EditOutlined onClick={() => showModal('edit')} />
            </Tooltip>
          </Link>

          <Popconfirm
            placement="topRight"
            title="Are you sure you want to delete."
            onConfirm={onConfirmDelete}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table columns={columns} dataSource={data} />
  )
}

export default IntentoryList;
