import React, { Fragment } from "react";
import { Button, Form, Select } from "antd";
import {
  ImportOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const NumberOfAssetModal = ({ handleCancel }) => {
  const onChangeResale = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  return (
    <Fragment>
      <div className="no-asset-header">
        <h2>Straight Sale</h2>
        <h3>
          <ImportOutlined /> Carthage Film Festival
        </h3>
      </div>
      <div className="buyer-slot">
        <h4>SELECT POTENTIAL BUYERS</h4>
        <Form name="dynamic_form_nest_item" onFinish={onFinish}>
          <div className="buyer-slot-main">
            <div className="buyer-single-row">
              <div className="buyer-single-name">
                <span>BUYER</span>
              </div>
              <div className="buyer-single-select">
                <Form.Item name="re_sales" rules={[{ required: false }]}>
                  <Select defaultValue="1" onChange={onChangeResale}>
                    <Option value="1">China TV</Option>
                    <Option value="2">DanceFlix</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <Form.List name="users">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Form.Item className="add-buyer-btn">
                      <p className="add-selection-btn">
                        <Button
                          type="link"
                          className="p0 border-0"
                          onClick={() => {
                            add();
                          }}
                        >
                          <PlusOutlined /> Add New Buyer
                        </Button>
                      </p>
                    </Form.Item>
                    {fields.map((field) => (
                      <Fragment>
                        <div className="buyer-single-row buyer-list-row">
                          <div className="buyer-single-name">
                            <span>BUYER</span>
                          </div>
                          <div className="buyer-single-select">
                            <Form.Item
                              name={[field.name, "buyer"]}
                              fieldKey={[field.fieldKey, "buyer"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Select the buyer!",
                                },
                              ]}
                            >
                              <Select
                                defaultValue="1"
                                onChange={onChangeResale}
                              >
                                <Option value="1">China TV</Option>
                                <Option value="2">DanceFlix</Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <MinusCircleOutlined
                            className="negotiation-close"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                );
              }}
            </Form.List>
          </div>
          <div className="no-asset-btns">
            <Button onClick={handleCancel} type="default">
              CANCEL
            </Button>
            <Button onClick={handleCancel} type="primary">
              SEND PROPOSAL
            </Button>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default NumberOfAssetModal;
