import React from 'react';
import { Input, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


const Filter = ({
  onSearch,
  search,
}) => {

  return (
    <Row gutter={16} className="mb10 add-selection-filter saved-search-filter">
      <Col span={24}>
        <h2>Saved Search Presets</h2>
      </Col>
      <Col span={24}>
        <Input
          value={search}
          onChange={onSearch}
          prefix={<SearchOutlined />}
          placeholder="Search"
          className="full-width"
        />
      </Col>
    </Row>
  )
}

export default Filter;