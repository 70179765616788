import React from 'react';
import { Row, Col, Button } from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';

import CataGrid1 from '../../../../statics/images/premium-catalogue/p-catalogue2.svg';
import CataGrid2 from '../../../../statics/images/premium-catalogue/p-catalogue1.svg';
import CataGrid3 from '../../../../statics/images/premium-catalogue/p-catalogue3.svg';
import CataGrid4 from '../../../../statics/images/premium-catalogue/p-catalogue4.svg';

const PremiumGrid = ({ showModal }) => {

  return (
    <Row gutter={16}>
      <Col className="mb40" span={12}>
        <div className="catalogues-p-grid">
          <Button type="link" className="p0 border-0" onClick={() => showModal('inventory')}><img src={CataGrid1} alt="Catalogues" /></Button>
          <div className="catalogues-p-info">
            <h4>Die Frau Ohne</h4>
            <p className="duration"><span>2020</span> 1 hr 52 min</p>
            <p className="m-cata"><span>Production Company:</span> Unitel</p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type... <Button className="more-btn" type="link">MORE</Button></p>
            <p className="m-cata"><span>No:</span> A04345654345</p>
            <p className="m-cata"><span>Conductor:</span> Gustavo Dudamel</p>
            <p className="m-cata"><span>Artists:</span> Evgeny Kissin</p>
            <p className="add-selection-btn"><Button type="link" className="p0 border-0" onClick={() => showModal('add_to_selection')}><PlusOutlined /> Add to Selection</Button></p>
          </div>
        </div>
      </Col>
      <Col className="mb40" span={12}>
        <div className="catalogues-p-grid">
          <Button type="link" className="p0 border-0" onClick={() => showModal('inventory')}><img src={CataGrid2} alt="Catalogues" /></Button>
          <div className="catalogues-p-info">
            <h4>Die Frau Ohne</h4>
            <p className="duration"><span>2020</span> 1 hr 52 min</p>
            <p className="m-cata"><span>Production Company:</span> Unitel</p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type... <Button className="more-btn" type="link">MORE</Button></p>
            <p className="m-cata"><span>No:</span> A04345654345</p>
            <p className="m-cata"><span>Conductor:</span> Gustavo Dudamel</p>
            <p className="m-cata"><span>Artists:</span> Evgeny Kissin</p>
            <p className="add-selection-btn"><Button type="link" className="p0 border-0" onClick={() => showModal('add_to_selection')}><PlusOutlined /> Add to Selection</Button></p>
          </div>
        </div>
      </Col>
      <Col className="mb40" span={12}>
        <div className="catalogues-p-grid">
          <Button type="link" className="p0 border-0" onClick={() => showModal('inventory')}><img src={CataGrid3} alt="Catalogues" /></Button>
          <div className="catalogues-p-info">
            <h4>Die Frau Ohne</h4>
            <p className="duration"><span>2020</span> 1 hr 52 min</p>
            <p className="m-cata"><span>Production Company:</span> Unitel</p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type... <Button className="more-btn" type="link">MORE</Button></p>
            <p className="m-cata"><span>No:</span> A04345654345</p>
            <p className="m-cata"><span>Conductor:</span> Gustavo Dudamel</p>
            <p className="m-cata"><span>Artists:</span> Evgeny Kissin</p>
            <p className="add-selection-btn"><Button type="link" className="p0 border-0" onClick={() => showModal('add_to_selection')}><PlusOutlined /> Add to Selection</Button></p>
          </div>
        </div>
      </Col>
      <Col className="mb40" span={12}>
        <div className="catalogues-p-grid">
          <Button type="link" className="p0 border-0" onClick={() => showModal('inventory')}><img src={CataGrid4} alt="Catalogues" /></Button>
          <div className="catalogues-p-info">
            <h4>Die Frau Ohne</h4>
            <p className="duration"><span>2020</span> 1 hr 52 min</p>
            <p className="m-cata"><span>Production Company:</span> Unitel</p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type... <Button className="more-btn" type="link">MORE</Button></p>
            <p className="m-cata"><span>No:</span> A04345654345</p>
            <p className="m-cata"><span>Conductor:</span> Gustavo Dudamel</p>
            <p className="m-cata"><span>Artists:</span> Evgeny Kissin</p>
            <p className="add-selection-btn"><Button type="link" className="p0 border-0" onClick={() => showModal('add_to_selection')}><PlusOutlined /> Add to Selection</Button></p>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default PremiumGrid;