import React, { useState } from 'react';
import { Form, Input, Button, InputNumber, Radio, Modal } from 'antd';
import {flagIcon} from './../Icons';

const PermissionsModal = ({
  handleCancel,
  visible
}) => {
  const [value, setValue] = useState("")

  const onChange = e => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Modal
      title={false}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      className="modal-style"
      width={520}
      centered
    >
      <div className="permissions-modal">
        <h2>Toonz Media Group</h2>
        <p>How many proposals you want to receive from this client in future?</p>
        <Form
          name="permissions_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="select_radio"
            rules={[{ required: false, message: 'Please select the option!' }]}
          >
            <Radio.Group onChange={onChange} value={value} defaultValue={1}>
              <Radio style={radioStyle} value={1}>
                Unlimited
        </Radio>
              <Radio style={radioStyle} value={2}>
                No of Proposals
          {value === 4 ? <Input style={{ width: 100, marginLeft: 10 }} /> : null}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="number"
            rules={[{ required: true, message: 'Please input your number!' }]}
          >
            <InputNumber disabled={value === 2 ? false : true} placeholder="Enter No" />
          </Form.Item>
          <div className="block-p-text">
            {flagIcon} Block Proposals from Euro Distribution
        </div>
          <div className="no-asset-btns">
            <Button onClick={handleCancel} type="default">
              CANCEL
            </Button>
            <Button onClick={handleCancel} type="primary" htmlType="submit">
              SAVE
        </Button>
          </div>

        </Form>
      </div>
    </Modal>
  )
}

export default PermissionsModal;