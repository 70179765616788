import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Modal } from "antd";
import Filehistory from "./FileHistory";
import ProposalHeaderFilter from "./ProposalHeaderFilter";
import RulesRegulationModal from "./Chat/RulesRegulationModal";
import LicenseContract from "./LicenseContract";
import ChatAPP from "./../ChatApp";
import NegotiationInvitation from "./../../Common/SendInvitationModal/NegotiationInvitationModal";
import FormalLicenseEdit from "./FormalLicenseEdit";

import {
  getUrlSecondLastId,
  getRolesPermissions,
} from "../../Common/reUseFunctions";

const SelectionProposal = ({
  location,
  history,
  mainUserRes,
  getLogsListRes,
  onGetLogsList,
  onGetAllTradingTask,
  getAllTradingTaskRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  createFormalLicenseRes,
  onCreateFormalLicense,
  selectionAssetsListRes,
  onsendInvitation,
  sendInvitationRes,
  getOrganizationProfileRes,
  onGetOrganizationProfile,
  onGetOrganizationImage,
  organizationImageRes,
  onGetSelectionAssets,
  onUpdateFormalLicenseForm,
  updateFormalLicenseFormRes,
  deleteFormalLicenseFormRes,
  onDeleteFormalLicenseForm,
  onCancelNegotiation,
  cancelNegotiationRes,
  uploadAgreementFileRes,
  onUploadAgreementFile,
  onUpdateTradingTaskStatus
}) => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [videoCall, setVideoCall] = useState(false);
  const [showChat, setShowChat] = useState("");
  const [userId, setUserId] = useState("");
  const [licenseAssetId, setLicenseAssetId] = useState("");
  const [licenseObj, setLicenseObj] = useState("");
  const [assetId, setAssetId] = useState("");
  const [updateFormalLicense, setUpdateFormalLicense] = useState({});
  const [tradingTaskId, setTradingTaskId] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [leftOrganizationData, setLeftOrganizationData] = useState({});
  const [rightOrganizationData, setRightOrganizationData] = useState({});

  useEffect(() => {
    let _data  = {
      status: "In Progress"
    }
    const locationUrl = location.pathname;
    const tradingId = getUrlSecondLastId(locationUrl);
    onUpdateTradingTaskStatus( tradingId , _data)
  }, []);

  useEffect(() => {
    const locationUrl = location.pathname;
    const tradingId = getUrlSecondLastId(locationUrl);
    onGetAllTradingTask(tradingId);
    setTradingTaskId(tradingId);
    if (getRolesPermissions(mainUserRes, "trading-task.logs") === false) {
      onGetLogsList(tradingId);
    }
  }, []);

  useEffect(() => {
    onGetOrganizationProfile().then((res) => {
      if (res && res.payload && res.payload.logo !== null) {
        onGetOrganizationImage(res && res.payload && res.payload.logo).then(
          (res) => {
            const blob = new Blob([res.payload], {
              type: "application/octet-stream",
            });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64String = reader.result;
              setLogoImage(base64String);
            };
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    let tradingAggregationId = "";
    let companyId = "";
    let createdByCompanyId = "";
    let companyName = "";
    let createdByCompanyName = "";

    if (getOrganizationProfileRes && getAllTradingTaskRes) {
      tradingAggregationId =
        getOrganizationProfileRes.trading_aggregation_organization_id;
      companyId =
        getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_id;
      createdByCompanyId =
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.created_by_company_id;
      companyName =
        getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_title;
      createdByCompanyName =
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.created_by_company_title;
    }

    if (tradingAggregationId === companyId) {
      setLeftOrganizationData({
        company_title: companyName,
        contact_id: companyId,
      });
      setRightOrganizationData({
        created_by_company_title: createdByCompanyName,
        created_by_company_id: createdByCompanyId,
      });
    }

    if (tradingAggregationId === createdByCompanyId) {
      setLeftOrganizationData({
        created_by_company_title: createdByCompanyName,
        created_by_company_id: createdByCompanyId,
      });
      setRightOrganizationData({
        company_title: companyName,
        contact_id: companyId,
      });
    }
  }, [getOrganizationProfileRes, getAllTradingTaskRes]);

  const onChangeSelections = (e) => {
    // setChangeSelectionView(e)
  };

  const handleCancel = (value) => {
    setVisible(false);
    setShowChat(value);
  };

  const dropVideoCall = () => {
    setVideoCall(false);
  };

  const onVideoCall = () => {
    setVisible(false);
    setVideoCall(true);
  };

  const showModal = (value, id, licenseObj) => {
    setVisible(true);
    setModalType(value);
    setLicenseAssetId(id);
    setLicenseObj(licenseObj);
    setUpdateFormalLicense(licenseObj);
  };

  return (
    <Fragment>
      <ProposalHeaderFilter
        location={location}
        mainUserRes={mainUserRes}
        onChangeSelections={onChangeSelections}
        showModal={showModal}
      />
      <div className="licnse-contract-sec">
        <Row gutter={16} className="mt20 margin--24">
          <Col span={12}>
            <LicenseContract
              history={history}
              showModal={showModal}
              getAllTradingTaskRes={getAllTradingTaskRes}
              getFormalLicenseRes={getFormalLicenseRes}
              onGetFormalLicense={onGetFormalLicense}
              location={location}
              selectionAssetsListRes={selectionAssetsListRes}
              onGetSelectionAssets={onGetSelectionAssets}
              setAssetId={setAssetId}
              deleteFormalLicenseFormRes={deleteFormalLicenseFormRes}
              onDeleteFormalLicenseForm={onDeleteFormalLicenseForm}
              assetId={assetId}
              licenseObj={licenseObj}
              uploadAgreementFileRes={uploadAgreementFileRes}
              onUploadAgreementFile={onUploadAgreementFile}
              onGetLogsList={onGetLogsList}
              getOrganizationProfileRes={getOrganizationProfileRes}
              onGetAllTradingTask={onGetAllTradingTask}
              logoImage={logoImage}
              mainUserRes={mainUserRes}
              leftOrganizationData={leftOrganizationData}
              getLogsListRes={getLogsListRes}
            />
          </Col>
          <Col span={12}>
            <ChatAPP
              location={location}
              history={history}
              tradingTaskId={tradingTaskId}
              getAllTradingTaskRes={getAllTradingTaskRes}
              mainUserRes={mainUserRes}
              dropVideoCall={dropVideoCall}
              videoCall={videoCall}
              showModal={showModal}
              userId={userId}
              showChat={showChat}
              onCancelNegotiation={onCancelNegotiation}
              cancelNegotiationRes={cancelNegotiationRes}
              rightOrganizationData={rightOrganizationData}
            />
          </Col>
        </Row>
      </div>

      {visible && (
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className={
            modalType === "file_history"
              ? "modal-style history-modal"
              : "modal-style"
          }
          width={
            (modalType === "rules_regulation" && 500) ||
            (modalType === "send_invitation" && 800) ||
            (modalType === "file_history" && 500) ||
            (modalType === "license_edit" && 650) ||
            (modalType === "add_definition" && 650)
          }
          centered
        >
          {modalType === "rules_regulation" && (
            <RulesRegulationModal
              onVideoCall={onVideoCall}
              handleCancel={handleCancel}
              setUserId={setUserId}
            />
          )}
          {modalType === "send_invitation" && (
            <NegotiationInvitation
              handleCancel={handleCancel}
              onsendInvitation={onsendInvitation}
              sendInvitationRes={sendInvitationRes}
              location={location}
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              tradingTaskId={tradingTaskId}
            />
          )}
          {visible &&
            (modalType === "license_edit" ||
              modalType === "add_definition") && (
              <FormalLicenseEdit
                handleCancel={handleCancel}
                modalType={modalType}
                createFormalLicenseRes={createFormalLicenseRes}
                onCreateFormalLicense={onCreateFormalLicense}
                onGetFormalLicense={onGetFormalLicense}
                getAllTradingTaskRes={getAllTradingTaskRes}
                location={location}
                licenseAssetId={licenseAssetId}
                onUpdateFormalLicenseForm={onUpdateFormalLicenseForm}
                updateFormalLicenseFormRes={updateFormalLicenseFormRes}
                licenseObj={licenseObj}
                assetId={assetId}
                updateFormalLicense={updateFormalLicense}
                onGetAllTradingTask={onGetAllTradingTask}
                mainUserRes={mainUserRes}
              />
            )}
          {modalType === "file_history" && (
            <Filehistory
              mainUserRes={mainUserRes}
              tradingTaskId={tradingTaskId}
              onGetLogsList={onGetLogsList}
              getLogsListRes={getLogsListRes}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default SelectionProposal;
