import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const tradingTaskReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TRADING_TASKS_SUCCESS:
      return { ...state, getTradingListRes: action.payload }
    case types.GET_TRADING_TASKS_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, getTradingListRes: action.payload }

      case types.GET_USER_LIST_SUCCESS:
        return { ...state, getUserListRes: action.payload }
      case types.GET_USER_LIST_FAILURE:
        openNotificationWithIcon(
          'error',
          'Error',
          "Please Try Again"
        );
        return { ...state, getUserListRes: action.payload }

      case types.GET_DEPARTMENTS_WITH_USERS_SUCCESS:
        return { ...state, getDepartmentsWithUsersListRes: action.payload }
      case types.GET_DEPARTMENTS_WITH_USERS_FAILURE:
        openNotificationWithIcon(
          'error',
          'Error',
          "Please Try Again"
        );
        return { ...state, getUserListRes: action.payload }

      case types.PATCH_TRADING_TASK_SUCCESS:
        return { ...state, getTradingTaskRes: action.payload }
      case types.PATCH_TRADING_TASK_FAILURE:
        openNotificationWithIcon(
          'error',
          'Error',
          "Please Try Again"
        );
        return { ...state, getTradingTaskRes: action.payload }

      case types.PATCH_TRADING_TASK_APPROVAL_SUCCESS:
        return { ...state, getTradingTaskRes: action.payload }
      case types.PATCH_TRADING_TASK_APPROVAL_FAILURE:
        openNotificationWithIcon(
          'error',
          'Error',
          "Please Try Again"
        );
        return { ...state, getTradingTaskRes: action.payload }
   
    default:
      return state
  }
}

   
