import React, { useState } from "react";
import { Form, Button, Row, Col, Input, Select } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { countryList } from "./../../../../../statics/data/countryList";

const { Option } = Select;

const AgeRestrictions = ({ helperText, requiredField }) => {
  const [selectedContry, setSelectedContry] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const handleChangeCountry = (selectedContry) => {
    setSelectedContry(selectedContry);
  };

  return (
    <Row
      gutter={16}
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >
      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Age Restrictions</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>Provide at least one indication for an age restriction and the respective jurisdiction.</p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="ageRestriction">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <Row key={field.key} gutter={16} className="mb20">
                      <Col span={24}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="">
                              <span>Age Restriction</span>
                            </h3>
                          </div>
                          <div className="metadata-filed d-flex align-item-center">
                            <Form.Item
                              {...field}
                              name={[field.name, "value"]}
                              fieldKey={[field.fieldKey, "value"]}
                              className="full-width mr20 mb0"
                            >
                              <Input
                                className="full-width"
                                placeholder="Age"
                                type="number"
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, "jurisdiction"]}
                              fieldKey={[field.fieldKey, "jurisdiction"]}
                              rules={[
                                {
                                  pattern: new RegExp("[A-Z]{2}"),
                                  message:
                                    "Please enter the correct jurisdiction!",
                                },
                              ]}
                              className="full-width mr20 mb0"
                            >
                              <Select
                                placeholder="Jurisdiction"
                                value={selectedContry}
                                onChange={handleChangeCountry}
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                              >
                                {countryList.map((item, index) => (
                                  <Option key={index} value={item.code}>
                                    {item.code}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col span={24}>
                        <MinusCircleOutlined
                          className="ml10 remove-gray-icon"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add New
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default AgeRestrictions;
