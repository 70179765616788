import React, { useState } from 'react';
import { Row, Col, Radio, Form } from 'antd';
import { Link } from 'react-router-dom';

const Status = ({
  helperText
}) => {
  const [tabsChange, setTabsChange] = useState(true);

  return (
    <Row gutter={16} className="mb5">

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Status</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Indicate the status of the production.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <div className="metadata-field-box">
            <Row gutter={16}>
              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className="required"><span>Status</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="status"
                    >
                      <Radio.Group>
                        <Radio value="ideation">Ideation</Radio>
                        <Radio value="in progress">In progress</Radio>
                        <Radio value="completed">Completed</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

    </Row>
  )
}

export default Status;