import React, { useState  } from 'react';
import Filter from './Filter';
import SelectionList from './List';

const SelectColumnModal = ({
  onGetSelectionsList,
  selectionsList,

  onGetSelectionDetail,
  selectionDetail,

  onCreateSelection,
  createSelection,

  onUpdateSelection,
  updateSelection,

  onDeleteSelection,
  deleteSelection,

  selectionType,
  onClickSelection,

  handleCloseSelection,
  setSelectiveColumns,
  handleCancel

}) => {
  const [search, setSearch] = useState('');

  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  return (
    <div className="select-colum-modal">
      <Filter
        onSearch={onSearch}
        search={search}
      />
      <SelectionList
        selectionsList={selectionsList}
        onCreateSelection={onCreateSelection}
        onGetSelectionsList={onGetSelectionsList}
        onUpdateSelection={onUpdateSelection}

        handleCloseSelection={handleCloseSelection}
        setSelectiveColumns={setSelectiveColumns}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default SelectColumnModal;