import React from "react";
import { Progress, Button } from "antd";
import { successfullyTickIcon } from "./../../../Common/Icons";

import { Link } from "react-router-dom";

const UploadingCsvFile = ({
                          }) => {


  return (
    <div className="promotional-banner uploading-csv-file-sec">
      <div className="import-file-button-sec a-import-ss">
        <div>
          <Progress
            type="circle"
            percent={100}
            width={220}
            format={() => successfullyTickIcon}
            strokeColor="#ff2d6c"
            strokeWidth="8"
          />
          <h3>Asset Import Successfully</h3>
          <p>820 Total Assets Import</p>
          <Link to="asset-management"><Button type="primary">VIEW ASSETS</Button></Link>

        </div>
      </div>
    </div>
  );
};

export default UploadingCsvFile;
