import React from 'react';
import { Tooltip, Button } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import { recordingIcon, voiceCallIcon, videoCameraIcon, fullScreenIcon } from './../../Common/Icons';

import RingTone from './../../Common/ring/ringtone.mp3';
import SignalTone from './../../Common/ring/signaling.mp3';

// import { userPhoneMain } from './js';

const AudioVideoCall = ({ userId, videoCall, showModal }) => {

  // useEffect(() => {
  //   const div1 = document.getElementById('bt-call-action-microphone');
  //   const align = div1.getAttribute('data-default-state');
  //   alert(align)
  //   if (align === "enabled") {
  //     div1.innerHTML = "<PhoneOutlined />";
  //   } else {
  //   }
  // }, [])

  const alertModal = () => {
    alert("Other Instants are not available");
  }


  return (
    <div>
      <audio id="userphone-ringer" preload="auto" loop>
        <source src={RingTone} type="audio/mp3" />
      </audio>

      <audio id="userphone-signaler" preload="auto" loop>
        <source src={SignalTone} type="audio/mp3" />
      </audio>

      <div id="dialog-incoming-call" title="Eingehender Anruf!"></div>
      <div id="dialog-warning" title="Warnung"></div>

      <div className="server-info">
        <table>
          <tbody>
            <tr>
              <td>Server</td>
              <td>
                <input value="vbox0001.telematica.at" id="webrtc-server" />
              </td>
            </tr>
            <tr>
              <td>Username</td>
              <td>
                <input value={`${userId}_web`} id="webrtc-username" />
              </td>
            </tr>
            <tr>
              <td>Password</td>
              <td>
                <input value={(userId === "1" && "1170vxb05okn") || (userId === "2" && "GYUD0B2Q0dv2")} id="webrtc-password" />
              </td>
            </tr>
            <tr>
              <td>Microphone</td>
              <td>
                <select id="device-audioinput"></select>
              </td>
            </tr>
            <tr>
              <td>Camera</td>
              <td>
                <select id="device-videoinput"></select>
              </td>
            </tr>
            {/* <tr>
            <td>Actions</td>
            <td>
              <button id="bt-webrtc-connect">Connect</button>
            </td>
          </tr> */}
          </tbody>
        </table>
      </div>

      <div>
        <div>
          <div>
            <div>
              {/* <div className="" id="current-call-details-container">
                <div id="current-call-details">
                  <div id="current-call-target">
                    <div className="current-call-data-value">-</div>
                  </div>
                  <div id="current-call-duration">
                    <div className="current-call-data-value">-</div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="video-sec">
              <div id="wrapper-remote-video">
                <video id="remoteVideo"></video>
              </div>
              <div className="call-btns">
                <div>
                  <input
                    type="text"
                    className="server-info"
                    id="input-tel"
                    placeholder="123..."
                    required=""
                    value={(userId === "1" ? "2" : '') || (userId === "2" ? "1" : '')}
                    title=""
                  />
                </div>
                <div className="a-w-call">
                  <button onClick={() => alertModal()} type="button" id="bt-dial-audio" className="bt-dial dialstatus-none">{voiceCallIcon}</button>
                  <div className={videoCall ? "disply-show start-call-btn" : "displya-hide start-call-btn"}>
                    <span>
                      <button type="button" id="bt-webrtc-connect">Connect</button>
                    </span>
                  </div>
                  {
                    videoCall ?
                      // <span className="call-progress">Call in Progress 02:00:36</span> 
                      <div className="" id="current-call-details-container">
                        <div id="current-call-details">
                          <div id="current-call-duration">
                            <div className="current-call-data-value"></div>
                          </div>
                        </div>
                      </div>
                      :
                      <Tooltip title="Call">
                        <Button className="call-btn" onClick={() => showModal("rules_regulation")} shape="circle" icon={<PhoneOutlined />} />
                      </Tooltip>
                  }
                  <button onClick={() => alertModal()} type="button" id="bt-dial-video" className="bt-dial dialstatus-none">{videoCameraIcon}</button>
                </div>
              </div>
              <div className="full-screen">
                <Tooltip title="Full Screen">
                  <button onClick={() => alertModal()} type="button" id="bt-call-action-fullscreen">{fullScreenIcon}</button>
                </Tooltip>
              </div>
              <div className="my-video" id="current-call-localvideo-container">
                <div id="wrapper-local-video">
                  <video id="localVideo" muted="muted"></video>
                </div>
              </div>
              <div id="call-action-bar">
                <button onClick={() => alertModal()} type="button" id="bt-call-action-microphone" data-label-when-enabled="E"
                  data-label-when-disabled="D" data-current-state=""
                  data-default-state="enabled">{recordingIcon}</button>
                <button onClick={() => alertModal()} type="button" id="bt-call-action-camera" data-label-when-enabled="E"
                  data-label-when-disabled="D" data-current-state="" data-default-state="enabled">{videoCameraIcon}</button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default AudioVideoCall;