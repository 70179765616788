import React, { useState, useEffect } from "react";
import { Button, Form, Spin, Input, Upload, message, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import { countryList } from "./../../../../statics/data/countryList";
import { GET_TIMEZONES_SUCCESS } from "../../../../store/Constants";

const { Option } = Select;

const EditProfileModal = ({
  handleCancel,
  modalType,
  onGetOrganization,
  onUpdateOrganization,
  onGetOrganizationImage,
  setHeroImage,
  setLogoImage,
  createOrganizationRes,
  onCreateOrganization,
  organizationRes,
  onGetTimezones,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [logoImageFile, setLogoImageFile] = useState([]);
  const [fileList, setFileList] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState('')
  // const [selectedTimezone, setSelectedTimezone] = useState('')
  const [timezoneList, setTimezoneList] = useState([]);

  useEffect(() => {
    onGetTimezones()
      .then((res) => {
        if (res.type === GET_TIMEZONES_SUCCESS) {
          setTimezoneList(res.payload);
        }
      })
      .catch((err) => {
        console.log("Err ::: ", err);
      });

    if (modalType === "edit-organization") {
      setLoading(true);

      onGetOrganization()
        .then((res) => {
          form.setFieldsValue({
            short_name: res.payload.short_name,
            full_name: res.payload.name,
            about: res.payload.about,
            country: res.payload.country,
            timezone: res.payload.timezone,
            state: res.payload.state,
            city: res.payload.city,
            street: res.payload.street,
            postcode: res.payload.postcode,
            phone: res.payload.phone,
            website: res.payload.website,
            company_registration_number:
              res.payload.company_registration_number,
            company_tax_id: res.payload.company_tax_id,
            company_vat_id: res.payload.company_vat_id,
          });
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleChangeCountry = (selectedCountry) => {
    form.setFieldsValue({ country: selectedCountry });
  };

  const handleChangeTimezone = (selectedTimezone) => {
    form.setFieldsValue({ timezone: selectedTimezone });
  };

  const props = {
    name: "file",
    action: "//jsonplaceholder.typicode.com/posts/",
    listType: "picture",
    // headers: {
    //   authorization: 'authorization-text',
    // },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isJPEG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!(isJPG || isJPEG || isGIF || isPNG)) {
        message.error(
          "The format of the uploaded image is incorrect, not JPG, JPEG, GIF, PNG"
        );
      }
      if (!isLt1M) {
        message.error("Image must smaller than 1MB!");
      }
      return new Promise((resolve, reject) => {
        if (!(isJPG || isJPEG || isGIF || isPNG) || !isLt1M) {
          reject(file);
        } else {
          resolve(file);
        }
      });
    },

    uploadService() {
      return Promise.resolve();
    },

    async customRequest(options) {
      options.onProgress({ percent: 0 });
      const url = await uploadService();
      options.onProgress({ percent: 100 });
      options.onSuccess({}, { ...options.file, url });
      console.log(options);
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setLogoImageFile(info.fileList);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onFinish = (values) => {
    setLoading(true);

    if (modalType === "add-organization") {
      let organizationForm = new FormData();
      organizationForm.append("name", values.full_name);
      organizationForm.append("short_name", values.short_name);
      organizationForm.append("about", values.about);
      values.country && organizationForm.append("country", values.country);
      values.timezone && organizationForm.append("timezone", values.timezone);
      values.state && organizationForm.append("state", values.state);
      values.city && organizationForm.append("city", values.city);
      values.street && organizationForm.append("street", values.street);
      values.postcode && organizationForm.append("postcode", values.postcode);
      values.phone && organizationForm.append("phone", values.phone);
      values.website && organizationForm.append("website", values.website);
      values.company_registration_number &&
        organizationForm.append(
          "company_registration_number",
          values.company_registration_number
        );
      values.company_tax_id &&
        organizationForm.append("company_tax_id", values.company_tax_id);
      values.company_vat_id &&
        organizationForm.append("company_vat_id", values.company_vat_id);

      fileList &&
        fileList.length > 0 &&
        organizationForm.append("hero_image", fileList[0].originFileObj);
      logoImageFile &&
        logoImageFile.length > 0 &&
        organizationForm.append("logo", logoImageFile[0].originFileObj);
      onCreateOrganization(organizationForm)
        .then((res) => {
          handleCancel();
          message.success(res.payload.message);
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    }

    if (modalType === "edit-organization") {
      let organizationForm = new FormData();
      organizationForm.append("name", values.full_name);
      organizationForm.append("short_name", values.short_name);
      organizationForm.append("about", values.about);
      values.country && organizationForm.append("country", values.country);
      values.timezone && organizationForm.append("timezone", values.timezone);
      values.state && organizationForm.append("state", values.state);
      values.city && organizationForm.append("city", values.city);
      values.street && organizationForm.append("street", values.street);
      values.postcode && organizationForm.append("postcode", values.postcode);
      values.phone && organizationForm.append("phone", values.phone);
      values.website && organizationForm.append("website", values.website);
      values.company_registration_number &&
        organizationForm.append(
          "company_registration_number",
          values.company_registration_number
        );
      values.company_tax_id &&
        organizationForm.append("company_tax_id", values.company_tax_id);
      values.company_vat_id &&
        organizationForm.append("company_vat_id", values.company_vat_id);

      fileList &&
        fileList.length > 0 &&
        organizationForm.append(
          "promotional_banner",
          fileList[0].originFileObj
        );
      logoImageFile &&
        logoImageFile.length > 0 &&
        organizationForm.append("logo", logoImageFile[0].originFileObj);

      onUpdateOrganization(organizationForm)
        .then((res) => {
          message.success(res.payload.message);
          onGetOrganization().then((res) => {
            if (res && res.payload && res.payload.logo) {
              onGetOrganizationImage(res && res.payload && res.payload.logo)
                .then((res) => {
                  const blob = new Blob([res.payload], {
                    type: "application/octet-stream",
                  });
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = function () {
                    var base64String = reader.result;
                    setLogoImage(base64String);
                  };
                  setLoading(false);
                  handleCancel();
                })
                .catch(() => {
                  onGetOrganizationImage(
                    res && res.payload && res.payload.logo
                  );
                  setLoading(false);
                });
            }

            if (res && res.payload && res.payload.heroImage) {
              onGetOrganizationImage(
                res && res.payload && res.payload.heroImage
              )
                .then((res) => {
                  const blob = new Blob([res.payload], {
                    type: "application/octet-stream",
                  });
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = function () {
                    var base64String = reader.result;
                    setHeroImage(base64String);
                  };
                  setLoading(false);
                  handleCancel();
                })
                .catch(() => {
                  onGetOrganizationImage(
                    res && res.payload && res.payload.logo
                  );
                  setLoading(false);
                  message.error("Error");
                });
            }
          });
          setLoading(false);
          handleCancel();
        })
        .catch(() => {
          setLoading(false);
          message.success("save changes");
          handleCancel();
        });
    }
  };

  const onChangeLogoPicture = (event) => {
    const imagedata = event.target.files;
    setLogoImageFile(imagedata);
  };

  const beforeUpload = (file) => {
    const isJPG = file.type === "image/jpeg";
    const isJPEG = file.type === "image/jpeg";
    const isGIF = file.type === "image/gif";
    const isPNG = file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      message.error(
        "The format of the uploaded image is incorrect, not JPG, JPEG, GIF, PNG"
      );
    }
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return new Promise((resolve, reject) => {
      if (!(isJPG || isJPEG || isGIF || isPNG) || !isLt2M) {
        reject(file);
      } else {
        resolve(file);
      }
    });
  };

  const uploadService = () => {
    return Promise.resolve();
  };

  const customRequest = async (options) => {
    options.onProgress({ percent: 0 });
    const url = await uploadService();
    options.onProgress({ percent: 100 });
    options.onSuccess({}, { ...options.file, url });
    console.log(options);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <div className="license-edit-model organization-profile">
      <div style={{ width: "500px" }}>
        <div className="organization-p-header">
          <h3>Edit Organization Profile</h3>
          {/* <Button type="link">View My Profile</Button> */}
        </div>
        <Spin size="large" spinning={loading}>
          <Form name="validate_other" onFinish={onFinish} form={form}>
            <div
              className="slection-asset-view organization-p-body"
              style={{ height: "400px", overflowY: "scroll" }}
            >
              <div className="formal-license-view">
                <div className="formal-license-row">
                  <div className="formal-license-name required">
                    <span>SHORT NAME</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="short_name"
                      className="full-width mr20 mb0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Short Name",
                        },
                      ]}
                    >
                      <Input placeholder="Short name" className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row required">
                  <div className="formal-license-name">
                    <span>FULL NAME</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="full_name"
                      className="full-width mr20 mb0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Full Name",
                        },
                      ]}
                    >
                      <Input placeholder="Full name" className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row edit-profile-textarea">
                  <div className="formal-license-name required">
                    <span>ABOUT</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="about"
                      className="full-width mr20 mb0"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please provide a short description of your organization",
                        },
                        {
                          max: 500,
                          message: "Username must be max 500 characters.",
                        },
                      ]}
                    >
                      <Input.TextArea
                        rows="5"
                        placeholder="Max 500 Characters..."
                        className="full-width"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row organization-logo-btn edit-profile-textarea">
                  <div className="formal-license-name">
                    <span>UPLOAD LOGO</span>
                    <p>220x90 px. Max File Size 1MB</p>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="upload_logo"
                      className="full-width mr20 mb0"
                      rules={[
                        { required: false, message: "Please upload the Logo" },
                      ]}
                      onChange={onChangeLogoPicture}
                    >
                      <Upload maxCount={1} {...props}>
                        <Button icon={<PlusCircleOutlined />}></Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row edit-profile-textarea">
                  <div className="formal-license-name">
                    <span>UPLOAD HERO IMAGE</span>
                    <p>700x350 px. Max File Size 2MB</p>
                  </div>
                  <div className="formal-license-data upload-hero-image">
                    <Form.Item
                      name="upload_hero_image"
                      className="full-width mr20 mb0"
                      rules={[
                        {
                          required: false,
                          message: "Please upload the Hero Image",
                        },
                      ]}
                    >
                      {/* <div className="hero-image-thumbnail">
                        <img src={heroImage} />
                        <input type="file" />
                      </div> */}
                      <div className="hero-image-thumbnail">
                        <ImgCrop
                          aspect={16 / 9}
                          modalTitle="Crop Image"
                          modalOk="SAVE CHANGES"
                          modalCancel="CANCEL"
                          className="crop-image-style"
                        >
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={customRequest}
                            beforeUpload={beforeUpload}
                          >
                            {fileList && fileList.length < 5 && (
                              <u>Upload Image</u>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div>
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>PHONE</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="phone" className="full-width mr20 mb0">
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>WEBSITE</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="website" className="full-width mr20 mb0">
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>ADDRESS</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="street" className="full-width mr20 mb0">
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>POSTAL CODE</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="postcode" className="full-width mr20 mb0">
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>CITY</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="city" className="full-width mr20 mb0">
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>STATE</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="state" className="full-width mr20 mb0">
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>COUNTRY</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="country">
                      <Select
                        placeholder="Select Country"
                        onChange={handleChangeCountry}
                        style={{ width: "100%" }}
                      >
                        {countryList.map((item, index) => (
                          <Option key={index} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>TIMEZONE</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item name="timezone">
                      <Select
                        placeholder="Select Timezone"
                        onChange={handleChangeTimezone}
                        style={{ width: "100%" }}
                      >
                        {timezoneList.map((item, index) => (
                          <Option key={index} value={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>COMPANY REGISTRATION NUMBER</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="company_registration_number"
                      className="full-width mr20 mb0"
                    >
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>TAX ID</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="company_tax_id"
                      className="full-width mr20 mb0"
                    >
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>VAT ID</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="company_vat_id"
                      className="full-width mr20 mb0"
                    >
                      <Input className="full-width" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="formal-license-btn">
              <Button htmlType="submit" type="primary">
                SAVE CHANGES
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default EditProfileModal;
