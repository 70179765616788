import React, { useState, useEffect, Fragment } from 'react';
import { Button, Form, Input, message, Avatar } from 'antd';

const SelectionList = ({
  selectionsList,
  list,
  index,
  onUpdateSelection,
  onGetSelectionsList
}) => {
  const [showSelectionForm, setShowSelectionForm] = useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      name: list && list.name
    });
  });

  const onCloseSelectionEdit = (e, value) => {
    e.stopPropagation();
    setShowSelectionForm(value)
  };

  const onFinish = values => {
    onUpdateSelection(list && list._id, values)
      .then(() => onGetSelectionsList())
    message.success('Successfully Updated');
    setShowSelectionForm(false)
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Fragment>
      {
        showSelectionForm === false &&
        <div className="radio-slection">
          <span className="radion-s-icon">
          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
             {list.name} 
             </span>
          <p  >{list.role}</p>
        </div>
      }
      {
        showSelectionForm === true &&
        <div className="edit-selection">
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Selection name!',
                },
              ]}
            >
              <Input

                placeholder="Selection name..."
              // className="width-300"
              />
            </Form.Item>
            <div className="add-select-update">
              <Form.Item>
                <Button
                  type="link"
                  htmlType="submit"
                >
                  Update
                </Button>
                <Button
                  type="link"
                  onClick={(e) => onCloseSelectionEdit(e, false)}
                >
                  Cancel
                        </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      }
    </Fragment>
  )
}

export default SelectionList;
