import React, { Fragment, useState } from 'react';
import { Button, Radio, message } from 'antd';

import AddSelectionList from './SelectionList';
// import AddToSelectionBtn from './AddNewSelectionBtn';

const selectionsList = [
  { name: "Worldwide Formal License" },
  { name: "USA Formal License" },
  { name: "Broadcast Formal License" },
  { name: "Australia Formal License" },
  { name: "Worldwide Formal License 1" },
  { name: "USA Formal License 2" },
  { name: "Broadcast Formal License 3" },
  { name: "Australia Formal License 4" },
  { name: "Australia Formal License 5" },
]

const SelectionList = ({
  addSelectionsList,
  // selectionsList,
  onCreateSelection,
  onGetSelectionsList,
  onUpdateSelection,

  handleCancel,
  onShowModal
}) => {

  const [selectedSelection, setSelectedSelection] = useState("");

  const onAddSelection = () => {
    if (selectedSelection) {
      handleCancel()
    } else {
      message.info('Please select the preset first');
    }
  }

  const onChangeSelection = e => {
    setSelectedSelection(e.target.value)
  };
    
  return (
    <Fragment>
      <ul className="selection-radio column-tasks-area l-p-list">

        {
          (selectionsList && selectionsList.length > 0) &&
          selectionsList.map((list, index) => {
            return (
              <li key={index}>
                <Radio.Group onChange={onChangeSelection} value={selectedSelection}>
                  <Radio value={list.name}>
                    <AddSelectionList
                      list={list}
                      index={index}
                      onGetSelectionsList={onGetSelectionsList}
                      onUpdateSelection={onUpdateSelection}
                      onShowModal={onShowModal}
                    />
                  </Radio>
                </Radio.Group>
              </li>

            )
          })
        }

      </ul>
      <div className="add-selections-style">
        <Button type="primary" onClick={onAddSelection}>APPLY PRESET</Button>
      </div>
    </Fragment>
  )
}

export default SelectionList;
