import React, { useState } from 'react';
import { Input, Row, Col, Form, Select } from 'antd';
import { Link } from 'react-router-dom';

import { countryList } from './../../../../../statics/data/countryList';

const { Option } = Select;

const ProductionCompany = ({
  helperText,
  requiredField,
}) => {
  const [value, setValue] = useState('');
  const [selectedContry, setSelectedContry] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const handleChangeCountry = selectedContry => {
    setSelectedContry(selectedContry)
  };

  const onChangename = (e) => {
    setValue(e.target.value);
  }

  const onChangeContact = (e) => {
    setValue(e.target.value);
  }
  return (
    <Row gutter={16} className="mb5">

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Production Company</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Provide information on the organization responsible for the production of the asset.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <div className="metadata-field-box">
            <Row gutter={16}>

              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className="required"><span>Name</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="productionCompanyName"
                    >
                      <Input placeholder="Name" onChange={onChangename} value={value} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className="required"><span>Seat</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="productionCompanySeat"
                      className="full-width mr20 mb0"
                      rules={[
                        {
                          pattern: new RegExp("[A-Z]{2}"),
                          message: "Please enter the correct seat!"
                        }
                      ]}
                    >
                      <Select
                        placeholder="Seat"
                        value={selectedContry}
                        onChange={handleChangeCountry}
                        style={{ width: '100%' }}
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        className="full-width mr20 mb0"
                        getPopupContainer={trigger => trigger.parentElement}
                      >
                        {countryList.map((item, index) => (
                          <Option key={index} value={item.code}>
                            {item.code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col span={24} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className=""><span>Contact</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="productionCompanyContact"
                      className="full-width mr20 mb0"
                    >
                      <Input placeholder="Contact" onChange={onChangeContact} value={value} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

            </Row>
          </div>
        </div>
      </Col>

    </Row>
  )
}

export default ProductionCompany;