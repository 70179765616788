import React from 'react'
import { Row, Col } from 'antd'

import MemberProfile from '../../../../statics/images/salzburg.png'

const PremiumProfile = () => {
  return (
    <Row gutter={24}>
      <Col className="mb40" span={20}>
        <div className="member-profile-text">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and
            typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.{' '}
          </p>
        </div>
      </Col>
      <Col className="mb40" span={4}>
        <div className="premium-profile-img">
          <img src={MemberProfile} alt="Catalogues" />
        </div>
      </Col>
    </Row>
  )
}

export default PremiumProfile
