import React, { Fragment, useState } from "react";
import { Modal, Button } from "antd";
import HeaderComponent from "./../../../Header";
import HeaderFilter from './HeaderFilter';

import ProgressScale from './ProgressScales';
import AssetsDetailOptions from './AssetsDetailOptions';
import ColumnsMappingValuesCollectoinModal from './../ColumnsMappingValuesCollectoinModal';
import SaveMappingPreset from './../../../Common/Components/SaveMappingPreset';

import { ArrowLeftOutlined } from '@ant-design/icons';

const ColumnsMapping = ({
  
  getSpecificUserDetail,
  onGetSpecificUserDetail,

  specificUserImage,
  onGetSpecificUserImage,

  mainUserRes,
  onGetMainUserDetail,

  onLogout
}) => {
  const [visibleMapping, setVisibleMapping] = useState(false);
  const [visibleSaveMapping, setVisibleSaveMapping] = useState(false);
  const [valuesCorrection, setValuesCorrection] = useState(false);

  const showSaveMapping = (value, id) => {
    setVisibleSaveMapping(true);
  };

  const cancelSaveMapping = e => {
    setVisibleSaveMapping(false);
  };

  const showColumnMapping = (value, id) => {
    setVisibleMapping(true);
  };

  const cancelColumnMapping = e => {
    setVisibleMapping(false);
  };

  const saveValuesCorrection = () => {
    setValuesCorrection(true)
    cancelColumnMapping()
  }

  return (
    <Fragment>
      <HeaderFilter />
      <div className="asset-detail-style">
        <div className="form-section">
          {
            <AssetsDetailOptions
           
            valuesCorrection={valuesCorrection}
            />
          }
        </div>
        <div className="right-side-section">
          {
            <ProgressScale
           
            showColumnMapping={showColumnMapping}
            showSaveMapping={showSaveMapping}

            valuesCorrection={valuesCorrection}
            />
          }

        </div>
      </div>
      <Modal
        visible={visibleMapping}
        onCancel={cancelColumnMapping}
        footer={false}
        className="modal-trading"
        width="100%"
      >
        <div className="app-header-trading-task"> 
          <HeaderComponent
            getSpecificUserDetail={getSpecificUserDetail}
            onGetSpecificUserDetail={onGetSpecificUserDetail}
            specificUserImage={specificUserImage}
            onGetSpecificUserImage={onGetSpecificUserImage}
            mainUserRes={mainUserRes}
            onGetMainUserDetail={onGetMainUserDetail}
            onLogout={onLogout}
          />
        </div>
        <div className="trading-task-detail ">
          <Button type="link" className="back-button" onClick={cancelColumnMapping}>
            <ArrowLeftOutlined /> Back to Columns Mapping
            </Button>
          <ColumnsMappingValuesCollectoinModal 
          saveValuesCorrection={saveValuesCorrection}
          />
        </div>
      </Modal>
      <SaveMappingPreset
        visible={visibleSaveMapping}
        handleCancel={cancelSaveMapping}
      />
    </Fragment>
  );
};

export default ColumnsMapping;
