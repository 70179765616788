import React, { Fragment } from "react";
import { Row, Col, Avatar, Button } from "antd";
import { Link } from "react-router-dom";
import {
  getLocalCountrytime,
  getCountrytime,
} from "./../../Common/reUseFunctions";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import LicenseAssetCollapse from "./LicenseAssetCollapse";
import LicenceAgreementSec from "./LicenceAgreementSec";

const LicenseSellingContract = ({
  showModal,
  sellingAsset,
  getAllTradingTaskRes,
  getOrganizationProfileRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  location,
  SignSaleConfirmModal,
  signSaleloading,
  rightOrganizationData,
}) => {
  let tradingAggregationId = "";
  let createdByCompanyId = "";
  let tradingtaskcreater = "";
  let tradingtaskacceptor = "";
  let selectionType = "";

  if (getOrganizationProfileRes && getAllTradingTaskRes) {
    tradingAggregationId =
      getOrganizationProfileRes.trading_aggregation_organization_id;
    createdByCompanyId =
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.created_by_company_id;
    tradingtaskcreater =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.creator_signed;
    tradingtaskacceptor =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.acceptor_signed;
    selectionType = getAllTradingTaskRes.data && getAllTradingTaskRes.data.type;
  }

  const signNowCheck = () => {
    if (selectionType === "sale" || selectionType === "purchase") {
      if (tradingAggregationId === createdByCompanyId) {
        if (tradingtaskacceptor === true) {
          return (
            <div className="sign-text-icon">
              <span className="icon-style">
                {" "}
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </span>
              <span className="sign-text">Sign From Otherside</span>
            </div>
          );
        } else {
          return (
            <div className="sign-text-icon">
              <span className="icon-style">
                {" "}
                <CloseCircleTwoTone twoToneColor="#eb2f96" />
              </span>
              <span className="sign-text">No Sign From Otherside</span>
            </div>
          );
        }
      } else {
        if (tradingtaskcreater === true) {
          return (
            <div className="sign-text-icon">
              <span className="icon-style">
                {" "}
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </span>
              <span className="sign-text">Sign From Otherside</span>
            </div>
          );
        } else {
          return (
            <div className="sign-text-icon">
              <span className="icon-style">
                {" "}
                <CloseCircleTwoTone twoToneColor="#eb2f96" />
              </span>
              <span className="sign-text">No Sign From Otherside</span>
            </div>
          );
        }
      }
    }

    // if (selectionType === "purchase") {
    //   if (tradingAggregationId === createdByCompanyId) {
    //     if (tradingtaskcreater === true) {
    //       return (
    //         <div className="sign-text-icon">
    //           <span className="icon-style">
    //             {" "}
    //             <CheckCircleTwoTone twoToneColor="#52c41a" />
    //           </span>
    //           <span className="sign-text">Sign From Otherside</span>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className="sign-text-icon">
    //           <span className="icon-style">
    //             {" "}
    //             <CloseCircleTwoTone twoToneColor="#eb2f96" />
    //           </span>
    //           <span className="sign-text">No Sign From Otherside</span>
    //         </div>
    //       );
    //     }
    //   } else {
    //     if (tradingtaskacceptor === true) {
    //       return (
    //         <div className="sign-text-icon">
    //           <span className="icon-style">
    //             {" "}
    //             <CheckCircleTwoTone twoToneColor="#52c41a" />
    //           </span>
    //           <span className="sign-text">Sign From Otherside</span>
    //         </div>
    //       );
    //     } else {
    //       return (
    //         <div className="sign-text-icon">
    //           <span className="icon-style">
    //             {" "}
    //             <CloseCircleTwoTone twoToneColor="#eb2f96" />
    //           </span>
    //           <span className="sign-text">No Sign From Otherside</span>
    //         </div>
    //       );
    //     }
    //   }
    // }
  };

  return (
    <Fragment>
      <div className="license-contract">
        <div className="contract-header">
          <Row gutter={16}>
            <Col span={10}>
              <div className="contract-asset-profile">
                <Avatar
                  src={
                    getAllTradingTaskRes &&
                    getAllTradingTaskRes.data &&
                    getAllTradingTaskRes.data.contact_id_object &&
                    getAllTradingTaskRes.data.contact_id_object.photo
                  }
                  size={60}
                />
                <div>
                  <h3>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.contact_id_object &&
                      getAllTradingTaskRes.data.contact_id_object.name}
                  </h3>
                  <p>
                    {rightOrganizationData.company_title ||
                      rightOrganizationData.created_by_company_title}
                  </p>
                </div>
              </div>
            </Col>
            {/* <Col className="contract-time" span={6}>
              <p>
                {getOrganizationProfileRes && getOrganizationProfileRes.country}
                , Local Time {getCountrytime(getOrganizationProfileRes)}
              </p>
            </Col>
            <Col className="contract-time" span={6}>
              <p>Your Local Time {getLocalCountrytime()}</p>
            </Col> */}
          </Row>
        </div>
        {/* <div className="contract-upload">
          <h3 className="mb20">LICENSE CONTRACT</h3>
          <div style={{ maxWidth: "400px" }}>
            <Upload {...fileUploadProps}>
              <Button>
                <UploadOutlined /> Upload the file...
                </Button>
            </Upload>
          </div>
          <Button className="upload-history" type="link" onClick={() => showModal('file_history')}><HistoryOutlined /> File history</Button>
        </div> */}
        <div>
          {/* <RoomPersonsSellingCom showModal={showModal} /> */}
          <LicenceAgreementSec
            showModal={showModal}
            getAllTradingTaskRes={getAllTradingTaskRes}
          />
          <LicenseAssetCollapse
            getAllTradingTaskRes={getAllTradingTaskRes}
            showModal={showModal}
            sellingAsset={sellingAsset}
            getFormalLicenseRes={getFormalLicenseRes}
            onGetFormalLicense={onGetFormalLicense}
            location={location}
          />
        </div>

        <div className="signature-btn-sec">
          <h5>SIGNATORIES</h5>
          <div className="signature-agreement-btns">
            {signNowCheck()}
            {/* <Link to="#">
              <Button
                disabled={
                  getAllTradingTaskRes &&
                  getAllTradingTaskRes.data &&
                  getAllTradingTaskRes.data.creator_signed === true
                    ? false
                    : true
                }
                loading={signSaleloading}
                onClick={() => SignSaleConfirmModal("Sign Sale")}
                type="primary"
              >
                SIGN NOW
              </Button>
            </Link> */}
            {/* <div>
              <div className="signature-select">
                <Select defaultValue="Steve Baker" style={{ width: 180 }} disabled>
                  <Option value="Steve Baker">Steve Baker</Option>
                </Select>
              </div>
            </div>
            <div>
            <Link ><Button type="default">PENDING SIGNATURE</Button></Link>
            </div> */}
          </div>
        </div>

        {/* <div className="close-agreement-btns">
           <Button type="primary" onClick={()=> setVisibleSignature(!visibleSignature)}>CLOSE AGREEMENT</Button>
         </div> */}
      </div>
    </Fragment>
  );
};

export default LicenseSellingContract;
