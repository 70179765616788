import React from 'react';

import { Radio } from 'antd';

const FilterByAlphabet = ({setStartsWith}) => {

  const onChange = e => {
    setStartsWith(e.target.value);
  };

  return (
    <div className="alphabet-filter" onChange={onChange}>
      <Radio.Group defaultValue="all" size="small">
        <Radio.Button value="">ALL</Radio.Button>
        <Radio.Button value="a">A</Radio.Button>
        <Radio.Button value="b">B</Radio.Button>
        <Radio.Button value="c">C</Radio.Button>
        <Radio.Button value="d">D</Radio.Button>
        <Radio.Button value="e">E</Radio.Button>
        <Radio.Button value="f">F</Radio.Button>
        <Radio.Button value="g">G</Radio.Button>
        <Radio.Button value="h">H</Radio.Button>
        <Radio.Button value="i">I</Radio.Button>
        <Radio.Button value="j">J</Radio.Button>
        <Radio.Button value="k">K</Radio.Button>
        <Radio.Button value="l">L</Radio.Button>
        <Radio.Button value="m">M</Radio.Button>
        <Radio.Button value="n">N</Radio.Button>
        <Radio.Button value="o">O</Radio.Button>
        <Radio.Button value="p">P</Radio.Button>
        <Radio.Button value="q">Q</Radio.Button>
        <Radio.Button value="r">R</Radio.Button>
        <Radio.Button value="s">S</Radio.Button>
        <Radio.Button value="t">T</Radio.Button>
        <Radio.Button value="u">U</Radio.Button>
        <Radio.Button value="v">V</Radio.Button>
        <Radio.Button value="w">W</Radio.Button>
        <Radio.Button value="x">X</Radio.Button>
        <Radio.Button value="y">Y</Radio.Button>
        <Radio.Button value="z">Z</Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default FilterByAlphabet;