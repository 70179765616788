import React, { useEffect, useState } from "react";
import { Button, Form, Select, Input, Spin, message } from "antd";

const { Option } = Select;

const AddNewUser = ({
  onUpdateUserInfo,
  onGetSpecificUserDetail,
  selectedData,
  checkingBlockedStatus,
  checkingStatus,
  modalType,
  handleCancel,
  currentPage,
  search,
  checkingPendingStatus,
  departmentList,
  onGetDepartmentList,
  onCreateNewUser,
  onGetUserManagementList,
  rolesList,
  onGetRolesList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  // const [specificUsername, setSpecificUsername] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  useEffect(() => {
    setLoading(true);

    onGetRolesList(0, 100, "")
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    onGetDepartmentList(0, 100, "")
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    if (modalType === "edit-user") {
      onGetSpecificUserDetail(selectedData && selectedData._id).then((res) => {
        // setSpecificUsername(selectedData && selectedData.username);
        form.setFieldsValue({
          firstname: res && res.payload.first_name,
          lastname: res && res.payload.last_name,
          username: res && res.payload.username,
          email: res && res.payload.email,
          roles: res && res.payload.roles.map((data) => data._id),
          phone: res && res.payload.phone,
          departments: res && res.payload.departments.map((data) => data._id),
        });
      });
    }
  }, []);

  const handleChangeRoles = (value) => {
    setSelectedRole(value);
  };

  const handleChangeDepartments = (value) => {
    setSelectedDepartments(value);
  };

  const onFinish = (values) => {
    setLoading(true);
    if (modalType === "add-user") {
      const data = {
        email: values.email,
        first_name: values.firstname,
        last_name: values.lastname,
        username: values.username,
        roles: values.roles,
        phone: values.phone,
        departments: values.departments,
      };
      onCreateNewUser(data)
        .then((res) => {
          const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
          setLoading(false);
          onGetUserManagementList(
            skip,
            9,
            checkingBlockedStatus(),
            checkingStatus(),
            checkingPendingStatus(),
            search
          );
          handleCancel();
          message.success(res.payload.message);
        })
        .catch((res) => {
          const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
          setLoading(false);
          onGetUserManagementList(
            skip,
            9,
            checkingBlockedStatus(),
            checkingStatus(),
            checkingPendingStatus(),
            search
          );
        });
    }
    if (modalType === "edit-user") {
      const userUpdateBody = {
        email: values.email,
        first_name: values.firstname,
        last_name: values.lastname,
        username: values.username,
        roles: values.roles,
        phone: values.phone,
        departments: values.departments,
      };

      if (selectedData && selectedData.email !== values.email) {
        userUpdateBody.email = values.email;
      }
      const userId = selectedData && selectedData._id;

      onUpdateUserInfo(userId, userUpdateBody)
        .then((res) => {
          const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
          setLoading(false);
          onGetUserManagementList(
            skip,
            9,
            checkingBlockedStatus(),
            checkingStatus(),
            checkingPendingStatus(),
            search
          );
          handleCancel();
          message.success(res.payload.message);
        })
        .catch((res) => {
          const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
          setLoading(false);
          onGetUserManagementList(
            skip,
            9,
            checkingBlockedStatus(),
            checkingStatus(),
            checkingPendingStatus(),
            search
          );
        });
    }
  };

  return (
    <div className="license-edit-model">
      <h3>{modalType === "add-user" ? "Add New User" : "Edit User"}</h3>
      <Spin size="large" spinning={loading}>
        <Form name="add_new_user" onFinish={onFinish} form={form}>
          <div className="slection-asset-view">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>FIRST NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="firstname"
                    className="full-width mr20 mb0"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the first name",
                      },
                    ]}
                  >
                    <Input placeholder="First name" className="full-width" />
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>LAST NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="lastname"
                    className="full-width mr20 mb0"
                    rules={[
                      { required: true, message: "Please enter the last name" },
                    ]}
                  >
                    <Input placeholder="Last name" className="full-width" />
                  </Form.Item>
                </div>
              </div>
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>USER NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="username"
                    className="full-width mr20 mb0"
                    rules={[
                      { required: true, message: "Please enter the username" },
                    ]}
                  >
                    <Input placeholder="User name" className="full-width" />
                  </Form.Item>

                  {/* {
                    modalType === "edit-user" ?
                      <span style={{ marginLeft: "10px" }}>{specificUsername && specificUsername}</span>
                      :
                      <Form.Item
                        name="username"
                        className="full-width mr20 mb0"
                        rules={[
                          { required: true, message: 'Please enter the username' }
                        ]}
                      >
                        <Input placeholder="User name" className="full-width" />
                      </Form.Item>
                  } */}
                </div>
              </div>
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>PHONE</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item name="phone" className="full-width mr20 mb0">
                    <Input placeholder="Number" className="full-width" />
                  </Form.Item>
                </div>
              </div>
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>EMAIL ADDRESS</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="email"
                    className="full-width mr20 mb0"
                    rules={[
                      { required: true, message: "Please enter the email" },
                    ]}
                  >
                    <Input placeholder="Email Address" className="full-width" />
                  </Form.Item>
                </div>
              </div>
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SELECT ROLE</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="roles"
                    rules={[
                      {
                        required: true,
                        message: "Please select user role!",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Role"
                      value={selectedRole}
                      onChange={handleChangeRoles}
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {rolesList &&
                        rolesList.roles &&
                        rolesList.roles.map((item, index) => (
                          <Option
                            key={index}
                            search={item.name}
                            value={item._id}
                          >
                            <div className="demo-option-label-item">
                              {item.name}
                            </div>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SELECT DEPARTMENTS</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item name="departments">
                    <Select
                      mode="multiple"
                      placeholder="Select Department"
                      value={selectedDepartments}
                      onChange={handleChangeDepartments}
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {departmentList &&
                        departmentList.departments &&
                        departmentList.departments.map((item, index) => (
                          <Option
                            key={index}
                            search={item.name}
                            value={item._id}
                          >
                            <div className="demo-option-label-item">
                              {item.name}
                            </div>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="formal-license-btn">
            <Button htmlType="submit" type="primary">
              {modalType === "add-user" ? "INVITE NEW USER" : "USER UPDATE"}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default AddNewUser;
