import React from 'react';

import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor,
  onChangeSelectionType,
  selectSales,
  setSelectSales,
  selectPurchase,
  setSelectPurchase,
  setSelectionTypeChange,
  setNoLicense,
  setHasLicense,
}) => {
  return (
    <div>
      <InventoryFilters
        showTableEditor={showTableEditor}
        onChangeSelectionType={onChangeSelectionType}

        selectSales={selectSales}
        setSelectSales={setSelectSales}

        selectPurchase={selectPurchase}
        setSelectPurchase={setSelectPurchase}

        setSelectionTypeChange={setSelectionTypeChange}
        setNoLicense={setNoLicense}
        setHasLicense={setHasLicense}
      />
    </div>
  )
}

export default HeaderFilter;