export const singular = (str) => {
    return str.substring(0, str.length - 1)
}

export const getMissingNo = (a, n) => {

    let total = Math.floor((n + 1) * (n + 2) / 2)
    
    for (let i = 0; i < n; i++)
        total -= a[i]
    return total ? total : 1
}

export const mergeDups = (arr) => {
    return [...new Set([].concat(...arr))]
}

const dec2hex = dec => {
    return dec.toString(16).padStart(2, "0")
}
  
export const generateId = len => {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}