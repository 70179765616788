import React from 'react';
import TopHeader from './TopTitleSection';

const HelpComp = () => {
  
  return (
    <div className="help-text">
      <TopHeader />
      {/* <p>We are currently working on a FAQ section and Content.Agent Support Forum. Please bear with us!</p> */}
      {/* <p>In the meantime, Should you have any questions regarding technical support, payment, content or other, please contact us:</p> */}
      <p>Should you have any questions regarding technical support, payment, content or other, please contact us:</p>
      <ul>
        <li>Support AUT: +431375075601</li>
        <li>Support GER: +4981524803937</li>
        <li>Signal: +431375075601</li>
        <li>Email support: support@contentagent.net</li>
        <li>Wiki: support.contentagent.net</li>
      </ul>
    </div>
  )
}

export default HelpComp;