import React from "react";
import { Modal, Tag } from "antd";

const langData = [
  {name: "English"},
  {name: "Arabic"},
  {name: "German"},
  {name: "Turkish"},
]

const LanguagesModal = ({ handleCancel, visible, assetDetail, selectedData }) => {
  return (
    <Modal
      title={false}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      className="modal-style"
      width={420}
      centered
    >
      <div className="specific-roles">
        <h3>Languages</h3>
        {
          selectedData ?
          langData && langData.length > 0 && langData.map((data, index) => {
              return (
                <Tag key={index} className="text-uppercase">{data.name}</Tag>
              )
            }) :
            assetDetail && assetDetail.audioTrack && assetDetail.audioTrack.map((data, index) => {
              return (
                <Tag key={index} className="text-uppercase">{data.language}</Tag>
              )
            })
        }
      </div>
    </Modal>
  );
};

export default LanguagesModal;
