import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const PublishedAssetsForm = ({ handleCancel, modalType }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalType === "edit") {
      form.setFieldsValue({
        name: "Default Input Value",
      });
    }
  });

  const onFinish = (values) => {
    console.log("Success:", values);
    if (modalType === "edit") {
      message.success("Successfully Update");
    }
    handleCancel();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Asset Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your Asset name!",
          },
        ]}
      >
        <Input placeholder="Asset Name" />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="ml10">
            {modalType === "edit" ? "Update" : "Create"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default PublishedAssetsForm;
