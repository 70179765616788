import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const createRoles = (data) => {
  const options = { url: `roles` };

  options.types = [
    types.CREATE_ROLES_SUCCESS,
    types.CREATE_ROLES_FAILURE
  ];

  return apiNew.post(options, data);
}

export const getRolesList = (skip, limit, search) => {
  const options = {
    url: `roles?skip=${skip}&limit=${limit}&order=asc&name=${search}`
  };

  options.types = [
    types.GET_ROLES_LIST_SUCCESS,
    types.GET_ROLES_LIST_FAILURE
  ];

  return apiNew.get(options);
}

export const getSpecificRole = (id) => {
  const options = { url: `roles/${id}` };

  options.types = [
    types.GET_SPECIFIC_ROLES_SUCCESS,
    types.GET_SPECIFIC_ROLES_FAILURE
  ];

  return apiNew.get(options);
}

export const updateRole = (id, data) => {
  const options = { url: `roles/${id}` };

  options.types = [
    types.UPDATE_ROLES_SUCCESS,
    types.UPDATE_ROLES_FAILURE
  ];

  return apiNew.put(options, data);
}

export const deleteRole = (id) => {
  const options = { url: `roles/${id}` };

  options.types = [
    types.DELETE_ROLES_SUCCESS,
    types.DELETE_ROLES_FAILURE
  ];

  return apiNew.delete(options);
}

export const getAvailablePermissoins = () => {
  const options = { url: `permissions` };

  options.types = [
    types.GET_AVAILABLE_PERMISSIONS_SUCCESS,
    types.GET_AVAILABLE_PERMISSIONS_FAILURE
  ];

  return apiNew.get(options);
}