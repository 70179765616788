import React from "react";
import AssetTriler from "./AssetTriler";
import AssetTrilerScreener from "./AssetTrilerScreener";

const AssetVideos = ({ assetDetail, getInventorySeachDetailRes }) => {
  return (
    <div>
      <AssetTriler getInventorySeachDetailRes={getInventorySeachDetailRes} />
      {/* {
        getInventorySeachDetailRes && getInventorySeachDetailRes.assets && getInventorySeachDetailRes.assets.trailers && getInventorySeachDetailRes.assets.trailers.lenght > 0 && */}
      <AssetTrilerScreener
        assetDetail={assetDetail}
        getInventorySeachDetailRes={getInventorySeachDetailRes}
      />
      {/* } */}
    </div>
  );
};

export default AssetVideos;
