import React, { Fragment, useEffect, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getOnlyYear, getOnlyMinutes } from "../../../Common/reUseFunctions";

const SelectionDetail = ({ data }) => {
  const [singleVersion, setSingleVersion] = useState({});
  const [durationsArr, setdurationsArr] = useState([]);
  const [versionLength, setversionLength] = useState(0);
  const [versionIndex, setversionIndex] = useState(0);

  useEffect(() => {
    versionFirstValueSet(data);
  }, [data]);

  useEffect(() => {
    if (singleVersion) {
      saveVersionCompleteData(singleVersion);
    }
  }, [singleVersion]);

  const versionFirstValueSet = (inventorySeachDetail) => {
    if (
      inventorySeachDetail &&
      inventorySeachDetail.asset &&
      inventorySeachDetail.asset.versions &&
      inventorySeachDetail.asset.versions.length > 0
    ) {
      setSingleVersion(inventorySeachDetail.asset.versions[0]);
      setversionLength(inventorySeachDetail.asset.versions.length);
      setversionIndex(0);
    }
  };

  const saveVersionCompleteData = (versionObj) => {
    const inventoryDetail = data && data.asset;
    if (!versionObj || !inventoryDetail) {
      return;
    }
    let durations = [];

    if (versionObj.duration) {
      durations.push(versionObj.duration);
    }

    setdurationsArr(durations);
  };

  const nextVersionData = () => {
    setSingleVersion(data.asset.versions[versionIndex + 1]);
    setversionIndex(versionIndex + 1);
  };

  const previewVersionData = () => {
    setSingleVersion(data.asset.versions[versionIndex - 1]);
    setversionIndex(versionIndex - 1);
  };

  return (
    <Fragment>
      <div className="catalogues-p-grid c-col-panel">
        <div className="c-col-img">
          <img
            src={data && data.asset && data.asset.preview_image}
            alt="Catalogues"
          />
        </div>
        <div className="catalogues-p-info">
          <div className="c-d-title">
            <h4>
              {data && data.asset && data.asset.title && data.asset.title.value}
            </h4>
            <span>
              {versionIndex > 0 && versionLength > 1 ? (
                <LeftOutlined onClick={previewVersionData} />
              ) : (
                <LeftOutlined style={{ opacity: 0.3 }} />
              )}
              {singleVersion.id}
              {versionLength > 1 && versionIndex !== versionLength - 1 ? (
                <RightOutlined onClick={nextVersionData} />
              ) : (
                <RightOutlined style={{ opacity: 0.3 }} />
              )}
            </span>
          </div>
          <p className="duration">
            <span>
              {data && data.asset && getOnlyYear(data.asset.production_date)}
            </span>{" "}
            {durationsArr.map((data) => getOnlyMinutes(data))}
          </p>
          <p className="m-cata">
            <span>PRODUCTION COMPANY</span>
            {data &&
              data.asset &&
              data.asset.production_company &&
              data.asset.production_company.value}
          </p>
          <p className="m-cata">
            <span>CO-PRODUCTION</span>
            {data &&
              data.asset &&
              data.asset.co_production_companies &&
              data.asset.co_production_companies.map((data) => data.value)}
          </p>
          <p className="m-cata">
            <span>FILM DIRECTOR</span>
            {data &&
              data.asset &&
              data.asset.directors &&
              data.asset.directors.join(", ")}
          </p>
          <p className="m-cata">
            <span>FEATURED ARTISTS</span>{" "}
            {data &&
              data.asset &&
              data.asset.contributors &&
              data.asset.contributors.map(
                (data) => data.artist && data.artist.value
              )}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectionDetail;
