import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SpecialCatalogues from "../Components/ContentArea/Administration/SpecialCatalogues";

import {
  getSpecialCatalogues,
  createSpecialCatalogue,
  updateSpecialCatalogue,
  deleteSpecialCatalogue,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    specialCataloguesList: state.catalogueReducer.getSpecialCatalogues,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSpecialCatalogues: bindActionCreators(getSpecialCatalogues, dispatch),
    onCreateSpecialCatalogue: bindActionCreators(
      createSpecialCatalogue,
      dispatch
    ),
    onUpdateSpecialCatalogue: bindActionCreators(
      updateSpecialCatalogue,
      dispatch
    ),
    onDeleteSpecialCatalogue: bindActionCreators(
      deleteSpecialCatalogue,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialCatalogues);
