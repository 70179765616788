import React from 'react';
import { Button } from 'antd';
import { closeIcon } from './../Icons';

import { StarOutlined } from '@ant-design/icons';

import { getOnlyYear, onlyMinutesByDate, onlyHoursByDate } from './../reUseFunctions';

const InventorySearchResult = ({
  searchResult,
  setSearchResult,
  onSaveSearch
}) => {

  const removeFilterItems = (index, item) => {
    let array = [...searchResult];
    if (item) {
      if (array[index].film_genre) {
        let innerIndex = array[index].film_genre.indexOf(item);
        array[index].film_genre.splice(innerIndex, 1);
        setSearchResult(array);
      }
      if (array[index].Music_genre) {
        let innerIndex = array[index].Music_genre.indexOf(item);

        array[index].Music_genre.splice(innerIndex, 1);
        setSearchResult(array);
      }
    }
    if (!item) {
      array.splice(index, 1);
      setSearchResult(array);
    }
  };

  return (
    <div className="inventory-search-result inventory-save-search">
      <div>
        <div><h3>Search results for “Cosi fan tutte” in My Inventory</h3></div>
        <div>
          {
            searchResult.map((data, index) => {
              return (
                <div className="inventory-search-tabs" key={index}>
                  <span>{data.asset_select_filter}</span>
                  <span className="inventory-result">

                    {
                      (data && data.film_genre && data.film_genre.length > 0) &&
                      data.film_genre.map((item, i) => {
                        return (
                          <span key={i}>
                            {item}
                            <Button type="link" onClick={() => removeFilterItems(index, item)}>
                              {closeIcon}
                            </Button>
                          </span>
                        )
                      })
                    }

                    {
                      (data && data.Music_genre && data.Music_genre.length > 0) &&
                      data.Music_genre.map((item, i) => {
                        return (
                          <span key={i}>
                            {item}
                            <Button type="link" onClick={() => removeFilterItems(index, item)}>
                              {closeIcon}
                            </Button>
                          </span>
                        )
                      })
                    }

                    {
                      (data && data.video_resolution) &&
                      <span>
                        {data.video_resolution}
                        <Button type="link" onClick={() => removeFilterItems(index)}>
                          {closeIcon}
                        </Button>
                      </span>
                    }

                    {
                      (data && data.production_year) &&
                      <span>
                        {data.production_year[0] && getOnlyYear(data.production_year[0])} - {data.production_year[0] && getOnlyYear(data.production_year[1])}
                        <Button type="link" onClick={() => removeFilterItems(index)}>
                          {closeIcon}
                        </Button>
                      </span>
                    }

                    {
                      (data && data.director) &&
                      <span>
                       {data.director}
                        <Button type="link" onClick={() => removeFilterItems(index)}>
                          {closeIcon}
                        </Button>
                      </span>
                    }
                    {
                      (data && data.duration_minute) &&
                      <span>
                        {data && data.duration_minute[0] && onlyMinutesByDate(data.duration_minute[0])} - 
                        {data && data.duration_minute[1] && onlyMinutesByDate(data.duration_minute[1])}
                        <Button type="link" onClick={() => removeFilterItems(index)}>
                          {closeIcon}
                        </Button>
                      </span>
                    }
                    {
                      (data && data.duration_hour) &&
                      <span>
                        {data && data.duration_hour[0] &&  onlyHoursByDate(data.duration_hour[0])} - 
                        {data && data.duration_hour[1] && onlyHoursByDate(data.duration_hour[1])}
                        <Button type="link" onClick={() => removeFilterItems(index)}>
                          {closeIcon}
                        </Button>
                      </span>
                    }
                    {
                      (data && data.production_company) &&
                      <span>
                       {data.production_company}
                        <Button type="link" onClick={() => removeFilterItems(index)}>
                          {closeIcon}
                        </Button>
                      </span>
                    }
                  </span>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="save-search"><Button onClick={() => onSaveSearch("save-search", searchResult)} type="link"><StarOutlined /> Save this Search</Button></div>
    </div>
  )
}

export default InventorySearchResult;