import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SpecialCatalogueDetail from "../Components/ContentArea/Administration/SpecialCataloguesDetail";

import {
  getSpecialCatalogue,
  getSpecialCatalogues,
  updateSpecialCatalogue,
  deleteSpecialCatalogue,
  getSpecificUserDetail,
  mianUserDetail,
  getSpecificUserImage,
  logout,
  getInventory,
  addAssetToSpecialCatalogue,
  deleteAssetFromSpecialCatalogue,
  getNotifications,
  putNotifications,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    specialCatalogueRes: state.catalogueReducer.specialCatalogueRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    mainUserRes: state.userReducer.mainUserRes,
    specificUserImage: state.userManagementReducer.specificUserImage,
    logoutRes: state.userReducer.logoutRes,
    inventoryListRes: state.inventorySearchReducer.inventoryListRes,
    spceialCataloguesAssetRes:
      state.specialCatalogueReducer.specialCatalogueAssetsRes,
    organizationRes: state.organizationReducer.organizationRes,
    getNotificationsRes: state.notificationsReducer.getNotificationsRes,
    putNotificationsRes: state.notificationsReducer.putNotificationsRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSpecialCatalogue: bindActionCreators(getSpecialCatalogue, dispatch),
    onGetSpecialCatalogues: bindActionCreators(getSpecialCatalogues, dispatch),
    onUpdateSpecialCatalogue: bindActionCreators(
      updateSpecialCatalogue,
      dispatch
    ),
    onDeleteSpecialCatalogue: bindActionCreators(
      deleteSpecialCatalogue,
      dispatch
    ),
    onGetSpecificUserDetail: bindActionCreators(
      getSpecificUserDetail,
      dispatch
    ),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
    onGetSpecificUserImage: bindActionCreators(getSpecificUserImage, dispatch),
    onLogout: bindActionCreators(logout, dispatch),
    onGetInventory: bindActionCreators(getInventory, dispatch),
    onAddAssetToSpecialCatalogue: bindActionCreators(
      addAssetToSpecialCatalogue,
      dispatch
    ),
    onDeleteAssetFromSpecialCatalogue: bindActionCreators(
      deleteAssetFromSpecialCatalogue,
      dispatch
    ),
    onGetNotifications: bindActionCreators(getNotifications, dispatch),
    onPutNotifications: bindActionCreators(putNotifications, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialCatalogueDetail);
