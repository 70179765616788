import React from 'react';
import { Row, Col } from 'antd';

import AssetChart from "./AssetChart";
import SubLicense from "./SubLicensIssued";
import RecentPublished from "./RecentPublished";
import RfpReceived from "./RfpReceived";
import Proposals from "./ProposalReceived";
import Actions from "./Auctions";

const DashboardPage = () => {

  return (
    <Row gutter={16}>
      <Col className="mb20" span={8}>
        {/* <AssetChart /> */}
      </Col>
      <Col className="mb20" span={8}>
        {/* <SubLicense /> */}
      </Col>
      <Col className="mb20" span={8}>
        {/* <RecentPublished /> */}
      </Col>

      <Col className="mb20" span={8}>
        {/* <RfpReceived /> */}
      </Col>
      <Col className="mb20" span={8}>
        {/* <Proposals /> */}
      </Col>
      <Col className="mb20" span={8}>
        {/* <Actions /> */}
      </Col>
    </Row>
  )
}

export default DashboardPage;