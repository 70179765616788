import React from 'react';

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory c-m-title">
      <div><h3>Columns Mapping - Values Correction </h3></div>
    </div>
  )
}

export default TopTitleSection;