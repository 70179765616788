import React from "react";

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Unitel Catalogue</h3>
      </div>
    </div>
  );
};

export default TopTitleSection;
