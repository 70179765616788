import React from 'react';
import { Input, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const Filter = ({
  onSearch,
  search,
}) => {


  return (
    <Row gutter={16} className="mb15 add-selection-filter">
      <Col span={24}>
        <h2>Select Column</h2>
      </Col>
      <Col span={24}>
        <Input
          value={search}
          onChange={onSearch}
          prefix={<SearchOutlined />}
          placeholder="Search Selections"
        />
      </Col>
    </Row>
  )
}

export default Filter;