import React, { useState } from 'react';
import { Row, Col, Button, Radio, Form, DatePicker, Select, } from 'antd';

import { countryList } from './../../../../statics/data/countryList'
import { licenseFlags } from './../../../../statics/data/LicenseFlags'

const { Option } = Select;

const optionsOne = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

const optionsTwo = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

const Detail = ({
  setVisible,
  setLicenseData,
  licenseData,
  handleCancel

}) => {

  const [resale, setResale] = useState("Yes");
  const [exlusive, setExlusive] = useState("No");
  const [selectedContry, setSelectedContry] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState([]);

  const reSaleValue = e => {
    console.log('radio1 checked', e.target.value);
    setResale(e.target.value)
  };

  const exlusiveLicense = e => {
    setExlusive(e.target.value)
  };

  const handleChangeCountry = selectedContry => {
    setSelectedContry(selectedContry)
  };

  const handleChangeLicense = selectedLicense => {
    setSelectedLicense(selectedLicense)
  };

  const onFinish = values => {
    const dataList = [...licenseData, values]
    setLicenseData(dataList)
    setVisible(false);
  };

  return (
    <div className="checkout-box-group mb20">
      <Form
        name="validate_other"
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="resale" label="Resale Allowed">
              <Radio.Group options={optionsOne} onChange={reSaleValue} value={resale} defaultValue={resale} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="exlusive" label="Exclusive License">
              <Radio.Group options={optionsTwo} onChange={exlusiveLicense} value={exlusive} defaultValue={exlusive} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="dateTime"
              label="DateTime"
              rules={[{ required: true, message: 'Please choose the dateTime' }]}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                getPopupContainer={trigger => trigger.parentElement}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="select_countries"
              label="Select Countries"
              hasFeedback
              rules={[{ required: true, message: 'Please select your country!' }]}
            >

              <Select
                mode="multiple"
                placeholder="Countries"
                value={selectedContry}
                onChange={handleChangeCountry}
                style={{ width: '100%' }}
              >
                {countryList.map((item, index) => (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

          </Col>
          <Col span={24}>
            <Form.Item
              name="select_license"
              label="License Flags"
              hasFeedback
              rules={[{ required: true, message: 'Please select your License Flag!' }]}
            >

              <Select
                mode="multiple"
                placeholder="License Flag"
                value={selectedLicense}
                onChange={handleChangeLicense}
                style={{ width: '100%' }}
              >
                {licenseFlags.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

          </Col>
          <Col span={24}>
            <Form.Item style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
                Add New
                  </Button>
              <Button type="secondary" onClick={handleCancel}>
                Cancel
                  </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Detail;