import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {exclamationIcon} from './../../../../Common/Icons';
import { Link } from 'react-router-dom';

const OPTIONS = [
  'Action',
  'Adult',
  'Adventure',
  'Animation',
  'Biography',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'Film Noir',
  'History',
  'Horror',
  'Live Event',
  'Music',
  'Musical',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Short Film',
  'Sport',
  'Thriller',
  'War',
  'Western',
];

const FilmGenres = ({ helperText, validationField, valuesCorrection }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "film_genres")) {
      setTabsChange(false)
    }
  }, [validationField])

  const handleChange = selectedItems => {
    setSelectedItems(selectedItems);
  };

  const filteredOptions = OPTIONS.filter(o => !selectedItems.includes(o));
  return (
    <Row gutter={16} className="mb5">
      <Col className="pl0_important" span={24}>
        <Link onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title error-f-tab" : "metadata-tab-title metadata-tab-active error-f-tab"}
          >
            <h3>Film Genres</h3>
            <div className="tab-error-sec">
              {
                !valuesCorrection &&
                <div className="tab-error-found">
                  <Button type="link" >
                  {exclamationIcon} Errors Found
                  </Button>
                </div>
              }
            <DownOutlined />
            </div>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Header text would go here for this section.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <div className="metadata-field-box">
            <Row gutter={16}>
              <Col span={24}>
                <div className="metadata-field-row">
                  <div className="metadata-field-name"><h3 className=""><span>Film Genres</span></h3></div>
                  <div className="metadata-filed">
                    <Form.Item
                      name="filmGenre"
                      rules={[{ required: false, message: 'Please select your FilmGenres!', type: 'array' }]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select multiple..."
                        value={selectedItems}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                        getPopupContainer={trigger => trigger.parentElement}
                      >
                        {filteredOptions.map(item => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FilmGenres;