import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Login from '../Components/User/Login';

import {
  signIn,
  getSession
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    signInRes: state.userReducer.signInRes,
    sessionRes: state.userReducer.sessionRes
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSignIn: bindActionCreators(signIn, dispatch),
    onGetSession: bindActionCreators(getSession, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);