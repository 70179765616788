import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import WalletComp from "../Components/ContentArea/Wallet";

import {
  getWalletList,
  getWalletDetail,
  createWallet,
  updateWallet,
  deleteWallet,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    walletList: state.walletReducer.walletList,
    walletDetail: state.walletReducer.walletDetail,
    createWallet: state.walletReducer.createWallet,
    updateWallet: state.walletReducer.updateWallet,
    deleteWallet: state.walletReducer.deleteWallet,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetWalletList: bindActionCreators(getWalletList, dispatch),
    onGetWalletDetail: bindActionCreators(getWalletDetail, dispatch),
    onCreateWallet: bindActionCreators(createWallet, dispatch),
    onUpdateWallet: bindActionCreators(updateWallet, dispatch),
    onDeleteWallet: bindActionCreators(deleteWallet, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletComp);
