import React from 'react';
import { Input } from 'antd'
import { searchIcon } from './../../../../../../../../Common/Icons';

const InventorySearchDropdown = ({onFinish} ) => {

  return (
    <div className="d-flex">
      <div className="inventory-search-bar s-c-filterbar">
        <Input placeholder="Search" prefix={searchIcon} onChange={payload => onFinish(payload)} />
      </div>
    </div>
  )
}

export default InventorySearchDropdown;