import React from 'react';
import InventorySearchDropDown from "./InventorySearchDropdown";

const InventorySearchDropdown = ({
  showTableEditor,
        onSearch,
}) => {

  return (
    <div className="inventory-bottom-filter flex-end-imp">
      <InventorySearchDropDown
       showTableEditor={showTableEditor}
       onSearch={onSearch}
      />
    </div>
  )
}

export default InventorySearchDropdown;