import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const updateTradingList = (id) => {
  const options = {
    url: `trading-tasks/${id}`
  };

  options.types = [
    types.UPDATE_TRADING_TASKS_SUCCESS,
    types.UPDATE_TRADING_TASKS_FAILURE
  ];

  return apiNew.put(options);
}

export const createTradingList = (data) => {
  const options = {
    url: `trading-tasks`
  };

  options.types = [
    types.CREATE_TRADING_TASKS_SUCCESS,
    types.CREATE_TRADING_TASKS_FAILURE
  ];

  return apiNew.post(options, data);
}

export const getAllTradingTask = (id) => {
  const options = {
    url: `trading-tasks/${id}`
  };

  options.types = [
    types.ADD_TRADING_TASKS_SUCCESS,
    types.ADD_TRADING_TASKS_FAILURE
  ];

  return apiNew.get(options);
}

export const postTradingTask = (data) => {
  const options = {
    url: `trading-tasks/vendor-determination`
  };

  options.types = [
    types.POST_TRADING_TASKS_SUCCESS,
    types.POST_TRADING_TASKS_FAILURE
  ];

  return apiNew.post(options, data);
}



