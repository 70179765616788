import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const createOrganization = (data) => {
  const options = {
    url: `organizations/profile`
  };

  options.types = [
    types.CREATE_ORGANIZATION_SUCCESS,
    types.CREATE_ORGANIZATION_FAILURE
  ];

  return apiNew.post(options, data);
}

export const getOrganizationDetail = () => {
  const options = {
    url: `organizations/profile`
  };

  options.types = [
    types.GET_ORGANIZATION_DETAIL_SUCCESS,
    types.GET_ORGANIZATION_DETAIL_FAILURE
  ];

  return apiNew.get(options);
}

export const getOrganizationImage = (path) => {
  const options = {
    url: `organizations/profile/${path}`
  };

  options.types = [
    types.GET_ORGANIZATION_IMAGE_SUCCESS,
    types.GET_ORGANIZATION_IMAGE_FAILURE
  ];

  return apiNew.getBlobImage(options);
}

export const updateOrganizationProfile = (data) => {
  const options = { url: `organizations/profile` };

  options.types = [
    types.UPDATE_ORGANIZATION_PROFILE_SUCCESS,
    types.UPDATE_ORGANIZATION_PROFILE_FAILURE
  ];

  return apiNew.put(options, data);
}

export const getOrganizationProfile = () => {
  const options = { url: `organizations/profile` };

  options.types = [
    types.GET_ORGANIZATION_PROFILE_SUCCESS,
    types.GET_ORGANIZATION_PROFILE_FAILURE
  ];

  return apiNew.get(options);
}
