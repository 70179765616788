import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { yyyymmddAndTimeFormate } from "./../../../Common/reUseFunctions";
import { ExportOutlined, ImportOutlined } from "@ant-design/icons";

const ListArchive = ({ showTableEditor, getTradingListRes }) => {
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (
      getTradingListRes &&
      getTradingListRes.data &&
      getTradingListRes.data.trading_tasks &&
      getTradingListRes.data.trading_tasks.length > 0
    ) {
      let listPush = [];
      getTradingListRes &&
        getTradingListRes.data &&
        getTradingListRes.data.trading_tasks.map((data) => {
          if (
            data.status === "Cancelled" ||
            data.status === "Contract Signed"
          ) {
            data.key = data._id;
            listPush.push(data);
          }
        });
      setTableList(listPush);
    } else {
      setTableList([]);
    }
  }, [getTradingListRes]);

  // const changeAssign = (value, record) => {
  //   console.log('Selected', value)

  //   const assignedToObj = {
  //     company_id: record.created_by_company_id,
  //     contact_id: value,
  //   }
  //   onGetTradingTask(record._id, assignedToObj)
  //   .then((res) => {
  //     message.success(res && res.payload && res.payload.data && res.payload.data.message);
  //   })
  // }

  const columns = [
    {
      title: "Record Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <div className="d-flex align-item-center">
          {record.type === "purchase" && (
            <span className="mr10 expor-icons-height">
              <ImportOutlined />
            </span>
          )}
          {record.type === "sale" && (
            <span className="mr10 expor-icons-height">
              <ExportOutlined />
            </span>
          )}
          <span className="ml10">
            {yyyymmddAndTimeFormate(record.created_at)}
          </span>
        </div>
      ),
    },
    {
      title: "Number of Assets",
      dataIndex: "assets",
      key: "assets",
      render: (text, record) => (
        <Link to="#" onClick={() => showTableEditor("asset-list", record)}>
          <u>{text.length} Assets</u>
        </Link>
      ),
    },
    {
      title: "Contractor",
      dataIndex: "company_title",
      key: "company_title",
    },
    {
      title: "Contact",
      dataIndex: "contact_id_object",
      key: "contact_id_object",
      render: (text, record) =>
        record.contact_id_object && record.contact_id_object.name,
    },
    {
      title: "Assigned To",
      key: "assigned_to_object",
      dataIndex: "assigned_to_object",
      render: (text, record) =>
        record && record.assigned_to_object && record.assigned_to_object.name,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (text, record) => yyyymmddAndTimeFormate(record.deadline),
    },
    {
      title: "Rescheduled To (Optional)",
      dataIndex: "reschedule_date",
      key: "reschedule_date",
    },
    {
      title: "Closing Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <Table
      pagination={false}
      className="table-style task-table"
      columns={columns}
      dataSource={tableList}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
    />
  );
};

export default ListArchive;
