import React from 'react';
import { Tabs } from 'antd';
import { exportIcon } from '../../../../Common/Icons';
import NoAssetsModal from './NumberAssets';
import PermissionsModal from './PermissionsModal';
const { TabPane } = Tabs;

const EvaluateModal = ({
  cancelTableEditor,
  showTradingTask,
}) => {
  return (
    <div className="evaluate-sec">
      <div className="no-asset-header">
        <h3>{exportIcon} Euro Distribution</h3>
      </div>
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Assets" key="1">
          <NoAssetsModal
            cancelTableEditor={cancelTableEditor}
            showTradingTask={showTradingTask}
          />
        </TabPane>
        <TabPane tab="Permissions" key="2">
          <PermissionsModal
            cancelTableEditor={cancelTableEditor}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default EvaluateModal;