import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const getMemberCatalogues = (skip, limit, name, starts_with) => {
  const options = {
    url: `organizations?skip=${skip}&limit=${limit}&order=asc&name=${name}&starts_with=${starts_with}`
  };

  options.types = [
    types.GET_CATALOGUES_SUCCESS,
    types.GET_CATALOGUES_FAILURE
  ];

  return apiNew.get(options);
}

export const getGeneralCatalogue = (skip, limit, id) => {
  const options = {
    url: `organizations/${id}?skip=${skip}&limit=${limit}&order=asc`
  };

  options.types = [
    types.GET_GENERAL_CATALOGUES_SUCCESS,
    types.GET_GENERAL_CATALOGUES_FAILURE
  ];

  return apiNew.get(options);
}

export const getHighlights = () => {
  const options = {
    url: `organizations/profile/highlights`
  };

  options.types = [
    types.GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS,
    types.GET_HIGHLIGHTS_OF_ORGANIZATION_FAILURE
  ];

  return apiNew.get(options);
}

export const updateHighlights = (data) => {
  const options = {
    url: `organizations/profile/highlights`
  };

  options.types = [
    types.UPDATE_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS,
    types.UPDATE_HIGHLIGHTS_OF_ORGANIZATION_FAILURE
  ];

  return apiNew.put(options, data);
}