import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import {exclamationIcon} from './../../../../Common/Icons';
import { Link } from 'react-router-dom';

const Screener = ({
  validationField,
  helperText,
  valuesCorrection 
}) => {
  const [screenerPreview, setScreenerPreview] = useState('');
  const [tabsChange, setTabsChange] = useState(true);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "screener")) {
      setTabsChange(false)
    }
  }, [validationField])

  const onChangeTrailer = (e) => {
    setScreenerPreview(e.target.value);
  }
  return (
    <Row gutter={16} className="mb5">

      <Col className="pl0_important" span={24}>
        <Link onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title error-f-tab" : "metadata-tab-title metadata-tab-active error-f-tab"}
          >
            <h3>Preview Images</h3>
            <div className="tab-error-sec">
              {
                !valuesCorrection &&
                <div className="tab-error-found">
                  <Button type="link" >
                  {exclamationIcon} Errors Found
                  </Button>
                </div>
              }
            <DownOutlined />
            </div>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Header text would go here for this section.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="previewImages">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map(field => (
                    <div>
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Preview</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "preview"]}
                                fieldKey={[field.fieldKey, "preview"]}
                                rules={[
                                  { required: false, message: 'Please enter the preview link' },
                                ]}
                                className="full-width mr10 mb0"
                                required
                              >
                                <Input placeholder="Preview link" value={screenerPreview} onChange={onChangeTrailer} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '100%' }}
                      >
                        <PlusOutlined /> Add New
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>

        </div>
      </Col>

    </Row>
  )
}

export default Screener;