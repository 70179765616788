import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const selectionProposalReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_FORMAL_LICENSE_SUCCESS:
      return { ...state, getFormalLicenseRes: action.payload };
    case types.GET_FORMAL_LICENSE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, getFormalLicenseRes: action.payload };

    case types.CREATE_FORMAL_LICENSE_SUCCESS:
      return { ...state, createFormalLicenseRes: action.payload };
    case types.CREATE_FORMAL_LICENSE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, createFormalLicenseRes: action.payload };

    case types.UPDATE_FORMAL_LICENSE_SUCCESS:
      return { ...state, updateFormalLicenseFormRes: action.payload };
    case types.UPDATE_FORMAL_LICENSE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, updateFormalLicenseFormRes: action.payload };

    case types.DELETE_FORMAL_LICENSE_SUCCESS:
      return { ...state, deleteFormalLicenseFormRes: action.payload };
    case types.DELETE_FORMAL_LICENSE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, deleteFormalLicenseFormRes: action.payload };

    case types.CANCEL_NEGOTIATION_SUCCESS:
      return { ...state, cancelNegotiationRes: action.payload };
    case types.CANCEL_NEGOTIATION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, cancelNegotiationRes: action.payload };

    case types.UPLOAD_AGREEMENT_FILE_SUCCESS:
      return { ...state, uploadAgreementFileRes: action.payload };
    case types.UPLOAD_AGREEMENT_FILE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, uploadAgreementFileRes: action.payload };

    case types.GET_LOGS_LIST_SUCCESS:
      return { ...state, getLogsListRes: action.payload };
    case types.GET_LOGS_LIST_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, getLogsListRes: action.payload };

    case types.SEND_INVITATIONS_SUCCESS:
      return { ...state, sendInvitationRes: action.payload };
    case types.SEND_INVITATIONS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, sendInvitationRes: action.payload };

    default:
      return state;
  }
};
