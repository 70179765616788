import React from "react";
import { Button, Form, Select, DatePicker, TimePicker } from "antd";
import { ImportOutlined } from "@ant-design/icons";
import moment from "moment";
import { Link } from "react-router-dom";

const { Option } = Select;
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

const RequestProposalModal = ({ modelType, handleCancel }) => {
  const onChangeUser = (value) => {
    console.log(`selected ${value}`);
  };

  const onSelectUser = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div className="license-edit-model request-p-modal">
      <h3>Request Proposal</h3>
      <h4>
        <ImportOutlined /> Sundance Film Festival 2021
      </h4>
      <Form name="validate_other" onFinish={onFinish}>
        <div className="slection-asset-view">
          <div className="formal-license-view">
            <h5>DEADLINE FOR TASK</h5>
            <div className="r-p-bg">
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SET TIME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="start_date"
                    rules={[
                      { required: true, message: "Please choose the time" },
                    ]}
                  >
                    <TimePicker defaultValue={moment("12:08:23", "HH:mm:ss")} />
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SET DATE</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="end_date"
                    rules={[
                      { required: true, message: "Please choose the date" },
                    ]}
                  >
                    <DatePicker
                      defaultValue={moment("01/01/2015", dateFormatList[0])}
                      format={dateFormatList}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <h5>ASSIGN TASK</h5>

            <div className="r-p-bg">
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>ROLE</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="re_sales"
                    rules={[
                      { required: true, message: "Please choose the resales" },
                    ]}
                  >
                    <Select defaultValue="User" onChange={onChangeUser}>
                      <Option value="User">User</Option>
                      <Option value="Admin">Admin</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SELECT USER</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="exclusive_license"
                    rules={[
                      {
                        required: true,
                        message: "Please choose the exclusive license",
                      },
                    ]}
                  >
                    <Select
                      defaultValue="Fionna Jordan"
                      onChange={onSelectUser}
                    >
                      <Option value="Jhone Smith">Jhone Smith</Option>
                      <Option value="Fionna Jordan">Fionna Jordan</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formal-license-btn">
          {modelType === "request" && (
            <div className="r-p-btn">
              <Button onClick={handleCancel}>CANCEL</Button>

              <Button type="primary">
                <Link to="/trading-tasks">CREATE PURCHASING TASK</Link>
              </Button>
            </div>
          )}
          {modelType === "add" && (
            <Button htmlType="submit" type="primary">
              ADD LICENSE DEFINITION
            </Button>
          )}
          {modelType === "edit" && (
            <Button htmlType="submit" type="primary">
              SAVE CHANGES
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default RequestProposalModal;
