import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

const AssetCatalogue = ({
  items,
  notExists,
  addToHighlights,
  removeFromHighlights,
}) => {
  return (
    <Fragment>
      <Row gutter={16}>
        {items &&
          items.map((item) => (
            <Col className="mb20" span={8} key={item._id}>
              {/* <Link to="/#!"> */}
              <div className="catalogues-banner catalogue-b-grid">
                <div
                  className={"catalogue-bg"}
                  style={{ backgroundImage: `url(${item.preview_image})` }}
                >
                  <img
                    className="catalogue-bg-img"
                    src={item.preview_image}
                    alt="Catalogues"
                  />
                  <div className="new-items-grid-img-overlay"></div>
                  <h2 className="new-items-grid-img-text">
                    {item.title.value}
                  </h2>
                </div>
                {/* <img className="catalogue-bg-img" src={item.preview_image} alt="Catalogues" />
                <div className="catalogues-b-text new-items-grid-img-overlay">
                  <div>
                    <h2 className="new-items-grid-img-overlay-text">{item.title.value} </h2>
                  </div>
                </div> */}
              </div>
              {/* </Link> */}
              <div className="new-catalogues-info highlights-catalogue s-catalogues-text">
                <h3>{item.title.value}</h3>
                {notExists("assets", item._id) ? (
                  <div className="catalouge-btns">
                    <Button
                      type="link"
                      className="p0 border-0"
                      onClick={() =>
                        addToHighlights("assets", "asset", item._id)
                      }
                    >
                      {" "}
                      Add to Highlights
                    </Button>
                  </div>
                ) : (
                  <div className="catalouge-btns">
                    <Button
                      type="link"
                      className="p0 border-0"
                      onClick={() => removeFromHighlights("assets", item._id)}
                    >
                      {" "}
                      Remove from Highlights
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          ))}
      </Row>
    </Fragment>
  );
};

export default AssetCatalogue;
