import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainRoutes from '../Components';

import {
  getSession
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    sessionRes: state.userReducer.sessionRes,
    logoutRes: state.userReducer.logoutRes,
    signInRes: state.userReducer.signInRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSession: bindActionCreators(getSession, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);