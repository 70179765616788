import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  TableEditorComp from '../Components/Common/TableEditor/TableDragDrop';

import {
  updateInventoryHeader,
  getInventoryHeader,
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    updateInventoryHeaderRes: state.inventorySearchReducer.updateInventoryHeaderRes,
    getInventoryHeaderRes: state.inventorySearchReducer.getInventoryHeaderRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateInventoryHeader: bindActionCreators(updateInventoryHeader, dispatch),
    onGetInventoryHeader: bindActionCreators(getInventoryHeader, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableEditorComp);
