import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColumnMapping from '../Components/ContentArea/Administration/ColumnsMapping';

import {
  logout,

  getSpecificUserDetail,
  getSpecificUserImage,
  changeUserPassword,
  updateProfileAndUsername,
  updateUserPicture,
  mianUserDetail

} from '../store/Actions';

const mapStateToProps = state => {
  return {
    logoutRes: state.userReducer.logoutRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    specificUserImage: state.userManagementReducer.specificUserImage,
    changePasswordRes: state.userManagementReducer.changePasswordRes,
    updateUserprofileAndUsername: state.userManagementReducer.updateUserprofileAndUsername,
    updateUserProfileRes: state.userReducer.updateUserProfileRes,
    mainUserRes: state.userReducer.mainUserRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: bindActionCreators(logout, dispatch),
    onGetSpecificUserDetail: bindActionCreators(getSpecificUserDetail, dispatch),
    onGetSpecificUserImage: bindActionCreators(getSpecificUserImage, dispatch),
    onChangeUserPassword: bindActionCreators(changeUserPassword, dispatch),
    onUpdateProfileAndUsername: bindActionCreators(updateProfileAndUsername, dispatch),
    onUpdateUserPicture: bindActionCreators(updateUserPicture, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnMapping);

