import React from 'react';
import { Input, Row, Col  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {searchIcon} from './../Icons'

const Filter = ({
  onSearch,
  search,
  ViewSearchPreset
}) => {
                
  return (
    <Row gutter={16} className="mb15 add-selection-filter">
      <Col span={24}>
        <h2>{ViewSearchPreset === "search_preset" ? "Licence Preset" : "Columns Mapping Preset"}</h2>
      </Col>
      <Col span={24}>
        <Input
          value={search}
          onChange={onSearch}
          prefix={searchIcon}
          placeholder="Search Presets"
        />
      </Col>
    </Row>
  )
}

export default Filter;