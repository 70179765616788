import React from "react";
import { Table, Tag, Space, Button } from "antd";

const DataMaintenanceList = () => {
  const columns = [
    {
      title: "STATUS",
      dataIndex: "Status",
      key: "Status",
      render: (text) => text,
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "LENGTH",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "DIRECTOR",
      dataIndex: "director",
      key: "director",
    },
    {
      title: "LANGUAGES",
      key: "languages",
      dataIndex: "languages",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
          })}
        </>
      ),
    },
    {
      title: "PRODUCTION COMPANY",
      dataIndex: "productionCompany",
      key: "productionCompany",
    },
    {
      title: "PRODUCTION YEAR",
      dataIndex: "productionYear",
      key: "productionYear",
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="table-action">
          <Button type="link">History</Button>
          <Button type="link">View</Button>
          <Button type="link">Publish</Button>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      Status: "Published",
      title: "The Female Voice of Iran 1",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2001,
    },
    {
      key: "2",
      Status: "Market-Ready",
      title: "The Female Voice of Iran 2",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2002,
    },
    {
      key: "3",
      Status: "Non Market-Ready",
      title: "The Female Voice of Iran 3",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2003,
    },
    {
      key: "4",
      Status: "Market-Ready",
      title: "The Female Voice of Iran 2",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2002,
    },
    {
      key: "5",
      Status: "Published",
      title: "The Female Voice of Iran 3",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2003,
    },
    {
      key: "6",
      Status: "Published",
      title: "The Female Voice of Iran 2",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2002,
    },
    {
      key: "7",
      Status: "Published",
      title: "The Female Voice of Iran 3",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2003,
    },
    {
      key: "8",
      Status: "Published",
      title: "The Female Voice of Iran 2",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2002,
    },
    {
      key: "9",
      Status: "Published",
      title: "The Female Voice of Iran 3",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2003,
    },
    {
      key: "10",
      Status: "Published",
      title: "The Female Voice of Iran 2",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2002,
    },
    {
      key: "11",
      Status: "Non Market-Ready",
      title: "The Female Voice of Iran 3",
      length: "1hr 20mins",
      director: "Francine Richards",
      languages: ["EN", "DE", "FR"],
      productionCompany: "Horror House Entertainment",
      productionYear: 2003,
    },
  ];

  return (
    <Table
      pagination={false}
      className="table-style"
      columns={columns}
      dataSource={data}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
    />
  );
};

export default DataMaintenanceList;
