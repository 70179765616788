import React from "react";
import { Table, Space, Badge, Button, Tag, Select, message } from "antd";
import { Link } from "react-router-dom";

import { ExportOutlined, ImportOutlined } from "@ant-design/icons";
import {
  getRolesPermissions,
  yyyymmddAndTimeFormate,
} from "./../../../Common/reUseFunctions";

const { Option } = Select;

const ListBoth = ({
  showTableEditor,
  getTradingListRes,
  getUserListRes,
  onGetTradingTask,
  mainUserRes,
  getOrganizationProfileRes,
}) => {
  // const [tableList, setTableList] = useState([]);

  // useEffect(() => {
  //   if (
  //     getTradingListRes &&
  //     getTradingListRes.data &&
  //     getTradingListRes.data.trading_tasks &&
  //     getTradingListRes.data.trading_tasks.length > 0
  //   ) {
  //     let listPush = [];
  //     getTradingListRes &&
  //       getTradingListRes.data &&
  //       getTradingListRes.data.trading_tasks.map((data) => {
  //         if (
  //           data.status !== "Cancelled" &&
  //           data.status !== "Contract Signed"
  //         ) {
  //           data.key = data._id;
  //           listPush.push(data);
  //         }
  //       });
  //     setTableList(listPush);
  //   } else {
  //     setTableList([]);
  //   }
  // }, [getTradingListRes]);

  const changeAssign = (value, record) => {
    const assignedToObj = {
      company_id:
        getOrganizationProfileRes &&
        getOrganizationProfileRes.trading_aggregation_organization_id,
      contact_id: value,
    };
    onGetTradingTask(record._id, assignedToObj).then((res) => {
      message.success(
        res && res.payload && res.payload.data && res.payload.data.message
      );
    });
  };

  const columns = [
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div className="d-flex align-item-center">
          {record.type === "purchase" && (
            <span className="mr10 expor-icons-height">
              <ImportOutlined />
            </span>
          )}
          {record.type === "sale" && (
            <span className="mr10 expor-icons-height">
              <ExportOutlined />
            </span>
          )}
          <Badge
            color={
              (text === "Overdue" && "#FF3131") ||
              (text === "Not Assigned" && "white") ||
              (text === "Not Accepted" && "#C2C2C2") ||
              (text === "In Progress" && "#36E15E") ||
              (text === "Rescheduled" && "#9370DB") ||
              (text === "Closing" && "#FFA500") ||
              (text === "Promotion In" && "#FFFF66")
            }
            text={text}
            className="badge-dot-style"
          />
          {record.new === true && <Tag className="new-label ml10">New</Tag>}
        </div>
      ),
    },
    {
      title: "Number of Assets",
      dataIndex: "assets",
      key: "assets",
      render: (text, record) =>
        record.assets && record.assets.length > 0 ? (
          <Link to="#" onClick={() => showTableEditor("asset-list", record)}>
            <u>{text.length} Assets</u>
          </Link>
        ) : (
          <Link to="#">
            <u>{text.length} Assets</u>
          </Link>
        ),
    },
    {
      title: "Contractor",
      dataIndex: "company_title",
      key: "company_title",
    },
    {
      title: "Contact",
      dataIndex: "contact_id_object",
      key: "contact_id_object",
      render: (text, record) =>
        record.contact_id_object && record.contact_id_object.name,
    },
    {
      title: "Assigned To",
      key: "assigned_to_object",
      dataIndex: "assigned_to_object",
      render: (text, record) => (
        <Select
          value={record && record.assigned_to_object && record.assigned_to_object._id}
          className="select-without-border full-width"
          defaultValue={
            record && record.assigned_to_object && record.assigned_to_object._id
          }
          onChange={(e) => changeAssign(e, record)}
        >
          {getUserListRes &&
            getUserListRes.users &&
            getUserListRes.users.length > 0 &&
            getUserListRes.users.map((data, index) => {
              return (
                data &&
                data.tas_user_id && (
                  <Option
                    key={index}
                    disabled={!data.tas_user_id ? true : false}
                    value={data.tas_user_id}
                  >
                    {data.name}
                  </Option>
                )
              );
            })}
        </Select>
      ),
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (text, record) => yyyymmddAndTimeFormate(record.deadline),
    },
    {
      title: "Rescheduled To (Optional)",
      dataIndex: "reschedule_date",
      key: "reschedule_date",
    },
    {
      title: "Approval status",
      dataIndex: "approval_status",
      key: "approval_status",
      render: (text, record) => (
        <Link to="#">
          {record.approval_status.length > 0 ? (
            <u>Approved {record.approval_status}</u>
          ) : (
            ""
          )}
        </Link>
      ),
    },
    {
      title: "ACTIONS",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <Space size="middle" className="table-action">
          {/* <Link onClick={text === "Preview" ? () => showTableEditor("preview-modal") : () => showTableEditor("evaluate-modal")}><Button type="link">{text}</Button></Link> */}
          <Link to="#" onClick={() => showTableEditor("preview-modal", record)}>
            <Button
              disabled={
                record.assets && record.assets.length > 0 ? false : true
              }
              type="link"
            >
              Preview
            </Button>
          </Link>
          {record.action === "Evaluate" ? (
            ""
          ) : getRolesPermissions(mainUserRes, "trading-task.show") ===
            false ? (
            <Link to={`/trading-task/${record._id}`}>
              <Button
                disabled={
                  record.assets && record.assets.length > 0 ? false : true
                }
                type="link"
              >
                Detail
              </Button>
            </Link>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table

        pagination={false}
        className="table-style task-table"
        columns={columns}
        dataSource={getTradingListRes && getTradingListRes.data && getTradingListRes.data.trading_tasks}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        rowKey={(record) => record._id}
      />
    </div>
  );
};

export default ListBoth;
