import React from 'react';

import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  onChangeSelections,
  changeSelectionView,
  selection,
  showModal,
  selectionsList,
  location,
  updateSelection,
  selectionDetail
}) => {

  return (
    <div>
      <TopTitleSection
        onChangeSelections={onChangeSelections}
        selection={selection}
        showModal={showModal}
        selectionsList={selectionsList}
        location={location}
        updateSelection={updateSelection}
        selectionDetail={selectionDetail}
      />
      <InventoryFilters changeSelectionView={changeSelectionView} />
    </div>
  )
}

export default HeaderFilter;