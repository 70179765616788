import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import Placeholder from "../../../../statics/images/placeholder.jpg";

const AddAssets = ({ showCatalogue }) => {
  return (
    <div onClick={showCatalogue} className="maintain-add-asset-sec-plus-block">
      <div className="add-asset-sec-plus-block">
        <div>
          <PlusOutlined />
          <span className="promotional-info">Add Assets</span>
        </div>
      </div>
      <img src={Placeholder} alt="placeholder" width="100%" />
      {/* <img
        src="https://demo.world.imz.at/api/v1/special-catalogues/banner-banner-hollywood-618b9a902e5625001d692162-618b9a900fb598001c6c6922.jpeg/image"
        alt="test"
        width="100%"
      /> */}
    </div>
  );
};

export default AddAssets;
