import React, { useEffect } from "react";
import { Switch } from "antd";

const MetadataSwitchFilter = ({
  setHelperText,
  helperText,

  setRequiredField,
  requiredField,

  setAllMetadata,
  allMetadata,
}) => {
  useEffect(() => {
    if (allMetadata === true) {
      setRequiredField(false);
    }
  }, [requiredField, allMetadata, setRequiredField, setAllMetadata]);

  const onChangeRequiredSwitch = (checked) => {
    setRequiredField(checked);
    setAllMetadata(false);
  };

  const onChangeHelperSwitch = (checked) => {
    setHelperText(checked);
  };

  return (
    <div>
      <div className="inventry-switch-btns pl2px">
        <span>
          <Switch
            size="small"
            checked={requiredField}
            onChange={() => onChangeRequiredSwitch(!requiredField)}
          />{" "}
          Minimum Required Metadata
        </span>
        <span>
          <Switch
            size="small"
            onChange={() => onChangeHelperSwitch(!helperText)}
          />{" "}
          Helper Text
        </span>
      </div>
    </div>
  );
};

export default MetadataSwitchFilter;
