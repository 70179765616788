import React, { Fragment } from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

const TopTitleSection = ({
  showModal,
  assetDetail,
  InventoryDetail,
  getInventorySeachDetailRes,
  type,
}) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>
          {getInventorySeachDetailRes &&
            getInventorySeachDetailRes.title &&
            getInventorySeachDetailRes.title.value}
        </h3>
      </div>
      {type !== "from-metadata-form" && (
        <Fragment>
          {InventoryDetail ? (
            <div className="header-btn-groups">
              <Button onClick={showModal} type="primary">
                ADD TO SELECTION
              </Button>
            </div>
          ) : (
            <div className="header-btn-groups">
              <Link to={`/inventory-search`}>
                <Button onClick={showModal} type="default">
                  GO TO INVENTORY SEARCH
                </Button>
              </Link>
              <Link to={`/member-profile`}>
                <Button onClick={showModal} type="primary">
                  GO TO CATALOGUES
                </Button>
              </Link>
              <Button
                onClick={() =>
                  showModal(
                    getInventorySeachDetailRes && getInventorySeachDetailRes._id
                  )
                }
                type="primary"
              >
                ADD TO SELECTION
              </Button>
            </div>
          )}
        </Fragment>
      )}
      {/* <div className="header-btn-groups">
        <Link to={`/inventory-search`}><Button onClick={showModal} type="default">GO TO INVENTORY SEARCH</Button></Link>
        <Link to={`/member-profile`}><Button onClick={showModal} type="primary">GO TO CATALOGUES</Button></Link>
        <Button onClick={showModal} type="primary">ADD TO SELECTION</Button>
      </div> */}
    </div>
  );
};

export default TopTitleSection;
