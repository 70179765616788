import React from 'react';
import { Input, Button, Popover } from 'antd'
import InventorySelectForm from "./InventorySelectFilter"

import { searchIcon, settingIconTable } from '../../../../../../Common/Icons';

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onFinish,
  showTableEditor
}) => {

  return (
    <div className="d-flex">
      <div className="inventory-search-bar">
        <Popover className="topbar-popover" placement="bottom" content={<InventorySelectForm onFinish={onFinish} />} trigger="click">
          <Button><Input placeholder="Search" prefix={searchIcon} /></Button>
        </Popover>

      </div>
      <div className="layout-btn">
        <div className="radio-btn-style">
          <Button
            type="default"
            disabled={changeLayout === "GRID" ? true : false}
            icon={settingIconTable}
            onClick={showTableEditor}
          />
        </div>
      </div>
    </div>
  )
}

export default InventorySearchDropdown;