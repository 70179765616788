import React from 'react';

import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor
}) => {
  return (
    <div>
      <TopTitleSection />
      <InventoryFilters showTableEditor={showTableEditor} />
    </div>
  )
}

export default HeaderFilter;