import React from "react";
import { Button } from "antd";
import { getRolesPermissions } from "../../../../Common/reUseFunctions";

const TopTitleSection = ({ showModal, organizationRes, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>{organizationRes && organizationRes.name}</h3>
      </div>
      {getRolesPermissions(mainUserRes, "organization-profile.edit") ===
      false ? (
        <div className="header-btn-groups">
          <Button onClick={() => showModal("edit-organization")} type="default">
            EDIT PROFILE
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TopTitleSection;
