import React, { useEffect, useState } from "react";
import { Row, Col, Button, Skeleton } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const MemberProfile = ({ catalogue }) => {
  const [catalogueName, setCatalogueName] = useState("");
  const [catalogueDescription, setCatalogueDescription] = useState("");
  const [catalogueImage, setCatalogueImage] = useState("");
  const [catalogueId, setCatalogueId] = useState("");

  useEffect(() => {
    if (catalogue && catalogue.organization) {
      setCatalogueName(catalogue.organization.name);
      setCatalogueDescription(catalogue.organization.description);
      setCatalogueId(catalogue.organization._id);
      setCatalogueImage(catalogue.catalogue.special_catalogue_data.banner);
    }
  }, [catalogue]);

  return (
    <Row gutter={24}>
      <Col className="mb40" span={12}>
        <div className="trading-task-detail catalogue-back">
          <Link to={`premium-profile/${catalogueId}`}>
            <Button type="link" className="back-button">
              {/* <ArrowLeftOutlined /> {`Back to ${catalogueName} Catalogue`} */}
              <ArrowLeftOutlined /> {`Back to Standard Catalogue`}
            </Button>
          </Link>
        </div>
        <div className="member-profile-text">
          <p>{catalogueDescription}</p>
        </div>
      </Col>
      <Col className="mb40" span={12}>
        <div className="member-profile-img">
          {catalogueImage ? (
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${catalogueImage}/image`}
              alt={catalogueName}
              width={"100%"}
            />
          ) : (
            <Skeleton.Image />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default MemberProfile;
