import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectionProposalClosing from '../Components/ContentArea/SelectionProposalClosing';

import {
  getLogsList,
  getOrganizationProfile,
  getAllTradingTask,
  getFormalLicense,
  addSignSale,
  getSign,
  getSignSale,
  getAssetIdSale,
  getOrganizationImage,
  cancelNegotiation,
  updateTradingTaskStatus
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    getAllTradingTaskRes: state.requestProposalReducer.getAllTradingTaskRes,
    getLogsListRes: state.selectionProposalReducer.getLogsListRes,
    getOrganizationProfileRes: state.organizationReducer.getOrganizationProfileRes,
    getFormalLicenseRes: state.selectionProposalReducer.getFormalLicenseRes,
    addSignSaleRes: state.closingRoomReducer.addSignSaleRes,
    getSignRes: state.closingRoomReducer.getSignRes,
    getSignSaleRes: state.closingRoomReducer.getSignSaleRes,
    getAssetIdSaleRes: state.closingRoomReducer.getAssetIdSaleRes,
    organizationImageRes: state.organizationReducer.organizationImageRes,
    cancelNegotiationRes: state.selectionProposalReducer.cancelNegotiationRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTradingTask: bindActionCreators(getAllTradingTask, dispatch),
    onGetLogsList: bindActionCreators(getLogsList, dispatch),
    onGetOrganizationProfile: bindActionCreators(getOrganizationProfile, dispatch),
    onGetFormalLicense: bindActionCreators(getFormalLicense, dispatch),
    onAddSignSale: bindActionCreators(addSignSale, dispatch),
    onGetSign: bindActionCreators(getSign, dispatch),
    onGetSignSale: bindActionCreators(getSignSale, dispatch),
    onGetAssetIdSale: bindActionCreators(getAssetIdSale, dispatch),
    onGetOrganizationImage: bindActionCreators(getOrganizationImage, dispatch),
    onCancelNegotiation: bindActionCreators(cancelNegotiation, dispatch),
    onUpdateTradingTaskStatus: bindActionCreators(updateTradingTaskStatus, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionProposalClosing);
