import React, { useEffect, useState } from "react";
import { Button, Form, Input, Upload, Spin, Modal, message } from "antd";
import ImgCrop from "antd-img-crop";

const AddEditCatalogue = ({
  modal,
  handleCancel,
  onCreate,
  onUpdate,
  loading,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (modal.type === "edit-catalogue") {
      const { payload } = modal;

      form.setFieldsValue({
        name: payload.name,
        banner: payload.banner,
        description: payload.description,
      });
    }
  }, [modal]);

  const uploadService = () => {
    return Promise.resolve();
  };

  const customRequest = async (options) => {
    options.onProgress({ percent: 0 });
    const url = await uploadService();
    options.onProgress({ percent: 100 });
    options.onSuccess({}, { ...options.file, url });
    console.log(options);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onFinish = (values) => {
    const { payload, type } = modal;
    const data = fileList[0]
      ? {
          name: values.name,
          banner: fileList[0].originFileObj,
          description: values.description,
        }
      : {
          name: values.name,
          description: values.description,
        };

    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    if (type === "new-catalogue") onCreate(formData);
    else if (type === "edit-catalogue") onUpdate(formData, payload._id);
  };

  const beforeUpload = (file) => {
    const isJPG = file.type === "image/jpeg";
    const isJPEG = file.type === "image/jpeg";
    const isGIF = file.type === "image/gif";
    const isPNG = file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      message.error(
        "The format of the uploaded image is incorrect, not JPG, JPEG, GIF, PNG"
      );
    }
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return new Promise((resolve, reject) => {
      if (!(isJPG || isJPEG || isGIF || isPNG) || !isLt2M) {
        reject(file);
      } else {
        resolve(file);
      }
    });
  };

  const { TextArea } = Input;

  return (
    <Modal
      visible={modal.visible}
      onCancel={handleCancel}
      footer={false}
      className="modal-style"
      width={520}
    >
      <div className="license-edit-model">
        <h3>
          {modal.type === "new-catalogue"
            ? "Add New Catalogue"
            : "Edit Catalogue"}
        </h3>
        <Spin size="large" spinning={loading}>
          <Form
            name="add_edit_special_catalogue"
            onFinish={onFinish}
            form={form}
          >
            <div className="slection-asset-view">
              <div className="formal-license-view">
                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>NAME</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="name"
                      className="full-width mr20 mb0"
                      rules={[
                        { required: true, message: "Please enter the name" },
                      ]}
                    >
                      <Input placeholder="Name" className="full-width" />
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row edit-profile-textarea">
                  <div className="formal-license-name">
                    <span>UPLOAD BANNER IMAGE</span>
                    <p>700x350 px. Max File Size 2MB</p>
                  </div>
                  <div className="formal-license-data upload-hero-image">
                    <Form.Item
                      name="upload_hero_image"
                      className="full-width mr20 mb0"
                      rules={[
                        {
                          required: false,
                          message: "Please upload the hero image",
                        },
                      ]}
                    >
                      <div className="hero-image-thumbnail">
                        {!fileList.length && modal.payload ? (
                          <img
                            src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${modal.payload.banner}/image`}
                            alt="avatar"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          ""
                        )}

                        <ImgCrop
                          aspect={16 / 9}
                          modalTitle="Crop Image"
                          modalOk="SAVE CHANGES"
                          modalCancel="CANCEL"
                          className="crop-image-style"
                        >
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            customRequest={customRequest}
                            beforeUpload={beforeUpload}
                          >
                            {fileList.length < 5 && <u>Upload Image</u>}
                          </Upload>
                        </ImgCrop>
                      </div>
                    </Form.Item>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>DESCRIPTION</span>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="description"
                      className="full-width mr20 mb0"
                      rules={[
                        { required: true, message: "Please enter description" },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        placeholder="Description"
                        className="full-width"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="formal-license-btn">
              <Button htmlType="submit" type="primary">
                {modal.type === "new-catalogue"
                  ? "ADD NEW CATALOGUE"
                  : "CATALOGUE UPDATE"}
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default AddEditCatalogue;
