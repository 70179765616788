import React from "react";
import { Switch } from "antd";

const InventorySwitchFilter = ({
  setBlockedFilter,
  blockedFilter,
  setPendingFilter,
  pendingFilter,
}) => {

  const onChangePending = (checked) => {
    setPendingFilter(checked);
  };

  const onChangeIsBlock = (checked) => {
    setBlockedFilter(checked);
  };

  return (
    <div>
      <div className="inventry-switch-btns user-m-filter">

        <span>
          <Switch
            checked={blockedFilter}
            size="small"
            onChange={() => onChangeIsBlock(!blockedFilter)}
          />
          Pending
        </span>

        <span>
          <Switch
            checked={pendingFilter}
            size="small"
            onChange={() => onChangePending(!pendingFilter)}
          />
          Complete
        </span>
      </div>
    </div>
  );
};

export default InventorySwitchFilter;
