import React from 'react';
import InventoryFilterBar from "./InventoryFiltersBar"

const MyMarketInventory = ({
  showTableEditor,
        onSearch,
}) => {

  return (
    <div>
      <InventoryFilterBar
        showTableEditor={showTableEditor}
        onSearch={onSearch}
      />

    </div>
  )
}

export default MyMarketInventory;