import React, { useState } from 'react';
import { Form, DatePicker, Space, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const MUSICGENRE = [
  "Alternative",
  "Blues",
  "Children's Music",
  "Classical",
  "Comedy",
  "Commercial",
  "Country",
  "Electronic",
  "Enka",
  "Folk Music",
  "Hip-Hop/Rap",
  "Holiday",
  "Industrial",
  "Jazz",
  "Latin",
  "New Age",
  "Opera",
  "Pop",
  "R&amp;B/Soul",
  "Reggae",
  "Religious",
  "Rock",
  "Singer/Songwriter",
  "Soundtrack",
  "Vocal",
  "World",
];
const FILMGENRE = [
  'Action',
  'Adult',
  'Adventure',
  'Animation',
  'Biography',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'Film Noir',
  'History',
  'Horror',
  'Live Event',
  'Music',
  'Musical',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Short Film',
  'Sport',
  'Thriller',
  'War',
  'Western',
];

const SelectFilterItem = ({
  field,
  remove
}) => {
  const [searchFilterValue, setSearchFilterValue] = useState("")
  const [filmGenre, setFilmGenre] = useState("")

  const onChangeFilmGenre = (value) => {
    setFilmGenre(value)
  }

  const onChangeValue = (value) => {
    setSearchFilterValue(value)
  }

  const filteredMusic = MUSICGENRE.filter(o => !filmGenre.includes(o));
  const filteredFilm = FILMGENRE.filter(o => !filmGenre.includes(o));

  return (
    <Space key={field.key} style={{ display: 'flex', marginBottom: 8, alignItems: "baseline" }} align="start">

      <Form.Item
        {...field}
        name={[field.name, 'asset_select_filter']}
        fieldKey={[field.fieldKey, 'asset_select_filter']}
        rules={[{ required: true, message: 'Select First Filter' }]}
        className="select-width"
      >
        <Select
          placeholder="Please select..."
          onChange={onChangeValue}
        >
          <Option value="Production Year">Production Year</Option>
          <Option value="Film Genre">Film Genre</Option>
          <Option value="Music Genre">Music Genre</Option>
          <Option value="Video Resolution">Video Resolution</Option>
        </Select>
      </Form.Item>
      {
        searchFilterValue === "Production Year" &&

        <Form.Item
          {...field}
          name={[field.name, 'production_year']}
          fieldKey={[field.fieldKey, 'production_year']}
          rules={[{ required: true, message: 'Select Date' }]}
          className="input-width"
        >
          <RangePicker picker="year" />
        </Form.Item>
      }
      {
        searchFilterValue === "Film Genre" &&

        <Form.Item
          {...field}
          name={[field.name, 'film_genre']}
          fieldKey={[field.fieldKey, 'film_genre']}
          rules={[{ required: true, message: 'Select Genre' }]}
          className="input-width"
        >
          <Select
            mode="multiple"
            placeholder="Please select..."
            onChange={onChangeFilmGenre}
          >
            {filteredFilm.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }

      {
        searchFilterValue === "Music Genre" &&

        <Form.Item
          {...field}
          name={[field.name, 'Music_genre']}
          fieldKey={[field.fieldKey, 'Music_genre']}
          rules={[{ required: true, message: 'Select Genre' }]}
          className="input-width"
        >
          <Select
            mode="multiple"
            placeholder="Please select..."
          // onChange={onChangeValue}
          >
            {filteredMusic.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }

      {
        searchFilterValue === "Video Resolution" &&

        <Form.Item
          {...field}
          name={[field.name, 'video_resolution']}
          fieldKey={[field.fieldKey, 'video_resolution']}
          rules={[{ required: true, message: 'Select Resolution' }]}
          className="input-width"
        >
          <Select
            placeholder="Please select..."
          // onChange={onChangeValue}
          >
            <Option value="UHD/4K">UHD/4K</Option>
            <Option value="PAL/NTSC">PAL/NTSC</Option>
            <Option value="8K">8K</Option>
            <Option value="HD">HD</Option>
            <Option value="8K">Film 35 mm</Option>
          </Select>
        </Form.Item>
      }

      <MinusCircleOutlined
        onClick={() => {
          remove(field.name);
        }}
      />
    </Space>
  )
}

export default SelectFilterItem;