import React from 'react';
import { Button } from 'antd';
import { closeIcon } from '../../../../../Common/Icons';

const InventorySearchResult = ({
  searchResult,
  setSearchResult
}) => {

  const removeFilterItems = (data) => {
    let array = [...searchResult];
    let index = array.indexOf(data)
    if (index !== -1) {
      array.splice(index, 1);
      setSearchResult(array);
    }
  };

  return (
    <div className="inventory-search-result">
      <div><h3>Search results for “Cosi fan tutte”</h3></div>
      <div>
        {
          searchResult.map((data, index) => {
            return (
              <div className="inventory-search-tabs" index={index}>
                <span>{data.asset_select_filter}</span>
                <span className="inventory-result">
                  {data.asset_type_text}
                  <Button type="link" onClick={() => removeFilterItems(data)}>
                    {closeIcon}
                  </Button>
                </span>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default InventorySearchResult;