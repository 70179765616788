import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Modal, message } from "antd";
import LicenseContract from "./LicenseContract";
import LicenseSellingContract from "./LicenseSellingContract";
import ProposalHeaderFilter from "./ProposalHeaderFilter";
import RulesRegulationModal from "./Chat/RulesRegulationModal";
import NegotiationInvitation from "./Chat/NegotiationInvitationModal";
import FormalLicenseEdit from "./FormalLicenseEdit";
import Filehistory from "./../SelectionProposal/FileHistory";
import InvitePersonsInRoom from "../../Common/InvitePersonsInRoom";
import SignNowModal from "./SignNowModal";
import { getUrlSecondLastId } from "../../Common/reUseFunctions";
import DeleteConfirmation from "../../Common/Components/DeleteConfirmation";

const SelectionProposalClosing = ({
  history,
  location,
  onGetLogsList,
  getLogsListRes,
  onGetAllTradingTask,
  getAllTradingTaskRes,
  onGetOrganizationProfile,
  getOrganizationProfileRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  onAddSignSale,
  onGetSign,
  getSignRes,
  onGetSignSale,
  onGetAssetIdSale,
  onGetOrganizationImage,
  cancelNegotiationRes,
  onCancelNegotiation,
   onUpdateTradingTaskStatus
}) => {
  const [logoImage, setLogoImage] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);
  const [signSaleConfirm, setSignSaleConfirm] = useState(false);
  const [signSaleloading, setSignSaleLoading] = useState(false);
  const [leftOrganizationData, setLeftOrganizationData] = useState({});
  const [rightOrganizationData, setRightOrganizationData] = useState({});
  const [signedAndUnSignedAssets, setSignedAndUnSignedAssets] = useState([]);

  let tradingAggregationId = "";
  let companyId = "";
  let createdByCompanyId = "";
  let companyName = "";
  let createdByCompanyName = "";
  let selectionType = "";

  if (getOrganizationProfileRes && getAllTradingTaskRes) {
    tradingAggregationId =
      getOrganizationProfileRes.trading_aggregation_organization_id;
    companyId =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_id;
    createdByCompanyId =
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.created_by_company_id;
    companyName =
      getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_title;
    createdByCompanyName =
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.created_by_company_title;
    selectionType = getAllTradingTaskRes.data && getAllTradingTaskRes.data.type;
  }


  useEffect(() => {
    let _data  = {
      status: "Closing"
    }
    const locationUrl = location.pathname;
    const tradingId = getUrlSecondLastId(locationUrl);
    onUpdateTradingTaskStatus( tradingId, _data)
  }, []);

  useEffect(() => {
    const locationUrl = location.pathname;
    const tradingTaskId = getUrlSecondLastId(locationUrl);
    onGetAllTradingTask(tradingTaskId);
    onGetLogsList(tradingTaskId);
  }, []);

  useEffect(() => {
    onGetOrganizationProfile().then((res) => {
      if (res && res.payload && res.payload.logo !== null) {
        onGetOrganizationImage(res && res.payload && res.payload.logo).then(
          (res) => {
            const blob = new Blob([res.payload], {
              type: "application/octet-stream",
            });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64String = reader.result;
              setLogoImage(base64String);
            };
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    // if (tradingAggregationId === companyId) {
    //   setLeftOrganizationData({
    //     company_title: companyName,
    //     contact_id: companyId,
    //   });
    //   setRightOrganizationData({
    //     created_by_company_title: createdByCompanyName,
    //     created_by_company_id: createdByCompanyId,
    //   });
    // }


    if (tradingAggregationId === createdByCompanyId) {
      setLeftOrganizationData({
        created_by_company_title: createdByCompanyName,
        created_by_company_id: createdByCompanyId,
      });
      setRightOrganizationData({
        company_title: companyName,
        contact_id: companyId,
      });
    }
    else
    {
      setLeftOrganizationData({
        company_title: companyName,
        contact_id: companyId,
      });
      setRightOrganizationData({
        created_by_company_title: createdByCompanyName,
        created_by_company_id: createdByCompanyId,
      });
    }
  }, [getOrganizationProfileRes, getAllTradingTaskRes]);

  const signConfirmingModal = () => {
    setSignSaleConfirm(true);
  };

  const onChangeSelections = (e) => {
    // setChangeSelectionView(e)
  };

  const closeDelConfirmModal = () => {
    setSignSaleConfirm(false);
  };

  const showModal = (value) => {
    setVisible(true);
    setModalType(value);
  };

  const onVideoCall = () => {
    setVisible(false);
    // setVideoCall(true)
  };

  const handleCancel = (value) => {
    setVisible(false);
    // setShowChat(value)
  };

  // const dropVideoCall = () => {
  //   setVideoCall(false)
  // }

  // const onConfirmDelete = () => {
  //   setDelConfirm(false);
  //   setLoading(true);
  //   const locationUrl = location.pathname;
  //   const tradingTaskId = getUrlSecondLastId(locationUrl);
  //   onGetSign(tradingTaskId).then((res) => {
  //     message.success(
  //       res && res.payload && res.payload.data && res.payload.data.message
  //     );
  //     const locationUrl = location.pathname;
  //     const tradingTaskId = getUrlSecondLastId(locationUrl);
  //     onGetAllTradingTask(tradingTaskId);
  //     setLoading(false);
  //   });
  // };

  const SignSaleConfirmModal = (value) => {
    setSignSaleConfirm(true);
    setModalType(value);
  };

  const onConfirmSignSale = async () => {
    let tradingtaskcreater =
        getAllTradingTaskRes.data && getAllTradingTaskRes.data.creator_signed;
    let tradingtaskacceptor =
        getAllTradingTaskRes.data && getAllTradingTaskRes.data.acceptor_signed;
    setSignSaleConfirm(false);
    setLoading(true);
    const locationUrl = location.pathname;
    const tradingTaskId = getUrlSecondLastId(locationUrl);
    let allAssets = [...signedAndUnSignedAssets];

    await onGetSign(tradingTaskId).then((res) => {
      if (res && res.payload && res.payload.status === true) {
        message.success(
          res && res.payload && res.payload.data && res.payload.data.message
        );


        const locationUrl = location.pathname;
        const tradingId = getUrlSecondLastId(locationUrl);

            if (
                getAllTradingTaskRes &&
                getAllTradingTaskRes.data &&
                getAllTradingTaskRes.data.assets &&
                getAllTradingTaskRes.data.assets.length > 0
            ) {
              getAllTradingTaskRes.data.assets.map(async (data) => {
                await onGetSignSale(tradingTaskId, data.id).then(
                    async (response) => {
                        if (selectionType === "sale") {
                          if(tradingtaskcreater === false && tradingtaskacceptor === true)
                          {
                            await onGetAssetIdSale(data.id).then(async (res) => {
                              if (res && res.type === "GET_ASSET_ID_SALE_SUCCESS") {
                                const signSaleBody = {
                                  license: response && response.payload,
                                };
                                await onAddSignSale(
                                    res && res.payload && res.payload.asset_id,
                                    signSaleBody
                                )
                                    .then((responseData) => {
                                      let _data  = {
                                        status: "Contract Signed"
                                      }
                                      onUpdateTradingTaskStatus( tradingId, _data);
                                      message.success(
                                          responseData &&
                                          responseData.payload &&
                                          responseData.payload.message
                                      );
                                    })
                                    .catch(() => {
                                      data.signed_asset = false;
                                      allAssets.push(data);
                                    });
                              }
                            });
                          }
                          else
                          {
                            //donothing
                          }

                        }
                        if (selectionType === "purchase") {
                          if(tradingtaskcreater === true && tradingtaskacceptor === false)
                          {
                            await onGetAssetIdSale(data.id).then(async (res) => {
                              if (res && res.type === "GET_ASSET_ID_SALE_SUCCESS") {
                                const signSaleBody = {
                                  license: response && response.payload,
                                };
                                await onAddSignSale(
                                    res && res.payload && res.payload.asset_id,
                                    signSaleBody
                                )
                                    .then((responseData) => {
                                      let _data  = {
                                        status: "Contract Signed"
                                      }
                                      onUpdateTradingTaskStatus( tradingId, _data);
                                      message.success(
                                          responseData &&
                                          responseData.payload &&
                                          responseData.payload.message
                                      );
                                    })
                                    .catch(() => {
                                      data.signed_asset = false;
                                      allAssets.push(data);
                                    });
                              }
                            });
                          }
                          else
                          {
                            //donothing
                          }
                        }
                    }
                );
              });
            }
        }
        onGetAllTradingTask(tradingTaskId);
        setLoading(false);
    });
    setSignedAndUnSignedAssets(allAssets);
  };

  return (
    <Fragment>
      <ProposalHeaderFilter
          location={location}
        onChangeSelections={onChangeSelections}
        showModal={showModal}
      />
      <div className="licnse-contract-sec">
        <Row gutter={16} className="mt20 margin--24">
          <Col span={12}>
            <LicenseContract
              history={history}
              showModal={showModal}
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              getFormalLicenseRes={getFormalLicenseRes}
              onGetFormalLicense={onGetFormalLicense}
              location={location}
              onGetSign={onGetSign}
              signConfirmingModal={signConfirmingModal}
              loading={loading}
              logoImage={logoImage}
              cancelNegotiationRes={cancelNegotiationRes}
              onCancelNegotiation={onCancelNegotiation}
              leftOrganizationData={leftOrganizationData}
              signedAndUnSignedAssets={signedAndUnSignedAssets}
            />
          </Col>
          <Col span={12}>
            <LicenseSellingContract
              history={history}
              showModal={showModal}
              sellingAsset="selling-assets"
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              getFormalLicenseRes={getFormalLicenseRes}
              onGetFormalLicense={onGetFormalLicense}
              location={location}
              onGetSign={onGetSign}
              getSignRes={getSignRes}
              SignSaleConfirmModal={SignSaleConfirmModal}
              signSaleloading={signSaleloading}
              rightOrganizationData={rightOrganizationData}
            />
          </Col>
        </Row>
      </div>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className={
          modalType === "file_history"
            ? "modal-style history-modal"
            : "modal-style"
        }
        width={
          (modalType === "rules_regulation" && 500) ||
          (modalType === "send_invitation" && 800) ||
          (modalType === "file_history" && 500) ||
          (modalType === "license_edit" && 650) ||
          (modalType === "add_definition" && 650) ||
          (modalType === "invite_person" && 440) ||
          (modalType === "sign_now" && 440)
        }
      >
        {modalType === "rules_regulation" && (
          <RulesRegulationModal
            onVideoCall={onVideoCall}
            handleCancel={handleCancel}
            // setUserId={setUserId}
          />
        )}
        {modalType === "send_invitation" && (
          <NegotiationInvitation handleCancel={handleCancel} />
        )}
        {visible &&
          (modalType === "license_edit" || modalType === "add_definition") && (
            <FormalLicenseEdit
              handleCancel={handleCancel}
              modalType={modalType}
            />
          )}
        {modalType === "file_history" && (
          <Filehistory getLogsListRes={getLogsListRes} />
        )}
        {modalType === "invite_person" && (
          <InvitePersonsInRoom handleCancel={handleCancel} />
        )}
        {modalType === "sign_now" && (
          <SignNowModal handleCancel={handleCancel} />
        )}
      </Modal>
      <DeleteConfirmation
        visible={signSaleConfirm}
        handleModalCancel={closeDelConfirmModal}
        onDelete={onConfirmSignSale}
        modalType={modalType}
      />
    </Fragment>
  );
};

export default SelectionProposalClosing;
