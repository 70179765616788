import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppComponent from '../Components/App/App.js';

import {
  getSession
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    sessionRes: state.userReducer.sessionRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSession: bindActionCreators(getSession, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);