import React from 'react';
import InventoryFilterBar from "./InventoryFiltersBar"

const MyMarketInventory = ({
  onSearch,
}) => {

  return (
    <div>
      <InventoryFilterBar
        onSearch={onSearch}
      />
    </div>
  )
}

export default MyMarketInventory;