import React from "react";
import { Row, Col, Spin } from "antd";
import SpecialCatalogue from "./SpecialCatalogue";
import AssetCatalogue from "./AssetCatalogue";
import HeaderFilter from "./HeaderFilter";
import { singular, getMissingNo, mergeDups } from "../../../../../utils/helper";

const SpecialCataloguesModal = ({
  items,
  loading,
  highlights,
  setSearchResult,
  updateHighlight,
  index,
}) => {
  const addToHighlights = (category, key, id) => {
    let maxLength = 0;
    Object.values(highlights).forEach(
      (highlight) => (maxLength += highlight.length)
    );
    let orders = mergeDups(
      Object.values(highlights).map((highlight) =>
        highlight.map((h) => h.order)
      )
    );
    let next = getMissingNo(orders, orders.length);

    if (maxLength < 3) {
      const tempHighlights = [...highlights[category]];

      tempHighlights.push({ [key]: id, order: next });

      updateHighlight({
        ...highlights,
        [category]: [...tempHighlights],
      });
    }
  };

  const removeFromHighlights = (category, id) => {
    const tempHighlights = highlights[category].filter(
      (highlight) => highlight[singular(category)] !== id
    );

    updateHighlight({
      ...highlights,
      [category]: [...tempHighlights],
    });
  };

  const notExists = (category, id) => {
    const ids = highlights[category].length
      ? highlights[category].map((highlight) => highlight[singular(category)])
      : [];

    return !ids.includes(id);
  };

  return (
    <Row gutter={16}>
      <Col className="mb20" span={24}>
        <HeaderFilter setSearchResult={setSearchResult} index={index} />
        <Spin spinning={loading}>
          {items.type === "special_catalogues" ? (
            <SpecialCatalogue
              items={items.data}
              notExists={notExists}
              addToHighlights={addToHighlights}
              removeFromHighlights={removeFromHighlights}
            />
          ) : (
            <AssetCatalogue
              items={items.data}
              notExists={notExists}
              addToHighlights={addToHighlights}
              removeFromHighlights={removeFromHighlights}
            />
          )}
        </Spin>
      </Col>
    </Row>
  );
};

export default SpecialCataloguesModal;
