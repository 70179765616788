import React from "react";
import { Tag } from "antd";
import { convertoCapitalize } from "./../../../Common/reUseFunctions";

const SpecificUserRoles = ({ selectedData }) => {
  return (
    <div className="specific-roles">
      <h3>Roles</h3>
      {selectedData &&
        selectedData.roles &&
        selectedData.roles.length > 0 &&
        selectedData.roles.map((data, index) => (
          <Tag key={index} className="text-uppercase">
            {convertoCapitalize(data.name)}
          </Tag>
        ))}
    </div>
  );
};

export default SpecificUserRoles;
