import React from "react";
import { Row, Col } from "antd";
import OrganizationLogo from "../../../../statics/images/organization.jpg";

const OrgazinerDetail = () => {
  return (
    <div className="org-detail">
      <Row gutter={16}>
        <Col span={10}>
          <img src={OrganizationLogo} alt="Organization" />
        </Col>
        <Col span={14} className="d-flex align-item-center">
          <div>
            <h2>ARTE G.E.I.E</h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
              sanctus est Lorem ipsum dolor sit amet.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrgazinerDetail;
