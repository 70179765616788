import React, { Fragment } from "react";
import { Tag, Button } from "antd";
import { gearIcon } from "./../../../../Common/Icons";
import { toDateString } from "./../../../../Common/reUseFunctions";

const SelectionAssetListView = ({
  licenseDefinitions,
  showModalPe,
  modalType,
  getAllTradingTaskRes,
}) => {
  return (
    <Fragment>
      {modalType === "evaluate-detail" && (
        <div className="set-permission-btn">
          <Button
            type="link"
            onClick={() => showModalPe("permission")}
            className="back-button"
          >
            {gearIcon} <u>Set Permissoins</u>
          </Button>
        </div>
      )}
      {
        licenseDefinitions && licenseDefinitions.map((items, index) => {
            return (
              <div
                key={index}
                className={
                  modalType === "evaluate-detail"
                    ? "slection-asset-view"
                    : "slection-asset-view mt72"
                }
              >
                <h3>FORMAL LICENSE DEFINITION</h3>

                <div key={index} className="formal-license-view">
                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>TERRITORIES</span>
                    </div>
                    <div className="formal-license-data">
                      {items &&
                      items.territories &&
                      items.territories.length > 0 ? (
                        items.territories.map((data, index) => {
                          return <Tag key={index}>{data}</Tag>;
                        })
                      ) : (
                        <Tag key={index}>WorldWide</Tag>
                      )}
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>EXCLUDING</span>
                    </div>
                    <div className="formal-license-data">
                      {items.excluding.map((data, index) => {
                        return <Tag key={index}>{data}</Tag>;
                      })}
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>CHANNELS</span>
                    </div>
                    <div className="formal-license-data">
                      {items.channels.map((data, index) => {
                        return <Tag key={index}>{data}</Tag>;
                      })}
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>RESALE ALLOWED</span>
                    </div>
                    <div className="formal-license-data">
                      <span>
                        {(items.resale_allowed === true && "Yes") ||
                          (items.resale_allowed === false && "No")}
                      </span>
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>EXCLUSIVE LICENSE</span>
                    </div>
                    <div className="formal-license-data">
                      <span>
                        {(items.exclusive_license === true && "Yes") ||
                          (items.exclusive_license === false && "No")}
                      </span>
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>START DATE</span>
                    </div>
                    <div className="formal-license-data">
                      <span>{toDateString(items.start_date)}</span>
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>END DATE</span>
                    </div>
                    <div className="formal-license-data">
                      <span>{toDateString(items.end_date)}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
    </Fragment>
  );
};

export default SelectionAssetListView;
