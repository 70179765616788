import React from 'react';

import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./InventorySearchDropdown";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onFinish,
  showTableEditor
}) => {

  return (
    <div className="inventory-bottom-filter">
      <InventorySwitchSec />
      <InventorySearchDropDown
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
      />
    </div>
  )
}

export default InventorySearchDropdown;