import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const timezonesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_TIMEZONES_SUCCESS:
      return { ...state, timezonesList: action.payload };
    case types.GET_TIMEZONES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");

      return { ...state, timezonesList: action.payload };

    default:
      return state;
  }
};
