import React from "react";
import { Button, Select } from 'antd';

const { Option } = Select;

const RulesRegulationModal = ({
  onVideoCall,
  setUserId
}) => {

  const onChangeUser = (value) => {
    console.log(`user value ${value}`);
    setUserId(value)
  }

  return (
    <div className="call-r-regulation">
      <h2>Rules & Regulations</h2>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
      <ul>
        <li>1914 translation by H. Rackham</li>
        <li>Section 1.10.33 of "de Finibus Bonorum et Malorum</li>
        <li>1914 translation by H.</li>
        <li> written by Cicero in 45 BC</li>
      </ul>
      <p>Please contact us if you have any questions</p>
      <Select
        placeholder="Please select user"
        style={{ width: 220 }}
        onChange={onChangeUser}
      >
        <Option value="1">User 1</Option>
        <Option value="2">User 2</Option>
      </Select>
      <Button onClick={() => onVideoCall("video_call")} type="primary">I UNDERSTAND & CONTINUE</Button>
    </div>
  );
};

export default RulesRegulationModal;
