import React from 'react';
import { Form, Input, Button, Space, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const InventorySelectFilter = ({ onFinish }) => {

  return (
    <div className="inventory-select-filter">
      <Form name="inventory_filter_form" onFinish={onFinish} autoComplete="off">
        <Form.List name="inventory_search_filter">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map(field => (
                  <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="start">

                    <Form.Item
                      {...field}
                      name={[field.name, 'asset_select_filter']}
                      fieldKey={[field.fieldKey, 'asset_select_filter']}
                      rules={[{ required: true, message: 'Select First Filter' }]}
                      className="select-width"
                    >
                      <Select placeholder="Please select...">
                        <Option value="Production Year">Production Year</Option>
                        <Option value="Film Genre">Film Genre</Option>
                        <Option value="Music Genre">Music Genre</Option>
                        <Option value="Video Resolution">Video Resolution</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'asset_type_text']}
                      fieldKey={[field.fieldKey, 'asset_type_text']}
                      rules={[{ required: true, message: 'Text Missing' }]}
                      className="input-width"
                    >
                      <Input placeholder="Type..." />
                    </Form.Item>

                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    onClick={() => {
                      add();
                    }}
                    block
                    className="darkgray-btn"
                  >
                    <PlusOutlined /> Add New Filter
                </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            SEARCH
        </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default InventorySelectFilter;