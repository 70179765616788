import React from 'react';

const TopTitleSection = ({catalogue_name}) => {
  return (
    <div className="my-market-inventory">
      <div><h3>{catalogue_name} Catalogue</h3></div>
    </div>
  )
}

export default TopTitleSection;