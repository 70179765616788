import { apiNew } from '../../service/newApi';
import * as types from '../Constants';


export const addSignSale = (id, data) => {
  const options = {
    url: `assets/publish/${id}/sale`
  };

  options.types = [
    types.ADD_SIGN_SALE_SUCCESS,
    types.ADD_SIGN_SALE_FAILURE
  ];

  return apiNew.post(options, data);
}

export const getSign = (id) => {
  const options = {
    url: `trading-tasks/${id}/sign`
  };

  options.types = [
    types.GET_SIGN_SUCCESS,
    types.GET_SIGN_FAILURE
  ];

  return apiNew.get(options);
}

export const getSignSale = (tradingtaskid, assetId) => {
  const options = {
    url: `trading-tasks/${tradingtaskid}/license/${assetId}`
  };

  options.types = [
    types.GET_SIGN_SALE_SUCCESS,
    types.GET_SIGN_SALE_FAILURE
  ];

  return apiNew.get(options);
}

export const getAssetIdSale = (assetId) => {
  const options = {
    url: `trading-tasks/get-asset-id-for-sale/${assetId}`
  };

  options.types = [
    types.GET_ASSET_ID_SALE_SUCCESS,
    types.GET_ASSET_ID_SALE_FAILURE
  ];

  return apiNew.get(options);
}

