import React from "react";
import { Row, Col } from "antd";

import ProfileLogo from "../../../../statics/images/profile-logo-1.png";
import MemberProfileCoverImage from "../../../../statics/images/member-profile.png";

const MemberProfile = () => {
  return (
    <Row gutter={24}>
      <Col className="mb40" span={12}>
        <div className="member-profile-text">
          <img src={ProfileLogo} alt="Catalogues" />
          <h2>Unitel Gmbh & Co.KG</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. Lorem Ipsum is simply dummy text of
            the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the
            leap into electronic typesetting.
          </p>
          <div className="total-assets">
            <h3>3609</h3>
            <p>Total assets</p>
          </div>
        </div>
      </Col>
      <Col className="mb40" span={12}>
        <div className="member-profile-img">
          <img src={MemberProfileCoverImage} alt="Catalogues" />
        </div>
      </Col>
    </Row>
  );
};

export default MemberProfile;
