import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import {
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Popover,
  Badge,
  Tooltip,
  Radio,
  Modal,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import {
  notificationIcon,
  helpIcon,
  timeClock,
  videoCamera,
  checkSquare,
  closeCircle,
  editPenIcon,
} from "../Common/Icons";

import Notifications from "./Notifications";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import ProgressBar from "./ProgressBar";

const data = [
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: (
      <Avatar src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg" />
    ),
  },
  {
    title: (
      <p>
        <strong>Bidding</strong> on the trading task{" "}
        <strong>The Lion King</strong> has begun.
      </p>
    ),
    time: "3 hours ago",
    avatar: timeClock,
  },
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: (
      <Avatar src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg" />
    ),
  },
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: videoCamera,
  },
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: timeClock,
  },
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: checkSquare,
  },
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: closeCircle,
  },
  {
    title: (
      <p>
        Your scheduled video with <strong>Toonz Media</strong> is due in 10
        minutes
      </p>
    ),
    time: "3 hours ago",
    avatar: checkSquare,
  },
];

const { Header } = Layout;

const HeaderSection = ({
  toggleSidebar,
  collapsed,
  history,

  onLogout,
  logoutRes,

  mainUserRes,
  onGetMainUserDetail,

  specificUserImage,
  onGetSpecificUserImage,

  onChangeUserPassword,

  updateUserprofileAndUsername,
  onUpdateProfileAndUsername,

  updateUserProfileRes,
  onUpdateUserPicture,

  onGetNotifications,
  getNotificationsRes,
  onPutNotifications,
  putNotificationsRes,
  onGetOrganization,
}) => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState("");
  const [userProfile, setUserProfile] = useState();
  const [notificationFilter, setNotificationFilter] = useState("All");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    gettingProfilePicture();
    onGetNotifications();
    onGetOrganization();
  }, []);

  const onLogoutUser = () => {
    localStorage.clear();
    window.location.reload();
  };

  const onChangeInventory = (value) => {
    setNotificationFilter(value);
    console.log("onChangeInventory", value);
  };

  let filteredNotRes = getNotificationsRes &&  getNotificationsRes.sort(function compare(a, b) {
    let dateA = new Date(a.created_at);
    let dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  const getUnreadNotifications = (filteredNotRes) => {
    let _unreadNotifications = [];
    filteredNotRes && filteredNotRes.map((data, index) => {
      if(data.seen === false)
      {
        _unreadNotifications.push(data)
      }
      else
      {
        //donothing
      }
    } )
    return _unreadNotifications
  }

  const showModal = (e) => {
    setVisible(true);
    setModalType(e);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const gettingProfilePicture = () => {
    onGetMainUserDetail().then((res) => {
      if (res && res.payload && res.payload.photo) {
        onGetSpecificUserImage(res.payload.photo)
          .then((res) => {
            const blob = new Blob([res.payload], {
              type: "application/octet-stream",
            });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64String = reader.result;

              // Simply Print the Base64 Encoded String,
              // without additional data: Attributes.
              setUserProfile(base64String);
            };
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };

  const menu = (
    <Menu className="profile-menu">
      <Menu.Item key="0">
        <p onClick={() => showModal("edit_profile")}>
          {editPenIcon} Edit Profile
        </p>
      </Menu.Item>
      <Menu.Item key="1">
        <p onClick={onLogoutUser}>
          <PoweroffOutlined key="setting" /> Logout{" "}
        </p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Header style={{ position: "fixed", zIndex: 100, width: "100%" }}>
        {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggleSidebar,
      })} */}
        <div className="logo">
          <div className="logo-text-style">
             <img src="/images/logo.svg" alt="Content.Agent" />
            {/*<h1>digital lunapark</h1>*/}
            {/*<h4>protect & trade digital content rights</h4>*/}
          </div>
          {/* <img src={Logo} alt="MoviezCrop" /> */}
        </div>
        {/* <ProgressBar /> */}

        <div className="header-style">
          {/* <div className="header-search">
          <AssetSearch history={history} />
        </div> */}
          <div className="top-bar-actions">
            <Popover
              className="top-bar-notification"
              placement="bottomRight"
              title={
                <div className="notification-header">
                  <h2 className="mt5">Notifications</h2>
                  <div className="radio-btn-style">
                    <Radio.Group
                      onChange={(e) => onChangeInventory(e.target.value)}
                      defaultValue="All"
                      buttonStyle="solid"
                    >
                      <Radio.Button value="All">All</Radio.Button>
                      <Radio.Button value="Unread">Unread</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              }
              content={
                <Notifications
                  notificationFilter={notificationFilter}
                  onGetNotifications={onGetNotifications}
                  getNotificationsRes={getNotificationsRes}
                  onPutNotifications={onPutNotifications}
                  putNotificationsRes={putNotificationsRes}
                  data={data}
                />
              }
              trigger="click"
            >
              <span className="mr20 notification-style">
                <Badge
                  count={getUnreadNotifications(filteredNotRes).length}
                >
                  <Avatar shape="square" icon={notificationIcon} />
                </Badge>
              </span>
            </Popover>
            <div className="header-help">
              <Link to="/help">
                <Tooltip title="Help...">{helpIcon}</Tooltip>
              </Link>
            </div>
            <div className="header-profile">
              <Dropdown overlay={menu} trigger={["click"]}>
                <div className="login-user-info">
                  <div className="text">
                    <span className="name">
                      {mainUserRes && mainUserRes.first_name}{" "}
                      {mainUserRes && mainUserRes.last_name}
                    </span>
                    <span className="time">
                      {mainUserRes &&
                        mainUserRes.last_login &&
                        `Last Login: ${moment(mainUserRes.last_login).format(
                          "YYYY/MM/DD, kk:mm"
                        )}`}
                    </span>
                    {/* <span className="time">{mainUserRes && mainUserRes.last_login && `Last Login: ${mainUserRes.last_login}`}</span> */}
                  </div>
                  <Spin spinning={loading} size="small">
                    <Avatar src={userProfile} icon={<UserOutlined />} />
                  </Spin>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </Header>

      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={490}
        >
          {modalType === "edit_profile" && visible && (
            <EditProfile
              showModal={showModal}
              onUpdateProfileAndUsername={onUpdateProfileAndUsername}
              specificUserImage={specificUserImage}
              onGetSpecificUserImage={onGetSpecificUserImage}
              mainUserRes={mainUserRes}
              onGetMainUserDetail={onGetMainUserDetail}
              onUpdateUserPicture={onUpdateUserPicture}
              setUserProfile={setUserProfile}
              userProfile={userProfile}
            />
          )}
          {modalType === "change_password" && (
            <ChangePassword
              handleCancel={handleCancel}
              onChangeUserPassword={onChangeUserPassword}
              onLogout={onLogout}
              history={history}
            />
          )}
        </Modal>
      )}
    </Fragment>
  );
};

export default HeaderSection;
