import React from 'react';
import { Input } from 'antd'
import { searchIcon } from '../../../../../../../Common/Icons';
import GearButton from '../../../../../../../Common/GearButton';

const InventorySearchDropdown = ({
  onSearch,
}) => {
  const showTableEditor = (e) => {
    console.log(e)
  }

  return (
    <div className="d-flex">
      <div className="inventory-search-bar">
        <Input placeholder="Search" prefix={searchIcon} onChange={onSearch} />
      </div>
      <div className="layout-btn">
        <GearButton
          showTableEditor={showTableEditor}
        />
      </div>
    </div>
  )
}

export default InventorySearchDropdown;