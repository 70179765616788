import React from 'react';
import {   Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const InternalIdentification = ({
  requiredField,
  showModal
}) => {
  return (
    <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name"><h3 className=""><span>Internal Identification</span></h3></div>
          <div className="map-to-sec">
            <span>Map to:</span>
            <div className="map-select-colmn" onClick={()=>showModal("select-column") }>
              Select Column 
              <DownOutlined />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default InternalIdentification;