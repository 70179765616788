import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const getSpecialCatalogues = (skip = 0, limit = 10000, starts_with = '') => {
  const options = {
    url: `special-catalogues?skip=${skip}&limit=${limit}&name=${starts_with}&order=asc`
  };

  options.types = [
    types.GET_SPECIAL_CATALOGUES_SUCCESS,
    types.GET_SPECIAL_CATALOGUES_FAILURE
  ];

  return apiNew.get(options);
}

export const getSpecialCatalogue = (id) => {
  const options = {
    url: `special-catalogues/${id}`
  };

  options.types = [
    types.GET_SPECIAL_CATALOGUE_SUCCESS,
    types.GET_SPECIAL_CATALOGUE_FAILURE
  ];

  return apiNew.get(options);
}

export const createSpecialCatalogue = (data) => {
    const options = {
      url: `special-catalogues`,
    };
  
    options.types = [
      types.CREATE_SPECIAL_CATALOGUE_SUCCESS,
      types.CREATE_SPECIAL_CATALOGUE_FAILURE
    ];
  
    return apiNew.post(options, data);
  }

export const updateSpecialCatalogue = (id, data) => {
  const options = {
    url: `special-catalogues/${id}`
  };

  options.types = [
    types.UPDATE_SPECIAL_CATALOGUE_SUCCESS,
    types.UPDATE_SPECIAL_CATALOGUE_FAILURE
  ];

  return apiNew.put(options, data);
}

export const deleteSpecialCatalogue = (id) => {
  const options = {
    url: `special-catalogues/${id}`
  };

  options.types = [
    types.DELETE_SPECIAL_CATALOGUE_SUCCESS,
    types.DELETE_SPECIAL_CATALOGUE_FAILURE
  ];

  return apiNew.delete(options);
}

export const addAssetToSpecialCatalogue = (catalogue_id, item_id) => {
  const options = {
    url: `special-catalogues/${catalogue_id}/items`
  };

  options.types = [
    types.ADD_ASSET_TO_SPECIAL_CATALOGUE_SUCCESS,
    types.ADD_ASSET_TO_SPECIAL_CATALOGUE_FAILURE
  ];

  return apiNew.post(options, {id: item_id});
}

export const deleteAssetFromSpecialCatalogue = (catalogue_id, item_id) => {
  const options = {
    url: `special-catalogues/${catalogue_id}/items/${item_id}`
  };

  options.types = [
    types.DELETE_ASSET_FROM_SPECIAL_CATALOGUE_SUCCESS,
    types.DELETE_ASSET_FROM_SPECIAL_CATALOGUE_FAILURE
  ];

  return apiNew.delete(options);
}