import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const notificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS:
      return { ...state, getNotificationsRes: action.payload };
    case types.GET_NOTIFICATIONS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, getNotificationsRes: action.payload };

    case types.PUT_NOTIFICATIONS_SUCCESS:
      return { ...state, putNotificationsRes: action.payload };
    case types.PUT_NOTIFICATIONS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, putNotificationsRes: action.payload };

    default:
      return state;
  }
};
