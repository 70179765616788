import React from 'react';
import { Button, Form, Select, DatePicker } from 'antd';
import moment from 'moment';
import SavedPresetFormFilter from './SavedPresetFormFilterModal';

const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const FormalLicenseEdit = ({ modelType, handleCancel, showModal }) => {

  const onChangeTerritories = (value) => {
    console.log(`selected ${value}`);
  }

  const onChangeExcluding = (value) => {
    console.log(`selected ${value}`);
  }

  const onChangeChannels = (value) => {
    console.log(`selected ${value}`);
  }

  const onChangeResale = (value) => {
    console.log(`selected ${value}`);
  }

  const onChangeExclusive = (value) => {
    console.log(`selected ${value}`);
  }

  const onFinish = values => {
    console.log('Received values of form: ', values);
  };

  return (
    <div className="license-edit-model">
      <h3>FORMAL LICENSE DEFINITION</h3>
      <SavedPresetFormFilter handleCancel={handleCancel} showModal={showModal} />
      <Form
        name="validate_other"
        onFinish={onFinish}
      >
        <div className="slection-asset-view">
          <div className="formal-license-view">
            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>TERRITORIES</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="territories"
                  rules={[{ required: true, message: 'Please choose the territories' }]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    defaultValue={['Worldwide']}
                    onChange={onChangeTerritories}
                    optionLabelProp="label"
                    placeholder="Select Territories"
                  >
                    <Option value="Worldwide" label="Worldwide">
                      <div className="demo-option-label-item">
                        Worldwide
                      </div>
                    </Option>
                    <Option value="american" label="American Samoa">
                      <div className="demo-option-label-item">
                        American Samoa
                      </div>
                    </Option>
                    <Option value="unitedStates" label="United States">
                      <div className="demo-option-label-item">
                        United States
                      </div>
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>EXCLUDING</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="excluding"
                  rules={[{ required: true, message: 'Please choose the excluding' }]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    defaultValue={['Australia', 'Bangladesh']}
                    onChange={onChangeExcluding}
                    optionLabelProp="label"
                    placeholder="Select Excluding"
                  >
                    <Option value="Australia" label="Australia">
                      <div className="demo-option-label-item">
                        Australia
                      </div>
                    </Option>
                    <Option value="Bangladesh" label="Bangladesh">
                      <div className="demo-option-label-item">
                        Bangladesh
                      </div>
                    </Option>
                    <Option value="unitedStates" label="United States">
                      <div className="demo-option-label-item">
                        United States
                      </div>
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>CHANNELS</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="channels"
                  rules={[{ required: true, message: 'Please choose the channels' }]}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    defaultValue={['Audio Only', 'DVD', 'Pay TV']}
                    onChange={onChangeChannels}
                    optionLabelProp="label"
                    placeholder="Select Channels"
                  >
                    <Option value="Audio Only" label="Audio Only">
                      <div className="demo-option-label-item">
                        Audio Only
                      </div>
                    </Option>
                    <Option value="DVD" label="DVD">
                      <div className="demo-option-label-item">
                        DVD
                      </div>
                    </Option>
                    <Option value="Pay TV" label="Pay TV">
                      <div className="demo-option-label-item">
                        Pay TV
                      </div>
                    </Option>
                    <Option value="Play Tv" label="Play Tv">
                      <div className="demo-option-label-item">
                        Play Tv
                      </div>
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>RESALE ALLOWED</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="re_sales"
                  rules={[{ required: true, message: 'Please choose the resales' }]}
                >
                  <Select defaultValue="Yes" onChange={onChangeResale}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>EXCLUSIVE LICENSE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="exclusive_license"
                  rules={[{ required: true, message: 'Please choose the exclusive license' }]}
                >
                  <Select defaultValue="No" onChange={onChangeExclusive}>
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>START DATE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="start_date"
                  rules={[{ required: true, message: 'Please choose the start date' }]}
                >
                  <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>END DATE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="end_date"
                  rules={[{ required: true, message: 'Please choose the end date' }]}
                >
                  <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="formal-license-btn">
          <Button htmlType="submit" type="primary">{modelType === "add" ? "ADD LICENSE DEFINITION" : "SAVE CHANGES"}</Button>
        </div>
      </Form>
    </div>
  )
}

export default FormalLicenseEdit;