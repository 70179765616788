import React from "react";
import { Button, Modal, Form, Input } from "antd";

const SaveMappingPreset = ({
  saveLicenesPreset,
   handleCancel,
  visible,
}) => {
  
  return (
    <Modal
      footer={false}
      className="modal-style"
      title={false}
      visible={visible}
      onCancel={handleCancel}
      width={450}
    >
      <div className="license-edit-model">
        <h3>
          {
        (saveLicenesPreset === "saveLicenesPreset" ? "Save Licenes Preset" : "Save Columns Mapping Preset")
         }
         </h3>
        <Form
          name="save_search"
        // onFinish={onFinish}
        >
          <div className="slection-asset-view mb20-imp">
            <div className="formal-license-view">

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span> PRESET NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter preset name!' }]}
                    required
                  >
                    <Input placeholder="August Media 2020" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="mapping-tip">
          <p><strong>Tip:</strong> Save the column mapping preset with vendor name for easlier future import references.</p>
          </div>
          <div className="formal-license-btn">
            <Button htmlType="submit" onClick={handleCancel} type="primary">
              SAVE PRESET
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default SaveMappingPreset;
