import React from "react";
import { Button } from "antd";
import { pdfFileIcon } from "./../../Common/Icons";
import { saveAs } from "file-saver";
import { HistoryOutlined } from "@ant-design/icons";
import axios from "axios";


const LicenceAgreementSec = ({ getAllTradingTaskRes, showModal }) => {

   const onDownloadDoc = (value) => {
       axios({
           url: `${process.env.REACT_APP_API_BASE_URL}trading-tasks/download/${getAllTradingTaskRes &&
           getAllTradingTaskRes.data &&
           getAllTradingTaskRes.data.agreement_file}`,
           method: "GET",
           responseType: "blob" // important
       }).then(res => {
               let blob = new Blob([res.data], { type: "application/pdf" })
                saveAs(blob, "");
           })
    };
  return (
    <div className="licence-agreement-sec l-a-section">
      <h2>LICENSE AGREEMENT</h2>
      <div className="l-a-file">
      <button
        onClick={onDownloadDoc}
        className="agreement-url"
        style={{ border: "none", background: "none", textDecoration: "underline", cursor: "pointer" }}
      >
        <span className="mr10">{pdfFileIcon}</span>
        <span>
          {getAllTradingTaskRes &&
            getAllTradingTaskRes.data &&
            getAllTradingTaskRes.data.agreement_file &&
            getAllTradingTaskRes.data.agreement_file.substring(0, 50) + "..."}
        </span>
      </button>
        {/* <a
            onClick={onDownloadDoc}
          className="agreement-url"
        >
          <span className="mr10">{pdfFileIcon}</span>
          <span>
            {getAllTradingTaskRes &&
              getAllTradingTaskRes.data &&
              getAllTradingTaskRes.data.agreement_file &&
              getAllTradingTaskRes.data.agreement_file.substring(0, 50) + "..."}
          </span>
        </a> */}
      </div>
      <p>
        The Agreement will only be shared with your contractor and is not stored
        on any platform server.
      </p>
      <Button onClick={() => showModal("file_history")} type="link">
        <HistoryOutlined /> Negotiation Log
      </Button>
    </div>
  );
};

export default LicenceAgreementSec;
