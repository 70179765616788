import React, { useState } from "react";
import { Form, Button, Row, Col, Input, Select, Radio } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { countryList } from "./../../../../../../statics/data/countryList";

const { Option } = Select;

const OPTIONS = [
  "Stage Director",
  "Director of Photography",
  "Executive Producer",
  "Production Manager",
  "Author",
  "Camera",
  "Light Design",
  "Editor",
  "Sound Engineering",
  "Cast",
  "Dance Company",
  "Dancer",
  "Orchestra",
  "Band",
  "Chorus",
  "Chorus Master",
  "Musician ",
  "Singer",
  "Composer",
  "Conductor",
  "other",
];

const Index = ({ helperText, requiredField }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedContry, setSelectedContry] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const handleChangeCountry = (selectedContry) => {
    setSelectedContry(selectedContry);
  };

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  return (
    <Row gutter={16} className="mb5">
      <Col className="pl0_important" span={24}>
        <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Contributors</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>
              Name all contributors that are featured (visible or audible) in
              this production.
            </p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="contributors">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <div>
                      <Row gutter={16} className="mb20">
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Name</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "value"]}
                                fieldKey={[field.fieldKey, "value"]}
                                className="full-width mr10 mb20"
                              >
                                <Input placeholder="Name" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          className={
                            requiredField === true
                              ? "metadata-tab-body_hide"
                              : "mb5"
                          }
                        >
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Country</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "country"]}
                                fieldKey={[field.fieldKey, "country"]}
                                className="full-width mr10 mb20"
                                rules={[
                                  {
                                    pattern: new RegExp("[A-Z]{2}"),
                                    message:
                                      "Please enter the correct country!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Country"
                                  value={selectedContry}
                                  onChange={handleChangeCountry}
                                  style={{ width: "100%" }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {countryList.map((item, index) => (
                                    <Option key={index} value={item.code}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Function</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "function"]}
                                fieldKey={[field.fieldKey, "function"]}
                              >
                                <Select
                                  placeholder="Select function..."
                                  value={selectedItems}
                                  onChange={handleChange}
                                  style={{ width: "100%" }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          className={
                            requiredField === true
                              ? "metadata-tab-body_hide"
                              : "mb5"
                          }
                        >
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Agent</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.List name={[field.fieldKey, "agent"]}>
                                {(fields, { add, remove }) => {
                                  return (
                                    <div>
                                      {fields.map((field) => (
                                        <Row gutter={16} className="mb0">
                                          <Col span={24}>
                                            <div className="d-flex align-item-center">
                                              <Form.Item
                                                {...field}
                                                name={[field.name, "value"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "value",
                                                ]}
                                                className="full-width mr10 mb20"
                                              >
                                                <Input placeholder="Name" />
                                              </Form.Item>

                                              <Form.Item
                                                {...field}
                                                name={[field.name, "seat"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "seat",
                                                ]}
                                                rules={[
                                                  {
                                                    pattern: new RegExp(
                                                      "[A-Z]{2}"
                                                    ),
                                                    message:
                                                      "Please enter the correct seat!",
                                                  },
                                                ]}
                                                className="full-width mr20 mb0"
                                              >
                                                <Select
                                                  placeholder="Seat"
                                                  value={selectedContry}
                                                  onChange={handleChangeCountry}
                                                  style={{ width: "100%" }}
                                                  showSearch
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                  getPopupContainer={(
                                                    trigger
                                                  ) => trigger.parentElement}
                                                >
                                                  {countryList.map(
                                                    (item, index) => (
                                                      <Option
                                                        key={index}
                                                        value={item.code}
                                                      >
                                                        {item.code}
                                                      </Option>
                                                    )
                                                  )}
                                                </Select>
                                              </Form.Item>

                                              <Form.Item
                                                {...field}
                                                name={[field.name, "contact"]}
                                                fieldKey={[
                                                  field.fieldKey,
                                                  "contact",
                                                ]}
                                                className="full-width mr20 mb0"
                                              >
                                                <Input placeholder="Contact" />
                                              </Form.Item>

                                              <MinusCircleOutlined
                                                className="ml10 remove-circle"
                                                onClick={() => {
                                                  remove(field.name);
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      ))}

                                      {fields.length > 0 ? null : (
                                        <Form.Item>
                                          <Button
                                            type="link"
                                            onClick={() => {
                                              add();
                                            }}
                                            style={{ width: "100%" }}
                                          >
                                            <PlusOutlined /> Add Agent
                                          </Button>
                                        </Form.Item>
                                      )}
                                    </div>
                                  );
                                }}
                              </Form.List>
                            </div>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          className={
                            requiredField === true
                              ? "metadata-tab-body_hide"
                              : "mb5"
                          }
                        >
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Featured</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "featured"]}
                              >
                                <Radio.Group>
                                  <Radio value="yes">Yes</Radio>
                                  <Radio value="no">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Contributors
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;
