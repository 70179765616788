import React from "react";
import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor,
  showModal,
  onChangeSelectionType,
  sessionRes,
  setStatusFilter,
  statusFilter,
  setBlockedFilter,
  blockedFilter,
  setUnBlockedFilter,
  unBlockedFilter,
  setPendingFilter,
  pendingFilter,
  setSelectionTypeChange,
  onSearch,
  mainUserRes,
}) => {
  return (
    <div>
      <TopTitleSection
        showModal={showModal}
        sessionRes={sessionRes}
        mainUserRes={mainUserRes}
      />
      <InventoryFilters
        showTableEditor={showTableEditor}
        onChangeSelectionType={onChangeSelectionType}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setBlockedFilter={setBlockedFilter}
        blockedFilter={blockedFilter}
        setUnBlockedFilter={setUnBlockedFilter}
        unBlockedFilter={unBlockedFilter}
        setPendingFilter={setPendingFilter}
        pendingFilter={pendingFilter}
        setSelectionTypeChange={setSelectionTypeChange}
        onSearch={onSearch}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default HeaderFilter;
