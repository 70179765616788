import React from 'react';

import InventoryFilterBar from "./InventoryFiltersBar"

const MyMarketInventory = ({
  onChangeLayout,
  changeLayout,
  showTableEditor,
  onChangeSelectionType,
  setStatusFilter,
  statusFilter,
  setBlockedFilter,
  blockedFilter,
  setUnBlockedFilter,
  unBlockedFilter,
  setPendingFilter,
  pendingFilter,
  setSelectionTypeChange,
  onSearch,
}) => {

  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onChangeSelectionType={onChangeSelectionType}
        showTableEditor={showTableEditor}

        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setBlockedFilter={setBlockedFilter}
        blockedFilter={blockedFilter}
        setUnBlockedFilter={setUnBlockedFilter}
        unBlockedFilter={unBlockedFilter}
        setPendingFilter={setPendingFilter}
        pendingFilter={pendingFilter}

        setSelectionTypeChange={setSelectionTypeChange}

        onSearch={onSearch}
      />

    </div>
  )
}

export default MyMarketInventory;