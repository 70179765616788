import * as types from "../Constants";
import { openNotificationWithIcon, addAccessToken, addSpecificUserId } from '../../Components/Common/reUseFunctions';

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      addAccessToken(action && action.payload && action.payload.token);
      addSpecificUserId(action && action.payload && action.payload.user && action.payload.user._id);
      return { ...state, signInRes: action.payload };
    case types.LOGIN_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, signInRes: action.payload };
    case types.GET_SESSION_SUCCESS:
      return { ...state, sessionRes: action.payload };
    case types.GET_SESSION_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, sessionRes: action.payload };
    case types.LOGOUT_SUCCESS:
      return { ...state, logoutRes: action.payload };
    case types.LOGOUT_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, logoutRes: action.payload };
    case types.POST_FORGOT_PASSWORD_SUCCESS:
      return { ...state, resetForgotPasswordRes: action.payload };
    case types.POST_FORGOT_PASSWORD_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, resetForgotPasswordRes: action.payload };
    case types.POST_UESR_PICTURE_SUCCESS:
      return { ...state, updateUserProfileRes: action.payload };
    case types.POST_UESR_PICTURE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, updateUserProfileRes: action.payload };

    case types.GET_MAIN_USER_DETAIL_SUCCESS:
      return { ...state, mainUserRes: action.payload };
    case types.GET_MAIN_USER_DETAIL_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, mainUserRes: action.payload };

    case types.POST_SET_USER_PASSWORD_SUCCESS:
      return { ...state, setUserPasswordRes: action.payload };
    case types.POST_SET_USER_PASSWORD_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, setUserPasswordRes: action.payload };
    default:
      return state;
  }
};
