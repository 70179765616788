import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const inventorySearchReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_MARKET_READY_SUCCESS:
      return { ...state, marketList: action.payload }
    case types.GET_MARKET_READY_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, marketList: action.payload }

    case types.GET_INVENTORY_SUCCESS:
      return { ...state, inventoryListRes: action.payload }
    case types.GET_INVENTORY_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, inventoryListRes: action.payload }

    case types.GET_INVENTORY_LIST_SUCCESS:
      return { ...state, inventoryListRes: action.payload }
    case types.GET_INVENTORY_LIST_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, inventoryListRes: action.payload }

    case types.ADD_ASSET_IN_SELECTION_SUCCESS:
      return { ...state, addAssetInSelectionRes: action.payload }
    case types.ADD_ASSET_IN_SELECTION_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, addAssetInSelectionRes: action.payload }

      case types.GET_INVENTORY_DETAIL_SUCCESS:
        return { ...state, getInventorySeachDetailRes: action.payload }
      case types.GET_INVENTORY_DETAIL_FAILURE:
        openNotificationWithIcon(
          'error',
          'Error',
          "Please Try Again"
        );
        return { ...state, getInventorySeachDetailRes: action.payload }

        case types.UPDATE_INVENTORY_HEADER_SUCCESS:
          return { ...state, updateInventoryHeaderRes: action.payload }
        case types.UPDATE_INVENTORY_HEADER_FAILURE:
          openNotificationWithIcon(
            'error',
            'Error',
            "Please Try Again"
          );
          return { ...state, updateInventoryHeaderRes: action.payload }

          case types.GET_INVENTORY_HEADER_SUCCESS:
          return { ...state, getInventoryHeaderRes: action.payload }
        case types.GET_INVENTORY_HEADER_FAILURE:
          openNotificationWithIcon(
            'error',
            'Error',
            "Please Try Again"
          );
          return { ...state, getInventoryHeaderRes: action.payload }
          
    default:
      return state
  }
}