import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

const AssetList = [
  {
    title: "The Lion King",
    duration: "180 mins",
    Production: "Toonz Media Group",
    director: "Adam Jhons"
  },
  {
    
    title: "The Lion King",
    duration: "180 mins",
    Production: "Toonz Media Group",
    director: "Adam Jhons"
  },
  {
    title: "The Lion King",
    duration: "180 mins",
    Production: "Toonz Media Group",
    director: "Adam Jhons"
  },
  {
    title: "The Lion King",
    duration: "180 mins",
    Production: "Toonz Media Group",
    director: "Adam Jhons"
  },
  {
    title: "The Lion King",
    duration: "180 mins",
    Production: "Toonz Media Group",
    director: "Adam Jhons"
  },
  {
    title: "The Lion King",
    duration: "180 mins",
    Production: "Toonz Media Group",
    director: "Adam Jhons"
  }
]

const NumberAssets = ({
  cancelTableEditor,
  showTradingTask
}) => {
  return (
    <Row gutter={16} className="mb5">
      <div className="evaluate-assets-modal">
        <div className="no-asset-box-scroll column-tasks-area">
          {
            (AssetList && AssetList.length > 0) &&
            AssetList.map((item, index) => {
              return (
                <Col key={index} span={24}>
                  <div className="no-asset-box">
                    <h2>{item.title}</h2>
                    <p className="duration">{item.duration}<span>2020</span></p>
                    <p className="m-cata"><span>Production Company:</span> {item.Production}</p>
                    <p className="m-cata mb0"><span>Film Director:</span> {item.director}</p>
                    <div className="asset-detail"><Link to={`/inventory-search/${"5f75a74df764d13030528685"}`}><Button type="link"><u>Details</u></Button></Link></div>
                  </div>
                </Col>
              )
            })
          }
        </div>
        <div className="no-asset-btns">
          <Button onClick={cancelTableEditor} type="default">DECLINE PROPOSAL</Button>
          <Button onClick={() => showTradingTask("evaluate-detail")} type="primary">PROPOSAL DETAILS</Button>
        </div>
      </div>
    </Row>
  )
}

export default NumberAssets;