import React, { Fragment, useState, useEffect } from "react";
import { Modal, message, Button } from "antd";
import SelectionDetialFilter from "./HeaderFilter";
import SelectionAssets from "./SelectionAssets";
import StraightSales from "./StraightSaleModal";
import { getUrlLastId, getAssetArray } from "./../../Common/reUseFunctions";
import { Empty } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const SelectionDetail = ({
  location,
  selection,
  history,
  onDeleteSelectionAssets,
  onAddFormalLicenseDefinition,
  selectionAssetsListRes,
  onGetSelectionAssets,
  selectionDetail,
  onGetSelectionDetail,
  selectionsList,
  getFormalLicenseRes,
  onGetFormalLicenseList,
  onDeleteFormalLicense,
  deleteFormalLicenseRes,
  onUpdateFormalLicense,
  updateFormalLicenseRes,
  onPostTradingTask,
  postTradingTaskRes,
  updateSelection,
  onUpdateSelection,
  onGetAllOrganization,
  getAllOrganizationRes,
  onCreateTradingList,
  mainUserRes,
  organizationRes
}) => {
  const [visible, setVisible] = useState(false);
  const [selectionsID, setSelectionsID] = useState();

  useEffect(() => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);
    setSelectionsID(getUrlPath);
    onGetSelectionDetail(getUrlPath);
  }, [location]);

  const showModal = (modalType, selectedData) => {
    const isLicience = getAssetArray(selectionDetail.assets);
    if (isLicience === true) {
      message.error("Please add the license of assets");
    } else {
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const pushToSelections = () => {
    history.push("/selections");
  };

  return (
    <Fragment>
      <SelectionDetialFilter
        selection={selection}
        showModal={showModal}
        selectionsList={selectionsList}
        location={location}
        selectionDetail={selectionDetail}
      />
      {selectionDetail &&
      selectionDetail.assets &&
      selectionDetail.assets.length > 0 ? (
        <SelectionAssets
          selection={selection}
          selectionAssetsListRes={selectionAssetsListRes}
          onGetSelectionAssets={onGetSelectionAssets}
          location={location}
          onDeleteSelectionAssets={onDeleteSelectionAssets}
          onAddFormalLicenseDefinition={onAddFormalLicenseDefinition}
          onDeleteFormalLicense={onDeleteFormalLicense}
          deleteFormalLicenseRes={deleteFormalLicenseRes}
          selectionsID={selectionsID}
          getFormalLicenseRes={getFormalLicenseRes}
          onGetFormalLicenseList={onGetFormalLicenseList}
          onUpdateFormalLicense={onUpdateFormalLicense}
          updateFormalLicenseRes={updateFormalLicenseRes}
          onPostTradingTask={onPostTradingTask}
          postTradingTaskRes={postTradingTaskRes}
          history={history}
          updateSelection={updateSelection}
          onUpdateSelection={onUpdateSelection}
          mainUserRes={mainUserRes}
          onGetSelectionDetail={onGetSelectionDetail}
        />
      ) : (
        <div>
          <div className="empty-icon">
            <Empty />
          </div>
          <div className="primary-link-btn content-center">
            <Button type="primary" onClick={() => pushToSelections()}>
              <ArrowLeftOutlined /> Back
            </Button>
          </div>
        </div>
      )}
      <Modal
        title={false}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className="modal-style"
        centered
      >
        <StraightSales
          organizationRes={organizationRes}
          handleCancel={handleCancel}
          onGetAllOrganization={onGetAllOrganization}
          getAllOrganizationRes={getAllOrganizationRes}
          onCreateTradingList={onCreateTradingList}
          selectionDetail={selectionDetail}
          mainUserRes={mainUserRes}
        />
      </Modal>
    </Fragment>
  );
};

export default SelectionDetail;
