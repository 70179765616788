import React from "react";
import {
  Table,
  Space,
  Tooltip,
  Button,
  Card,
  Avatar,
  Tag,
  Spin,
  Pagination,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import { getRolesPermissions } from "../../../Common/reUseFunctions";

const { Meta } = Card;

const UserManagementBox = ({
  showModal,
  showTableEditor,
  loading,
  onClickSelection,
  delConfirmModal,
  departmentList,
  onChangePagination,
  currentPage,
  mainUserRes,
}) => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Link to="#">
            <Meta className="meta-avatar-align meta-link-avatar" title={text} />
          </Link>
        );
      },
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table usre-management-action">
          {getRolesPermissions(mainUserRes, "department.edit") === false ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => showModal("edit-user", record)}
              >
                <u>Edit</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
          {getRolesPermissions(mainUserRes, "department.delete") === false ? (
            <Tooltip title="Delete">
              <Button type="link" onClick={() => delConfirmModal(record)}>
                <u>Delete</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      {getRolesPermissions(mainUserRes, "department.list") === false ? (
        <Table
          columns={columns}
          dataSource={departmentList && departmentList.departments}
          className="table-style"
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onClickSelection && onClickSelection(record);
              }, // click row
            };
          }}
        />
      ) : (
        <div className="d-flex justify-center full-width">
          <Empty />
        </div>
      )}

      <div span={24} className="d-flex justify-center mt50">
        <Pagination
          defaultCurrent={currentPage}
          defaultPageSize={
            departmentList &&
            departmentList.pagination &&
            departmentList.pagination.limit
          }
          onChange={onChangePagination}
          total={
            departmentList &&
            departmentList.pagination &&
            departmentList.pagination.total
          }
        />
      </div>
    </Spin>
  );
};

export default UserManagementBox;
