import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const sendMessageReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_FORMAL_LICENSE_SUCCESS:
      return { ...state, postSendMessageRes: action.payload };
    case types.GET_FORMAL_LICENSE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, postSendMessageRes: action.payload };

    case types.GET_MESSAGE_SUCCESS:
      return { ...state, getAllMessageRes: action.payload };
    case types.GET_MESSAGE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, getAllMessageRes: action.payload };

    default:
      return state;
  }
};
