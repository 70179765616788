import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { Link } from "react-router-dom";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const Login = ({ history, onSignIn, signInRes, onGetSession, sessionRes }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const data = {
      email: values.email,
      password: values.password,
    };
    onSignIn(data)
      .then((res) => {
        history.push("/asset-management");
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="auth-page license-edit-model">
      <div className="main-wrap">
        <div className="authentic-heading">
          <h1>Content.Agent</h1>
        </div>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h3 className="form-title">Login</h3>
          <div className="slection-asset-view">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name text-uppercase">
                  <span>Email Address</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: "email",
                        message: "Please input valid email!",
                      },
                    ]}
                  >
                    <Input placeholder="johndoe@sample.com" />
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name text-uppercase">
                  <span>Password</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="*********" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <Link to="/forgot-password">Forgot Password?</Link>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="full-width mt30 p10 h-auto text-uppercase"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
