import React from "react";
import { Table, Space, Tooltip, Button, Empty } from "antd";
import { Link } from "react-router-dom";
import { exportIcon, importSmallIcon } from "../../Common/Icons";
import {
  toCreatingDateString,
  getRolesPermissions,
} from "../../Common/reUseFunctions";

const SelectionList = ({
  showModal,
  mainUserRes,
  selectionsList,
  selectionType,
  delConfirmModal,
  onClickSelection,
}) => {
  const columns = [
    {
      title: "SELECTION TYPE",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return (
          <>
            {text === "purchase" ? (
              <span className="s-p-icon">
                {exportIcon} {text}
              </span>
            ) : (
              <span className="s-p-icon">
                {importSmallIcon} {text}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "SELECTION NAME",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Link
            to={
              record.type === "purchase"
                ? `/selections-purchase/${record._id}`
                : `/selections-sales/${record._id}`
            }
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "NUMBER OF ASSETS",
      dataIndex: "assets",
      key: "assets",
      render: (text, record) => {
        return (
          <>
            {selectionType === "add_to_selection" ? (
              { text }
            ) : text === 0 ? (
              <Link to="#">
                <u>{text} Assets</u>
              </Link>
            ) : (
              <Link to="#" onClick={() => showModal("asset", record)}>
                <u>{text} Assets</u>
              </Link>
            )}
          </>
        );
      },
    },
    {
      title: "CREATED DATE",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <span>{toCreatingDateString(text)}</span>,
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table">
          {getRolesPermissions(mainUserRes, "selection.show") === false ? (
            <Tooltip title="Details">
              <Link
                to={
                  record.type === "purchase"
                    ? `/selections-purchase/${record._id}`
                    : `/selections-sales/${record._id}`
                }
              >
                <u>Details</u>
              </Link>
            </Tooltip>
          ) : (
            ""
          )}
          {getRolesPermissions(mainUserRes, "selection.edit") === false ? (
            <Tooltip title="Edit">
              <Button type="link" onClick={() => showModal("edit", record)}>
                <u>Edit</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
          {getRolesPermissions(mainUserRes, "selection.delete") === false ? (
            <Button onClick={() => delConfirmModal(record)} type="link">
              <u>Delete</u>
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return getRolesPermissions(mainUserRes, "selection.list") === false ? (
    <Table
      columns={columns}
      dataSource={selectionsList && selectionsList.selections}
      className="table-style"
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
      pagination={false}
      onRow={(record, index) => {
        return {
          onClick: () => {
            onClickSelection && onClickSelection(record);
          }, // click row
        };
      }}
      rowKey={(record) => record._id}
    />
  ) : (
    <div className="full-width d-flex justify-center">
      <Empty />
    </div>
  );
};

export default SelectionList;
