import React from 'react';
import InventorySearchDropDown from "./InventorySearchDropdown";
import InventorySwitchFilter from "./InventorySwitchFilter";

const InventorySearchDropdown = ({
  onSearch,
}) => {

  return (
    <div className="inventory-bottom-filter">
      <InventorySwitchFilter />
      <InventorySearchDropDown
        onSearch={onSearch}
      />
    </div>
  )
}

export default InventorySearchDropdown;