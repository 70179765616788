import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { getUrlLastId } from "../../Common/reUseFunctions";

const WalletDetail = ({
  location,

  onGetWalletDetail,
  walletDetail,

  onUpdateWallet,
  updateWallet,

  onDeleteWallet,
  deleteWallet,

  onGetWalletBalance,
  walletBalance,
}) => {
  useEffect(() => {
    const id = getUrlLastId(location.pathname);
    onGetWalletDetail(id);
  }, []);
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={12} className="mb30">
            <h1 className="mb0">{walletDetail && walletDetail.name}</h1>
            <p className="mb0">
              Your wallet address: {walletDetail && walletDetail.address}
            </p>
          </Col>
          {/* <Col span={12} className="text-right mb30">
            <h1 className="mb0">ETH {walletBalance && walletBalance.eth}</h1>
            // <small style={{ color: 'green' }}>Convet to $ top up balance</small>
          </Col> */}
        </Row>
      </Col>

      {/* <Col span={24} className="mb20">
        <h2 className="mb20 pb10 border-bottom-1">Ownership Licenses Published With This Wallet</h2>
        <OwnershipLicenses data={ownershipData} />
      </Col>

      <Col span={24}>
        <h2 className="mb20 pb10 border-bottom-1">Sub Licenses Purchases With This Wallet</h2>
        <OwnershipLicenses data={subLicenses} />
      </Col> */}
    </Row>
  );
};

export default WalletDetail;
