import React from 'react';
import { Input, Row, Col, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { searchIcon } from './../Icons';

const { Option } = Select;

const Filter = ({
  onSearch,
  search,
}) => {

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  }

  return (
    <Row gutter={16} className="mb15 add-selection-filter">
      <Col span={24}>
        <h2>Invite Users</h2>
      </Col>
      <Col span={16}>
        <Input
          value={search}
          onChange={onSearch}
          prefix={searchIcon}
          placeholder="Search Users"
          className="width-300"
        />
      </Col>
      <Col span={8}>
        <Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
          <Option value="All">All</Option>
          <Option value="Last Invite Users">Last Invite Users</Option>
          <Option value="New Invite Users">New Invite Users</Option>
        </Select>
      </Col>
    </Row>
  )
}

export default Filter;