import React, { useEffect, useState } from "react";
import { Collapse, Button, Row, Col, Switch } from "antd";
import { LeftOutlined, RightOutlined, ImportOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  toDateString,
  TimeFormate,
  getOnlyYear,
  getOnlyMinutes,
} from "./../../../../Common/reUseFunctions";

const { Panel } = Collapse;

const VendorAssetList = ({
  getAssetFormalLicenseDefinitions,
  modalType,
  getAllTradingTaskRes,
  tradingTaskId,
  getInventorySeachDetailRes,
  onGetInventorySeachDetail,
  showModal,
  mainUserRes,
  onGetMainUserDetail,
}) => {
  const [assetId, setAssetId] = useState("");

  useEffect(() => {
    if (
      getAllTradingTaskRes &&
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.assets &&
      getAllTradingTaskRes.data.assets.length > 0
    ) {
      setAssetId(getAllTradingTaskRes.data.assets[0].id);
    }
  }, [getAllTradingTaskRes]);

  const [singleVersion, setSingleVersion] = useState({});
  const [durationsArr, setdurationsArr] = useState([]);
  const [versionLength, setversionLength] = useState(0);
  const [versionIndex, setversionIndex] = useState(0);

  useEffect(() => {
    versionFirstValueSet(getInventorySeachDetailRes);
  }, [getInventorySeachDetailRes]);

  useEffect(() => {
    if (singleVersion) {
      saveVersionCompleteData(singleVersion);
    }
  }, [singleVersion]);

  const versionFirstValueSet = (inventorySeachDetail) => {
    if (
      inventorySeachDetail &&
      inventorySeachDetail.assets &&
      inventorySeachDetail.assets.versions &&
      inventorySeachDetail.assets.versions.length > 0
    ) {
      setSingleVersion(inventorySeachDetail.assets.versions[0]);
      setversionLength(inventorySeachDetail.assets.versions.length);
      setversionIndex(0);
    }
  };

  const saveVersionCompleteData = (versionObj) => {
    const inventoryDetail =
      getInventorySeachDetailRes && getInventorySeachDetailRes.assets;
    if (!versionObj || !inventoryDetail) {
      return;
    }
    let durations = [];

    if (versionObj.duration) {
      durations.push(versionObj.duration);
    }

    setdurationsArr(durations);
  };

  const getLicenseDefList = (data) => {
    getAssetFormalLicenseDefinitions(data.formal_license_definitions)
  }

  const nextVersionData = () => {
    setSingleVersion(
      getInventorySeachDetailRes.assets.versions[versionIndex + 1]
    );
    setversionIndex(versionIndex + 1);
  };

  const previewVersionData = () => {
    setSingleVersion(
      getInventorySeachDetailRes.assets.versions[versionIndex - 1]
    );
    setversionIndex(versionIndex - 1);
  };

  function onPanel(id) {
    if (id) {
      onGetInventorySeachDetail(id);
    }
  }

  return (
    <div className="License-assets-collapse c-col-main trading-task-detail-tabs">
      <div className="no-asset-header">
        <h3>
          <ImportOutlined />
          {getAllTradingTaskRes &&
            getAllTradingTaskRes.data &&
            getAllTradingTaskRes.data.company_title}
        </h3>
        <p>
          From{" "}
          <strong>
            {getAllTradingTaskRes &&
              getAllTradingTaskRes.data &&
              getAllTradingTaskRes.data.name}
          </strong>{" "}
          by{" "}
          <strong>
            {getAllTradingTaskRes &&
              getAllTradingTaskRes.data &&
              getAllTradingTaskRes.data.selection_created_by}
          </strong>
        </p>
      </div>
      <div className="panel-style-trading">
        <Collapse accordion defaultActiveKey={[assetId]} onChange={onPanel}>
          {getAllTradingTaskRes &&
            getAllTradingTaskRes.data &&
            getAllTradingTaskRes.data && getAllTradingTaskRes.data.assets
              && getAllTradingTaskRes.data.assets.map((data, index) => {
              return (
                    <Panel
                        header={
                          <Row onClick={(e) => {getLicenseDefList(data)}}>
                            <Col span={12}>{data.title}</Col>
                            <Col span={12}>
                              <div className="rightout-style" >
                                <RightOutlined />
                              </div>
                            </Col>
                          </Row>
                        }
                        key={data.id}
                    >
                      {getInventorySeachDetailRes &&
                      getInventorySeachDetailRes.assets && (
                          <div className="catalogues-p-grid c-col-panel">
                            <div className="c-col-img">
                              <img
                                  src={
                                    getInventorySeachDetailRes.assets.preview_image &&
                                    getInventorySeachDetailRes.assets.preview_image[0]
                                  }
                                  alt="Catalogues"
                              />
                            </div>
                            <div className="catalogues-p-info">
                              <div className="c-d-title">
                                <h4>
                                  {getInventorySeachDetailRes.assets.title &&
                                  getInventorySeachDetailRes.assets.title.value}
                                </h4>
                                <span>
                              {versionIndex > 0 && versionLength > 1 ? (
                                  <LeftOutlined onClick={previewVersionData} />
                              ) : (
                                  <LeftOutlined style={{ opacity: 0.3 }} />
                              )}
                                  {singleVersion.id}
                                  {versionLength > 1 &&
                                  versionIndex !== versionLength - 1 ? (
                                      <RightOutlined onClick={nextVersionData} />
                                  ) : (
                                      <RightOutlined style={{ opacity: 0.3 }} />
                                  )}
                            </span>
                              </div>
                              <p className="duration">
                            <span>
                              {data &&
                              data.asset &&
                              getOnlyYear(
                                  getInventorySeachDetailRes.assets
                                      .production_date
                              )}
                            </span>{" "}
                                {durationsArr.map((data) => getOnlyMinutes(data))}
                              </p>
                              <p className="m-cata">
                                <span>PRODUCTION COMPANY</span>
                                {getInventorySeachDetailRes.assets
                                    .production_company &&
                                getInventorySeachDetailRes.assets
                                    .production_company.value}
                              </p>
                              <p className="m-cata">
                                <span>CO-PRODUCTION</span>
                                {getInventorySeachDetailRes.assets
                                    .co_production_companies &&
                                getInventorySeachDetailRes.assets
                                    .co_production_companies.length > 0 &&
                                getInventorySeachDetailRes.assets.co_production_companies.map(
                                    (data) => data.value
                                )}
                              </p>
                              <p className="m-cata">
                                <span>FILM DIRECTOR</span>
                                {getInventorySeachDetailRes.assets.directors &&
                                getInventorySeachDetailRes.assets.directors.join(
                                    ", "
                                )}
                              </p>
                              <p className="m-cata">
                                <span>FEATURED ARTISTS</span>
                                {getInventorySeachDetailRes.assets.contributors &&
                                getInventorySeachDetailRes.assets.contributors
                                    .length > 0 &&
                                getInventorySeachDetailRes.assets.contributors.map(
                                    (data, index) => {
                                      return (
                                          <div className="inlineProperty" key={index}>
                                            {data.artist.value}
                                            <span>,</span>{" "}
                                          </div>
                                      );
                                    }
                                )}
                              </p>
                            </div>
                          </div>
                      )}
                    </Panel>
              );
            })}
        </Collapse>
      </div>
      <div>
        <div className="preview-modal-info pt20_imp">
          <h4>DEADLINE</h4>
          <div className="preview-modal-sec">
            <div className="mr4rem">
              <span>
                {getAllTradingTaskRes &&
                  getAllTradingTaskRes.data &&
                  getAllTradingTaskRes.data.deadline &&
                  toDateString(getAllTradingTaskRes.data.deadline)}
              </span>
            </div>
            <div>
              <span>
                {getAllTradingTaskRes &&
                  getAllTradingTaskRes.data &&
                  getAllTradingTaskRes.data.deadline &&
                  TimeFormate(getAllTradingTaskRes.data.deadline)}
              </span>
            </div>
          </div>
        </div>
        <div className="preview-modal-info">
          <h4>COMMENTS</h4>
          <div className="preview-modal-sec">
            <div>
              <span>
                {getAllTradingTaskRes &&
                  getAllTradingTaskRes.data &&
                  getAllTradingTaskRes.data.comments}
              </span>
            </div>
          </div>
        </div>
        {getAllTradingTaskRes &&
          getAllTradingTaskRes.data &&
          getAllTradingTaskRes.data.approvals &&
          getAllTradingTaskRes.data.approvals.length > 0 && (
            <div className="preview-modal-info mb40">
              <h4>APPROVALS</h4>
              <div className="ant-row preview-modal-sec justify-between">
                {getAllTradingTaskRes.data.approvals.map((approval, index) => (
                  <div key={index} className="ant-col-8 mb10">
                    <h4>{approval.department}</h4>
                    <span>{approval.user}</span>
                    {mainUserRes.tas_user_id &&
                      getAllTradingTaskRes.data.assigned_to && (
                        <div>
                          <div className="inventry-switch-btns">
                            <span>
                              <Switch size="small" checked={approval.approved} onChange={() => {}} />
                              {approval.approved ? "APPROVED" : "DISAPPROVED"}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          )}
      </div>
      {/* <div className="no-asset-btns preview-modal-btns nego-and-pro-btns"> */}
      <div className="no-asset-btns preview-modal-btns">
        <Button onClick={() => showModal("Trading Detail")} type="default">
          INVITE FOR NEGOTIATION
        </Button>
        <Button
          // onClick={cancelTableEditor}
          type="primary"
        >
          {modalType === "evaluate-detail" ? (
            "CREATE PURCHASING TASK"
          ) : (
            <Link to={`/trading-task/${tradingTaskId}/proposal`}>
              PROCEED TO NEGOTIATION
            </Link>
          )}
        </Button>
      </div>
    </div>
  );
};

export default VendorAssetList;
