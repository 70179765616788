import React from 'react';
import { Card, List, Popover } from 'antd';
import { MailOutlined, FolderOpenOutlined, CaretDownOutlined } from '@ant-design/icons';

const data = [
  {
    title: 'Jane Doe',
    text: 'Loro ipsum....',
    icon: <MailOutlined />
  },
  {
    title: 'Max Mustermann',
    text: 'Loro ipsum....',
    icon: <FolderOpenOutlined />
  },
  {
    title: 'Micheal White',
    text: 'Loro ipsum....',
    icon: <MailOutlined />
  },
  {
    title: 'Sarah Musterfrau',
    text: 'Loro ipsum....',
    icon: <MailOutlined />
  },
  {
    title: 'Max Mustermann',
    text: 'Loro ipsum....',
    icon: <FolderOpenOutlined />
  },
  {
    title: 'Micheal White',
    text: 'Loro ipsum....',
    icon: <MailOutlined />
  },
  {
    title: 'Sarah Musterfrau',
    text: 'Loro ipsum....',
    icon: <MailOutlined />
  }
];

const content = (
  <ul className="capter-list">
    <li><a href="#!">All Proposals</a></li>
    <li><a href="#!">Unmark Proposals</a></li>
    <li><a href="#!">New Proposals</a></li>
  </ul>
);

const ProposalReceived = () => {

  return (
    <Card title={
      <div className="d-card-header">
        PROPOSALS RECEIVED
      < Popover placement="bottomLeft" content={content} trigger="click">
          <CaretDownOutlined />
        </Popover>
      </div>
    } bordered={true} hoverable>
      <div className="scroll-sec rep-receive">
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={item.icon}
                title={<a href="#!">{item.title}</a>}
                description={item.text}
              />
            </List.Item>
          )}
        />
      </div>
    </Card >
  )
}

export default ProposalReceived;