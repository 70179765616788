import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ForgetPassword from '../Components/User/EmailForPasswordReset';

import {
  resetForgotPassword
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    resetForgotPasswordRes: state.userReducer.resetForgotPasswordRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onForgetPassword: bindActionCreators(resetForgotPassword, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);

