import React from 'react';
import { Input } from 'antd'

import { searchIcon } from './../../../Common/Icons';

const TopTitleSection = ({onFinish}) => {


  return (
    <div className="my-market-inventory">
      <div><h3>Catalogues</h3></div>
      <div className="d-flex">
        <div className="inventory-search-bar">
        <Input placeholder="Search Members" prefix={searchIcon} onChange={e => onFinish(e.target.value)} />
        </div>
      </div>
    </div>
  )
}

export default TopTitleSection;