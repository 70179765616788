import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, TimePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const onChange = (time, timeString) => {
  console.log(time, timeString);
}

const Index = ({helperText, requiredField}) => { 
  const [tabsChange, setTabsChange] = useState(true);

  const [awardLogo, setAwardLogo] = useState('');

  const onChangeLogo = (e) => {
    setAwardLogo(e.target.value);
  }

  return (
    <Row
    gutter={16}
    className="mb5"
  >
    <Col className="pl0_important" span={24}>
      <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-title"
              : "metadata-tab-title metadata-tab-active" 
          }
        >
          <h3>Versions</h3>
          <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
        </div>
      </Link>
    </Col>

    {helperText && (
      <Col span={24} className={tabsChange === true && "hide-helper-text"}>
        <div className="heper-text">
          <p>Provide information on all asset versions that are available for purchase.</p>
        </div>
      </Col>
    )}

    <Col span={24}>
      <div
        className={
          tabsChange === true
            ? "metadata-tab-body metadata-tab-body_hide"
            : "metadata-tab-body"
        }
      >
          <Form.List name="version">
          {(fields, { add, remove }) => {
            return (
              <div className="metadata-field-box">
                {fields.map(field => (
                  <div key={field.fieldKey}>
                    <Row gutter={16}>

                      <Col span={24}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="required">
                              <span>Duration</span>
                            </h3>
                          </div>
                          <div className="metadata-filed">
                            <div className="d-flex align-item-center">
                            <Form.Item
                              {...field}
                              name={[field.name, 'duration']}
                              fieldKey={[field.fieldKey, 'duration']}
                              rules={[
                                // {
                                //   required: true,
                                //   pattern: new RegExp("[0-9]+:[0-5][0-9]:[0-5][0-9](\.[0-9]{3})?"),
                                //   message: "Please enter the correct duration"
                                // }
                              ]}
                              className="full-width mb20"
                            >
                              <TimePicker
                                onChange={onChange}
                                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                className="full-width"
                              />
                            </Form.Item>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col span={24} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="">
                              <span>Chapters</span>
                            </h3>
                          </div>
                          <div className="metadata-filed">
                            <Form.List name={[field.fieldKey, 'chapters']}> 
                              {(fields, { add, remove }) => {
                                return (
                                  <div>
                                    {fields.map((field) => (
                                      <div
                                        key={field.key}
                                        className="d-flex align-item-center mb10"
                                      >
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'chapter']}
                                          validateTrigger={['onChange', 'onBlur']}
                                          className="full-width mb0"
                                        >
                                          <Input placeholder="chapter" value={awardLogo} onChange={onChangeLogo} />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                          className="ml10 remove-circle-tow"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </div>
                                    ))}

                                    <Form.Item>
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add Chapter
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </div>
                        </div>
                      </Col>

                      <Col span={24} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="">
                              <span>Video Tracks</span>
                            </h3>
                          </div>
                          <div className="metadata-filed">
                            <Form.List name={[field.fieldKey, 'video_tracks']}> 
                              {(fields, { add, remove }) => {
                                return (
                                  <div>
                                    {fields.map((field) => (
                                      <div
                                        key={field.key}
                                        className="d-flex align-item-center mb10"
                                      >
                                        <Form.Item 
                                          {...field}
                                          name={[field.name, 'video_track']}
                                          validateTrigger={['onChange', 'onBlur']}
                                          className="full-width mb0"
                                        >
                                          <Input placeholder="Video Track" value={awardLogo} onChange={onChangeLogo} />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                          className="ml10 remove-circle-tow"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </div>
                                    ))}

                                    <Form.Item>
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add Video Track
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </div>
                        </div>
                      </Col>

                      <Col span={24} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="">
                              <span>Audio Tracks</span>
                            </h3>
                          </div>
                          <div className="metadata-filed">
                            <Form.List name={[field.fieldKey, 'audio_tracks']}>
                              {(fields, { add, remove }) => {
                                return (
                                  <div>
                                    {fields.map((field) => (
                                      <div
                                        key={field.key}
                                        className="d-flex align-item-center mb10"
                                      >
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'audio_track']}
                                          validateTrigger={['onChange', 'onBlur']}
                                          className="full-width mb0"
                                        >
                                          <Input placeholder="Audio Track" value={awardLogo} onChange={onChangeLogo} />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                          className="ml10 remove-circle-tow"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </div>
                                    ))}

                                    <Form.Item>
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add Audio Track
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </div>
                        </div>
                      </Col>

                      <Col span={24} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="">
                              <span>Subtitle Tracks</span>
                            </h3>
                          </div>
                          <div className="metadata-filed">
                            <Form.List name={[field.fieldKey, 'subtitle_tracks']}>
                              {(fields, { add, remove }) => {
                                return (
                                  <div>
                                    {fields.map((field) => (
                                      <div
                                        key={field.key}
                                        className="d-flex align-item-center mb10"
                                      >
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'subtitle_track']}
                                          validateTrigger={['onChange', 'onBlur']}
                                          className="full-width mb0"
                                        >
                                          <Input placeholder="Subtitle Track" value={awardLogo} onChange={onChangeLogo} />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                          className="ml10 remove-circle-tow"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        />
                                      </div>
                                    ))}

                                    <Form.Item>
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          add();
                                        }}
                                        block
                                      >
                                        <PlusOutlined /> Add Subtitle Track
                                      </Button>
                                    </Form.Item>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </div>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name">
                            <h3 className="required">
                              <span>Version Id</span>
                            </h3>
                          </div>
                          <div className="metadata-filed">
                            <div className="d-flex align-item-center">
                              <Form.Item
                                {...field}
                                name={[field.name, 'version_id']}
                                className="full-width mb0"
                              >
                                <Input placeholder="Id" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col span={24}>
                        <MinusCircleOutlined
                          className="ml10 remove-gray-icon"
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Versions
                      </Button>
                    </Form.Item>
                  </div>
              </div>
            );
          }}
        </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;