import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const LicenseSign = ({
  onGetAssetDetail,
  assetDetail,
  onUpdateAsset,

  onLoadSignLicense,
  signLicense,

  onSignatureLicenseProcess,
  signatureStatus,

  onRequestLicenseSignature,
  reqSignature,
  mainUserRes,
  setTitleEdit,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (process_id) {
    //   setLoading(true);
    //   signatureLicenseProcess(process_id);
    // }
    if (!signLicense) {
      const id = assetDetail && assetDetail._id;
      onLoadSignLicense(id);
    }
  }, []);

  useEffect(() => {
    if (
      assetDetail &&
      assetDetail.signature &&
      assetDetail.signature.includes("WIP_")
    ) {
      const id = assetDetail && assetDetail._id;
      setLoading(true);
      setTimeout(async () => {
        await onGetAssetDetail(id);
        setTitleEdit(true);
        setTitleEdit(false);
      }, 5000);
    } else {
      setLoading(false);
      setTitleEdit(false);
    }
  }, [assetDetail]);

  // const signatureLicenseProcess = (process_id) => {
  //   let interval = setInterval(() => {
  //     onSignatureLicenseProcess(process_id)
  //       .then((res) => {

  //         if (res.type === 'LICENSE_REQUEST_SIGNATURE_PROCESS_SUCCESS') {
  //           const id = assetDetail && assetDetail._id;
  //           const dataSig = {
  //             signature: res && res.payload && res.payload.signedLicense
  //           }
  //           setLoading(false)
  //           if(process_id) {
  //             onUpdateAsset(id, dataSig)
  //             .then(() => {
  //               onGetAssetDetail(id);
  //               clearInterval(interval);
  //             }).catch(() => {
  //               clearInterval(interval);
  //             })
  //           }
  //         }
  //       })
  //   }, 5000);
  // }

  const startLicense = async () => {
    setLoading(true);
    const id = assetDetail && assetDetail._id;

    if (signLicense) {
      await onRequestLicenseSignature(id, signLicense)
        .then(async (res) => {
          await onGetAssetDetail(id);
          setLoading(false);
          setTitleEdit(true);
          setTitleEdit(false);
        })
        .catch(() => setLoading(false));
    }
  };

  return assetDetail &&
    assetDetail.signature &&
    assetDetail.signature.includes("EUMLC") ? (
    ""
  ) : getRolesPermissions(mainUserRes, "asset.sign") === false ? (
    <Button style={{color: "#FF246E"}} type="link" onClick={startLicense} loading={loading}>
      <DoubleRightOutlined />
      Start License Signing
    </Button>
  ) : (
    ""
  );
};

export default LicenseSign;
