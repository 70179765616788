import { api } from "../../service/api";
import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

import { getSpecificUserId } from "./../../Components/Common/reUseFunctions";

export const signIn = (data) => {
  const options = { url: "login" };
  options.types = [types.LOGIN_SUCCESS, types.LOGIN_FAILURE];

  return apiNew.authLogin(options, data);
};

export const getSession = () => {
  const options = { url: "session" };
  options.types = [types.GET_SESSION_SUCCESS, types.GET_SESSION_FAILURE];

  return api.get(options);
};

export const logout = () => {
  const options = { url: "session" };
  options.types = [types.LOGOUT_SUCCESS, types.LOGOUT_FAILURE];

  return api.delete(options);
};

export const resetForgotPassword = (data) => {
  const options = { url: `auth/forget-password` };
  options.types = [
    types.POST_FORGOT_PASSWORD_SUCCESS,
    types.POST_FORGOT_PASSWORD_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const updateUserPicture = (userId, picture) => {
  const options = { url: `users/${userId}/profile/upload` };
  options.types = [
    types.POST_UESR_PICTURE_SUCCESS,
    types.POST_UESR_PICTURE_FAILURE,
  ];

  return apiNew.post(options, picture);
};

export const mianUserDetail = () => {
  const options = { url: `users/${getSpecificUserId()}` };
  options.types = [
    types.GET_MAIN_USER_DETAIL_SUCCESS,
    types.GET_MAIN_USER_DETAIL_FAILURE,
  ];

  return apiNew.get(options);
};

export const setUserPassword = (id, data) => {
  const options = { url: `auth/reset-password/${id}` };
  options.types = [
    types.POST_SET_USER_PASSWORD_SUCCESS,
    types.POST_SET_USER_PASSWORD_FAILURE,
  ];

  return apiNew.post(options, data);
};
