import React, { useState } from 'react';
import Filter from './Filter';
import SearchPresetListcCom from './SearchPresetList';

const searchPresetList = [
  { name: "Search Preset A" },
  { name: "Search Preset B" },
  { name: "Search Preset C" },
  { name: "Search Preset D" },
  { name: "Search Preset E" },
  { name: "Search Preset A" },
  { name: "Search Preset F" },
  { name: "Search Preset G" },
  { name: "Search Preset H" },

]

const SavedSearchPresetsModal = ({
  handleCloseSelection,
}) => {
  const [search, setSearch] = useState('');

  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}
      />
      <SearchPresetListcCom
        searchPresetList={searchPresetList}
        handleCloseSelection={handleCloseSelection}
      />
    </div>
  )
}

export default SavedSearchPresetsModal;