import React from 'react';
import { Button } from 'antd';
import { Link } from "react-router-dom";
import {
  pluseFilledIcon
} from './../../../../Common/Icons';

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory">
      <div><h3>Asset Imports</h3></div>
      <div>
        <p className="add-selection-btn add-new-selection">
          <Link to="/import-asset-file">
            <Button
              type="default"
              className="p0 border-0"
            >
              {pluseFilledIcon}
               IMPORT ASSETS
            </Button>
          </Link>
        </p>
      </div>
    </div>
  )
}

export default TopTitleSection;