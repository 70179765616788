import React from 'react';

import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./InventorySearchDropdown";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onChangeSelectionType,
  onFinish,
  showTableEditor,
  selectSales,
  setSelectSales,
  selectPurchase,
  setSelectPurchase,
  setSelectionTypeChange,
  setNoLicense,
  setHasLicense
}) => {

  return (
    <div className="inventory-bottom-filter">
      <InventorySwitchSec
        onChangeSelectionType={onChangeSelectionType}
        selectSales={selectSales}
        setSelectSales={setSelectSales}
        selectPurchase={selectPurchase}
        setSelectPurchase={setSelectPurchase}
        setSelectionTypeChange={setSelectionTypeChange}
        setNoLicense={setNoLicense}
        setHasLicense={setHasLicense}
      />
      <InventorySearchDropDown
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
      />
    </div>
  )
}

export default InventorySearchDropdown;