import React, { useState, useEffect } from "react";
import { message } from "antd";
import Filter from "./HeaderFilter";
import UserTaskListBox from "./List";

import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";
import ProposalsPermissions from "./../../../Common/Components/PermissionsModal";

const ProposalsManagement = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false)
  }, [])

  const showModal = (modalType, selectedSpecificData) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const delConfirmModal = (data) => {
    setDelConfirm(true);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const onDeletingItem = () => {
    message.success("Successfully Deleted!");
    setDelConfirm(false);

  };

  const onSearch = (e) => {
    setCurrentPage(1)
    setSearch(e.target.value);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  }

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}
        showModal={showModal}

      />

      <UserTaskListBox
        key={"0"}
        loading={loading}
        showModal={showModal}
        delConfirmModal={delConfirmModal}
        onChangePagination={onChangePagination}
        currentPage={currentPage}
      />

      {
        visible &&
        <ProposalsPermissions
          visible={visible}
          handleCancel={handleCancel}
        />

      }

      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onDeletingItem}
      />
    </div>
  );
};

export default ProposalsManagement;
