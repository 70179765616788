import React from 'react';
import { Switch } from 'antd';

const InventorySwitchFilter = () => {
  return (
    <div>
      <div className="inventry-switch-btns">
        <span><Switch size="small" /> Has Defined License</span>
        <span><Switch size="small" /> No Defined License</span>
      </div>
    </div>
  )
}

export default InventorySwitchFilter;