import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const OwnershipLicensePublish = ({
  onGetAssetDetail,
  assetDetail,
  onUpdateAsset,

  signLicense,

  onPublishLicenseBlockchain,
  licensePublish,

  onIsccGenerationProcess,
  showWalletModel,

  WalletAddress,
  mainUserRes,
  setTitleEdit,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (assetDetail && assetDetail.tx && assetDetail.tx.includes("WIP_")) {
      const id = assetDetail && assetDetail._id;
      setLoading(true);
      setTimeout(async () => {
        await onGetAssetDetail(id);
        setTitleEdit(true);
        setTitleEdit(false);
      }, 5000);
    } else {
      setLoading(false);
      setTitleEdit(false);
    }
  }, [assetDetail]);

  // useEffect(() => {
  //   const result = assetDetail && assetDetail.tx && assetDetail.tx.includes('WIP_');
  //   if (result) {
  //     setLoading(true);
  //     const process_code = assetDetail.tx.replace('WIP_', '');
  //     publishingLicenseProcess(process_code);
  //   }
  // }, [assetDetail]);

  // const publishingLicenseProcess = (process_code) => {
  //   debugger
  //   let interval = setInterval(() => {
  //     onIsccGenerationProcess(process_code)
  //       .then((res) => {
  //         if (res.type === 'ISCC_CODE_GENERATION_PROCESS_SUCCESS' && (res.payload.result && res.payload.result.from) && (res.payload.result && res.payload.result.transactionHash)) {
  //           const id = assetDetail && assetDetail._id;
  //           const dataSig = {
  //             wallet: res.payload.result.from,
  //             tx: res.payload.result.transactionHash
  //           }
  //           onUpdateAsset(id, dataSig)
  //             .then(() => {
  //               onGetAssetDetail(id);
  //               clearInterval(interval);
  //             }).catch(() => {
  //               clearInterval(interval);
  //             })
  //         }
  //       })
  //   }, 5000);
  // }

  // useEffect(() => {
  //   const result = assetDetail && assetDetail.tx;
  //   if (licensePublish) {
  //     const id = assetDetail && assetDetail._id;
  //     const data = {
  //       tx: `WIP_${licensePublish.process}`
  //     }
  //     if (!result) {
  //       onUpdateAsset(id, data)
  //         .then(() => onGetAssetDetail(id))
  //     }
  //   }
  // }, [licensePublish]);

  // useEffect(() => {
  //   if (ownership === true) {
  //     debugger
  //     onGetAssetDetail().then((res) => {
  //       debugger
  //       // onPublishLicenseBlockchain(WalletAddress, data);
  //     })
  //   }
  // }, [signLicense]);

  const startLicense = async () => {
    setLoading(true);
    const id = assetDetail && assetDetail._id;
    const signature = assetDetail && assetDetail.signature;
    const assetPublishBody = {
      wallet: WalletAddress,
      license: signature,
    };
    await onPublishLicenseBlockchain(id, assetPublishBody)
      .then(async (res) => {
        message.success(res && res.payload && res.payload.message);
        await onGetAssetDetail(id);
        setLoading(false);
        setTitleEdit(true);
        setTitleEdit(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return assetDetail && assetDetail.tx ? (
    ""
  ) : (
    <>
      {WalletAddress && WalletAddress.length > 0 ? (
        getRolesPermissions(mainUserRes, "asset.import-xml") === false ? (
          <Button style={{color: "#FF246E"}} onClick={() => startLicense()} type="link" loading={loading}>
            <DoubleRightOutlined />
            Start Publishing
          </Button>
        ) : (
          ""
        )
      ) : (
        <Button
          onClick={() => showWalletModel("wallet")}
          type="link"
          loading={loading}
          style={{color: "#FF246E"}}
        >
          <DoubleRightOutlined />
          Select Wallet
        </Button>
      )}
    </>
  );
};

export default OwnershipLicensePublish;
