import React, { useEffect, useState } from "react";
import { Row, Col, Button, Skeleton } from "antd";
import { Link } from "react-router-dom";

const CatalogueAssetsGrid = ({
  showModal,
  showTableEditor,
  showAssetLanguages,
  assets,
}) => {
  const [highlights, setHighlights] = useState([]);
  // const [highlights, setHighlights] = useState([])
  // const [highlights, setHighlights] = useState([])

  useEffect(() => {
    assets &&
      assets.catalogue &&
      assets.catalogue.highlights &&
      setHighlights(assets.catalogue.highlights);
  }, [assets]);
  return (
    <Row gutter={[32, 24]}>
      {highlights.assets &&
        highlights.assets.map((item) => (
          <Col className="inventory-grid" key={item._id}>
            <a type="primary" href="#!" onClick={() => showModal("inventory")}>
              <div>
                {item ? (
                  <img
                    src={item.asset_data.preview_image[0]}
                    alt={item.asset_data.title.value}
                  />
                ) : (
                  <Skeleton.Image />
                )}
              </div>
            </a>
            <div>
              <h3>{item.asset_data.title.value}</h3>
            </div>
            <div className="asset-grid-info">
              <div className="w30-per">
                <div className="mb10">
                  <h4>Ownership</h4>
                  <span>not active yet</span>
                </div>
                <div>
                  <h4>languages</h4>
                  <span>{item.asset_data.title.language}</span>
                  {/* <Button className="specific-role-btn" type="link" onClick={() => showAssetLanguages("languages")}> <u>+15</u></Button> */}
                </div>
              </div>
              <div className="w40-per">
                <div className="mb10">
                  <h4>Lenght</h4>
                  <span>1hr 20mins</span>
                </div>
                <div>
                  <h4>Production Company</h4>
                  <span>Water Gate</span>
                </div>
              </div>
              <div className="w30-per">
                <div className="mb10">
                  <h4>Director</h4>
                  <span>Francine Richards</span>
                </div>
                <div>
                  <h4>prodiction year</h4>
                  <span>{item.asset_data.production_date}</span>
                </div>
              </div>
            </div>
            <div className="asset-grid-btn">
              <Button
                type="link"
                className="p0 border-0"
                onClick={() => showModal("inventory")}
              >
                <u> Preview</u>
              </Button>
              <Button type="link" className="p0 border-0">
                <Link to={`/inventory-search/sfsdregtsef43545dfgtrdsf`}>
                  <u> Details</u>
                </Link>
              </Button>
              <Button
                type="link"
                className="p0 border-0"
                onClick={() => showModal("add_to_selection")}
              >
                <u> Add to Selection</u>
              </Button>
            </div>
            <div className="asset-grid-seprator"></div>
          </Col>
        ))}

      {/* {highlights.special_catalogues && highlights.special_catalogues.map(item => <Col className="inventory-grid">
        <a type="primary" href="#!" onClick={() => showModal('inventory')}>
          <div>
          
            {item ? <img src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.special_catalogue_data.banner}/image`} alt={item.special_catalogue_data.name} /> : <Skeleton.Image />}
          </div>
        </a>
        <div>
          <h3>{item.special_catalogue_data.name}</h3>
        </div>
        <div className="asset-grid-info">
          <div className="w30-per">
            <div className="mb10">
              <h4>Ownership</h4>
              <span>not active yet</span>
            </div>
            <div>
              <h4>languages</h4>
              <span>en, fr, es</span>
              <Button className="specific-role-btn" type="link" onClick={() => showAssetLanguages("languages")}> <u>+15</u></Button>
            </div>
          </div>
          <div className="w40-per">
            <div className="mb10">
              <h4>Lenght</h4>
              <span>1hr 20mins</span>
            </div>
            <div>
              <h4>Production Company</h4>
              <span>Water Gate</span>
            </div>
          </div>
          <div className="w30-per">
            <div className="mb10">
              <h4>Director</h4>
              <span>Francine Richards</span>
            </div>
            <div>
              <h4>prodiction year</h4>
              <span>{'2000'}</span>
            </div>
          </div>
        </div>
        <div className="asset-grid-btn">
          <Button type="link" className="p0 border-0" onClick={() => showModal('inventory')}><u> Preview</u></Button>
          <Button type="link" className="p0 border-0" ><Link to={`/inventory-search/sfsdregtsef43545dfgtrdsf`}><u> Details</u></Link></Button>
          <Button type="link" className="p0 border-0" onClick={() => showModal('add_to_selection')}><u> Add to Selection</u></Button>
        </div>
        <div className="asset-grid-seprator"></div>
      </Col>)} */}
    </Row>
  );
};

export default CatalogueAssetsGrid;
