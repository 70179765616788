import React, { Fragment, useState } from "react";
import { Row, Col, Modal } from "antd";

import HeaderFilter from "./HeaderFilter/TopTitleSection";
import MemberProfile from "./MemberProfile";
import NewCatalogue from "./NewCatalogue";
import GeneralCatalogue from "./GeneralCatalogue";

import { SelectionModelComp, InventoryModelComp } from "../../../Containers";

const CataloguesMemberProfile = ({ sessionRes }) => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);

  const showModal = (value) => {
    setVisible(true);
    setModalType(value);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <Fragment>
      <Row gutter={16}>
        <Col className="mb25" span={24}>
          <HeaderFilter />
          <MemberProfile />
          <NewCatalogue showModal={showModal} />
          <GeneralCatalogue showModal={showModal} />
        </Col>
      </Row>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className={modalType === "inventory" ? "detail-model" : "modal-style"}
        width={450}
      >
        {modalType === "add_to_selection" && (
          <SelectionModelComp handleCloseSelection={handleCancel} />
        )}
        {modalType === "inventory" && (
          <InventoryModelComp showTableEditor={showModal} />
        )}
      </Modal>
    </Fragment>
  );
};

export default CataloguesMemberProfile;
