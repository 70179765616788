import React from 'react';
import { Input } from 'antd'
import { searchIcon } from './../../Common/Icons';

const TopTitleSection = ({ onFinish }) => {
  return (
    <div className="my-market-inventory">
      <div><h3>Help</h3></div>
      <div className="inventory-search-bar wallet-search help-search">
        <Input placeholder="Search" prefix={searchIcon} />
      </div>
    </div>
  )
}

export default TopTitleSection;