import React from "react";
import {
  Table,
  Tag,
  Space,
  Tooltip,
  Card,
  Popconfirm,
  Button,
  Pagination,
  Spin,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import { toDateString, getRolesPermissions } from "../../Common/reUseFunctions";

const { Meta } = Card;

const WalletList = ({
  data,
  showModal,
  onConfirmDelete,
  onChangePagination,
  currentPage,
  mainUserRes,
  loading,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Link to={`/wallet/${record._id}`}>
            <Meta className="meta-avatar-align meta-link-avatar" title={text} />
          </Link>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      render: (text) => <Tag color="cyan">{text}</Tag>,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <span>{toDateString(text)}</span>,
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table usre-management-action">
          {getRolesPermissions(mainUserRes, "wallet.edit") === false ? (
            <Tooltip title="Edit">
              <Button type="link" onClick={() => showModal("edit", record)}>
                <u>Edit</u>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Edit">
              <Button type="link">
                <u>Edit</u>
              </Button>
            </Tooltip>
          )}

          {getRolesPermissions(mainUserRes, "wallet.delete") === false ? (
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to delete."
              onConfirm={() => onConfirmDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <u>Delete</u>
              </Button>
            </Popconfirm>
          ) : (
            <Button type="link">
              <u>Delete</u>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      {getRolesPermissions(mainUserRes, "wallet.list") === false ? (
        <Table
          columns={columns}
          dataSource={data && data.wallets}
          className="table-style"
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          pagination={false}
        />
      ) : (
        <div className="d-flex justify-center full-width">
          <Empty />
        </div>
      )}

      <div span={24} className="d-flex justify-center mt50">
        <Pagination
          defaultCurrent={currentPage}
          defaultPageSize={data && data.pagination && data.pagination.limit}
          onChange={onChangePagination}
          total={data && data.pagination && data.pagination.total}
        />
      </div>
    </Spin>
  );
};

export default WalletList;
