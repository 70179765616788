import React, { useState } from 'react';

import Asset from "./Asset"
import Filter from "./Filter"
import Poster from "../../../statics/images/poster.jpg"

const AssetList = [
  {
    "id": "1",
    "movie_title": "The Movie Title 1",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "genre": [
      "Music Genre 1",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "2",
    "movie_title": "The Movie Title 2",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 2",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "3",
    "movie_title": "The Movie Title 4",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 3",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "4",
    "movie_title": "The Movie Title 5",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 4",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "5",
    "movie_title": "The Movie Title 6",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 5",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "6",
    "movie_title": "The Movie Title 7",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 6",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "7",
    "movie_title": "The Movie Title 8",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 7",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "8",
    "movie_title": "The Movie Title 9",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 8",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "9",
    "movie_title": "The Movie Title 10",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 9",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "10",
    "movie_title": "The Movie Title 11",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 10",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "11",
    "movie_title": "The Movie Title 12",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 11",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "12",
    "movie_title": "The Movie Title 13",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 12",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "13",
    "movie_title": "The Movie Title 14",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 14",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "14",
    "movie_title": "The Movie Title 15",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 14",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  },
  {
    "id": "15",
    "movie_title": "The Movie Title 16",
    "watch_time": "45 min",
    "poster": Poster,
    "producer_by": "Producer, Coproducer. Producer",
    "genre": [
      "Music Genre 15",
      "Film Genre",
      "Dance Genre",
      "Drama Genre"
    ]
  }
]

const MarketPlace = ({ history }) => {

  const [visible, setVisible] = useState(false);
  const [selectionType, setSelectionType] = useState(false);
  const [selectionData, setSelectionData] = useState("")

  const handleCancel = () => {
    setVisible(false);
  };

  const onClickSelection = (data) => {
    setSelectionData(data)
    setVisible(false);
  }
  
  const showModal = (value, id) => {
    setVisible(true)
    setSelectionType(value)
  };

  return (
    <div>
      <Filter history={history} />
      <Asset
        history={history}
        AssetList={AssetList}
        showModal={showModal}
        handleCancel={handleCancel}
        visible={visible}
        selectionType={selectionType}
        onClickSelection={onClickSelection}
        selectionData={selectionData}
      />
    </div>
  )
}

export default MarketPlace;