import React, { useEffect, useState } from "react";
import { Button, Form, Select, Spin, message } from "antd";
import {
  GET_ALL_ORGANIZATIONS_SUCCESS,
  GET_ALL_USERS_SUCCESS,
} from "../../../../store/Constants";

const { Option } = Select;

const AddNewTask = ({
  modalType,
  handleCancel,
  onGetOrganizations,
  onGetUsers,
  onAddTask,
  onUpdateTask,
  selectedData,
  setReload,
}) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    setLoading(true);
    getList();
  }, []);

  useEffect(() => {
    selectedData &&
      form.setFieldsValue({
        user: selectedData.user._id,
        organization: selectedData.organization._id,
      });
  }, [selectedData]);

  const getList = () => {
    onGetUsers(0, 9000)
      .then((res) => {
        if (res.type === GET_ALL_USERS_SUCCESS) {
          setUsers(res.payload.users);
        }
        return onGetOrganizations();
      })
      .then((res) => {
        if (res.type === GET_ALL_ORGANIZATIONS_SUCCESS) {
          setOrganizations(res.payload.organizations.organizations);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    if (values) {
      const action = {
        "add-user": onAddTask,
        "edit-user": onUpdateTask,
      };

      const data = {
        payload: { ...values },
      };

      if (selectedData) data["id"] = selectedData._id;

      action[modalType](data)
        .then((res) => {
          handleCancel();
          message.success(res.payload.message);
          setReload(true);
        })
        .catch((err) => {});
    }
  };

  return (
    <div className="license-edit-model">
      <h3 className="mb5-imp">
        {modalType === "add-user"
          ? "Automated Task Assignment"
          : "Edit Automated Task Assignment"}
      </h3>
      <p className="task-add-text">
        All incoming trading task from particular organization would be
        automatically assigned to the selected user.
      </p>
      <Spin spinning={loading}>
        <Form name="add_new_task" onFinish={onFinish} form={form}>
          <div className="slection-asset-view">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SELECT USER</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="user"
                    rules={[
                      {
                        required: true,
                        message: "Please select a user!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select User"
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {users.map((user, index) => (
                        <Option key={index} search={user} value={user._id}>
                          <div className="demo-option-label-user">
                            {user.name}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>SELECT ORGANIZATION</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="organization"
                    rules={[
                      {
                        required: true,
                        message: "Please select an organization!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Organization"
                      // value={selectedRole}
                      // onChange={handleChangeRoles}
                      style={{ width: "100%" }}
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {organizations.map((organization, index) => (
                        <Option
                          key={index}
                          search={organization}
                          value={organization._id}
                        >
                          <div className="demo-option-label-organization">
                            {organization.name}
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="formal-license-btn">
            <Button htmlType="submit" type="primary">
              {modalType === "add-user"
                ? "ADD NEW ASSIGNMENT"
                : "TASK ASSIGNMENT UPDATE"}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default AddNewTask;
