import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradingTask from '../Components/ContentArea/LicenseTrading/TradingTasks/VendorAssets';

import {
  getAllTradingTask,
  getAssetDetail,
  getInventorySeachDetail,
  getOrganizationProfile,
  sendInvitation,
  mianUserDetail,
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    getAllTradingTaskRes: state.requestProposalReducer.getAllTradingTaskRes,
    assetDetail: state.assetReducer.assetDetail,
    getInventorySeachDetailRes: state.inventorySearchReducer.getInventorySeachDetailRes,
    getOrganizationProfileRes: state.organizationReducer.getOrganizationProfileRes,
    sendInvitationRes: state.selectionProposalReducer.sendInvitationRes,
    mainUserRes: state.userReducer.mainUserRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTradingTask: bindActionCreators(getAllTradingTask, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onGetInventorySeachDetail: bindActionCreators(getInventorySeachDetail, dispatch),
    onGetOrganizationProfile: bindActionCreators(getOrganizationProfile, dispatch),
    onsendInvitation: bindActionCreators(sendInvitation, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradingTask);
