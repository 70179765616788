import React from "react";
import { Row, Col, List, Avatar } from "antd";

const data = [
  {
    title: "Jhone Doe 1",
    location: "Europe & North America",
  },
  {
    title: "Jhone Doe 2",
    location: "Africa & South America",
  },
  {
    title: "Jhone Doe 3",
    location: "Asia & Oceania",
  },
  {
    title: "Jhone Doe 4",
    location: "Europe & North America",
  },
];

const OrgazinerContact = () => {
  return (
    <div>
      <Row gutter={16}>
        <Col span={10}>
          <h2>CONTACT PEOPLE</h2>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://avatars0.githubusercontent.com/u/981105?s=40&v=4" />
                  }
                  title={<a href="#!">{item.title}</a>}
                  description={item.location}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrgazinerContact;
