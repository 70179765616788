import React from "react";
import { Row, Col, Button, Spin, Pagination, Empty } from "antd";
import { getRolesPermissions } from "../../../Common/reUseFunctions";

const PermissionsRoleList = ({
  mainUserRes,
  delConfirmModal,
  rolesList,
  showModal,
  loading,
  currentPage,
  onChangePagination,
}) => {
  return (
    <div className="role-management-list">
      <Spin size="large" spinning={loading}>
        {getRolesPermissions(mainUserRes, "role.list") === false ? (
          <Row gutter="24">
            {rolesList && rolesList.roles && rolesList.roles.length > 0 ? (
              rolesList.roles.map((item, index) => {
                return (
                  <Col key={index} span={8} className="mb25">
                    <div key={index} className="role-list-box">
                      <div>
                        <h2>{item.name}</h2>
                        <div>
                          <h5>Permissions</h5>
                          <p className="text-capitalize">
                            {item &&
                              item.permissions &&
                              item.permissions.length > 0 &&
                              item.permissions
                                .join(", ")
                                .substring(0, 125)}{" "}
                            ...
                          </p>
                        </div>
                        <div className="role-list-btn">
                          {getRolesPermissions(mainUserRes, "role.edit") ===
                          false ? (
                            <Button
                              type="link"
                              onClick={() => showModal("edit_role", item._id)}
                            >
                              Edit
                            </Button>
                          ) : (
                            ""
                          )}
                          {getRolesPermissions(mainUserRes, "role.edit") ===
                          false ? (
                            <Button
                              type="link"
                              onClick={() =>
                                delConfirmModal("delete_role", item._id)
                              }
                            >
                              Delete
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <div className="d-flex justify-center full-width">
                <Empty />
              </div>
            )}
          </Row>
        ) : (
          <div className="d-flex justify-center full-width">
            <Empty />
          </div>
        )}

        <Row gutter={24}>
          <Col span={24} className="d-flex justify-center mt50">
            <Pagination
              defaultCurrent={currentPage}
              defaultPageSize={
                rolesList && rolesList.pagination && rolesList.pagination.limit
              }
              onChange={onChangePagination}
              total={
                rolesList && rolesList.pagination && rolesList.pagination.total
              }
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default PermissionsRoleList;
