import React from "react";
import InventorySearchDropDown from "./InventorySearchDropdown";
import { getRolesPermissions } from "./../../../../../../Common/reUseFunctions";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onFinish,
  showTableEditor,
  setSearch,
  onSearch,
  mainUserRes,
}) => {
  return (
    <div>
      {getRolesPermissions(mainUserRes, "role.list") === false ? (
        <div className="inventory-bottom-filter flex-end-imp mb25-imp">
          <InventorySearchDropDown
            onChangeLayout={onChangeLayout}
            changeLayout={changeLayout}
            onFinish={onFinish}
            showTableEditor={showTableEditor}
            setSearch={setSearch}
            onSearch={onSearch}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySearchDropdown;
