import React from 'react';

import MemberProfile from '../../../../statics/images/majorlogo.png';

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory">
      <div><h3>Salzburg 2020 Special Catalogue</h3></div>
      <div><img src={MemberProfile} alt="major logo" /></div>
    </div>
  )
}

export default TopTitleSection;