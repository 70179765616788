import React from "react";
import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./InventorySearchDropdown";
import { getRolesPermissions } from "./../../../../../../Common/reUseFunctions";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onChangeSelectionType,
  onFinish,
  showTableEditor,
  setStatusFilter,
  statusFilter,
  setBlockedFilter,
  blockedFilter,
  setUnBlockedFilter,
  unBlockedFilter,
  setPendingFilter,
  pendingFilter,
  setSelectionTypeChange,
  onSearch,
  mainUserRes,
}) => {
  return (
    <div>
      {getRolesPermissions(mainUserRes, "user.list") === false ? (
        <div className="inventory-bottom-filter">
          <div>
            <InventorySwitchSec
              onChangeSelectionType={onChangeSelectionType}
              setStatusFilter={setStatusFilter}
              statusFilter={statusFilter}
              setBlockedFilter={setBlockedFilter}
              blockedFilter={blockedFilter}
              setUnBlockedFilter={setUnBlockedFilter}
              unBlockedFilter={unBlockedFilter}
              setPendingFilter={setPendingFilter}
              pendingFilter={pendingFilter}
              setSelectionTypeChange={setSelectionTypeChange}
              mainUserRes={mainUserRes}
            />
          </div>
          <div>
            <InventorySearchDropDown
              onChangeLayout={onChangeLayout}
              changeLayout={changeLayout}
              onFinish={onFinish}
              showTableEditor={showTableEditor}
              onSearch={onSearch}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySearchDropdown;
