import React from "react";
import { Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

const MetadataReShare = ({ publishMetadataReshare, reLoading }) => {
  return (
    <Button style={{color: "#FF246E"}} type="link" onClick={publishMetadataReshare} loading={reLoading}>
      <DoubleRightOutlined />
      Metadata Reshare
    </Button>
  );
};

export default MetadataReShare;
