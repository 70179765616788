import React, { useState } from 'react';
import SearchFilter from './InventoryFilters';

const TopTitleSection = ({ onFinish, index }) => {
  const [title] = useState({
    'special_catalogues': 'Special Catalogues',
    'assets': 'General Catalogues'
  })

  return (
    <div className="s-catalogue-modal">
      <div><h3>{title[index]}</h3></div>
      <SearchFilter onFinish={onFinish} />
    </div>
  )
}

export default TopTitleSection;