import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

export const postSendMessage = (data) => {
  const options = {
    url: `chat`,
  };

  options.types = [
    types.POST_ADD_MESSAGE_SUCCESS,
    types.POST_ADD_MESSAGE_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const getAllMessage = (tradingTaskId) => {
  const options = {
    url: `chat/${tradingTaskId}`,
  };

  options.types = [types.GET_MESSAGE_SUCCESS, types.GET_MESSAGE_FAILURE];

  return apiNew.get(options);
};
