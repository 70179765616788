import React, { useEffect, useState } from 'react';
import { Button, Form, Select, Input, Spin, message } from 'antd';

const { Option } = Select;

const AddNewDepartment = ({
  modalType,
  handleCancel,
  currentPage,
  search,
  selectedData,

  onCreateDepartment,
  getSpecificDepartmentDetail,
  onGetSpecificDepartment,
  updateDepartmentRes,
  onUpdateDepartment,
  onGetDepartmentList,

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const [specificUsername, setSpecificUsername] = useState("");

  useEffect(() => {
    setLoading(true);
    const skip = currentPage === 1 ? 0 : ((currentPage - 1) * 9);
    onGetDepartmentList(skip, 9, "").then(() => {
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    })

    if (modalType === "edit-user") {

      onGetSpecificDepartment(selectedData && selectedData._id).then((res) => {
        setSpecificUsername(selectedData && selectedData.username);
        form.setFieldsValue({
          name: res && res.payload.name,
        })
      })

    }

  }, [])

  const handleChangeRoles = (value) => {
    setSelectedRole(value);
  };

  const onFinish = values => {
    setLoading(true);
    if (modalType === "add-user") {
      const data = {
        name: values.name,
      }
      onCreateDepartment(data).then((res) => {
        const skip = currentPage === 1 ? 0 : ((currentPage - 1) * 9);
        setLoading(false);
        onGetDepartmentList(skip, 9, search);
        handleCancel();
        message.success(res.payload.message);
      }).catch((res) => {
        const skip = currentPage === 1 ? 0 : ((currentPage - 1) * 9);
        setLoading(false);
        onGetDepartmentList(skip, 9, search);
      })
    }
    if (modalType === "edit-user") {
      const updateBody = {
        name: values.name
      }
      const userId = selectedData && selectedData._id;

      onUpdateDepartment(userId, updateBody).then((res) => {
        const skip = currentPage === 1 ? 0 : ((currentPage - 1) * 9);
        setLoading(false);
        onGetDepartmentList(skip, 9, search);
        handleCancel();
        message.success(res.payload.message);
      }).catch((res) => {
        const skip = currentPage === 1 ? 0 : ((currentPage - 1) * 9);
        setLoading(false);
        onGetDepartmentList(skip, 9, search);
      })

    }
  };

  return (
    <div className="license-edit-model">
      <h3>{modalType === "add-user" ? "Add New Department" : "Edit Department"}</h3>
      <Spin size="large" spinning={loading}>
        <Form
          name="add_new_user"
          onFinish={onFinish}
          form={form}
        >
          <div className="slection-asset-view">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span>DEPARTMENT NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="name"
                    className="full-width mr20 mb0"
                    rules={[
                      { required: true, message: 'Please enter the first name' }
                    ]}
                  >
                    <Input placeholder="Name" className="full-width" />
                  </Form.Item>
                </div>
              </div>
              
            </div>
          </div>
          <div className="formal-license-btn">
            <Button htmlType="submit" type="primary">
              {modalType === "add-user" ? "ADD NEW DEPARTMENT" : "DEPARTMENT UPDATE"}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  )
}

export default AddNewDepartment;