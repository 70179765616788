import { connect } from "react-redux";
import AddMessageComponent from "./../Components/ContentArea/ChatApp/AddMessage";
import { bindActionCreators } from "redux";

import { postSendMessage, addMessage, getAllMessage } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    postSendMessageRes: state.sendMessageReducer.postSendMessageRes,
    getAllTradingTaskRes: state.requestProposalReducer.getAllTradingTaskRes,
    getAllMessageRes: state.sendMessageReducer.getAllMessageRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPostSendMessage: bindActionCreators(postSendMessage, dispatch),
    onAddMessage: bindActionCreators(addMessage, dispatch),
    onGetAllMessage: bindActionCreators(getAllMessage, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMessageComponent);
