import React from "react";
import { Row, Col, Button, Skeleton } from "antd";
import { Link } from "react-router-dom";
import Placeholder from "../../../../../src/statics/images/placeholder.jpg";

const PremiumCataloguesGrid = ({
  data,
  highlights,
  logoImage,
  heroImage,
  showModal,
  setSelectedData,
}) => {
  const setSelection = (catalogue) => {
    setSelectedData(catalogue);
    showModal("add_to_selection");
  };

  return (
    <div className="p-cata-grid">
      <h2 className="highlight-mb">Highlights</h2>
      <Row className="mb5" gutter={16}>
        {highlights &&
          highlights.special_catalogues &&
          highlights.special_catalogues.map((item) => (
            <Col className="mb25" span={8} key={item._id}>
              <div className="new-items-grid">
                {item.special_catalogue_data.banner ? (
                  <div className="new-items-grid-img">
                    <div
                      className={"catalogue-bg"}
                      style={{
                        backgroundImage: `linear-gradient(to left, transparent 0%, black 75%), url(${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.special_catalogue_data.banner}/image)`,
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.special_catalogue_data.banner}/image`}
                        alt=""
                        width={"100%"}
                      />
                      <div className="new-items-grid-img-overlay"></div>
                      <div className="new-items-grid-img-text">
                        {item.special_catalogue_data.name}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Skeleton.Image />
                )}
                <div className="new-items-info">
                  <h3>{item.special_catalogue_data.name}</h3>
                  {item.special_catalogue_data.production_date ? (
                    <h4>
                      Production Year:{" "}
                      <span>{item.special_catalogue_data.production_date}</span>
                    </h4>
                  ) : (
                    <h4>--</h4>
                  )}
                  <div className="catalouge-btns">
                    <Link
                      to={{
                        pathname: `/catalogue-assets`,
                        state: {
                          organization: data,
                          catalogue: { ...item, highlights },
                        },
                      }}
                    >
                      <Button type="link" className="p0 border-0">
                        {" "}
                        View Assets
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))}

        {highlights &&
          highlights.assets &&
          highlights.assets.map((item) => {
            const newItem = { ...item, _id: item.asset };
            return (
              <Col className="mb25" span={8} key={item._id}>
                <div className="new-items-grid">
                  {/* <div className={"catalogue-bg"} style={{backgroundImage: `linear-gradient(to left, transparent 0%, black 75%), url(${item.preview_image}), linear-gradient(to left, transparent 0%, black 75%)`}}>
              <div className="new-items-grid-img-overlay"></div>
              </div> */}

                  {/* {item.asset_data.preview_image[0] ? <img src={item.asset_data.preview_image[0]} alt={item.asset_data.title.value} width={'100%'} /> : <Skeleton.Image />} */}
                  {item.asset_data.preview_image[0] ? (
                    <div className="new-items-grid-img">
                      <div
                        className={"catalogue-bg"}
                        style={{
                          backgroundImage: `url(${item.asset_data.preview_image[0]})`,
                        }}
                      >
                        <img
                          src={Placeholder}
                          alt={item.asset_data.title.value}
                          width={"100%"}
                        />
                      </div>
                    </div>
                  ) : (
                    <Skeleton.Image />
                  )}
                  <div className="new-items-info">
                    <h3>{item.asset_data.title.value}</h3>
                    {/* <h4>Production Year: <span>{asset_data.production_date}</span></h4> */}
                    {item.asset_data.production_date ? (
                      <h4>
                        Production Year:{" "}
                        <span>{item.asset_data.production_date}</span>
                      </h4>
                    ) : (
                      <h4>--</h4>
                    )}
                    <div className="catalouge-btns">
                      <Link to={`/inventory-search/${item.asset}`}>
                        {" "}
                        <Button type="link" className="p0 border-0">
                          {" "}
                          Details
                        </Button>
                      </Link>
                      <Button
                        type="link"
                        className="p0 border-0"
                        onClick={() => setSelection(newItem)}
                      >
                        {" "}
                        Add to Selection
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default PremiumCataloguesGrid;
