import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

import Banner1 from "../../../../statics/images/premium-cata-grid-1.png";
import Banner2 from "../../../../statics/images/asset-video.png";
import Banner3 from "../../../../statics/images/premium-cata-grid-3.svg";
import CataGrid4 from "../../../../statics/images/premium-cata-grid-1.png";

const GeneralCatalogue = ({ showModal }) => {
  return (
    <Fragment>
      <h2 className="catalog-title">General Catalogues</h2>
      <Row gutter={16}>
        <Col className="mb25" span={4}>
          <div className="new-catalogues-grid">
            <img src={Banner1} alt="Catalogues" />
            <div className="new-catalogues-info">
              <h3>Die Frau ohne Schatten</h3>
              <h4>
                Production Year: <span>2019</span>
              </h4>
              <div className="catalouge-btns">
                <Link to={`/inventory-search/qwertyui7654rffdeg`}>
                  {" "}
                  <Button type="link" className="p0 border-0">
                    {" "}
                    Detail
                  </Button>
                </Link>
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add_to_selection")}
                >
                  {" "}
                  Add to Selection
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col className="mb25" span={4}>
          <div className="new-catalogues-grid">
            <img src={Banner2} alt="Catalogues" />
            <div className="new-catalogues-info">
              <h3>Die Frau ohne Schatten</h3>
              <h4>
                Production Year: <span>2019</span>
              </h4>
              <div className="catalouge-btns">
                <Link to={`/inventory-search/qwertyui7654rffdeg`}>
                  {" "}
                  <Button type="link" className="p0 border-0">
                    {" "}
                    Detail
                  </Button>
                </Link>
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add_to_selection")}
                >
                  {" "}
                  Add to Selection
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col className="mb25" span={4}>
          <div className="new-catalogues-grid">
            <img src={Banner3} alt="Catalogues" />
            <div className="new-catalogues-info">
              <h3>Die Frau ohne Schatten</h3>
              <h4>
                Production Year: <span>2019</span>
              </h4>
              <div className="catalouge-btns">
                <Link to={`/inventory-search/qwertyui7654rffdeg`}>
                  {" "}
                  <Button type="link" className="p0 border-0">
                    {" "}
                    Detail
                  </Button>
                </Link>
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add_to_selection")}
                >
                  {" "}
                  Add to Selection
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col className="mb25" span={4}>
          <div className="new-catalogues-grid">
            <img src={Banner3} alt="Catalogues" />
            <div className="new-catalogues-info">
              <h3>Die Frau ohne Schatten</h3>
              <h4>
                Production Year: <span>2019</span>
              </h4>
              <div className="catalouge-btns">
                <Link to={`/inventory-search/qwertyui7654rffdeg`}>
                  {" "}
                  <Button type="link" className="p0 border-0">
                    {" "}
                    Detail
                  </Button>
                </Link>
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add_to_selection")}
                >
                  {" "}
                  Add to Selection
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col className="mb25" span={4}>
          <div className="new-catalogues-grid">
            <img src={CataGrid4} alt="Catalogues" />
            <div className="new-catalogues-info">
              <h3>Die Frau ohne Schatten</h3>
              <h4>
                Production Year: <span>2019</span>
              </h4>
              <div className="catalouge-btns">
                <Link to={`/inventory-search/qwertyui7654rffdeg`}>
                  {" "}
                  <Button type="link" className="p0 border-0">
                    {" "}
                    Detail
                  </Button>
                </Link>
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add_to_selection")}
                >
                  {" "}
                  Add to Selection
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col className="mb25" span={4}>
          <div className="new-catalogues-grid">
            <img src={CataGrid4} alt="Catalogues" />
            <div className="new-catalogues-info">
              <h3>Die Frau ohne Schatten</h3>
              <h4>
                Production Year: <span>2019</span>
              </h4>
              <div className="catalouge-btns">
                <Link to={`/inventory-search/qwertyui7654rffdeg`}>
                  {" "}
                  <Button type="link" className="p0 border-0">
                    {" "}
                    Detail
                  </Button>
                </Link>
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add_to_selection")}
                >
                  {" "}
                  Add to Selection
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default GeneralCatalogue;
