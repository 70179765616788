import React from "react";
import InventoryLayoutButtons from "./ListGridButtons";
import GearButton from "./../../../../../Common/GearButton";
import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./../../../../../Common/SearchFilterDropdown";
import { Input } from "antd";
import { searchIcon } from "../../../../../Common/Icons";
import { getRolesPermissions } from "../../../../../Common/reUseFunctions";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onHidePopover,
  visiblePopover,
  showTableEditor,
  setSearch,
  onFinish,
  changeInventory,
  setInventorySearch,
  mainUserRes,
}) => {
  return (
    <div className="inventory-bottom-filter flex-end-imp">
      {/* <InventorySwitchSec changeInventory={changeInventory} /> */}
      {getRolesPermissions(mainUserRes, "inventory.list") === false ? (
        <div className="d-flex">
          <div className="inventory-search-bar">
            <Input
              placeholder="Search"
              prefix={searchIcon}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* <InventorySearchDropDown
          onChangeLayout={onChangeLayout}
          changeLayout={changeLayout}
          onFinish={onFinish}
          onHidePopover={onHidePopover}
          visiblePopover={visiblePopover}
          showTableEditor={showTableEditor}
          setSearch={setSearch}
        /> */}
          <div className="layout-btn d-flex">
            <InventoryLayoutButtons
              onChangeLayout={onChangeLayout}
              changeLayout={changeLayout}
              showTableEditor={showTableEditor}
            />
            {/* <GearButton
            changeLayout={changeLayout}
            showTableEditor={showTableEditor}
          /> */}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySearchDropdown;
