import React from 'react';

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory">
      <div><h3>License Management</h3></div>
    </div>
  )
}

export default TopTitleSection;