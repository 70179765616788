import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";

import Placeholder from "../../../../statics/images/placeholder.jpg";

const AddCataloguesAssets = ({ showModal }) => {
  return (
    <div className="plus-icon-maintain">
      <div className="add-asset-sec-block">
        <div className="plusoutline-box">
          <PlusOutlined />
          <p>Add Catalogue or Asset</p>
        </div>
        <Row className="promotional-info" gutter={16}>
          <Col span={32}>
            <Button onClick={() => showModal("special_catalogues")} block>
              Add Catalogues
            </Button>
          </Col>
          <Col span={32}>
            <Button onClick={() => showModal("assets")} block>
              Add Assets
            </Button>
          </Col>
        </Row>
      </div>
      <img src={Placeholder} alt="placeholder" />
    </div>
  );
};

export default AddCataloguesAssets;
