import React from 'react';
import TopTitleSection from "./TopTitleSection";

const HeaderFilter = ({ setSearchResult }) => {

  const onFinish = e => {
    setSearchResult(e.target.value)
  };
  return (
    <div className="catalogue-filter">
      <TopTitleSection onFinish={onFinish} />
    </div>
  )
}

export default HeaderFilter;