import React from "react";
import { Button } from "antd";
import { pluseFilledIcon } from "./../../../../Common/Icons";
import { Link } from "react-router-dom";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const TopTitleSection = ({ onShowModal, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Special Catalogues</h3>
      </div>
      <div>
        {getRolesPermissions(mainUserRes, "special-catalogue.create") ===
        false ? (
          <p className="add-selection-btn add-new-selection">
            {/* <Link to="/special-catalogues-detail"> */}
            <Button
              type="default"
              className="p0 border-0"
              onClick={() =>
                onShowModal({ visible: true, type: "new-catalogue" })
              }
            >
              {pluseFilledIcon}
              ADD NEW CATALOGUE
            </Button>
            {/* </Link> */}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TopTitleSection;
