import React, { useState } from "react";
import Filter from "./Filter";
import List from "./List";

const organizationList = [
  {
    name: "Organization name 5",
    id: "4",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 6",
    id: "5",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 7",
    id: "6",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 1",
    id: "1",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 2",
    id: "2",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 4",
    id: "3",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 8",
    id: "7",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 9",
    id: "8",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 10",
    id: "9",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 11",
    id: "10",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 12",
    id: "11",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 13",
    id: "12",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 14",
    id: "13",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 15",
    id: "14",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
  {
    name: "Organization name 16",
    id: "15",
    contact: "micheal-white@bbc.co.uk",
    updated_at: "2020-06-05T14:20:51.236Z",
    detail: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
  },
];

const Organization = () => {
  const [search, setSearch] = useState("");

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <Filter onSearch={onSearch} search={search} />
      <List data={organizationList} />
    </div>
  );
};

export default Organization;
