import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InventoryComp from '../Components/ContentArea/DataMaintenance';

import {
  getAssetsList,
  getAssetDetail,
  createAsset,
  updateAsset,
  deleteAsset,
  getSpecificUserDetail,
  mianUserDetail
} from '../store/Actions';
 
const mapStateToProps = state => {
  return {
    assetsList: state.assetReducer.assetsList,
    assetDetail: state.assetReducer.assetDetail,
    createAsset: state.assetReducer.createAsset,
    updateAsset: state.assetReducer.updateAsset,
    deleteAsset: state.assetReducer.deleteAsset,
    sessionRes: state.userReducer.sessionRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    mainUserRes: state.userReducer.mainUserRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAssetsList: bindActionCreators(getAssetsList, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onCreateAsset: bindActionCreators(createAsset, dispatch),
    onUpdateAsset: bindActionCreators(updateAsset, dispatch),
    onDeleteAsset: bindActionCreators(deleteAsset, dispatch),
    onGetSpecificUserDetail: bindActionCreators(getSpecificUserDetail, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryComp);

