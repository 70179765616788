import React from "react";


export const dashboardIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <defs>
      <linearGradient
        id="a"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      className="a"
      d="M4.5,13.389h7.111V4.5H4.5Zm0,7.111h7.111V15.167H4.5Zm8.889,0H20.5V11.611H13.389Zm0-16V9.833H20.5V4.5Z"
      transform="translate(-4.5 -4.5)"
    />
  </svg>
);

export const startIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.308 17">
    <defs>
      <linearGradient
        id="a"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      className="a"
      d="M19.863,9.26H13.852L12.025,3.808a.662.662,0,0,0-1.242,0L8.956,9.26H2.9a.656.656,0,0,0-.654.654.481.481,0,0,0,.012.11.628.628,0,0,0,.274.462l4.941,3.482-1.9,5.513a.656.656,0,0,0,.225.736.632.632,0,0,0,.368.159.8.8,0,0,0,.409-.147L11.4,16.791l4.822,3.437a.766.766,0,0,0,.409.147A.587.587,0,0,0,17,20.216a.648.648,0,0,0,.225-.736l-1.9-5.513,4.9-3.514.119-.1a.686.686,0,0,0,.212-.437A.692.692,0,0,0,19.863,9.26Z"
      transform="translate(-2.25 -3.375)"
    />
  </svg>
);

export const shopIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.248 16">
    <defs></defs>
    <path
      className="a"
      d="M18.815,3.706,16.787.469A1,1,0,0,0,15.94,0H3.315a1,1,0,0,0-.847.469L.44,3.706A2.788,2.788,0,0,0,2.277,7.969,3.248,3.248,0,0,0,2.705,8,3.086,3.086,0,0,0,5.012,6.963a3.088,3.088,0,0,0,4.612,0,3.088,3.088,0,0,0,4.613,0A3.1,3.1,0,0,0,16.543,8a3.213,3.213,0,0,0,.428-.028A2.785,2.785,0,0,0,18.815,3.706ZM16.549,9a3.965,3.965,0,0,1-.922-.119V12h-12V8.881A4.14,4.14,0,0,1,2.705,9a4.217,4.217,0,0,1-.562-.037A3.93,3.93,0,0,1,1.63,8.85V15a1,1,0,0,0,1,1h14a1,1,0,0,0,1-1V8.85a3.191,3.191,0,0,1-.513.113A4.346,4.346,0,0,1,16.549,9Z"
      transform="translate(-0.004)"
    />
  </svg>
);

export const gearIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.509 18">
    <defs>
      <linearGradient
        id="a"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      className="a"
      d="M18.849,12.882a6.206,6.206,0,0,0,0-1.764l1.9-1.485a.454.454,0,0,0,.108-.576l-1.8-3.114a.452.452,0,0,0-.549-.2l-2.241.9a6.576,6.576,0,0,0-1.521-.882L14.4,3.378A.439.439,0,0,0,13.962,3h-3.6a.439.439,0,0,0-.441.378L9.579,5.763a6.915,6.915,0,0,0-1.521.882l-2.241-.9a.439.439,0,0,0-.549.2l-1.8,3.114a.444.444,0,0,0,.108.576l1.9,1.485A7.137,7.137,0,0,0,5.412,12a7.137,7.137,0,0,0,.063.882l-1.9,1.485a.454.454,0,0,0-.108.576l1.8,3.114a.452.452,0,0,0,.549.2l2.241-.9a6.576,6.576,0,0,0,1.521.882l.342,2.385a.439.439,0,0,0,.441.378h3.6a.439.439,0,0,0,.441-.378l.342-2.385a6.915,6.915,0,0,0,1.521-.882l2.241.9a.439.439,0,0,0,.549-.2l1.8-3.114a.454.454,0,0,0-.108-.576l-1.9-1.485ZM12.162,15.15A3.15,3.15,0,1,1,15.312,12,3.153,3.153,0,0,1,12.162,15.15Z"
      transform="translate(-3.406 -3)"
    />
  </svg>
);

export const administrationIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <defs>
      <linearGradient
        id="a"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <g transform="translate(5 -4)">
      <g transform="translate(-5 4)">
        <path
          className="a"
          d="M11.857,2a3.857,3.857,0,1,0,3.857,3.857A3.867,3.867,0,0,0,11.857,2Z"
          transform="translate(-4.143 -2)"
        />
        <path
          className="a"
          d="M7.786,16A5.787,5.787,0,0,0,2,21.786v1.286a.643.643,0,0,0,.643.643H10.7a5.755,5.755,0,0,1,1.166-7.7c-.074,0-.146-.011-.221-.011Z"
          transform="translate(-2 -7)"
        />
        <path
          className="a"
          d="M20.491,16a.644.644,0,0,0-.637.652v.7a3.2,3.2,0,0,0-1.127.473l-.493-.493a.622.622,0,0,0-.486-.206.649.649,0,0,0-.423,1.115l.5.5a3.2,3.2,0,0,0-.471,1.127h-.7a.643.643,0,1,0,0,1.286h.7a3.2,3.2,0,0,0,.47,1.117l-.5.5a.643.643,0,1,0,.909.909l.5-.5a3.2,3.2,0,0,0,1.125.472v.7a.643.643,0,1,0,1.286,0v-.7a3.2,3.2,0,0,0,1.124-.471l.5.5a.643.643,0,1,0,.909-.909l-.5-.5a3.2,3.2,0,0,0,.472-1.124h.7a.643.643,0,1,0,0-1.286h-.7a3.2,3.2,0,0,0-.473-1.134l.5-.5a.644.644,0,0,0-.457-1.1h0a.64.64,0,0,0-.449.195l-.5.5a3.2,3.2,0,0,0-1.126-.472v-.7A.641.641,0,0,0,20.494,16Zm.009,2.571A1.928,1.928,0,1,1,18.571,20.5,1.919,1.919,0,0,1,20.5,18.571Z"
          transform="translate(-7 -7)"
        />
      </g>
    </g>
  </svg>
);

export const cartBasketIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.86 15.447">
    <defs>
      <linearGradient
        id="a"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      className="a"
      d="M19.86,8.594v.552a.827.827,0,0,1-.828.827h-.276l-.9,6.3A1.655,1.655,0,0,1,16.218,17.7H3.642A1.655,1.655,0,0,1,2,16.276l-.9-6.3H.828A.827.827,0,0,1,0,9.146V8.594a.827.827,0,0,1,.828-.827H3.149L6.831,2.7A1.1,1.1,0,1,1,8.616,4L5.878,7.767h8.1L11.244,4a1.1,1.1,0,0,1,1.785-1.3l3.682,5.062h2.322A.827.827,0,0,1,19.86,8.594Zm-9.1,6.068V10.8a.827.827,0,1,0-1.655,0v3.862a.827.827,0,1,0,1.655,0Zm3.862,0V10.8a.827.827,0,1,0-1.655,0v3.862a.827.827,0,0,0,1.655,0Zm-7.723,0V10.8a.828.828,0,1,0-1.655,0v3.862a.828.828,0,0,0,1.655,0Z"
      transform="translate(0 -2.25)"
    />
  </svg>
);

export const gridIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.222 22.222">
    <path
      className="a"
      d="M30.962,24.328v6.634H24.328V24.328h6.634m.733-1.628h-8.14a.86.86,0,0,0-.855.855v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855v-8.14a.812.812,0,0,0-.855-.855Z"
      transform="translate(-22.7 -22.7)"
    />
    <path
      className="a"
      d="M61.321,24.328v6.634H54.687V24.328h6.634m.773-1.628h-8.14a.86.86,0,0,0-.855.855v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855v-8.14a.835.835,0,0,0-.855-.855Z"
      transform="translate(-40.727 -22.7)"
    />
    <path
      className="a"
      d="M30.962,54.628v6.634H24.328V54.628h6.634M31.695,53h-8.14a.86.86,0,0,0-.855.855v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855v-8.14A.834.834,0,0,0,31.695,53Z"
      transform="translate(-22.7 -40.668)"
    />
    <path
      className="a"
      d="M61.262,54.628v6.634H54.628V54.628h6.634M62.035,53H53.9a.9.9,0,0,0-.9.9v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855V53.9a.813.813,0,0,0-.814-.9Z"
      transform="translate(-40.668 -40.668)"
    />
  </svg>
);

export const listIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.241 22.722">
    <g transform="translate(0.25 0.25)">
      <g transform="translate(7.634 10.467)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M141.91,161.8H130.1a.647.647,0,0,0,0,1.294H141.91a.647.647,0,0,0,0-1.294Z"
            transform="translate(-129.45 -161.8)"
          />
        </g>
      </g>
      <g transform="translate(0 8.106)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M14.458,125.3a3.008,3.008,0,1,0,3.008,3.008A3.007,3.007,0,0,0,14.458,125.3Zm0,4.723a1.714,1.714,0,1,1,1.714-1.714A1.713,1.713,0,0,1,14.458,130.023Z"
            transform="translate(-11.45 -125.3)"
          />
        </g>
      </g>
      <g transform="translate(7.634 2.381)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M141.91,36.8H130.1a.647.647,0,1,0,0,1.294H141.91a.647.647,0,1,0,0-1.294Z"
            transform="translate(-129.45 -36.8)"
          />
        </g>
      </g>
      <g transform="translate(0)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M14.458,0a3.008,3.008,0,1,0,3.008,3.008A3.007,3.007,0,0,0,14.458,0Zm0,4.729a1.714,1.714,0,1,1,1.714-1.714A1.713,1.713,0,0,1,14.458,4.729Z"
            transform="translate(-11.45)"
          />
        </g>
      </g>
      <g transform="translate(7.634 18.554)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M141.91,286.8H130.1a.647.647,0,1,0,0,1.294H141.91a.647.647,0,1,0,0-1.294Z"
            transform="translate(-129.45 -286.8)"
          />
        </g>
      </g>
      <g transform="translate(0 16.206)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M14.458,250.5a3.008,3.008,0,1,0,3.008,3.008A3.007,3.007,0,0,0,14.458,250.5Zm0,4.729a1.714,1.714,0,1,1,1.714-1.714A1.713,1.713,0,0,1,14.458,255.229Z"
            transform="translate(-11.45 -250.5)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const settingIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
    <g transform="translate(0.75 0.75)">
      <path
        className="a"
        d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z"
        transform="translate(-8 -8)"
      />
      <path
        className="a"
        d="M18.227,14.227a1.5,1.5,0,0,0,.3,1.655l.055.055a1.819,1.819,0,1,1-2.573,2.573l-.055-.055a1.512,1.512,0,0,0-2.564,1.073v.155a1.818,1.818,0,1,1-3.636,0V19.6a1.5,1.5,0,0,0-.982-1.373,1.5,1.5,0,0,0-1.655.3l-.055.055a1.819,1.819,0,1,1-2.573-2.573l.055-.055a1.512,1.512,0,0,0-1.073-2.564H3.318a1.818,1.818,0,0,1,0-3.636H3.4a1.5,1.5,0,0,0,1.373-.982,1.5,1.5,0,0,0-.3-1.655l-.055-.055A1.819,1.819,0,1,1,6.991,4.491l.055.055a1.5,1.5,0,0,0,1.655.3h.073a1.5,1.5,0,0,0,.909-1.373V3.318a1.818,1.818,0,0,1,3.636,0V3.4a1.512,1.512,0,0,0,2.564,1.073l.055-.055a1.819,1.819,0,1,1,2.573,2.573l-.055.055a1.5,1.5,0,0,0-.3,1.655v.073a1.5,1.5,0,0,0,1.373.909h.155a1.818,1.818,0,0,1,0,3.636H19.6a1.5,1.5,0,0,0-1.373.909Z"
        transform="translate(-1.5 -1.5)"
      />
    </g>
  </svg>
);

export const notificationIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.093 23.88">
    <g transform="translate(-6.761 -3.93)">
      <path
        className="a"
        d="M17.481,31.5c1.856,0,2.871-1.313,2.871-3.163H14.6C14.6,30.186,15.619,31.5,17.481,31.5Z"
        transform="translate(-1.185 -3.689)"
      />
      <path
        className="a"
        d="M25.612,21.615c-.919-1.212-2.728-1.922-2.728-7.347,0-5.569-2.459-7.807-4.751-8.344-.215-.054-.37-.125-.37-.352V5.4a1.456,1.456,0,1,0-2.913,0v.173c0,.221-.155.3-.37.352-2.3.543-4.751,2.775-4.751,8.344,0,5.425-1.808,6.13-2.728,7.347a1.185,1.185,0,0,0,.949,1.9H24.669A1.185,1.185,0,0,0,25.612,21.615Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export const ListViewIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.241 22.722">
    <g transform="translate(0.25 0.25)">
      <g transform="translate(7.634 10.467)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M141.91,161.8H130.1a.647.647,0,0,0,0,1.294H141.91a.647.647,0,0,0,0-1.294Z"
            transform="translate(-129.45 -161.8)"
          />
        </g>
      </g>
      <g transform="translate(0 8.106)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M14.458,125.3a3.008,3.008,0,1,0,3.008,3.008A3.007,3.007,0,0,0,14.458,125.3Zm0,4.723a1.714,1.714,0,1,1,1.714-1.714A1.713,1.713,0,0,1,14.458,130.023Z"
            transform="translate(-11.45 -125.3)"
          />
        </g>
      </g>
      <g transform="translate(7.634 2.381)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M141.91,36.8H130.1a.647.647,0,1,0,0,1.294H141.91a.647.647,0,1,0,0-1.294Z"
            transform="translate(-129.45 -36.8)"
          />
        </g>
      </g>
      <g transform="translate(0)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M14.458,0a3.008,3.008,0,1,0,3.008,3.008A3.007,3.007,0,0,0,14.458,0Zm0,4.729a1.714,1.714,0,1,1,1.714-1.714A1.713,1.713,0,0,1,14.458,4.729Z"
            transform="translate(-11.45)"
          />
        </g>
      </g>
      <g transform="translate(7.634 18.554)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M141.91,286.8H130.1a.647.647,0,1,0,0,1.294H141.91a.647.647,0,1,0,0-1.294Z"
            transform="translate(-129.45 -286.8)"
          />
        </g>
      </g>
      <g transform="translate(0 16.206)">
        <g transform="translate(0)">
          <path
            className="a"
            d="M14.458,250.5a3.008,3.008,0,1,0,3.008,3.008A3.007,3.007,0,0,0,14.458,250.5Zm0,4.729a1.714,1.714,0,1,1,1.714-1.714A1.713,1.713,0,0,1,14.458,255.229Z"
            transform="translate(-11.45 -250.5)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const listGridIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.222 22.222">
    <path
      className="a"
      d="M30.962,24.328v6.634H24.328V24.328h6.634m.733-1.628h-8.14a.86.86,0,0,0-.855.855v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855v-8.14a.812.812,0,0,0-.855-.855Z"
      transform="translate(-22.7 -22.7)"
    />
    <path
      className="a"
      d="M61.321,24.328v6.634H54.687V24.328h6.634m.773-1.628h-8.14a.86.86,0,0,0-.855.855v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855v-8.14a.835.835,0,0,0-.855-.855Z"
      transform="translate(-40.727 -22.7)"
    />
    <path
      className="a"
      d="M30.962,54.628v6.634H24.328V54.628h6.634M31.695,53h-8.14a.86.86,0,0,0-.855.855v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855v-8.14A.834.834,0,0,0,31.695,53Z"
      transform="translate(-22.7 -40.668)"
    />
    <path
      className="a"
      d="M61.262,54.628v6.634H54.628V54.628h6.634M62.035,53H53.9a.9.9,0,0,0-.9.9v8.14a.86.86,0,0,0,.855.855h8.14a.86.86,0,0,0,.855-.855V53.9a.813.813,0,0,0-.814-.9Z"
      transform="translate(-40.668 -40.668)"
    />
  </svg>
);

export const settingIconTable = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.5 21.5">
    <g transform="translate(0.75 0.75)">
      <path
        className="a"
        d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z"
        transform="translate(-8 -8)"
      />
      <path
        className="a"
        d="M18.227,14.227a1.5,1.5,0,0,0,.3,1.655l.055.055a1.819,1.819,0,1,1-2.573,2.573l-.055-.055a1.512,1.512,0,0,0-2.564,1.073v.155a1.818,1.818,0,1,1-3.636,0V19.6a1.5,1.5,0,0,0-.982-1.373,1.5,1.5,0,0,0-1.655.3l-.055.055a1.819,1.819,0,1,1-2.573-2.573l.055-.055a1.512,1.512,0,0,0-1.073-2.564H3.318a1.818,1.818,0,0,1,0-3.636H3.4a1.5,1.5,0,0,0,1.373-.982,1.5,1.5,0,0,0-.3-1.655l-.055-.055A1.819,1.819,0,1,1,6.991,4.491l.055.055a1.5,1.5,0,0,0,1.655.3h.073a1.5,1.5,0,0,0,.909-1.373V3.318a1.818,1.818,0,0,1,3.636,0V3.4a1.512,1.512,0,0,0,2.564,1.073l.055-.055a1.819,1.819,0,1,1,2.573,2.573l-.055.055a1.5,1.5,0,0,0-.3,1.655v.073a1.5,1.5,0,0,0,1.373.909h.155a1.818,1.818,0,0,1,0,3.636H19.6a1.5,1.5,0,0,0-1.373.909Z"
        transform="translate(-1.5 -1.5)"
      />
    </g>
  </svg>
);

export const closeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.002 8">
    <path
      className="a"
      d="M16.234,15.289l2.858-2.858a.67.67,0,0,0-.947-.947l-2.858,2.858-2.858-2.858a.67.67,0,1,0-.947.947l2.858,2.858-2.858,2.858a.67.67,0,0,0,.947.947l2.858-2.858,2.858,2.858a.67.67,0,0,0,.947-.947Z"
      transform="translate(-11.285 -11.289)"
    />
  </svg>
);

export const pluseIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.591 10.591">
    <g transform="translate(0.75 0.75)">
      <path class="a" d="M18,7.5v9.091" transform="translate(-13.455 -7.5)" />
      <path class="a" d="M7.5,18h9.091" transform="translate(-7.5 -13.455)" />
    </g>
  </svg>
);

export const pitchingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="18.703"
    height="21"
    viewBox="0 0 18.703 21"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        y1="0.5"
        x2="1"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      id="Path_147"
      data-name="Path 147"
      d="M27.717,10.841A9.347,9.347,0,1,0,13.646,21.11l-2.459,3.757,9.859-2.6A9.343,9.343,0,0,0,27.717,10.841ZM22.708,12.3l-3.119,3.233L17.2,13.915l-2.732,3.119-1.321-1.161,3.734-4.28,2.459,1.662,1.821-1.913-1.389-.865,4.121-1.412.273,4.144Z"
      transform="translate(-9.327 -3.867)"
      fill="#ff2d6c"
    />
  </svg>
);

export const bigPluse = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 42 42"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <g id="noun_add_2721400" transform="translate(-7 -7)">
      <g id="Group_395" data-name="Group 395" transform="translate(7 7)">
        <path
          id="Path_145"
          data-name="Path 145"
          d="M7,28A21,21,0,1,1,28,49,21,21,0,0,1,7,28ZM28,15.483h0a2.959,2.959,0,0,1,2.95,2.95V25.05h6.617A2.958,2.958,0,0,1,40.517,28h0a2.959,2.959,0,0,1-2.95,2.95H30.951v6.617A2.959,2.959,0,0,1,28,40.517h0a2.959,2.959,0,0,1-2.95-2.95V30.951H18.433A2.959,2.959,0,0,1,15.483,28h0a2.959,2.959,0,0,1,2.95-2.95H25.05V18.433A2.959,2.959,0,0,1,28,15.483Z"
          transform="translate(-7 -7)"
          fillRule="evenodd"
          fill="#ff2d6c"
        />
      </g>
    </g>
  </svg>
);

export const importIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 39.63 46.48"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <g id="Group_391" data-name="Group 391" transform="translate(-9.5 -2.5)">
      <path
        id="Path_139"
        data-name="Path 139"
        d="M38.318,21.141v-6.9a4.01,4.01,0,0,0-1.174-2.789L29.217,3.625A3.848,3.848,0,0,0,26.477,2.5H14.539A5.032,5.032,0,0,0,9.5,7.539V33.128a5.032,5.032,0,0,0,5.039,5.039h6.7A14.138,14.138,0,1,0,38.318,21.141ZM13.023,33.128V7.539a1.524,1.524,0,0,1,1.517-1.517H25.352v6.654a2.84,2.84,0,0,0,2.838,2.838h6.654V20.75a14.163,14.163,0,0,0-13.993,13.9H14.49A1.515,1.515,0,0,1,13.023,33.128ZM34.991,45.506A10.617,10.617,0,1,1,45.608,34.889,10.639,10.639,0,0,1,34.991,45.506Z"
        fill="#ff2d6c"
      />
      <path
        id="Path_140"
        data-name="Path 140"
        d="M59.57,60.784H52.72l1.174-1.174a1.73,1.73,0,0,0-2.446-2.446l-4.159,4.159a1.7,1.7,0,0,0-.489,1.223,1.83,1.83,0,0,0,.489,1.223l4.159,4.159a1.73,1.73,0,0,0,2.446-2.446l-1.223-1.174h6.9a1.761,1.761,0,0,0,0-3.523Z"
        transform="translate(-19.05 -27.656)"
        fill="#ff2d6c"
      />
    </g>
  </svg>
);

export const syncIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 55.789 56.892"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <g id="noun_Sync_5264" transform="translate(40.929 -11.125) rotate(60)">
      <g
        id="Group_394"
        data-name="Group 394"
        transform="translate(6.511 5.887)"
      >
        <g id="Group_392" data-name="Group 392">
          <path
            id="Path_141"
            data-name="Path 141"
            d="M8.049,42.424l5.271-2.236s-4.862-9.847,5.3-17.462c0,0,6.35-4.345,13.314-1.381L34.1,16.036S23.525,11.6,14.645,18.505C14.645,18.5,2.268,26.685,8.049,42.424Z"
            transform="translate(-6.511 -9.549)"
            fill="#ff2d6c"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M50.332,5.887,43.409,22.872,55.362,17.84Z"
            transform="translate(-21.957 -5.887)"
            fill="#ff2d6c"
          />
        </g>
        <g
          id="Group_393"
          data-name="Group 393"
          transform="translate(9.07 17.508)"
        >
          <path
            id="Path_143"
            data-name="Path 143"
            d="M58.179,36l-5.272,2.23s4.857,9.849-5.307,17.46c0,0-6.355,4.344-13.316,1.379l-2.164,5.3s10.57,4.441,19.454-2.458C51.574,59.916,63.954,51.74,58.179,36Z"
            transform="translate(-26.302 -36.001)"
            fill="#ff2d6c"
          />
          <path
            id="Path_144"
            data-name="Path 144"
            d="M27.141,80.3l6.927-16.984L22.112,68.341Z"
            transform="translate(-22.112 -47.434)"
            fill="#ff2d6c"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const syncIconDisabled = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 55.789 56.892"
  >
    <defs>
      <linearGradient
        id="linear-gradient-disabled"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#999999" />
        <stop offset="1" stopColor="#666666" />
      </linearGradient>
    </defs>
    <g id="noun_Sync_5264" transform="translate(40.929 -11.125) rotate(60)">
      <g
        id="Group_394"
        data-name="Group 394"
        transform="translate(6.511 5.887)"
      >
        <g id="Group_392" data-name="Group 392">
          <path
            id="Path_141"
            data-name="Path 141"
            d="M8.049,42.424l5.271-2.236s-4.862-9.847,5.3-17.462c0,0,6.35-4.345,13.314-1.381L34.1,16.036S23.525,11.6,14.645,18.505C14.645,18.5,2.268,26.685,8.049,42.424Z"
            transform="translate(-6.511 -9.549)"
            fill="url(#linear-gradient-disabled)"
          />
          <path
            id="Path_142"
            data-name="Path 142"
            d="M50.332,5.887,43.409,22.872,55.362,17.84Z"
            transform="translate(-21.957 -5.887)"
            fill="url(#linear-gradient-disabled)"
          />
        </g>
        <g
          id="Group_393"
          data-name="Group 393"
          transform="translate(9.07 17.508)"
        >
          <path
            id="Path_143"
            data-name="Path 143"
            d="M58.179,36l-5.272,2.23s4.857,9.849-5.307,17.46c0,0-6.355,4.344-13.316,1.379l-2.164,5.3s10.57,4.441,19.454-2.458C51.574,59.916,63.954,51.74,58.179,36Z"
            transform="translate(-26.302 -36.001)"
            fill="url(#linear-gradient-disabled)"
          />
          <path
            id="Path_144"
            data-name="Path 144"
            d="M27.141,80.3l6.927-16.984L22.112,68.341Z"
            transform="translate(-22.112 -47.434)"
            fill="url(#linear-gradient-disabled)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const pluseFilledIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <g
      id="Group_233"
      data-name="Group 233"
      transform="translate(-271.5 -545.5)"
    >
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="8"
        cy="8"
        r="8"
        transform="translate(271.5 545.5)"
        fill="#ff2d6c"
      />
      <g id="plus_icon" transform="translate(275.864 549.864)">
        <path
          id="Path_5"
          data-name="Path 5"
          d="M18,7.5v7.273"
          transform="translate(-14.364 -7.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M7.5,18h7.273"
          transform="translate(-7.5 -14.364)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </g>
  </svg>
);

export const filledLable = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    id="Component_35_1"
    data-name="Component 35 – 1"
    viewBox="0 0 118 32"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#ae2150" />
      </linearGradient>
    </defs>
    <path
      id="Path_99"
      data-name="Path 99"
      d="M0,0H118L107.369,10.917,118,22H0Z"
      fill="#ff2d6c"
    />
    <path
      id="Path_98"
      data-name="Path 98"
      d="M0,0H10L0,10Z"
      transform="translate(10 22) rotate(90)"
      fill="url(#linear-gradient-2)"
    />
  </svg>
);

export const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.811"
    height="19.811"
    viewBox="0 0 19.811 19.811"
  >
    <g
      id="Icon_feather-search"
      data-name="Icon feather-search"
      transform="translate(0.75 0.75)"
    >
      <path
        id="Path_3"
        data-name="Path 3"
        d="M20.5,12.5a8,8,0,1,1-8-8A8,8,0,0,1,20.5,12.5Z"
        transform="translate(-4.5 -4.5)"
        fill="none"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M29.325,29.325l-4.35-4.35"
        transform="translate(-11.325 -11.325)"
        fill="none"
        stroke="#888"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export const exportIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.649"
    height="12.59"
    viewBox="0 0 14.649 12.59"
  >
    <g id="download" transform="translate(12.399 0.25) rotate(90)">
      <path
        id="Path_60"
        data-name="Path 60"
        d="M106.021,6.906l2.445,2.3a.311.311,0,0,0,.426,0l2.445-2.3a.311.311,0,1,0-.425-.453L108.989,8.26V.311a.311.311,0,1,0-.621,0V8.26l-1.921-1.806a.311.311,0,1,0-.425.453Zm0,0"
        transform="translate(-102.634 -2)"
        fill="#292929"
        stroke="#292929"
        strokeWidth="0.5"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M10.537,74.3H8.962a.311.311,0,0,0,0,.621h1.575a.933.933,0,0,1,.932.932v7.32a.933.933,0,0,1-.932.932H1.553a.933.933,0,0,1-.932-.932v-7.32a.933.933,0,0,1,.932-.932H3.128a.311.311,0,0,0,0-.621H1.553A1.555,1.555,0,0,0,0,75.854v7.32a1.555,1.555,0,0,0,1.553,1.553h8.984a1.555,1.555,0,0,0,1.553-1.553v-7.32A1.555,1.555,0,0,0,10.537,74.3Zm0,0"
        transform="translate(0 -72.578)"
        fill="#292929"
        stroke="#292929"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export const importSmallIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.385 12.59">
    <g id="download" transform="translate(12.106 0.25) rotate(90)">
      <path
        id="Path_60"
        data-name="Path 60"
        d="M.1,6.906,2.542,9.2a.311.311,0,0,0,.426,0l2.445-2.3a.311.311,0,1,0-.425-.453L3.066,8.26V.311a.311.311,0,1,0-.621,0V8.26L.523,6.454A.311.311,0,1,0,.1,6.907Zm0,0"
        transform="translate(8.8 6.289) rotate(180)"
        fill="#292929"
        stroke="#292929"
        strokeWidth="0.5"
      />
      <path
        id="Path_61"
        data-name="Path 61"
        d="M10.537,74.3H8.962a.311.311,0,0,0,0,.621h1.575a.933.933,0,0,1,.932.932v7.32a.933.933,0,0,1-.932.932H1.553a.933.933,0,0,1-.932-.932v-7.32a.933.933,0,0,1,.932-.932H3.128a.311.311,0,0,0,0-.621H1.553A1.555,1.555,0,0,0,0,75.854v7.32a1.555,1.555,0,0,0,1.553,1.553h8.984a1.555,1.555,0,0,0,1.553-1.553v-7.32A1.555,1.555,0,0,0,10.537,74.3Zm0,0"
        transform="translate(0 -72.871)"
        fill="#292929"
        stroke="#292929"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export const helpIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      id="Icon_ionic-ios-help-circle"
      data-name="Icon ionic-ios-help-circle"
      d="M15.375,3.375a12,12,0,1,0,12,12A12,12,0,0,0,15.375,3.375Zm-.248,17.538a1.19,1.19,0,1,1,1.24-1.188A1.2,1.2,0,0,1,15.127,20.913Zm2.319-5.59c-1,.583-1.344,1.01-1.344,1.748v.456h-2l-.017-.5a2.4,2.4,0,0,1,1.362-2.538c.975-.583,1.385-.952,1.385-1.667a1.4,1.4,0,0,0-1.552-1.24A1.459,1.459,0,0,0,13.731,13H11.683c.04-1.858,1.413-3.173,3.733-3.173,2.163,0,3.652,1.2,3.652,2.925A2.824,2.824,0,0,1,17.446,15.323Z"
      transform="translate(-3.375 -3.375)"
      fill="#292929"
    />
  </svg>
);

export const phoneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path
      id="Icon_awesome-phone-alt"
      data-name="Icon awesome-phone-alt"
      d="M13.6,9.893,10.538,8.581a.656.656,0,0,0-.766.189L8.416,10.427A10.135,10.135,0,0,1,3.571,5.581L5.228,4.225a.655.655,0,0,0,.189-.766L4.1.4a.661.661,0,0,0-.752-.38L.509.673A.656.656,0,0,0,0,1.313,12.686,12.686,0,0,0,12.688,14a.656.656,0,0,0,.64-.509l.656-2.844a.664.664,0,0,0-.383-.755Z"
      transform="translate(0 0)"
      fill="#292929"
    />
  </svg>
);

export const featherSearchIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.811 15.811">
    <g
      id="Icon_feather-search"
      data-name="Icon feather-search"
      transform="translate(-3.75 -3.75)"
    >
      <path
        id="Path_3"
        data-name="Path 3"
        d="M16.944,10.722A6.222,6.222,0,1,1,10.722,4.5,6.222,6.222,0,0,1,16.944,10.722Z"
        fill="none"
        stroke="#292929"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_4"
        data-name="Path 4"
        d="M28.358,28.358l-3.383-3.383"
        transform="translate(-9.858 -9.858)"
        fill="none"
        stroke="#292929"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export const sendInivititionIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Group_263"
    data-name="Group 263"
    viewBox="0 0 130 123.262"
  >
    <rect
      id="Rectangle_155"
      data-name="Rectangle 155"
      width="4.144"
      height="2.762"
      transform="translate(92.022 120.5)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_156"
      data-name="Rectangle 156"
      width="4.144"
      height="2.762"
      transform="translate(83.735 120.5)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_157"
      data-name="Rectangle 157"
      width="4.144"
      height="2.762"
      transform="translate(75.448 120.5)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_158"
      data-name="Rectangle 158"
      width="3.704"
      height="2.762"
      transform="translate(108.596 120.5)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_159"
      data-name="Rectangle 159"
      width="4.144"
      height="2.762"
      transform="translate(100.309 120.5)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_160"
      data-name="Rectangle 160"
      width="3.703"
      height="2.762"
      transform="translate(42.798)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_161"
      data-name="Rectangle 161"
      width="4.144"
      height="2.762"
      transform="translate(9.65)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_162"
      data-name="Rectangle 162"
      width="4.144"
      height="2.762"
      transform="translate(17.937)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_163"
      data-name="Rectangle 163"
      width="4.144"
      height="2.762"
      transform="translate(26.224)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_164"
      data-name="Rectangle 164"
      width="4.144"
      height="2.762"
      transform="translate(34.511)"
      fill="#ccc"
    />
    <path
      id="Path_72"
      data-name="Path 72"
      d="M39.721,74.647a59.215,59.215,0,0,1-19.256-3.156l-.9,2.613A61.968,61.968,0,0,0,39.721,77.41a63.46,63.46,0,0,0,9.609-.73l-.418-2.73A60.755,60.755,0,0,1,39.721,74.647Z"
      transform="translate(4.782 21.204)"
      fill="#ccc"
    />
    <path
      id="Path_73"
      data-name="Path 73"
      d="M74.012,33.237l2.32-1.5c-6.079-9.394-15.835-16.747-27.471-20.7l-.89,2.615C59.021,17.405,68.27,24.362,74.012,33.237Z"
      transform="translate(15.608 -1.841)"
      fill="#ccc"
    />
    <path
      id="Path_74"
      data-name="Path 74"
      d="M89.022,41.628C83.456,26.6,66.495,15.676,46.44,15.676c-24.571,0-44.5,16.422-44.5,36.67,0,8.77,3.743,16.836,10,23.149l-9.9,19.392L26.662,85.2A52.394,52.394,0,0,0,46.44,89.016a52.017,52.017,0,0,0,17.941-3.121C80.03,80.232,90.956,67.331,90.956,52.346A30.7,30.7,0,0,0,89.022,41.628ZM26.151,56.683a6.726,6.726,0,1,1,6.726-6.726A6.726,6.726,0,0,1,26.151,56.683Zm21.173,0a6.726,6.726,0,1,1,6.726-6.726A6.723,6.723,0,0,1,47.324,56.683Zm21.187,0a6.726,6.726,0,1,1,6.726-6.726A6.734,6.734,0,0,1,68.511,56.683Z"
      transform="translate(-1.939 -0.072)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_165"
      data-name="Rectangle 165"
      width="15.535"
      height="2.763"
      transform="translate(123.596 113.376) rotate(-158.518)"
      fill="#ccc"
    />
    <path
      id="Path_75"
      data-name="Path 75"
      d="M99.143,43.412l2.058-1.842a45.784,45.784,0,0,0-17.63-11.985l-1,2.578A43,43,0,0,1,99.143,43.412Z"
      transform="translate(28.798 5.23)"
      fill="#ccc"
    />
    <path
      id="Path_76"
      data-name="Path 76"
      d="M114.045,63.884c0-16.45-16.187-29.778-36.145-29.778-1.271,0-2.541.055-3.784.166a33.17,33.17,0,0,1,1.878,11.049c0,15.455-10.787,29.433-27.554,35.828C55,88.731,65.745,93.676,77.9,93.676a42.3,42.3,0,0,0,16.063-3.108l20.013,7.887-8.038-15.773A26.607,26.607,0,0,0,114.045,63.884ZM78.756,69.519a5.359,5.359,0,1,1,5.359-5.359A5.364,5.364,0,0,1,78.756,69.519Zm14.475,0a5.359,5.359,0,1,1,5.359-5.359A5.364,5.364,0,0,1,93.23,69.519Z"
      transform="translate(15.786 6.953)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_166"
      data-name="Rectangle 166"
      width="4.144"
      height="2.762"
      transform="translate(30.609 111.123)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_167"
      data-name="Rectangle 167"
      width="4.144"
      height="2.762"
      transform="translate(22.322 111.123)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_168"
      data-name="Rectangle 168"
      width="4.144"
      height="2.762"
      transform="translate(107.861 7.155)"
      fill="#ccc"
    />
    <rect
      id="Rectangle_169"
      data-name="Rectangle 169"
      width="4.144"
      height="2.762"
      transform="translate(99.574 7.155)"
      fill="#ccc"
    />
  </svg>
);

export const recordingIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 21.2">
    <g id="noun_recording_1614610" transform="translate(-25.9 -13.9)">
      <g id="Group_303" data-name="Group 303" transform="translate(26 14)">
        <path
          id="Path_85"
          data-name="Path 85"
          d="M33,966.362a4.378,4.378,0,0,0-4.375,4.375v6.417a4.375,4.375,0,0,0,8.75,0v-6.417A4.378,4.378,0,0,0,33,966.362Zm0,1.167a3.189,3.189,0,0,1,3.208,3.208v6.417a3.208,3.208,0,1,1-6.417,0v-6.417A3.189,3.189,0,0,1,33,967.529Zm-6.407,6.125a.607.607,0,0,0-.592.592v2.917a6.991,6.991,0,0,0,6.417,6.973v2.06H30.083a.583.583,0,0,0,0,1.167h5.833a.583.583,0,0,0,0-1.167H33.583v-2.06A6.991,6.991,0,0,0,40,977.163v-2.917a.583.583,0,1,0-1.167,0v2.917a5.833,5.833,0,1,1-11.667,0v-2.917a.606.606,0,0,0-.574-.592Z"
          transform="translate(-26 -966.362)"
          fill="#888"
          stroke="#888"
          strokeWidth="0.2"
        />
      </g>
    </g>
  </svg>
);

export const voiceCallIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.605 27.604">
    <path
      id="Icon_feather-phone"
      data-name="Icon feather-phone"
      d="M21.167,16.5v2.715a1.81,1.81,0,0,1-1.973,1.81,17.911,17.911,0,0,1-7.81-2.778,17.648,17.648,0,0,1-5.43-5.43A17.911,17.911,0,0,1,3.175,4.973,1.81,1.81,0,0,1,4.976,3H7.691A1.81,1.81,0,0,1,9.5,4.557,11.621,11.621,0,0,0,10.135,7.1a1.81,1.81,0,0,1-.407,1.91L8.578,10.159a14.481,14.481,0,0,0,5.43,5.43l1.149-1.149a1.81,1.81,0,0,1,1.91-.407,11.621,11.621,0,0,0,2.543.634A1.81,1.81,0,0,1,21.167,16.5Z"
      transform="translate(30.905 13.697) rotate(135)"
      fill="none"
      stroke="#888"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export const videoCameraIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.5 14.5">
    <g
      id="noun_Video_Camera_952497"
      data-name="noun_Video Camera_952497"
      transform="translate(-15.08 -25.84)"
    >
      <g id="Layer_1" data-name="Layer 1" transform="translate(15.33 26.09)">
        <path
          id="Path_87"
          data-name="Path 87"
          d="M35.327,38.535a.383.383,0,0,0,0-.088V27.756a.434.434,0,0,0-.213-.373.422.422,0,0,0-.425,0L29.54,30.376V26.523a.43.43,0,0,0-.427-.433H15.757a.43.43,0,0,0-.427.433V39.657a.43.43,0,0,0,.427.433H29.116a.43.43,0,0,0,.427-.433V35.8L34.707,38.8h.014l.072.029h.026a.419.419,0,0,0,.1,0h0a.418.418,0,0,0,.1,0h.026l.069-.029h.026a.425.425,0,0,0,.072-.056h0a.432.432,0,0,0,.064-.082h0v-.02l.02-.044Zm-6.639.7H16.182v-12.3H28.689v4.192a.434.434,0,0,0,0,.059v.029a.429.429,0,0,0,.017.053v.076l.017.026.035.041.02.018.043.035h.023l.049.026h.026l.052.018h.24l.043-.02h.02L34.441,28.5v9.181l-5.115-2.995H29.3l-.038-.018h-.245l-.055.018h-.023l-.052.029h-.017l-.046.038h-.014l-.038.044-.014.023V34.9a.436.436,0,0,0-.017.056v.026a.431.431,0,0,0,0,.061s0,0,0,.015Z"
          transform="translate(-15.33 -26.09)"
          fill="#888"
          stroke="#888"
          strokeWidth="0.5"
        />
      </g>
    </g>
  </svg>
);

export const fullScreenIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="noun_Full_Screen_1075146"
    data-name="noun_Full Screen_1075146"
    viewBox="0 0 16 16"
  >
    <g id="Group_306" data-name="Group 306" transform="translate(0 0)">
      <g id="Group_305" data-name="Group 305">
        <g id="Group_304" data-name="Group 304">
          <path
            id="Path_86"
            data-name="Path 86"
            d="M15.8,15.806a.66.66,0,0,1-.467.194H10a.667.667,0,0,1,0-1.333h4.67V10A.667.667,0,0,1,16,10v5.339a.66.66,0,0,1-.193.468ZM15.8.193A.66.66,0,0,0,15.336,0H10a.667.667,0,0,0,0,1.333h4.67V6A.667.667,0,0,0,16,6V.664A.66.66,0,0,0,15.806.2ZM.2,15.807A.66.66,0,0,0,.664,16H6a.667.667,0,0,0,0-1.333H1.333V10A.667.667,0,0,0,0,10v5.339a.66.66,0,0,0,.194.467ZM.193.2A.66.66,0,0,0,0,.664V6A.667.667,0,0,0,1.333,6V1.333H6A.667.667,0,0,0,6,0H.664A.66.66,0,0,0,.2.194Z"
            transform="translate(0 0)"
            fill="#fff"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const sendIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.7 18.698">
    <path
      id="Icon_ionic-ios-send"
      data-name="Icon ionic-ios-send"
      d="M22.613,4.545,4.735,12.337a.41.41,0,0,0,.015.745l4.836,2.732a.78.78,0,0,0,.891-.088l9.536-8.221c.063-.054.214-.156.273-.1s-.034.209-.088.273l-8.25,9.292a.777.777,0,0,0-.078.93l3.161,5.07a.411.411,0,0,0,.74-.01L23.164,5.085A.41.41,0,0,0,22.613,4.545Z"
      transform="translate(-4.503 -4.503)"
      fill="#fff"
    />
  </svg>
);

export const playIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85">
    <path
      id="play_icon"
      d="M45.875,3.375a42.5,42.5,0,1,0,42.5,42.5A42.494,42.494,0,0,0,45.875,3.375ZM63,46.672,34.964,63.631a.909.909,0,0,1-1.369-.8V28.916a.905.905,0,0,1,1.369-.8L63,45.078A.937.937,0,0,1,63,46.672Z"
      transform="translate(-3.375 -3.375)"
      fill="#fff"
    />
  </svg>
);

export const checkSquare = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.414 20">
    <g
      id="Icon_feather-check-square"
      data-name="Icon feather-check-square"
      transform="translate(-3.5 -3.5)"
    >
      <path
        id="Path_158"
        data-name="Path 158"
        d="M13.5,13l3,3,10-10"
        transform="translate(-3 -0.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_159"
        data-name="Path 159"
        d="M22.5,13.5v7a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2V6.5a2,2,0,0,1,2-2h11"
        transform="translate(0 0)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const closeCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g
      id="Icon_ionic-ios-close-circle-outline"
      data-name="Icon ionic-ios-close-circle-outline"
      transform="translate(-3.375 -3.375)"
    >
      <path
        id="Path_160"
        data-name="Path 160"
        d="M21.332,20.027l-3.04-3.04,3.04-3.04a.922.922,0,0,0-1.3-1.3l-3.04,3.04-3.04-3.04a.922.922,0,0,0-1.3,1.3l3.04,3.04-3.04,3.04a.891.891,0,0,0,0,1.3.916.916,0,0,0,1.3,0l3.04-3.04,3.04,3.04a.926.926,0,0,0,1.3,0A.916.916,0,0,0,21.332,20.027Z"
        transform="translate(-1.613 -1.612)"
        fill="#fff"
      />
      <path
        id="Path_161"
        data-name="Path 161"
        d="M15.375,4.99a10.381,10.381,0,1,1-7.344,3.04,10.316,10.316,0,0,1,7.344-3.04m0-1.615a12,12,0,1,0,12,12,12,12,0,0,0-12-12Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export const timeClock = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g
      id="Icon_ionic-md-time"
      data-name="Icon ionic-md-time"
      transform="translate(-3.375 -3.375)"
    >
      <path
        id="Path_151"
        data-name="Path 151"
        d="M15.363,3.375a12,12,0,1,0,12.012,12A12,12,0,0,0,15.363,3.375Zm.012,21.6a9.6,9.6,0,1,1,9.6-9.6A9.6,9.6,0,0,1,15.375,24.975Z"
        fill="rgba(255,255,255,0.9)"
      />
      <path
        id="Path_152"
        data-name="Path 152"
        d="M18.338,10.688h-1.8v7.2l6.3,3.779.9-1.477-5.4-3.2Z"
        transform="translate(-2.363 -1.313)"
        fill="rgba(255,255,255,0.9)"
      />
    </g>
  </svg>
);

export const videoCamera = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 14.8">
    <g
      id="noun_Video_Camera_952497"
      data-name="noun_Video Camera_952497"
      transform="translate(-14.93 -25.69)"
    >
      <g id="Layer_1" data-name="Layer 1" transform="translate(15.33 26.09)">
        <path
          id="Path_87"
          data-name="Path 87"
          d="M35.327,38.535a.383.383,0,0,0,0-.088V27.756a.434.434,0,0,0-.213-.373.422.422,0,0,0-.425,0L29.54,30.376V26.523a.43.43,0,0,0-.427-.433H15.757a.43.43,0,0,0-.427.433V39.657a.43.43,0,0,0,.427.433H29.116a.43.43,0,0,0,.427-.433V35.8L34.707,38.8h.014l.072.029h.026a.419.419,0,0,0,.1,0h0a.418.418,0,0,0,.1,0h.026l.069-.029h.026a.425.425,0,0,0,.072-.056h0a.432.432,0,0,0,.064-.082h0v-.02l.02-.044Zm-6.639.7H16.182v-12.3H28.689v4.192a.434.434,0,0,0,0,.059v.029a.429.429,0,0,0,.017.053v.076l.017.026.035.041.02.018.043.035h.023l.049.026h.026l.052.018h.24l.043-.02h.02L34.441,28.5v9.181l-5.115-2.995H29.3l-.038-.018h-.245l-.055.018h-.023l-.052.029h-.017l-.046.038h-.014l-.038.044-.014.023V34.9a.436.436,0,0,0-.017.056v.026a.431.431,0,0,0,0,.061s0,0,0,.015Z"
          transform="translate(-15.33 -26.09)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.8"
        />
      </g>
    </g>
  </svg>
);

export const editPenIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 401.52289 401">
    <path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" />
    <path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" />
  </svg>
);

export const arrowDown = (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="down"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
  </svg>
);

export const fileUploadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="85.816"
    height="100.648"
    viewBox="0 0 85.816 100.648"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <g id="Group_391" data-name="Group 391" transform="translate(-9.5 -2.5)">
      <path
        id="Path_139"
        data-name="Path 139"
        d="M71.9,42.865V27.927a8.683,8.683,0,0,0-2.543-6.039L52.2,4.937A8.333,8.333,0,0,0,46.263,2.5H20.412A10.9,10.9,0,0,0,9.5,13.412V68.822A10.9,10.9,0,0,0,20.412,79.734H34.927A30.613,30.613,0,1,0,71.9,42.865ZM17.128,68.822V13.412a3.3,3.3,0,0,1,3.284-3.284H43.826V24.537a6.149,6.149,0,0,0,6.145,6.145H64.38V42.018a30.668,30.668,0,0,0-30.3,30.088H20.306A3.281,3.281,0,0,1,17.128,68.822ZM64.7,95.626a22.99,22.99,0,1,1,22.99-22.99A23.037,23.037,0,0,1,64.7,95.626Z"
        transform="translate(0)"
        fill="#ff2d6c"
      />
      <path
        id="Path_140"
        data-name="Path 140"
        d="M74.452,65.6H59.619l2.543-2.543a3.746,3.746,0,1,0-5.3-5.3l-9.005,9.005A3.678,3.678,0,0,0,46.8,69.416a3.962,3.962,0,0,0,1.059,2.649l9.005,9.005a3.746,3.746,0,1,0,5.3-5.3L59.513,73.23H74.452a3.814,3.814,0,0,0,0-7.628Z"
        transform="translate(2.218 3.219)"
        fill="#ff2d6c"
      />
    </g>
  </svg>
);

export const successfullyTickIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="87.372"
    height="65.154"
    viewBox="0 0 87.372 65.154"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      id="Icon_awesome-check"
      data-name="Icon awesome-check"
      d="M29.675,68.452l-28.4-28.4a4.369,4.369,0,0,1,0-6.178L7.457,27.7a4.368,4.368,0,0,1,6.178,0L32.764,46.828,73.736,5.857a4.369,4.369,0,0,1,6.178,0l6.178,6.178a4.369,4.369,0,0,1,0,6.178L35.854,68.452A4.369,4.369,0,0,1,29.675,68.452Z"
      transform="translate(0 -4.577)"
      fill="#ff2d6c"
    />
  </svg>
);

export const exclamationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.75"
    height="14"
    viewBox="0 0 15.75 14"
  >
    <path
      id="Icon_awesome-exclamation-triangle"
      data-name="Icon awesome-exclamation-triangle"
      d="M15.573,12.032A1.313,1.313,0,0,1,14.436,14H1.314A1.313,1.313,0,0,1,.177,12.032L6.738.656a1.313,1.313,0,0,1,2.274,0ZM7.875,9.68a1.258,1.258,0,1,0,1.258,1.258A1.258,1.258,0,0,0,7.875,9.68ZM6.681,5.159l.2,3.719a.328.328,0,0,0,.328.31H8.539a.328.328,0,0,0,.328-.31l.2-3.719a.328.328,0,0,0-.328-.346H7.008a.328.328,0,0,0-.328.346Z"
    />
  </svg>
);

export const selectPreset = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g id="import-line" transform="translate(-2 -4)">
      <path
        id="Path_178"
        data-name="Path 178"
        d="M19.429,4h-7.5L8,7.92v2.366H9.143V9.491h4.349V5.143h5.937V18.857H8A1.143,1.143,0,0,0,9.143,20H19.429a1.143,1.143,0,0,0,1.143-1.143V5.143A1.143,1.143,0,0,0,19.429,4ZM12.571,8.571H9.143V8.389L12.4,5.143h.171Z"
        transform="translate(-2.571 0)"
        fill="#ff2d6c"
      />
      <path
        id="Path_179"
        data-name="Path 179"
        d="M7.68,21.908a.571.571,0,1,0,.806.806l3.229-3.251L8.469,16.217a.571.571,0,0,0-.806.806l1.88,1.869H2.571a.571.571,0,0,0,0,1.143H9.56Z"
        transform="translate(0 -5.177)"
        fill="#ff2d6c"
      />
    </g>
  </svg>
);

export const flagIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      id="Icon_open-flag"
      data-name="Icon open-flag"
      d="M0,0V16H2V0ZM4,0V8H8v2h8L12,6.06,16,2H10V0Z"
      fill="#ff2d6c"
    />
  </svg>
);

export const lockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="17.5"
    height="22.75"
    viewBox="0 0 17.5 22.75"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      id="Icon_ionic-ios-lock"
      data-name="Icon ionic-ios-lock"
      d="M22.063,11.25H20.75V8.7a5.25,5.25,0,1,0-10.5-.071V11.25H8.938A2.194,2.194,0,0,0,6.75,13.437v10.5a2.194,2.194,0,0,0,2.188,2.188H22.063a2.194,2.194,0,0,0,2.188-2.187v-10.5A2.194,2.194,0,0,0,22.063,11.25Zm-5.8,6.825v3.762a.784.784,0,0,1-.727.788.767.767,0,0,1-.8-.766V18.075a1.75,1.75,0,1,1,1.531,0Zm2.953-6.825H11.781V8.625a3.719,3.719,0,0,1,7.438,0Z"
      transform="translate(-6.75 -3.374)"
      fill="#ff2d6c"
    />
  </svg>
);

export const pdfFileIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width="15"
    height="20"
    viewBox="0 0 15 20"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      id="Icon_awesome-file-pdf"
      data-name="Icon awesome-file-pdf"
      d="M7.105,10a3.769,3.769,0,0,1-.078-1.832C7.355,8.172,7.324,9.613,7.105,10Zm-.066,1.844A18.025,18.025,0,0,1,5.93,14.3a14.384,14.384,0,0,1,2.457-.855A5.06,5.06,0,0,1,7.039,11.848ZM3.363,16.723c0,.031.516-.211,1.363-1.57A5.4,5.4,0,0,0,3.363,16.723ZM9.688,6.25H15V19.063a.935.935,0,0,1-.937.938H.938A.935.935,0,0,1,0,19.063V.938A.935.935,0,0,1,.938,0H8.75V5.313A.94.94,0,0,0,9.688,6.25Zm-.312,6.711a3.921,3.921,0,0,1-1.668-2.1,5.615,5.615,0,0,0,.242-2.508.978.978,0,0,0-1.867-.266A6.5,6.5,0,0,0,6.4,11.094,36.681,36.681,0,0,1,4.8,14.445s0,0-.008,0c-1.059.543-2.875,1.738-2.129,2.656a1.214,1.214,0,0,0,.84.391c.7,0,1.395-.7,2.387-2.414a22.266,22.266,0,0,1,3.086-.906,5.92,5.92,0,0,0,2.5.762,1.011,1.011,0,0,0,.77-1.7c-.543-.531-2.121-.379-2.875-.281ZM14.727,4.1,10.9.273A.937.937,0,0,0,10.234,0H10V5h5V4.762A.935.935,0,0,0,14.727,4.1Zm-2.895,9.973c.16-.105-.1-.465-1.672-.352C11.609,14.34,11.832,14.074,11.832,14.074Z"
      fill="#ff2d6c"
    />
  </svg>
);

export const chevronRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width="8"
    height="8"
    viewBox="0 0 407.436 407.436"
    xmlSpace="preserve"
  >
    <path d="M112.814 0L91.566 21.178 273.512 203.718 91.566 386.258 112.814 407.436 315.869 203.718z"></path>
  </svg>
);

export const importFileIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    width="100%"
    height="100%"
    viewBox="0 0 96.03 122.88"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      d="M63.31.72C62.83.29 62.15 0 61.48 0c-.15 0-.29 0-.43.05H6.1c-1.64 0-3.19.68-4.3 1.79A5.996 5.996 0 000 6.14V48h5.41V6.14c0-.19.05-.34.19-.43.1-.1.24-.19.43-.19h52.68v23.83a8.263 8.263 0 008.27 8.27h23.39v79.19c0 .15-.05.34-.19.43-.1.1-.24.19-.44.19H6.03c-.15 0-.34-.05-.43-.19-.1-.1-.19-.29-.19-.43v-42.9H0v42.9c0 1.69.68 3.19 1.79 4.3a6.028 6.028 0 004.3 1.79h83.76c1.69 0 3.19-.68 4.3-1.79a6.028 6.028 0 001.79-4.3V35.52c.05-.24.1-.43.1-.68 0-.82-.39-1.55-.92-2.08L63.65.92c-.1-.1-.14-.14-.24-.19h-.1V.72zM43.56 63.98H0v-6.04h43.53L31.42 44.07a2.987 2.987 0 01.25-4.21 2.95 2.95 0 014.18.25L52.3 58.96c1.02 1.16.98 2.88-.04 4L35.84 81.77c-1.09 1.23-2.95 1.37-4.18.25-1.23-1.09-1.33-2.98-.25-4.21l12.11-13.87.04.04zm20.62-34.64V9.38l22.47 22.76H66.99c-.77 0-1.45-.34-1.98-.82-.49-.49-.83-1.21-.83-1.98z"
      fill="#ff2d6c"
    ></path>
  </svg>
);

export const tickIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 96 96"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.5"
        x2="0"
        y2="0.5"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2e2a7d" />
        <stop offset="1" stopColor="#1a0dab" />
      </linearGradient>
    </defs>
    <path
      d="M86.994 25.593l-6.402-6.402a3.018 3.018 0 00-4.269 0L37.938 57.576 19.68 39.186a3.018 3.018 0 00-4.269 0l-6.402 6.402a3.018 3.018 0 000 4.269l26.772 26.952a3.018 3.018 0 004.269 0l46.944-46.947a3.018 3.018 0 000-4.269z "
      fill="#ff2d6c"
    ></path>
  </svg>
);
