import React, { useState } from 'react';
import Filter from './Filter';
import List from './List';

const auctionData = [
  {
    "name": "Auction Title 1",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 2",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 4",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 5",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 6",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 7",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 8",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 9",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 10",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 11",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 12",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 13",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 14",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 15",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  },
  {
    "name": "Auction Title 16",
    "auction_start": "01.01.2020",
    "address": "0x1683199880b3218A8F47E269D56bD730b269f032",
    "license_valid": "01.01.2020 – 31.12.2027",
    "price": "€ 2.000",
    "created_by": "john.doe@bbc.co.uk",
    "updated_at": "01.01.2020 – 31.12.2027",
    "updated_by": "john.doe@bbc.co.uk"
  }
]

const Auction = () => {
  const [search, setSearch] = useState('');

  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}

      />

      <List data={auctionData} />

    </div>
  )
}

export default Auction;