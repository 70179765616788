import React from "react";
import {
  Table,
  Space,
  Tooltip,
  Button,
  Card,
  Avatar,
  Spin,
  Pagination,
} from "antd";
import { Link } from "react-router-dom";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const { Meta } = Card;

const UserTaskListBox = ({
  showModal,
  loading,
  delConfirmModal,
  onChangePagination,
  currentPage,
  data,
  mainUserRes,
}) => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "username",
      key: "user",
      render: (text, record) => {
        return (
          <Link to="#">
            <Meta
              className="meta-avatar-align meta-link-avatar"
              disabled={record.user.status === "blocked" ? true : false}
              avatar={
                <Avatar
                  size={24}
                  src="https://avatars3.githubusercontent.com/u/10627086?s=40&v=4"
                />
              }
              title={record.user.username}
            />
          </Link>
        );
      },
    },
    {
      title: "USERNAME",
      dataIndex: "username",
      key: "username",
      render: (text, record) => record.user.username,
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      render: (text, record) => record.user.email,
    },
    {
      title: "ASSIGNED ORGANIZATION",
      dataIndex: "organization.name",
      key: "organization.name",
      render: (text, record) => record.organization.name,
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table usre-management-action">
          {getRolesPermissions(
            mainUserRes,
            "automated-task-assignment.edit"
          ) === false ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => showModal("edit-user", record)}
              >
                <u>Edit</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
          {getRolesPermissions(
            mainUserRes,
            "automated-task-assignment.delete"
          ) === false ? (
            <Tooltip title="Delete">
              <Button type="link" onClick={() => delConfirmModal(record)}>
                <u>Delete</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      <Table
        columns={columns}
        dataSource={data}
        className="table-style task-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={false}
        rowKey={(record) => record._id}
      />

      <div span={24} className="d-flex justify-center mt20">
        <Pagination
          defaultCurrent={currentPage}
          defaultPageSize={10}
          onChange={onChangePagination}
          total={data && data.pagination && data.pagination.total}
        />
      </div>
    </Spin>
  );
};

export default UserTaskListBox;
