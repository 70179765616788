import React from "react";
import { Button } from "antd";
import { pluseFilledIcon } from "./../../../Common/Icons";

// import PermissionsComponent from './../../../../Containers/PermissionsComponent';

const TopTitleSection = ({ showModal, userPermissions }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Asset Management</h3>
      </div>
      {/* <PermissionsComponent permissions={"Asset.Create"} component={() => <div><p className="add-selection-btn add-new-selection"><Button type="default" className="p0 border-0" onClick={() => showModal("add-asset")} disabled={userPermissions && userPermissions.includes('Asset.Create') ? false : true }>{pluseFilledIcon} Add New ASSET</Button></p></div>}   /> */}
      <div>
        <p className="add-selection-btn add-new-selection">
          <Button
            type="default"
            className="p0 border-0"
            onClick={() => showModal("add-asset")}
          >
            {pluseFilledIcon} Add New ASSET
          </Button>
        </p>
      </div>
    </div>
  );
};

export default TopTitleSection;
