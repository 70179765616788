import React, { Fragment, useState } from "react";
import { Modal } from "antd";
import SelectionDetialFilter from "./HeaderFilter";
import VendorSelectionAsset from "./VendorAssets";
import StraightSales from "./StraightSaleModal";

const RequestProposal = ({
  location,
  selection,
  history,
  onGetSelectionsList,
  selectionsList,
  onUpdateTradingList,
  updateTradingListRes,
  onCreateTradingList,
  onGetAllTradingTask,
  postTradingTaskRes,
  onGetFormalLicenseList,
  getFormalLicenseListRes,
  selectionAssetsListRes,
  updateSelection,
  onUpdateSelection,
  selectionDetail,
  onGetUserManagementList,
  userManagementList,
  onGetUserList,
  getUserListRes,
  mainUserRes,
  departmentsWithUsersRes,
  onGetDepartmentsWithUsers,
  organizationRes,
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState({});

  const assetSelectionFunc = (data) => {
    setSelectedItemData(data);
  };

  const showModal = (modalType, selectedData) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Fragment>
      <SelectionDetialFilter
        selection={selection}
        showModal={showModal}
        selectionsList={selectionsList}
        location={location}
        selectionDetail={selectionDetail}
      />
      <VendorSelectionAsset
        onUpdateTradingList={onUpdateTradingList}
        updateTradingListRes={updateTradingListRes}
        onCreateTradingList={onCreateTradingList}
        onGetAllTradingTask={onGetAllTradingTask}
        postTradingTaskRes={postTradingTaskRes}
        onGetFormalLicenseList={onGetFormalLicenseList}
        getFormalLicenseListRes={getFormalLicenseListRes}
        selectedItemData={selectedItemData}
        location={location}
        assetSelectionFunc={assetSelectionFunc}
        history={history}
        selectionAssetsListRes={selectionAssetsListRes}
        updateSelection={updateSelection}
        onUpdateSelection={onUpdateSelection}
        selectionDetail={selectionDetail}
        onGetUserManagementList={onGetUserManagementList}
        userManagementList={userManagementList}
        onGetUserList={onGetUserList}
        getUserListRes={getUserListRes}
        mainUserRes={mainUserRes}
        departmentsWithUsersRes={departmentsWithUsersRes}
        onGetDepartmentsWithUsers={onGetDepartmentsWithUsers}
        organizationRes={organizationRes}
      />
      <Modal
        title={false}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className="modal-style"
        centered
      >
        <StraightSales handleCancel={handleCancel} />
      </Modal>
    </Fragment>
  );
};

export default RequestProposal;
