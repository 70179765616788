import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Register from "../Components/User/Register";

import { signIn, setUserPassword } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    signInRes: state.userReducer.signInRes,
    setUserPasswordRes: state.userReducer.setUserPasswordRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSignIn: bindActionCreators(signIn, dispatch),
    onUpdateUserPassword: bindActionCreators(setUserPassword, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
