import React, { Fragment } from 'react';
import TableDragDrop from './TableDragDrop';

const TableEditor = () => {
  return (
    <Fragment>
      <h2 className="mb5">Table Editor</h2>
      <p>Drag and Drop to replace the table fields</p>
      <div>
        <TableDragDrop />
      </div>
    </Fragment>
  )
}

export default TableEditor;