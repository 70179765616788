import React, { useState, useEffect } from "react";
import { Radio, Button, Carousel } from "antd";
import { Link } from "react-router-dom";
import { getLength } from "./../../../../Common/reUseFunctions";
import LanguagesModal from "./../../../../Common/Components/LanguagesModal";
import ReactPlayer from "react-player";

const InventoryModal = ({
  assetId,
  showTableEditor,
  getInventorySeachDetailRes,
  onGetInventorySeachDetail,
}) => {
  const [visibleLanguages, setVisibleLanguages] = useState(false);

  useEffect(() => {
    onGetInventorySeachDetail(assetId);
  }, [assetId]);

  function onChangeSlider(a, b, c) {
    console.log(a, b, c);
  }

  const onChangeLanguage = (e) => {
    console.log("language", e.target.value);
  };

  const cancelAssetLanguages = () => {
    setVisibleLanguages(false);
  };

  return (
    <div>
      <div className="asset-detail-model">
        <div className="asset-model-video">
          {getInventorySeachDetailRes &&
          getInventorySeachDetailRes.assets &&
          getInventorySeachDetailRes.assets.trailers[0] &&
          getInventorySeachDetailRes.assets.trailers[0].url ? (
            <ReactPlayer
              url={
                getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.trailers[0] &&
                getInventorySeachDetailRes.assets.trailers[0].url
              }
              className="react-video"
              controls={true}
              playing={true}
              light={true}
            />
          ) : (
            <Carousel afterChange={onChangeSlider}>
              {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.preview_image.map(
                  (data, index) => {
                    return (
                      <div key={index} className="preview-image">
                        <div class="container">
                          {/* Image start */}
                          <div class="image-wrapper">
                            <img
                              src={data}
                              style={{ width: "100%" }}
                              alt="image"
                            />
                          </div>
                          {/* Image end */}
                        </div>
                        {/* <img src={data} style={{ width: "100%" }} /> */}
                      </div>
                    );
                  }
                )}
            </Carousel>
          )}
          {/* {playIcon} */}
        </div>
        <div className="asset-model-info">
          <div
            style={{ width: "42px" }}
            className="radio-btn-style lang-btn preview-modal-style"
          >
            <Radio.Group
              onChange={(e) => onChangeLanguage(e.target.value)}
              buttonStyle="solid"
            >
              {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets.audio_tracks &&
                getInventorySeachDetailRes.assets.audio_tracks.length > 0 &&
                getInventorySeachDetailRes.assets.audio_tracks.map(
                  (data, index) => (
                    <Radio.Button key={index}>{data.language}</Radio.Button>
                  )
                )}
              {/* {
                assetDetail && assetDetail.audioTrack && assetDetail.audioTrack.length > 4 &&
                <Button className="specific-role-btn specific-lang-btn" type="link" onClick={() => showAssetLanguages("languages", languages)}><u>+{assetDetail && assetDetail.audioTrack && assetDetail.audioTrack.length - 4}</u></Button>
              } */}
            </Radio.Group>
          </div>
          <div className="d-flex">
            <div className="asset-model-text">
              <h3>
                {getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.assets &&
                  getInventorySeachDetailRes.assets.title &&
                  getInventorySeachDetailRes.assets.title.value}
              </h3>
              <p className="duration">
                <span>
                  {getInventorySeachDetailRes &&
                    getInventorySeachDetailRes.assets &&
                    getInventorySeachDetailRes.assets.production_date}
                </span>
                {getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.assets &&
                  getLength(
                    getInventorySeachDetailRes.assets.versions[0].duration
                  )}
              </p>
              <h4>Summary</h4>
              {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.content_summaries_short.map(
                  (data, index) => {
                    return data.value;
                  }
                )}
            </div>
            <div className="asset-model-func">
              <ul>
                {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.production_company &&
                getInventorySeachDetailRes.assets.production_company.value ? (
                  <li>
                    <span>Production Company:</span>
                    <div className="overflowDots">
                      {getInventorySeachDetailRes &&
                        getInventorySeachDetailRes.assets &&
                        getInventorySeachDetailRes.assets.production_company &&
                        getInventorySeachDetailRes.assets.production_company
                          .value}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.co_production_companies
                  .length > 0 ? (
                  <li>
                    <span>Co-Production Company:</span>
                    <div className="overflowDots">
                      {getInventorySeachDetailRes &&
                        getInventorySeachDetailRes.assets &&
                        getInventorySeachDetailRes.assets.co_production_companies.map(
                          (data, index) => {
                            return data.value;
                          }
                        )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.directors.length > 0 ? (
                  <li>
                    <span>Film Director:</span>
                    <div className="overflowDots">
                      {getInventorySeachDetailRes &&
                        getInventorySeachDetailRes.assets &&
                        getInventorySeachDetailRes.assets.directors.map(
                          (data, index) => {
                            return data;
                          }
                        )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.film_genres.length > 0 ? (
                  <li>
                    <span>Film Genre:</span>
                    <div className="overflowDots">
                      {getInventorySeachDetailRes &&
                        getInventorySeachDetailRes.assets &&
                        getInventorySeachDetailRes.assets.film_genres.join(
                          ", "
                        )}
                    </div>
                  </li>
                ) : (
                  ""
                )}
                {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.assets &&
                getInventorySeachDetailRes.assets.contributors.length > 0 ? (
                  <li>
                    <span>Featured Artist:</span>
                    <div className="f-artist-com overflowDots">
                      {getInventorySeachDetailRes &&
                      getInventorySeachDetailRes.assets &&
                      getInventorySeachDetailRes.assets.contributors.length
                        ? getInventorySeachDetailRes.assets.contributors.map(
                            (data, index) => {
                              return (
                                <div className="inlineProperty" key={index}>
                                  {data.artist.value}
                                  <span>,</span>{" "}
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="asset-model-btn">
          <Link
            to={`/inventory-search/${
              getInventorySeachDetailRes &&
              getInventorySeachDetailRes.assets &&
              getInventorySeachDetailRes.assets._id
            }`}
          >
            <Button type="default">Details</Button>
          </Link>
          <Link
            to="#"
            onClick={() =>
              showTableEditor(
                "add_to_selection",
                getInventorySeachDetailRes.assets
              )
            }
          >
            <Button type="primary">Add to Selection</Button>
          </Link>
        </div>
      </div>
      <LanguagesModal
        visible={visibleLanguages}
        handleCancel={cancelAssetLanguages}
      />
    </div>
  );
};

export default InventoryModal;
