import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

export const createNewUser = (data) => {
  const options = { url: `users` };

  options.types = [
    types.CREATE_NEW_USER_INVITE_SUCCESS,
    types.CREATE_NEW_USER_INVITE_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const getUsersList = (
  skip,
  limit,
  isBlocked,
  status,
  isPending,
  search
) => {
  const options = {
    url: `users?search=${search}&skip=${skip}&limit=${limit}&order=asc&status=${isPending}&status=${status}&status=${isBlocked}`,
  };

  options.types = [types.GET_USERS_LIST_SUCCESS, types.GET_USERS_LIST_FAILURE];

  return apiNew.get(options);
};

export const getSpecificUserDetail = (id) => {
  const options = { url: `users/${id}` };

  options.types = [
    types.GET_SPECIFIC_USER_DETAIL_SUCCESS,
    types.GET_SPECIFIC_USER_DETAIL_FAILURE,
  ];

  return apiNew.get(options);
};

export const getSpecificUserImage = (path) => {
  const options = { url: `users/profile/${path}` };

  options.types = [
    types.GET_USER_PICTURE_SUCCESS,
    types.GET_USER_PICTURE_FAILURE,
  ];

  return apiNew.getBlobImage(options);
};

export const changeUserPassword = (data) => {
  const options = { url: "auth/change-password" };
  options.types = [
    types.UPDATE_NEW_PASSWORD_SUCCESS,
    types.UPDATE_NEW_PASSWORD_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const updateProfileAndUsername = (id, data) => {
  const options = { url: `users/${id}` };
  options.types = [
    types.UPDATE_USERPRIFILE_AND_USERNAME_SUCCESS,
    types.UPDATE_USERPRIFILE_AND_USERNAME_FAILURE,
  ];

  return apiNew.patch(options, data);
};

export const getBlockSpecificUser = (id) => {
  const options = { url: `users/profile/block/${id}` };

  options.types = [
    types.GET_BLOCK_SPECIFIC_USER_SUCCESS,
    types.GET_BLOCK_SPECIFIC_USER_FAILURE,
  ];

  return apiNew.get(options);
};

export const getUnblockSpecificUser = (id) => {
  const options = { url: `users/profile/unblock/${id}` };

  options.types = [
    types.GET_UNBLOCK_SPECIFIC_USER_SUCCESS,
    types.GET_UNBLOCK_SPECIFIC_USER_FAILURE,
  ];

  return apiNew.get(options);
};

export const updateUseInformation = (id, data) => {
  const options = { url: `users/${id}` };

  options.types = [
    types.UPDATE_USER_INFORMATION_SUCCESS,
    types.UPDATE_USER_INFORMATION_FAILURE,
  ];

  return apiNew.put(options, data);
};

export const updateProfileStatus = (id, status, data) => {
  const options = { url: `users/${id}/make-${status}` };
  const action = { public: "post", private: "get" };

  options.types = [
    types.GET_PROFILE_STATUS_USER_SUCCESS,
    types.GET_PROFILE_STATUS_USER_FAILURE,
  ];

  return apiNew[action[status]](options, data);
};

export const userDelete = (id) => {
  const options = { url: `users/${id}` };

  options.types = [types.USER_DELETE_SUCCESS, types.USER_DELETE_FAILURE];

  return apiNew.delete(options);
};
