import React, { Fragment, useState } from 'react';
import { Form, DatePicker, TimePicker, Row, Col } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { timeFormateAmPm, dateFormateAmPm } from "./../reUseFunctions";

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const ShowDateTime = ({
  getAllTradingTaskRes, 
  getOrganizationProfileRes, 
  field, 
  remove
}) => {

  const [timeData, setTimeData] = useState('');
  const [dateData, setDateData] = useState('');

const onTimeSelect = (value) => {
  if (getOrganizationProfileRes && (getOrganizationProfileRes.timezone && getOrganizationProfileRes.country)) {
    setTimeData(value);
  }
}

const onDateSelect = (value) => {
  if (getOrganizationProfileRes && (getOrganizationProfileRes.timezone && getOrganizationProfileRes.country)) {
    setDateData(value);
  }
}

  return (
    <Fragment>
      <h5>TIME SLOT {field.key}</h5>
      <Row gutter={16}>
        <Col span={12}>
          <div className="r-p-bg">
            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>SET TIME</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name={[field.name, 'time']}
                  fieldKey={[field.fieldKey, 'time']}
                  rules={[{ required: true, message: 'Time is required' }]}
                >
                  <TimePicker onChange={onTimeSelect} />
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>SET DATE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name={[field.name, 'date']}
                  fieldKey={[field.fieldKey, 'date']}
                  rules={[{ required: true, message: 'Date is required' }]}
                >
                  <DatePicker onChange={onDateSelect} format={dateFormatList} />
                </Form.Item>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="r-p-bg">
            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>{getAllTradingTaskRes && getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_title} TIME</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  fieldKey={[field.fieldKey]}
                >
                { timeData && timeFormateAmPm(new moment(timeData), getOrganizationProfileRes && getOrganizationProfileRes.country, getOrganizationProfileRes && getOrganizationProfileRes.timezone)}
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>{getAllTradingTaskRes && getAllTradingTaskRes.data && getAllTradingTaskRes.data.company_title} DATE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  fieldKey={[field.fieldKey]}
                >
                  {dateData && dateFormateAmPm(new moment(dateData), getOrganizationProfileRes && getOrganizationProfileRes.country, getOrganizationProfileRes && getOrganizationProfileRes.timezone)}
                </Form.Item>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <MinusCircleOutlined
        className="negotiation-close"
        onClick={() => {
          remove(field.name);
        }}
      />
    </Fragment>
            
  )
}

export default ShowDateTime;