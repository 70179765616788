import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Filter from "./HeaderFilter";
import UserTaskListBox from "./List";
import AddNewTask from "./AddNewTask";
import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";
import {
  DELETE_ASSIGNED_TASK_SUCCESS,
  GET_ASSIGNED_TASKS_SUCCESS,
} from "../../../../store/Constants";

const TaskAssignment = ({
  onGetAssignedTasks,
  onCreateAssignedTask,
  onUpdateAssignedTask,
  onDeleteAssignedTask,
  onGetOrganizations,
  onGetUsers,
  mainUserRes,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [delConfirm, setDelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAssignedTasks();
  }, [currentPage, search, reload, mainUserRes]);

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const getAssignedTasks = () => {
    if (
      getRolesPermissions(mainUserRes, "automated-task-assignment.list") ===
      false
    ) {
      const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;

      onGetAssignedTasks(skip, 9, search)
        .then((res) => {
          setLoading(false);
          if (res.type === GET_ASSIGNED_TASKS_SUCCESS) {
            const { automated_task_selections } = res.payload;
            setData(automated_task_selections);
          }
          setReload(false);
        })
        .catch((err) => {
          setLoading(false);
          setReload(false);
        });
    } else {
      setLoading(false);
    }
  };

  const showModal = (modalType, selectedSpecificData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedSpecificData);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const delConfirmModal = (data) => {
    setSelectedData(data);
    setDelConfirm(true);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const onDeletingItem = () => {
    onDeleteAssignedTask(selectedData._id).then((res) => {
      if (res.type === DELETE_ASSIGNED_TASK_SUCCESS) {
        message.success(res.payload.message);
      } else {
        message.error(res.payload.message);
      }

      setReload(true);
    });
    setDelConfirm(false);
  };

  return (
    <div>
      <Filter
        mainUserRes={mainUserRes}
        onSearch={onSearch}
        search={search}
        showModal={showModal}
      />

      <UserTaskListBox
        loading={loading}
        showModal={showModal}
        delConfirmModal={delConfirmModal}
        onChangePagination={onChangePagination}
        currentPage={currentPage}
        data={data}
        mainUserRes={mainUserRes}
      />

      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={520}
        >
          <AddNewTask
            handleCancel={handleCancel}
            modalType={modalType}
            selectedData={selectedData}
            currentPage={currentPage}
            search={search}
            onGetOrganizations={onGetOrganizations}
            onGetUsers={onGetUsers}
            onAddTask={onCreateAssignedTask}
            onUpdateTask={onUpdateAssignedTask}
            reload={setReload}
            setReload={setReload}
          />
        </Modal>
      )}

      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        selectedData={selectedData}
        onDelete={() => {}}
        onDeleteItem={onDeletingItem}
      />
    </div>
  );
};

export default TaskAssignment;
