import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import SelectionList from "./List";

const Selection = ({
  assetID,
  mainUserRes,
  selectedData,
  onGetSelectionsListModal,
  selectionsList,
  onCreateSelection,
  onGetSelectionDetail,
  selectionDetail,
  onUpdateSelection,
  handleCloseSelection,
  onAddAssetInSelection,
}) => {
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    onGetSelectionsListModal(search, type, true, true);
  }, [search, type]);



  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const onSelect = (value) => {
    setType(value);
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <Filter onSearch={onSearch} onSelect={onSelect} search={search} />
      <SelectionList
        selectionsList={selectionsList}
        onCreateSelection={onCreateSelection}
        onGetSelectionsListModal={onGetSelectionsListModal}
        onUpdateSelection={onUpdateSelection}
        onGetSelectionDetail={onGetSelectionDetail}
        selectionDetail={selectionDetail}
        handleCloseSelection={handleCloseSelection}
        onAddAssetInSelection={onAddAssetInSelection}
        selectedData={selectedData}
        assetID={assetID}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default Selection;
