import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { multiLanguages } from "./../../../../../statics/data/multiLanguages";

const MultiLanguageTitles = ({ requiredField }) => {
  const [selectedLang, setSelectedLang] = useState([]);

  const onChangeLanguage = (value) => {
    setSelectedLang(value);
  };

  return (
    <Row
      gutter={16}
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name">
            <h3 className="">
              <span>Alternative Titles</span>
            </h3>
          </div>
          <div className="metadata-filed">
            <Form.List name="alternativeTitle">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field) => (
                      <Row key={field.key} gutter={16}>
                        <Col span={24}>
                          <div
                            key={field.key}
                            className="d-flex align-item-center mb10"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "value"]}
                              fieldKey={[field.fieldKey, "value"]}
                              className="full-width mr20 mb0"
                            >
                              <Input
                                placeholder="Title name"
                                className="full-width"
                              />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              name={[field.name, "language"]}
                              fieldKey={[field.fieldKey, "language"]}
                              rules={[
                                {
                                  required: true,
                                  pattern: new RegExp("[a-z]{2}(-[A-Z]{2})?"),
                                  message: "Please enter the correct language!",
                                },
                              ]}
                              className="full-width mr20 mb0"
                            >
                              <Select
                                placeholder="Select language"
                                value={selectedLang}
                                onChange={onChangeLanguage}
                                style={{ width: "100%" }}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                              >
                                {multiLanguages.map((item, index) => (
                                  <Select.Option key={index} value={item.value}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>

                            <MinusCircleOutlined
                              className="ml10 remove-circle"
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Multi Language
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MultiLanguageTitles;
