import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Select, DatePicker, message } from "antd";
import {
  ImportOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const { Option } = Select;

const NumberOfAssetModal = ({
  handleCancel,
  onGetAllOrganization,
  getAllOrganizationRes,
  onCreateTradingList,
  selectionDetail,
  mainUserRes,
  organizationRes
}) => {
  const [form] = Form.useForm();
  const [dropdownResult, setDropdownResult] = useState("");
  const [dropdownItems, setDropdownItems] = useState();
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [selectedArr, setSelectedArr] = useState([]);
  const [setseletedOrganizationList, setSetseletedOrganizationList] = useState(
    []
  );

  useEffect(() => {

    onGetAllOrganization().then((res) => {
      let filteredOrgList = res &&
      res.payload &&
      res.payload.organizations &&
      res.payload.organizations.organizations.filter((data) => data._id !== organizationRes.trading_aggregation_organization_id
      && data.name !== organizationRes && organizationRes.name);
      setDropdownItems(
          filteredOrgList
      );
    });
  }, []);

  const onAddingFilter = (add) => {
    add();
    removeDropDownItems();
  };

  const onChangeResale = (value) => {
    let selectedOrg = [...setseletedOrganizationList];
    const orgObj = value && JSON.parse(value);
    if (orgObj) {
      selectedOrg.push(orgObj);
    }
    setSetseletedOrganizationList(selectedOrg);
    setSearchFilterValue(selectedOrg);
    setDropdownResult(selectedOrg);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  const onFinish = (values) => {
    let tradingbody = {};
    if (values.deadline) {
      const newTime = new Date(values.deadline);
      tradingbody.deadline = newTime;
    }
    if (setseletedOrganizationList && setseletedOrganizationList.length > 0) {
      setseletedOrganizationList.map((itemData) => {
        tradingbody.name = selectionDetail && selectionDetail.name;
        tradingbody.selection_created_by =
          selectionDetail &&
          selectionDetail.created_by &&
          selectionDetail.created_by.username;
        tradingbody.type = selectionDetail && selectionDetail.type;
        tradingbody.company_title = itemData.name;
        tradingbody.company_id = itemData._id;
        tradingbody.comments = "";
        tradingbody.assets = assetDataFunc();
        tradingbody.approvals = [];
        if (getRolesPermissions(mainUserRes, "trading-task.create") === false) {
          onCreateTradingList(tradingbody)
            .then((res) => {
              message.success(
                res &&
                  res.payload &&
                  res.payload.data &&
                  res.payload.data.message
              );
            })
            .catch((err) => {
              console.log("error", err);
            });
        } else {
          message.error("You Don't Have the Permission");
        }
      });
    }
  };

  const onRemovingFilter = (remove, fieldName, removeSelected) => {
    let array = [...selectedArr];
    if (array && array.length > 0 && removeSelected) {
      let innerIndex = array.indexOf(removeSelected);

      array.splice(innerIndex, 1);
      setSelectedArr(array);
    }

    remove(fieldName);
  };

  const removeDropDownItems = () => {
    let array = [...dropdownItems];
    if (array && array.length > 0 && dropdownResult) {
      let innerIndex = array.indexOf(dropdownResult);
      array.splice(innerIndex, 1);

      setDropdownItems(array);
      let arr = [...selectedArr];

      arr.push(dropdownResult);
      setSelectedArr(arr);
    }
  };

  const assetDataFunc = () => {
    let assetArray = [];
    selectionDetail.assets.map((items, index) =>
      assetArray.push({
        id: items && items.asset && items.asset._id,
        title:
          items && items.asset && items.asset.title && items.asset.title.value,
        formal_license_definitions: items.formal_license_definitions,
      })
    );

    return assetArray;
  };

  const changingDeadlineDate = (date) => {
    const selectedDate = new Date(date);
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 28);

    if (selectedDate > currentDate) {
      message.error("You can select maximum 28 days");
      form.setFieldsValue({
        deadline: null,
      });
      return;
    }
  };

  return (
    <Fragment>
      <div className="no-asset-header">
        <h2>Straight Sale</h2>
        <h3>
          <ImportOutlined /> {selectionDetail && selectionDetail.name}
        </h3>
      </div>
      <div className="buyer-slot">
        <h4>SELECT POTENTIAL BUYERS</h4>
        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish}>
          <div className="buyer-slot-main">
            <div className="buyer-single-row">
              <div className="buyer-single-name">
                <span>BUYER</span>
              </div>
              <div className="buyer-single-select">
                <Form.Item name="name" rules={[{ required: false }]}>
                  {dropdownItems && (
                    <Select onChange={onChangeResale}>
                      {dropdownItems.map((data, index) => {
                        return (
                          <Option value={JSON.stringify(data)} key={index}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>
            <Form.List name="users">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    <Form.Item className="add-buyer-btn">
                      <p className="add-selection-btn">
                        <Button
                          type="link"
                          className="p0 border-0"
                          onClick={() => onAddingFilter(add)}
                          disabled={dropdownItems === [] ? false : true}
                        >
                          <PlusOutlined /> Add New Buyer
                        </Button>
                      </p>
                    </Form.Item>
                    {fields.map((field) => (
                      <Fragment>
                        <div className="buyer-single-row buyer-list-row">
                          <div className="buyer-single-name">
                            <span>BUYER</span>
                          </div>
                          <div className="buyer-single-select">
                            <Form.Item
                              name="buyer"
                              rules={[
                                {
                                  required: true,
                                  message: "Select the buyer!",
                                },
                              ]}
                            >
                              {dropdownItems && (
                                <Select onChange={onChangeResale}>
                                  {dropdownItems.map((data, index) => {
                                    return (
                                      <Option
                                        value={JSON.stringify(data)}
                                        key={index}
                                      >
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                          <MinusCircleOutlined
                            className="negotiation-close"
                            onClick={() =>
                              onRemovingFilter(
                                remove,
                                field.name,
                                searchFilterValue
                              )
                            }
                          />
                        </div>
                      </Fragment>
                    ))}
                  </div>
                );
              }}
            </Form.List>
            <div className="buyer-single-row">
              <div className="buyer-single-name">
                <span>PROPOSAL VALID UNTIL</span>
              </div>
              <div className="buyer-single-select">
                <Form.Item name="deadline" rules={[{ required: false }]}>
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={disabledDate}
                    // disabledTime={disabledDateTime}
                    showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                    onChange={changingDeadlineDate}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* <p className="can-send">*You can send maximum number of proposals/month allowed by the buyer.</p> */}
          <div className="no-asset-btns">
            <Button onClick={handleCancel} type="default">
              CANCEL
            </Button>
            <Button onClick={handleCancel} type="primary" htmlType="submit">
              SEND PROPOSAL
            </Button>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default NumberOfAssetModal;
