import React, { useState } from "react";
import { Modal, Pagination } from "antd";
import HeaderFiltter from "./HeaderFilter";
import List from "./List";
import LicenseManagementForm from "./LicenseManagementForm";
import TableEditor from "../../Common/TableEditor";

const LicenseManagement = ({
  onGetAssetsList,
  assetsList,

  onGetAssetDetail,
  assetDetail,

  onCreateAsset,
  createAsset,

  onUpdateAsset,
  updateAsset,

  onDeleteAsset,
  deleteAsset,
  location,
  history,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [visibleTE, setVisibleTE] = useState(false);

  const showModal = (modalType, selectedData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedData);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const showTableEditor = (value) => {
    setVisibleTE(true);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  return (
    <div>
      <HeaderFiltter showTableEditor={showTableEditor} />

      <List
        data={assetsList && assetsList.result}
        showModal={showModal}
        onDeleteAsset={onDeleteAsset}
        selectedData={selectedData}
        location={location}
        currentPage={currentPage}
      />

      <div className="mt20 center">
        <Pagination
          defaultCurrent={currentPage}
          total={assetsList && assetsList.pagination.total}
          onChange={onChangePagination}
        />
      </div>

      <Modal
        title={modalType === "edit" ? "Update Asset" : "New Asset"}
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        {visible && (
          <LicenseManagementForm
            handleCancel={handleCancel}
            modalType={modalType}
            selectedData={selectedData}
            onCreateAsset={onCreateAsset}
            createAsset={createAsset}
            onGetAssetDetail={onGetAssetDetail}
            assetDetail={assetDetail}
            onUpdateAsset={onUpdateAsset}
            history={history}
            currentPage={currentPage}
          />
        )}
      </Modal>

      <Modal
        visible={visibleTE}
        onCancel={cancelTableEditor}
        footer={false}
        className="modal-style"
        width={740}
      >
        <TableEditor />
      </Modal>
    </div>
  );
};

export default LicenseManagement;
