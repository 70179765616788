import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  TimePicker,
  Radio,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Link } from "react-router-dom";

import { multiLanguages } from "./../../../../../../statics/data/multiLanguages";

const MUSIC = [
  "Alternative",
  "Blues",
  "Children's Music",
  "Classical",
  "Comedy",
  "Commercial",
  "Country",
  "Electronic",
  "Enka",
  "Folk Music",
  "Hip-Hop/Rap",
  "Holiday",
  "Industrial",
  "Jazz",
  "Latin",
  "New Age",
  "Opera",
  "Pop",
  "R&amp;B/Soul",
  "Reggae",
  "Religious",
  "Rock",
  "Singer/Songwriter",
  "Soundtrack",
  "Vocal",
  "World",
  "other",
];

const DANCE = [
  "African and African-American",
  "Ballet",
  "Ballroom",
  "Ceremonial dance",
  "Contemporary",
  "Disco/Electronic dance",
  "Flamenco",
  "Free and improvised dance",
  "Historical dance",
  "Jazz dance",
  "Latin/Rhythm",
  "Novelty and fad dances",
  "Social dance",
  "Street dance",
  "Swing dance",
  "Tap dance",
  "Traditional Dance",
  "other",
];

const OPTIONS = [
  "Stage Director",
  "Director of Photography",
  "Executive Producer",
  "Production Manager",
  "Author",
  "Camera",
  "Light Design",
  "Editor",
  "Sound Engineering",
  "Cast",
  "Dance Company",
  "Dancer",
  "Orchestra",
  "Band",
  "Chorus",
  "Chorus Master",
  "Musician ",
  "Singer",
  "Conductor",
  "Composer",
  "other",
];

const onChangeTime = (time, timeString) => {
  console.log("time", time, timeString);
};

const Index = ({ helperText, requiredField, tabsCheckbox }) => {
  const [filmItems, setFilmItems] = useState([]);
  const [danceItems, setDanceItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const [selectedMultiLang, setSelectedMultiLang] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  // useEffect(() => {
  //   const [form] = Form.useForm();
  //   form.setFieldsValue(
  //     {
  //       series: [
  //         { series_title: 'Abdul Mughni', series_title_language: 'en' }
  //       ]
  //     }
  //   )
  // }, [])

  // const handleTabs = value => {
  //   setTabsChange(value)
  // };

  const filmHandleChange = (selectedItems) => {
    setFilmItems(selectedItems);
  };

  const danceHandleChange = (selectedItems) => {
    setDanceItems(selectedItems);
  };

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const onChangeLanguage = (value) => {
    setSelectedLang(value);
  };

  const onChangeMultiLanguage = (value) => {
    setSelectedMultiLang(value);
  };

  const filmFilteredOptions = MUSIC.filter((o) => !filmItems.includes(o));
  const danceFilteredOptions = DANCE.filter((o) => !danceItems.includes(o));
  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  return (
    <Row
      gutter={16}
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >
      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Chapters</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>If this asset consists of various chapters, acts, or parts, provide information on each of those.</p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="chapter">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <div>
                      <Row gutter={16}>
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Chapter Title</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <div className="d-flex align-item-center">
                                <Form.Item
                                  {...field}
                                  name={[field.name, "name"]}
                                  className="full-width mr20 mb0"
                                >
                                  <Input
                                    placeholder="Title name"
                                    className="full-width"
                                  />
                                </Form.Item>

                                <Form.Item
                                  {...field}
                                  name={[field.name, "language"]}
                                  className="full-width mr20 mb0"
                                  rules={[
                                    {
                                      pattern: new RegExp(
                                        "[a-z]{2}(-[A-Z]{2})?"
                                      ),
                                      message:
                                        "Please enter the correct language!",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select language"
                                    value={selectedLang}
                                    onChange={onChangeLanguage}
                                    style={{ width: "100%" }}
                                    showSearch
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                  >
                                    {multiLanguages.map((item, index) => (
                                      <Select.Option
                                        key={index}
                                        value={item.value}
                                      >
                                        {item.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Multi Language Titles</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.List
                                name={[field.fieldKey, "multiLanguageTitle"]}
                              >
                                {(fields, { add, remove }) => {
                                  return (
                                    <div>
                                      {fields.map((field) => (
                                        <div
                                          key={field.key}
                                          className="d-flex align-item-center mb10"
                                        >
                                          <Form.Item
                                            {...field}
                                            name={[field.name, "value"]}
                                            fieldKey={[field.fieldKey, "value"]}
                                            className="full-width mr20 mb0"
                                          >
                                            <Input
                                              placeholder="Title name"
                                              className="full-width"
                                            />
                                          </Form.Item>

                                          <Form.Item
                                            {...field}
                                            name={[field.name, "language"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "language",
                                            ]}
                                            rules={[
                                              {
                                                pattern: new RegExp(
                                                  "[a-z]{2}(-[A-Z]{2})?"
                                                ),
                                                message:
                                                  "Please enter the correct language!",
                                              },
                                            ]}
                                            className="full-width mr20 mb0"
                                          >
                                            <Select
                                              placeholder="Select language"
                                              value={selectedMultiLang}
                                              onChange={onChangeMultiLanguage}
                                              style={{ width: "100%" }}
                                              showSearch
                                              filterOption={(input, option) =>
                                                option.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                              getPopupContainer={(trigger) =>
                                                trigger.parentElement
                                              }
                                            >
                                              {multiLanguages.map(
                                                (item, index) => (
                                                  <Select.Option
                                                    key={index}
                                                    value={item.value}
                                                  >
                                                    {item.name}
                                                  </Select.Option>
                                                )
                                              )}
                                            </Select>
                                          </Form.Item>

                                          <MinusCircleOutlined
                                            className="ml10 remove-circle-tow"
                                            onClick={() => {
                                              remove(field.name);
                                            }}
                                          />
                                        </div>
                                      ))}

                                      <Form.Item>
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            add();
                                          }}
                                          block
                                        >
                                          <PlusOutlined /> Add Multi Language
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  );
                                }}
                              </Form.List>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Music Genres</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "musicGenre"]}
                                fieldKey={[field.fieldKey, "musicGenre"]}
                                rules={[
                                  {
                                    type: "array",
                                  },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  placeholder="Select multiple..."
                                  value={filmItems}
                                  onChange={filmHandleChange}
                                  style={{ width: "100%" }}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {filmFilteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Dance Genres</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "danceGenre"]}
                                fieldKey={[field.fieldKey, "danceGenre"]}
                                rules={[
                                  {
                                    type: "array",
                                  },
                                ]}
                              >
                                <Select
                                  mode="multiple"
                                  placeholder="Select multiple..."
                                  value={danceItems}
                                  onChange={danceHandleChange}
                                  style={{ width: "100%" }}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {danceFilteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Duration</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "duration"]}
                                fieldKey={[field.fieldKey, "duration"]}
                                rules={[
                                  {
                                    pattern: new RegExp(
                                      "[0-9]+:[0-5][0-9]:[0-5][0-9](.[0-9]{3})?"
                                    ),
                                    message:
                                      "Please enter the correct duration",
                                  },
                                ]}
                                className="full-width mb20"
                              >
                                <TimePicker
                                  onChange={onChangeTime}
                                  format="HH:mm:ss"
                                  use12Hours="HH:mm:ss"
                                  showNow={false}
                                  className="full-width"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Contributors</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.List
                                name={[field.fieldKey, "contributers"]}
                              >
                                {(fields, { add, remove }) => {
                                  return (
                                    <div>
                                      {fields.map((field) => (
                                        <div
                                          key={field.key}
                                          className="d-flex align-item-center mb10"
                                        >
                                          {/* <Form.Item
                                            {...field}
                                            name={[field.name, "featured"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "featured",
                                            ]}
                                            className="full-width mr20 mb0"
                                          >
                                            <Input placeholder="Name" />
                                          </Form.Item> */}

                                          <Form.Item
                                            {...field}
                                            name={[field.name, "featured"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "featured",
                                            ]}
                                            className="full-width mr20 mb0"
                                          >
                                            <Radio.Group>
                                              <Radio value="yes">Yes</Radio>
                                              <Radio value="no">No</Radio>
                                            </Radio.Group>
                                          </Form.Item>

                                          <Form.Item
                                            {...field}
                                            name={[field.name, "function"]}
                                            fieldKey={[
                                              field.fieldKey,
                                              "function",
                                            ]}
                                            className="full-width mr20 mb0"
                                          >
                                            <Select
                                              placeholder="Select function..."
                                              value={selectedItems}
                                              onChange={handleChange}
                                              style={{ width: "100%" }}
                                              getPopupContainer={(trigger) =>
                                                trigger.parentElement
                                              }
                                            >
                                              {filteredOptions.map((item) => (
                                                <Select.Option
                                                  key={item}
                                                  value={item}
                                                >
                                                  {item}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Form.Item>

                                          <MinusCircleOutlined
                                            className="ml10 remove-circle-tow"
                                            onClick={() => {
                                              remove(field.name);
                                            }}
                                          />
                                        </div>
                                      ))}

                                      <Form.Item>
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            add();
                                          }}
                                          block
                                        >
                                          <PlusOutlined /> Add Contributors
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  );
                                }}
                              </Form.List>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Chapter Id</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "id"]}
                                fieldKey={[field.fieldKey, "id"]}
                              >
                                <Input placeholder="Id" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Chapters
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;
