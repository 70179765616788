import React, { useEffect, useState } from 'react';
import { Button, Form, Avatar, Upload, message, Spin } from 'antd';
import { UploadOutlined, UserOutlined } from "@ant-design/icons";

const EditProfile = ({
  showModal,
  onUpdateProfileAndUsername,

  specificUserImage,
  onGetSpecificUserImage,

  mainUserRes,
  onGetMainUserDetail,

  onUpdateUserPicture,

  setUserProfile,
  userProfile
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    gettingProfilePicture();
  }, [])

  const gettingProfilePicture = () => {
    onGetMainUserDetail().then((res) => {
      if (res && res.payload && res.payload.photo) {
        onGetSpecificUserImage(res.payload.photo)
          .then((res) => {
            const blob = new Blob([res.payload], {
              type: "application/octet-stream"
            });
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64String = reader.result;

              setUserProfile(base64String);
            }
            setLoading(false);
          }).catch(() => {
            setLoading(false);
          })
      } else {
        setLoading(false);
      }

    })
  }

  const beforeUpload = (file) => {

    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!(isJPG || isJPEG || isPNG)) {
      message.error("The format of the uploaded image is incorrect, not JPG, JPEG, PNG");
    }
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return new Promise((resolve, reject) => {
      if (!(isJPG || isJPEG || isPNG) || (!isLt2M)) {
        reject(file);
      } else {
        resolve(file);
      }
    });
  }

  const uploadService = () => {

    return Promise.resolve();
  };

  const customRequest = async (options) => {

    options.onProgress({ percent: 0 });
    const url = await uploadService();
    options.onProgress({ percent: 100 });
    options.onSuccess({}, { ...options.file, url });
    console.log(options);
  };

  const onChangeUserPicture = (info) => {
    setLoading(true);
    if (info.file.status !== 'uploading') {

      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {

      const data = new FormData();
      const imagedata = info.file.originFileObj;
      data.append('image', imagedata);
      const logingUserId = mainUserRes && mainUserRes._id;
      onUpdateUserPicture(logingUserId, data)
        .then((res) => {
          message.success(`${info.file.name} file uploaded successfully`);
          setLoading(false);
          gettingProfilePicture();
        }).catch(() => {
          // message.success("Successfully Updated!");
          setLoading(false);
        })

    } else if (info.file.status === 'error') {

      message.error(`${info.file.name} file upload failed.`);
    }
  }

  return (
    <div className="edit-profile-style">
      <h1>Edit Profile</h1>
      <Form
        name="validate_other"
      >
        <Spin spinning={loading} size="small">
          <div className="user-profile-picture">
            <Form.Item
              name="image"
              className="mr0 mb0"
              rules={[
                { required: false, message: 'Please enter the name' }
              ]}

            >
              <div>
                <Avatar
                  src={userProfile}
                  icon={<UserOutlined />}
                />
              </div>
              <div className="upload-profile-btn">

                <Upload
                  onChange={onChangeUserPicture}
                  customRequest={customRequest}
                  beforeUpload={beforeUpload}
                >
                  <Button icon={<UploadOutlined />}></Button>
                </Upload>
              </div>
            </Form.Item>
          </div>
        </Spin>
        <div className="user-info-text">
          <h2>{mainUserRes && mainUserRes.firstname} {mainUserRes && mainUserRes.lastname}</h2>
          <p>{mainUserRes && mainUserRes.email}</p>
        </div>
        <div className="slection-asset-view profile-username">
          <div className="formal-license-view">

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>USERNAME</span>
              </div>
              <div className="formal-license-data">
                <div className="edit-profile-username"><span>@{mainUserRes && mainUserRes.username}</span></div>
              </div>
            </div>

          </div>
        </div>
      </Form>
      <div className="change-password">
        <Button type="link" onClick={() => showModal("change_password")}>
          Change Password
        </Button>
      </div>
    </div>
  )
}

export default EditProfile;