import React, { Fragment } from "react";
import { Tag } from "antd";
import { toDateString } from "./../../../Common/reUseFunctions";

const SelectionAssetListView = ({ showModal, assetObject }) => {
  return (
    <Fragment>
      {assetObject &&
        assetObject.licenses &&
        assetObject.licenses.length > 0 &&
        assetObject.licenses.map((data, index) => {
          return (
            <div className="slection-asset-view mt50" key={index}>
              <h3>FORMAL LICENSE DEFINITION</h3>

              <div key={index} className="formal-license-view">
                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>TERRITORIES</span>
                  </div>
                  <div className="formal-license-data">
                    {data.territories.map((data, index) => {
                      return <Tag key={index}>{data}</Tag>;
                    })}
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>EXCLUDING</span>
                  </div>
                  <div className="formal-license-data">
                    {data.excluding.map((data, index) => {
                      return <Tag key={index}>{data}</Tag>;
                    })}
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>CHANNELS</span>
                  </div>
                  <div className="formal-license-data">
                    {data.channels.map((data, index) => {
                      return <Tag key={index}>{data}</Tag>;
                    })}
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>RESALE ALLOWED</span>
                  </div>
                  <div className="formal-license-data">
                    <span>
                      {data.resale_allowed === true &&
                        "Yes" &&
                        data.resale_allowed === false &&
                        "No"}
                    </span>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>EXCLUSIVE LICENSE</span>
                  </div>
                  <div className="formal-license-data">
                    <span>
                      {data.exclusive_license === true &&
                        "Yes" &&
                        data.exclusive_license === false &&
                        "No"}
                    </span>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>START DATE</span>
                  </div>
                  <div className="formal-license-data">
                    <span>{toDateString(data.start_date)}</span>
                  </div>
                </div>

                <div className="formal-license-row">
                  <div className="formal-license-name">
                    <span>END DATE</span>
                  </div>
                  <div className="formal-license-data">
                    <span>{toDateString(data.end_date)}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Fragment>
  );
};

export default SelectionAssetListView;
