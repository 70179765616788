import React, { Fragment, useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

import {
  shopIcon,
  gearIcon,
  administrationIcon,
  pitchingIcon,
} from "../Common/Icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

function defaultMenuSelectedKey() {
  const selectedMenu = localStorage.getItem("selectedMenu");
  if (selectedMenu) {
    const menuData = JSON.parse(selectedMenu);
    return menuData.selectedKey;
  } else {
    return "2";
  }
}

function defaultMenuOpenKey() {
  const selectedMenu = localStorage.getItem("selectedMenu");
  if (selectedMenu) {
    const menuData = JSON.parse(selectedMenu);
    return menuData.openKey;
  } else {
    return "sub1";
  }
}

const Sidebar = ({ collapsed, onGetMainUserDetail, mainUserRes }) => {
  // eslint-disable-next-line no-unused-vars
  const [userPermissions, setUserPermissions] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(defaultMenuSelectedKey());
  const [openKeys, setOpenKeys] = useState(defaultMenuOpenKey());

  useEffect(() => {
    if (mainUserRes) {
      getUserPermissions(mainUserRes);
    }
  }, [mainUserRes]);

  const getUserPermissions = (mainUser) => {
    let allPermissions = [];

    if (mainUser && mainUser.roles && mainUser.roles.length > 0) {
      mainUser.roles.map((data, index) => {
        allPermissions = [...allPermissions, ...data.permissions];

        return allPermissions;
      });
    }
    const uniqPermissoins = allPermissions.filter(function (item, pos) {
      return allPermissions.indexOf(item) == pos;
    });
    setUserPermissions(uniqPermissoins);
  };

  const onSelectValue = (value) => {
    if (value && value.keyPath) {
      const data = {
        selectedKey: value.keyPath[0],
        openKey: value.keyPath[1],
      };
      setSelectedKeys(data.selectedKey);
      setOpenKeys(data.openKey);
      localStorage.setItem("selectedMenu", JSON.stringify(data));
    }
  };

  return (
    <Fragment>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={300}
        className="side-bar-sec"
      >
        <Menu
          mode="inline"
          defaultOpenKeys={[openKeys]}
          defaultSelectedKeys={[selectedKeys]}
          className="mt20"
          onClick={onSelectValue}
        >
          {process.env.REACT_APP_TRADING_ENABLED === "true"  ? (
            <SubMenu key="sub1" icon={shopIcon} title="License Trading">
              <Menu.Item key="1">
                <Link to="/member-catalogues">Catalogues</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/inventory-search">Inventory Search</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/trading-tasks#">Trading Tasks</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/selections">My Selections</Link>
              </Menu.Item>
            </SubMenu>
          ) : (
            <SubMenu
              className="license-menu"
              disabled={true}
              icon={shopIcon}
              title="License Trading"
            ></SubMenu>
          )}

          <Menu.Item
            key="5"
            icon={gearIcon}
            //  disabled={userPermissions && userPermissions.includes('ASSET_CREATE') ? false : true }
          >
            <Link to="/asset-management">Assets Management</Link>
          </Menu.Item>
          {/* <SubMenu key="sub2" className="menu-double-title" icon={pitchingIcon} title={<div className="double-title">Pitching & Co-Financing <span>Planned for 2020</span></div>} >
          </SubMenu> */}
          <SubMenu key="sub3" icon={administrationIcon} title="Administration">
            <Menu.Item key="6">
              <Link to="/role-management">Roles Management</Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/user-management">User Management</Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Link to="/departments-management">Departments Management</Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link to="/organization-profile">Organisation Profile</Link>
            </Menu.Item>
            <Menu.Item key="10">
              <Link to="/special-catalogues">Special Catalogues</Link>
            </Menu.Item>
            {/* <Menu.Item key="11">
              <Link to="/automated-task-assignment">Automated Task Assignment</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="12">
              <Link to="/proposals-management">Proposals Management</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="13">
              <Link to="/asset-import">Asset Imports</Link>
            </Menu.Item> */}

            <Menu.Item
              key="14"
              //  disabled={userPermissions && userPermissions.roles && userPermissions.roles && userPermissions.roles[0] && userPermissions.roles[0].permissions && userPermissions.roles[0].permissions.includes('ASSET_CREATE') ? false : true }
            >
              <Link to="/wallet">Wallets</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    </Fragment>
  );
};

export default Sidebar;
