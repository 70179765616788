import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const requestProposalReducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_TRADING_TASKS_SUCCESS:
      return { ...state, updateTradingListRes: action.payload };
    case types.UPDATE_TRADING_TASKS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, updateTradingListRes: action.payload };

    case types.CREATE_TRADING_TASKS_SUCCESS:
      return { ...state, createTradingListRes: action.payload };
    case types.CREATE_TRADING_TASKS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, createTradingListRes: action.payload };

    case types.ADD_TRADING_TASKS_SUCCESS:
      return { ...state, getAllTradingTaskRes: action.payload };
    case types.ADD_TRADING_TASKS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, getAllTradingTaskRes: action.payload };

    case types.POST_TRADING_TASKS_SUCCESS:
      return { ...state, postTradingTaskRes: action.payload };
    case types.POST_TRADING_TASKS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, postTradingTaskRes: action.payload };

    default:
      return state;
  }
};
