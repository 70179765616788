import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

export const getAssetsList = (
  skip,
  limit,
  marketReady,
  nonMarketReady,
  publishedAsset,
  search,
  orderDisorder
) => {
  const options = {
    url: `assets?skip=${skip}&limit=${limit}&search=${search}&market_ready=${marketReady}&not_market_ready=${nonMarketReady}&published=${publishedAsset}`,
    // url: `assets?status=${marketReady}&status=${nonMarketReady}&status=${publishedAsset}&sort=created_at&order=asc`
  };

  options.types = [
    types.GET_ASSETS_LIST_SUCCESS,
    types.GET_ASSETS_LIST_FAILURE,
  ];

  return apiNew.get(options);
};

export const getAssetDetail = (id) => {
  const options = { url: `assets/${id ? id : ""}` };

  options.types = [
    types.GET_ASSET_DETAIL_SUCCESS,
    types.GET_ASSET_DETAIL_FAILURE,
  ];

  return apiNew.get(options);
};

export const createAsset = (data) => {
  const options = { url: `assets` };
  options.types = [types.CREATE_ASSET_SUCCESS, types.CREATE_ASSET_FAILURE];

  return apiNew.post(options, data);
};

export const updateAsset = (id, data) => {
  const options = { url: `assets/${id}` };

  options.types = [types.UPDATE_ASSET_SUCCESS, types.UPDATE_ASSET_FAILURE];

  return apiNew.put(options, data);
};

export const deleteAsset = (id) => {
  const options = { url: `assets/${id}` };

  options.types = [types.DELETE_ASSET_SUCCESS, types.DELETE_ASSET_FAILURE];

  return apiNew.delete(options);
};
