import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const organizationReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_ORGANIZATION_SUCCESS:
      return { ...state, createOrganizationRes: action.payload }
    case types.CREATE_ORGANIZATION_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, createOrganizationRes: action.payload }

    case types.GET_ORGANIZATION_DETAIL_SUCCESS:
      return { ...state, organizationRes: action.payload }
    case types.GET_ORGANIZATION_DETAIL_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, organizationRes: action.payload }

    case types.GET_ORGANIZATION_IMAGE_SUCCESS:
      return { ...state, organizationImageRes: action.payload }
    case types.GET_ORGANIZATION_IMAGE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, organizationImageRes: action.payload }

    case types.UPDATE_ORGANIZATION_PROFILE_SUCCESS:
      return { ...state, organizationProfileRes: action.payload }
    case types.UPDATE_ORGANIZATION_PROFILE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, organizationProfileRes: action.payload }

      case types.GET_ORGANIZATION_PROFILE_SUCCESS:
      return { ...state, getOrganizationProfileRes: action.payload }
    case types.GET_ORGANIZATION_PROFILE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, getOrganizationProfileRes: action.payload }

    default:
      return state
  }
}