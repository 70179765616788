import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MoreOutlined } from '@ant-design/icons';

class Tasks extends Component {

    render() {
        const { task, index } = this.props;

        return (
            <Draggable draggableId={task.id} index={index}>
                {(provided, snapshot) => {
                    return (
                        <div className={snapshot.isDragging ? 'table-edit-task move-task' : 'table-edit-task'}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <span className="drag-icon">
                                <MoreOutlined />
                                <MoreOutlined />
                            </span>
                            <span className="title">{task.title}</span>
                        </div>
                    )
                }}
            </Draggable>
        )
    }
}

export default Tasks;
