import React from "react";
import {
  Table,
  Space,
  Tooltip,
  Button,
  Card,
  Avatar,
  Tag,
  Spin,
  Pagination,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import {
  yyyymmddAndTimeFormate,
  getRolesPermissions,
  deleteSuperAdmin,
} from "../../../Common/reUseFunctions";

const { Meta } = Card;

const UserManagementBox = ({
  mainUserRes,
  currentPage,
  showModal,
  showTableEditor,
  loading,
  onClickSelection,
  delConfirmModal,
  userManagementList,
  onChangePagination,
}) => {
  const columns = [
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <div className="user-status">{text}</div>,
    },
    {
      title: "NAME",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => {
        return (
          <Link to="#" onClick={() => showModal("edit-user", record)}>
            <Meta
              className="meta-avatar-align meta-link-avatar"
              disabled={record.status === "blocked" ? true : false}
              avatar={
                <Avatar
                  size={24}
                  src={`${process.env.REACT_APP_API_BASE_URL}users/profile/${record.photo}`}
                />
              }
              title={text}
            />
          </Link>
        );
      },
    },
    {
      title: "USER NAME",
      dataIndex: "username",
      key: "username",
      render: (text, record) => (
        <div disabled={record.status === "blocked" ? true : false}>{text}</div>
      ),
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <div disabled={record.status === "blocked" ? true : false}>{text}</div>
      ),
    },
    {
      title: "CREATED DATE",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => (
        <div disabled={record.status === "blocked" ? true : false}>
          {yyyymmddAndTimeFormate(text)}
        </div>
      ),
    },
    {
      title: "PROFILE STATUS",
      dataIndex: "profile_status",
      key: "profile_status",
      render: (text, record) => (
        <div disabled={record.status === "blocked" ? true : false}>
          <Space size="middle" className="action-table usre-management-action">
            <span className="text-capitalize">{text}</span>
            {getRolesPermissions(mainUserRes, "user.profile-status") ===
            false ? (
              <Tooltip title="Change">
                <Button
                  type="link"
                  onClick={() => showTableEditor("change-status", record)}
                >
                  <u>Change</u>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "ROLES",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        return (
          <>
            {record && record.roles && record.roles.length > 0 && (
              <>
                <Tag className="text-uppercase">
                  {record.roles && record.roles[0] && record.roles[0].name}
                </Tag>
                {record.roles && record.roles[1] && (
                  <Tag className="text-uppercase">
                    {record.roles && record.roles[1] && record.roles[1].name}
                  </Tag>
                )}
              </>
            )}
            {record && record.roles && record.roles.length > 2 && (
              <Button
                className="specific-role-btn"
                type="link"
                onClick={() => showTableEditor("user-roles", record)}
              >
                <u>+{record.roles.length - 2}</u>
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => {
        const adminCheck =
          record.username === "superadmin" || record.username === "admin";

        return (
          <Space size="middle" className="action-table usre-management-action">
            {getRolesPermissions(mainUserRes, "user.block") === false ? (
              <Tooltip
                title={record.status === "blocked" ? "Unblock" : "Block"}
              >
                {record.status === "blocked" ? (
                  <Button
                    type="link"
                    onClick={() => showTableEditor("block-user", record)}
                  >
                    <u style={{ width: "30px" }}>Unblock</u>
                  </Button>
                ) : (
                  <Button
                    // disabled={adminCheck ? true : false}
                    // style={{ color: adminCheck ? "#999" : "" }}
                    type="link"
                    onClick={() => showTableEditor("block-user", record)}
                  >
                    {adminCheck ? (
                      <div style={{ width: "30px" }}>Block</div>
                    ) : (
                      <u style={{ width: "30px" }}>Block</u>
                    )}
                  </Button>
                )}
              </Tooltip>
            ) : (
              ""
            )}
            {getRolesPermissions(mainUserRes, "user.edit") === false ? (
              <Tooltip title="Edit">
                <Button
                  type="link"
                  onClick={() => showModal("edit-user", record)}
                >
                  <u>Edit</u>
                </Button>
              </Tooltip>
            ) : (
              ""
            )}
            {getRolesPermissions(mainUserRes, "user.delete") === false ? (
              <Tooltip title="Delete">
                {
                   record.roles.map(role => role.name).includes('Super Admin') ?
                   <Button type="link" onClick={() => delConfirmModal(record)} disabled={!deleteSuperAdmin(userManagementList)}>
                  {
                    deleteSuperAdmin(userManagementList) ? (
                      <u>Delete</u>
                    ) : (
                      <div>Delete</div>
                    )
                  }
                  </Button> :
                  <Button type="link" onClick={() => delConfirmModal(record)}>
                    <u>Delete</u>
                  </Button>
                }
              </Tooltip>
            ) : (
              ""
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      {getRolesPermissions(mainUserRes, "user.list") === false ? (
        <Table
          columns={columns}
          dataSource={
            userManagementList &&
            userManagementList.users.length > 0 &&
            userManagementList.users
          }
          className="table-style"
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                onClickSelection && onClickSelection(record);
              }, // click row
            };
          }}
          rowKey={(record) => `user-${record._id}`}
        />
      ) : (
        <div className="d-flex justify-center full-width">
          <Empty />
        </div>
      )}

      <div span={24} className="d-flex justify-center mt50">
        <Pagination
          defaultCurrent={currentPage}
          defaultPageSize={
            userManagementList &&
            userManagementList.pagination &&
            userManagementList.pagination.limit
          }
          onChange={onChangePagination}
          total={
            userManagementList &&
            userManagementList.pagination &&
            userManagementList.pagination.total
          }
        />
      </div>
    </Spin>
  );
};

export default UserManagementBox;
