import React, { Fragment, useState } from 'react';
import { Button, Avatar, Modal, Input } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

const usersList = [
  { name: 'John Doe', type: 'Asset Owner' },
  { name: 'Sophia', type: 'Asset Owner' },
  { name: 'Jacob', type: 'Asset Owner' },
  { name: 'William', type: 'Asset Owner' },
  { name: 'Jayden', type: 'Asset Owner' },
  { name: 'Olivia', type: 'Asset Owner' },
  { name: 'Easton', type: 'Asset Owner' },
  { name: 'Rylee', type: 'Asset Owner' }
]

const AuctionDetail = ({
  history
}) => {
  const [search, setSearch] = useState('');
  const [visible, setVisible] = useState(false);

  const onSearchUser = (e) => {
    setSearch(e.target.value);
  }

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = e => {
    setVisible(false);
  };

  return (
    <Fragment>
      <div className="d-flex align-item-center w50-per-imp">
        <div className="checkout-box-group mb20">
          <div className="d-flex asset-vendor align-item-center">
            <div>
              <Avatar shape="square" size={50} icon={<UserOutlined />} />
            </div>
            <div>
              <h4>VENDOR COMPANY</h4>
              <h3>Asset owner</h3>
            </div>
          </div>
        </div>
        <Button type="primary" icon={<PlusOutlined />} onClick={showModal} />
      </div>

      <Modal
        title="Invite User"
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <Input placeholder="Search Users" onChange={onSearchUser} value={search} className="mb10" />
        {
          usersList.map((data, index) => {
            return (
              <div className="checkout-box-group mb10" key={index}>
                <Link onClick={handleCancel}>
                  <div className="d-flex asset-vendor align-item-center">
                    <div>
                      <Avatar shape="square" size={50} icon={<UserOutlined />} />
                    </div>
                    <div>
                      <h4>{data.name}</h4>
                      <h3>{data.type}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })
        }
      </Modal>
    </Fragment>
  )
}

export default AuctionDetail;