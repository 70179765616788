import React, { useState } from 'react';
import { Form, DatePicker, Space, Select, Input, TimePicker  } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

const formatMin = 'mm';
const formatHour = 'HH';

const FILMGENRE = [
  'Action',
  'Adult',
  'Adventure',
  'Animation',
  'Biography',
  'Comedy',
  'Crime',
  'Documentary',
  'Drama',
  'Family',
  'Fantasy',
  'Film Noir',
  'History',
  'Horror',
  'Live Event',
  'Music',
  'Musical',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Short Film',
  'Sport',
  'Thriller',
  'War',
  'Western',
];

const MUSICGENRE = [
  "Alternative",
  "Blues",
  "Children's Music",
  "Classical",
  "Comedy",
  "Commercial",
  "Country",
  "Electronic",
  "Enka",
  "Folk Music",
  "Hip-Hop/Rap",
  "Holiday",
  "Industrial",
  "Jazz",
  "Latin",
  "New Age",
  "Opera",
  "Pop",
  "R&amp;B/Soul",
  "Reggae",
  "Religious",
  "Rock",
  "Singer/Songwriter",
  "Soundtrack",
  "Vocal",
  "World",
];

const { Option } = Select;
const { RangePicker } = DatePicker;

const SelectFilterItem = ({
  field,
  remove,

  dropdownItems,
  setDropdownResult,
  selectedArr,

  onRemovingFilter,
  setDisableAdd,
}) => {

  const [filmGenre, setFilmGenre] = useState("");
  const [musicGenre, setMusicGenre] = useState("");
  const [searchFilterValue, setSearchFilterValue] = useState("");


  const onChangeDropDownValue = (value) => {
    setSearchFilterValue(value);
    setDropdownResult(value);
    setDisableAdd(false);
  }

  const onChangeFilmGenre = (value) => {
    setFilmGenre(value)
  }

  const onChangeMusicGenre = (value) => {
    setMusicGenre(value)
  }

  const filteredFilm = FILMGENRE.filter(o => !filmGenre.includes(o));

  const filteredMusic = MUSICGENRE.filter(o => !musicGenre.includes(o));

  return (
    <Space key={field.key} style={{ display: 'flex', marginBottom: 8, alignItems: "baseline" }} align="start">

      <Form.Item
        {...field}
        name={[field.name, 'asset_select_filter']}
        fieldKey={[field.fieldKey, 'asset_select_filter']}
        rules={[{ required: true, message: 'Select First Filter' }]}
        className="select-width"
      >
        <Select
          placeholder="Please select..."
          onChange={onChangeDropDownValue}
        >
          {dropdownItems.map((item, index) => (
            <Select.Option key={index} value={item} disabled={
              ((selectedArr && selectedArr.length > 0) && selectedArr.includes(item)) ?
                true : false
            }>
              <span>
                {item}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {
        searchFilterValue === "Production Year" &&

        <Form.Item
          {...field}
          name={[field.name, 'production_year']}
          fieldKey={[field.fieldKey, 'production_year']}
          rules={[{ required: true, message: 'Select Date' }]}
          className="input-width"
        >
          <RangePicker picker="year" />
        </Form.Item>
      }
      {
        searchFilterValue === "Film Genre" &&

        <Form.Item
          {...field}
          name={[field.name, 'film_genre']}
          fieldKey={[field.fieldKey, 'film_genre']}
          rules={[{ required: true, message: 'Select Genre' }]}
          className="input-width"
        >
          <Select
            mode="multiple"
            placeholder="Please select..."
            onChange={onChangeFilmGenre}
          >
            {filteredFilm.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }

      {
        searchFilterValue === "Music Genre" &&

        <Form.Item
          {...field}
          name={[field.name, 'Music_genre']}
          fieldKey={[field.fieldKey, 'Music_genre']}
          rules={[{ required: true, message: 'Select Genre' }]}
          className="input-width"
        >
          <Select
            mode="multiple"
            placeholder="Please select..."
            onChange={onChangeMusicGenre}
          >
            {filteredMusic.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }

      {
        searchFilterValue === "Video Resolution" &&

        <Form.Item
          {...field}
          name={[field.name, 'video_resolution']}
          fieldKey={[field.fieldKey, 'video_resolution']}
          rules={[{ required: true, message: 'Select Resolution' }]}
          className="input-width"
        >
          <Select
            placeholder="Please select..."
          // onChange={onChangeValue}
          >
            <Option value="UHD/4K">UHD/4K</Option>
            <Option value="PAL/NTSC">PAL/NTSC</Option>
            <Option value="8K">8K</Option>
            <Option value="HD">HD</Option>
            <Option value="8K">Film 35 mm</Option>
          </Select>
        </Form.Item>
      }
      {
        searchFilterValue === "Director" &&

        <Form.Item
          {...field}
          name={[field.name, 'director']}
          fieldKey={[field.fieldKey, 'director']}
          rules={[{ required: true, message: 'Select Director' }]}
          className="input-width"
        >
         <Input />
        </Form.Item>
      }
      {
        searchFilterValue === "Duration Minutes" &&

        <Form.Item
          {...field}
          name={[field.name, 'duration_minute']}
          fieldKey={[field.fieldKey, 'duration_minute']}
          rules={[{ required: true, message: 'Select Minutes' }]}
          className="input-width"
        >
          <TimePicker.RangePicker format={formatMin} />
        </Form.Item>
      }
      {
        searchFilterValue === "Duration Hour" &&

        <Form.Item
          {...field}
          name={[field.name, 'duration_hour']}
          fieldKey={[field.fieldKey, 'duration_hour']}
          rules={[{ required: true, message: 'Select Hours' }]}
          className="input-width"
        >
          <TimePicker.RangePicker format={formatHour} />
        </Form.Item>
      }
      {
        searchFilterValue === "Production Company" &&

        <Form.Item
          {...field}
          name={[field.name, 'production_company']}
          fieldKey={[field.fieldKey, 'production_company']}
          rules={[{ required: true, message: 'Select Production Company' }]}
          className="input-width"
        >
         <Input />
        </Form.Item>
      }

      <MinusCircleOutlined
        onClick={() => onRemovingFilter(remove, field.name, searchFilterValue)}
      />
    </Space>
  )
}

export default SelectFilterItem;