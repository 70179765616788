import React from "react";
import { Input, Row, Col } from "antd";
import { searchIcon } from "./../../Common/Icons";
import { getRolesPermissions } from "./../../Common/reUseFunctions";

const Filter = ({ mainUserRes }) => {
  return (
    <Row gutter={16} className="mb15">
      {getRolesPermissions(mainUserRes, "automated-task-assignment.list") ===
      false ? (
        <Col span={24}>
          <div className="inventory-search-bar wallet-search">
            <Input placeholder="Search" prefix={searchIcon} />
          </div>
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
};

export default Filter;
