import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ProgressScale = ({
  saveValuesCorrection
}) => {

  return (
    <div className="columns-mapping-right">
      <div className="mapping-right-item mapping-right-item-gray">
        <h5>FILE NAME</h5>
        <p>import2021.csv</p>
      </div>
      <div className="mapping-right-item mapping-right-item-gray">
        <h5>TOTAL ASSETS</h5>
        <h3>820</h3>
      </div>
      <div className="mapping-right-item mapping-right-item-gray">
        <h5>COLUMNS MAPPED</h5>
        <h3>52 out of 55</h3>
      </div>
      <div className="mapping-right-item">
        <h5>ERRORS FOUND</h5>
        <h3>12</h3>
      </div>
      <div className="header-btn-groups d-block">
        <Link onClick={() => saveValuesCorrection()}>
        <Button type="primary">SAVE CHANGES</Button>
        </Link>
      </div>
    </div>
  )
}

export default ProgressScale;