import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Index = ({
  requiredField,
  helperText
}) => {

  const [tabsChange, setTabsChange] = useState(true);
  return (

    <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Film Setting</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Indicate where and when this production was recorded.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="filmSetting">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field, index) => (
                    <Row gutter={16} className="mb0" key={index}>
                      <Col span={24}>
                        <div className="metadata-field-row">
                          <div className="metadata-field-name"><h3 className=""><span>Film Setting</span></h3></div>
                          <div className="metadata-filed">
                            <div className="d-flex align-item-center">
                              <Form.Item
                                {...field}
                                name={[field.name, 'date']}
                                key={`date-${index}`}
                                fieldKey={[field.fieldKey, 'date']}
                                rules={[
                                  {
                                    pattern: new RegExp("[0-9]{4}(\-[0-1][0-9]\-[0-3][0-9])?"),
                                    message: "Please enter the correct date"
                                  }
                                ]}
                                className="full-width mr20 mb0"
                              >
                                <DatePicker
                                  className="full-width"
                                  getPopupContainer={trigger => trigger.parentElement}
                                />
                              </Form.Item>

                              <Form.Item
                                {...field}
                                name={[field.name, 'location']}
                                key={`location-${index}`}
                                fieldKey={[field.fieldKey, 'location']}
                                rules={[{ required: false, message: 'Please enter the location' }]}
                                className="full-width mr20 mb0"
                              >
                                <Input placeholder="Location" />
                              </Form.Item>

                              <MinusCircleOutlined
                                className="ml10 remove-circle-tow"
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    {
                      fields.length > 0 ? null :
                        <Form.Item>
                          <Button
                            type="link"
                            onClick={() => {
                              add();
                            }}
                            style={{ width: '100%' }}
                          >
                            <PlusOutlined /> Add Film Setting
                      </Button>
                        </Form.Item>
                    }
                  </div>

                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;