import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const LicenseManagementForm = ({
  handleCancel,
  modalType,
  selectedData,

  onCreateAsset,
  onGetAssetDetail,
  assetDetail,

  search,
  currentPage,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (modalType === "edit") {
      onGetAssetDetail(selectedData && selectedData._id);
    }
  }, []);

  useEffect(() => {
    if (modalType === "edit") {
      form.setFieldsValue({
        title: assetDetail && assetDetail.title,
      });
    }
  }, [assetDetail]);

  const onFinish = (values) => {
    if (modalType === "edit") {
      handleCancel();
    } else {
      onCreateAsset(values);
      //   .then((res) => {
      //   history.push(`/inventory/${res && res.payload._id}`)
      // });
      message.success("Successfully Created");
      handleCancel();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Inventory Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Please input your Inventory title!",
          },
        ]}
      >
        <Input placeholder="Inventory Title" />
      </Form.Item>

      <Form.Item>
        <div className="text-right">
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="ml10">
            {modalType === "edit" ? "Update" : "Create"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default LicenseManagementForm;
