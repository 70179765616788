import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Filter from "./HeaderFilter";
import PermissionsRoleList from "./PermissionsRoleList";
import CreateNewRole from "./CreateNewRole";
import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";
import { getRolesPermissions } from "../../../Common/reUseFunctions";

const Selection = ({
  onUpdateRole,
  specificRole,
  onGetSpecificRole,
  onDeleteRole,
  permissionsRes,
  onGetPermissions,
  mainUserRes,
  sessionRes,
  onCreateRoles,
  rolesList,
  onGetRolesList,
  onGetSpecificUserDetail,
  getSpecificUserDetail,
  onGetMainUserDetail,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [delConfirm, setDelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "role.list") === false) {
      setLoading(true);
      getRolesList(currentPage, 9, search);
    }
  }, [currentPage, search, mainUserRes]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const showModal = (modalType, selectedData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedData);
  };

  const delConfirmModal = (modalType, data) => {
    setSelectedData(data);
    setModalType(modalType);
    setDelConfirm(true);
  };

  const onDeletingItem = () => {
    setLoading(true);
    if (selectedData) {
      onDeleteRole(selectedData)
        .then((resp) => {
          getRolesList(currentPage, 9, search);
          setDelConfirm(false);
          message.success(resp && resp.payload && resp.payload.message);
          setLoading(false);
        })
        .catch((res) => {
          message.error(
            res &&
              res.payload &&
              res.payload.response &&
              res.payload.response.data &&
              res.payload.response.data.message
          );
          setDelConfirm(false);
          setLoading(false);
        });
    }
  };

  const getRolesList = (currentPage, limit, search) => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
    onGetRolesList(skip, limit, search)
      .then((res) => {
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}
        showModal={showModal}
        mainUserRes={mainUserRes}
        sessionRes={sessionRes}
        setSearch={setSearch}
      />

      <PermissionsRoleList
        delConfirmModal={delConfirmModal}
        rolesList={rolesList}
        showModal={showModal}
        loading={loading}
        currentPage={currentPage}
        onChangePagination={onChangePagination}
        mainUserRes={mainUserRes}
      />
      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={420}
        >
          <CreateNewRole
            handleCancel={handleCancel}
            modalType={modalType}
            sessionRes={sessionRes}
            onCreateRoles={onCreateRoles}
            specificRole={specificRole}
            onGetSpecificRole={onGetSpecificRole}
            selectedData={selectedData}
            onUpdateRole={onUpdateRole}
            onGetRolesList={onGetRolesList}
            permissionsRes={permissionsRes}
            onGetPermissions={onGetPermissions}
            currentPage={currentPage}
            search={search}
            onGetSpecificUserDetail={onGetSpecificUserDetail}
            getSpecificUserDetail={getSpecificUserDetail}
            onGetMainUserDetail={onGetMainUserDetail}
            mainUserRes={mainUserRes}
          />
        </Modal>
      )}

      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onDeletingItem}
        text="Some of your colleagues might lose their permissions. This action cannot be undone."
      />
    </div>
  );
};

export default Selection;
