import React from "react";
import { Button } from "antd";

const UpdateAssetModal = ({ cancelTableEditor }) => {
  return (
    <div className="update-asset-modal">
      <h2>Update Assets</h2>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </p>
      <p>
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur.
      </p>
      <Button onClick={cancelTableEditor} type="primary">
        I UNDERSTAND & CONTINUE
      </Button>
    </div>
  );
};

export default UpdateAssetModal;
