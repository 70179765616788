import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Modal } from "antd";
import { useLocation } from "react-router-dom";

import HeaderFilter from "./HeaderFilter/TopTitleSection";
import MemberProfile from "./MemberProfile";
import CatalogueAssetsGrid from "./CatalogueAssetsGrid";

import LanguagesModal from "./../../Common/Components/LanguagesModal";

import { SelectionModelComp, InventoryModelComp } from "../../../Containers";

const selectedData = [
  { name: "English" },
  { name: "French" },
  { name: "Arabic" },
  { name: "Urdu" },
];

const CataloguesAssetsView = ({ sessionRes }) => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [visibleLanguages, setVisibleLanguages] = useState(false);
  const [data, setData] = useState({});
  const [catalogueName, setCatalogueName] = useState(null);
  const [catalogueImage, setCatalogueImage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { state } = location;

      setData(state);
      setCatalogueName(state.organization.name);
      setCatalogueImage(state.organization.logo);
    }
  }, [location]);

  const showAssetLanguages = (type, data) => {
    setVisibleLanguages(true);
  };

  const cancelAssetLanguages = () => {
    setVisibleLanguages(false);
  };

  const showModal = (value) => {
    setVisible(true);
    setModalType(value);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <Fragment>
      <Row gutter={16}>
        <Col className="mb25" span={24}>
          <HeaderFilter
            catalogueName={catalogueName}
            catalogueImage={catalogueImage}
          />
          <MemberProfile catalogue={data} />
          <CatalogueAssetsGrid
            showModal={showModal}
            showAssetLanguages={showAssetLanguages}
            assets={data}
          />
        </Col>
      </Row>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className={modalType === "inventory" ? "detail-model" : "modal-style"}
        width={450}
      >
        {modalType === "inventory" && (
          <InventoryModelComp showTableEditor={showModal} />
        )}
        {modalType === "add_to_selection" && (
          <SelectionModelComp handleCloseSelection={handleCancel} />
        )}
      </Modal>

      <LanguagesModal
        visible={visibleLanguages}
        selectedData={selectedData}
        handleCancel={cancelAssetLanguages}
      />
    </Fragment>
  );
};

export default CataloguesAssetsView;
