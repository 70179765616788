import React, { useState } from "react";
import { Button, Form, Input, Spin, message } from "antd";

const ProfileStatusModal = ({
  checkingStatus,
  checkingPendingStatus,
  selectedData,
  handleCancel,
  currentPage,
  search,
  onGetUserManagementList,
  onProfileStatus,
  checkingBlockedStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const updateStatus = {
    public: "private",
    private: "public",
  };

  const userId = selectedData && selectedData._id;
  const status = selectedData && selectedData.profile_status;

  const onFinish = (values) => {
    setLoading(true);

    onProfileStatus(userId, updateStatus[status], values)
      .then((res) => {
        const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
        setLoading(false);
        onGetUserManagementList(
          skip,
          9,
          checkingBlockedStatus(),
          checkingStatus(),
          checkingPendingStatus(),
          search
        );
        handleCancel();
        message.success(`Profile status changed to ${updateStatus[status]}`);
      })
      .catch((res) => {
        const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
        setLoading(false);
        onGetUserManagementList(
          skip,
          9,
          checkingBlockedStatus(),
          checkingStatus(),
          checkingPendingStatus(),
          search
        );
        handleCancel();
      });
  };

  return (
    // <div className="delete-confirm-style license-edit-model">
    <div className="license-edit-model">
      <h3>Are you sure?</h3>

      <Spin size="large" spinning={loading}>
        <Form name="change_profile_status" onFinish={onFinish}>
          {updateStatus[status] === "public" && (
            <div className="slection-asset-view">
              <div className="formal-license-view">
                <div className="formal-license-row">
                  <div className="formal-license-name required">
                    <span>REASON</span>
                    <p>Provide reason for profile status change</p>
                  </div>
                  <div className="formal-license-data">
                    <Form.Item
                      name="description"
                      className="full-width mb0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the description",
                        },
                      ]}
                    >
                      <Input placeholder="" className="full-width" />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="text-right asset-action-btn">
            <Button type="default" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ml10"
              // onClick={onFinish}
              loadinging={loading.toString()}
            >
              Change
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ProfileStatusModal;
