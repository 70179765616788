import React, { useState } from "react";
import ReactPlayer from "react-player";
import { PlaySquareOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";

const videoPlayer = ({ url }) => {
  const [permissionError, setPermissionError] = useState(false);
  return (
    <div className="width-video">
      {permissionError === true ? (
        <div className="main-video-player">
          <div className="video-error">
            <span>
              <h1>Sorry</h1>
              <h3>
                Because of its privacy settings, this video cannot be played
                here.
              </h3>
              <Button
                href={url}
                target="_blank"
                shape="round"
                type="primary"
                icon={<PlayCircleOutlined />}
              >
                Watch Here
              </Button>
            </span>
          </div>
        </div>
      ) : (
        <div className="embed-video-player">
          <Button
            className="play-btn"
            shape="round"
            type="primary"
            icon={<PlayCircleOutlined />}
          >
            Play Now
          </Button>

          <div className="video-player">
            <ReactPlayer
              playing={true}
              url={url}
              controls={true}
              playing={true}
              light={true}
              onError={(error) => {
                setPermissionError(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default videoPlayer;
