import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import ContentArea from '../ContentArea';
import FooterSection from './../Footer';
import { useIdleTimer } from 'react-idle-timer'

import { HeaderSection, MainSidebar } from '../../Containers';

const { Content, Footer } = Layout;

const App = ({ history, onGetSession, sessionRes }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [lastActive, setLastActive] = useState(false)

  useEffect(() => {
    if (lastActive === true) {
      localStorage.clear();
      window.location.reload();
      // history.push('/')
    }
  }, [lastActive]);

  const handleOnIdle = event => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    setLastActive(true)
  }

  const handleOnActive = event => {
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = (e) => {
    // console.log('user did something', e)
  }

  const { 
    // getRemainingTime, getLastActiveTime 
  } = useIdleTimer({
    timeout: 60000 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Layout className="main-layout-style">

        <HeaderSection
          toggleSidebar={toggleSidebar}
          collapsed={collapsed}
          history={history}
        />

        <Layout className="site-layout">
          <MainSidebar collapsed={collapsed} />
          <Content className="site-content">
            <ContentArea
              history={history}
              sessionRes={sessionRes}
            />
          </Content>
        </Layout>
        <Footer><FooterSection /></Footer>
      </Layout>
    </>
  );
}

export default App;