import React from "react";

import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./../../../../../Common/SearchFilterDropdown";
import GearButton from "./../../../../../Common/GearButton";
import UpdateAssetButton from "./UpdateAssetButton";
import { Input } from "antd";
import { searchIcon } from "../../../../../Common/Icons";
import { getRolesPermissions } from "./../../../../../Common/reUseFunctions";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onFinish,
  showTableEditor,
  setSearch,

  setMarketReady,
  marketReady,
  setNotMarketReady,
  notMarketReady,
  setPublishedAssets,
  publishedAssets,

  onHidePopover,
  setVisiblePopover,
  visiblePopover,
  mainUserRes,
  assetsList,
}) => {
  return (
    <div>
      {getRolesPermissions(mainUserRes, "asset.list") === false ? (
        <div className="inventory-bottom-filter">
          <InventorySwitchSec
            setMarketReady={setMarketReady}
            marketReady={marketReady}
            setNotMarketReady={setNotMarketReady}
            notMarketReady={notMarketReady}
            setPublishedAssets={setPublishedAssets}
            publishedAssets={publishedAssets}
            assetsList={assetsList}
          />
          {/* <div className="d-flex">
        <InventorySearchDropDown
          onChangeLayout={onChangeLayout}
          changeLayout={changeLayout}
          onFinish={onFinish}
          showTableEditor={showTableEditor}
          setSearch={setSearch}

          onHidePopover={onHidePopover}
          setVisiblePopover={setVisiblePopover}
          visiblePopover={visiblePopover}
        />
        <div className="layout-btn d-flex">
          <GearButton
            changeLayout={changeLayout}
            showTableEditor={showTableEditor}
          />
          <UpdateAssetButton
            showTableEditor={showTableEditor}
          />
        </div>
      </div> */}
          <div className="inventory-search-bar">
            <Input
              placeholder="Search"
              prefix={searchIcon}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySearchDropdown;
