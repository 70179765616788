import React, { useState } from "react";
import { Row, Col, Pagination, Button } from "antd";
import { Link } from "react-router-dom";
import {
  getOnlyMinutes,
  getOnlyYear,
} from "./../../../../Common/reUseFunctions";

const InventoryGrid = ({
  showTableEditor,
  inventoryListRes,
  showModal,
  changeInventory,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {inventoryListRes &&
        inventoryListRes.assets &&
        !Array.isArray(inventoryListRes.assets) && (
          <Row gutter={[32, 24]}>
            {inventoryListRes &&
              inventoryListRes.assets &&
              inventoryListRes.assets.assets &&
              inventoryListRes.assets.assets.map((data, index) => {
                return (
                  <Col key={index} className="inventory-grid">
                    <a
                      type="primary"
                      href="#!"
                      onClick={() => showModal(data._id)}
                    >
                      <div className="style-image-grid">
                        <div class="container">
                          {/* Image start */}
                          <div class="image-wrapper">
                            <img
                              src={data.preview_image && data.preview_image[0]}
                              alt="poster"
                            />
                          </div>
                          {/* Image end */}
                        </div>
                        {/* <img
                          src={data.preview_image && data.preview_image[0]}
                          alt="poster"
                        /> */}
                      </div>
                    </a>
                    <div>
                      <h3>{data.title.value}</h3>
                    </div>
                    <div className="asset-grid-info">
                      <div className="w30-per">
                        <div className="mb10">
                          <h4>Ownership</h4>
                          <span>{data.owner}</span>
                        </div>
                        <div>
                          <h4>languages</h4>
                          <span>{data.title.language}</span>
                          {/* <Button className="specific-role-btn" type="link" onClick={() => showAssetLanguages("languages")}> <u>+15</u></Button> */}
                        </div>
                      </div>
                      <div className="w40-per">
                        <div className="mb10">
                          <h4>Length</h4>
                          <span>{getOnlyMinutes(data.updated_at)}</span>
                        </div>
                        <div>
                          <h4>Production Company</h4>
                          <span>{data.production_company.value}</span>
                        </div>
                      </div>
                      <div className="w30-per">
                        <div className="mb10">
                          <h4>Director</h4>
                          <span>{data.directors.join(", ")}</span>
                        </div>
                        <div>
                          <h4>production year</h4>
                          <span>{getOnlyYear(data.production_date)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="asset-grid-btn">
                      <Button
                        type="link"
                        className="p0 border-0"
                        onClick={() => showModal(data._id)}
                      >
                        <u> Preview</u>
                      </Button>
                      <Button type="link" className="p0 border-0">
                        <Link to={`/inventory-search/${data._id}`}>
                          <u> Details</u>
                        </Link>
                      </Button>
                      <Button
                        type="link"
                        className="p0 border-0"
                        onClick={() => showTableEditor("add_to_selection")}
                      >
                        <u> Add to Selection</u>
                      </Button>
                    </div>
                    <div className="asset-grid-seprator"></div>
                  </Col>
                );
              })}
          </Row>
        )}

      {changeInventory === "Both" && (
        <Row className="d-flex justify-center">
          <Col col={24}>
            <Pagination
              defaultCurrent={currentPage}
              defaultPageSize={
                inventoryListRes &&
                inventoryListRes.pagination &&
                inventoryListRes.pagination.limit
              }
              onChange={onChangePagination}
              total={
                inventoryListRes &&
                inventoryListRes.pagination &&
                inventoryListRes.pagination.total
              }
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default InventoryGrid;
