import React from "react";
import { Button, Modal, Form, Input } from "antd";

import { closeIcon } from './../../Common/Icons';
import { getOnlyYear } from './../../Common/reUseFunctions';

const SaveSearchModal = ({
  handleCancel,
  visible,
  searchResult,
  setSearchResult,
}) => {

  const removeFilterItems = (index, item) => {
    let array = [...searchResult];

    if (item) {
      if (array[index].film_genre) {
        let innerIndex = array[index].film_genre.indexOf(item);

        array[index].film_genre.splice(innerIndex, 1);
        setSearchResult(array);
      }

      if (array[index].Music_genre) {
        let innerIndex = array[index].Music_genre.indexOf(item);

        array[index].Music_genre.splice(innerIndex, 1);
        setSearchResult(array);
      }
    }

    if (!item) {
      array.splice(index, 1);
      setSearchResult(array);
    }
  };

  const onFinish = () => {
    handleCancel()
  }

  return (
    <Modal
      title={false}
      visible={visible}
      onCancel={handleCancel}
      footer={false}
      className="modal-style"
      width={420}
    >
      <div className="license-edit-model">
        <h3>Save Search</h3>
        <Form
          name="save_search"
        onFinish={onFinish}
        >
          <div className="slection-asset-view mb20-imp">
            <div className="formal-license-view">

              <div className="formal-license-row">
                <div className="formal-license-name">
                  <span> PRESET NAME</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter role name!' }]}
                    required
                  >
                    <Input placeholder="New Search" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <h4>QUERIES</h4>
          <div className="inventory-search-result save-search-quries">
            {
              searchResult.map((data, index) => {
                return (
                  <div className="inventory-search-tabs" key={index}>
                    <span>{data.asset_select_filter}</span>
                    <span className="inventory-result">

                      {
                        (data && data.film_genre && data.film_genre.length > 0) &&
                        data.film_genre.map((item, index) => {
                          return (
                            <span key={index}>
                              {item}
                              <Button type="link" onClick={() => removeFilterItems(index, item)}>
                                {closeIcon}
                              </Button>
                            </span>
                          )
                        })
                      }

                      {
                        (data && data.Music_genre && data.Music_genre.length > 0) &&
                        data.Music_genre.map((item, index) => {
                          return (
                            <span key={index}>
                              {item}
                              <Button type="link" onClick={() => removeFilterItems(index, item)}>
                                {closeIcon}
                              </Button>
                            </span>
                          )
                        })
                      }

                      {
                        (data && data.video_resolution) &&
                        <span>
                          {data.video_resolution}
                          <Button type="link" onClick={() => removeFilterItems(index)}>
                            {closeIcon}
                          </Button>
                        </span>
                      }

                      {
                        (data && data.production_year) &&
                        <span>
                          {getOnlyYear(data.production_year[0])} - {getOnlyYear(data.production_year[1])}
                          <Button type="link" onClick={() => removeFilterItems(index)}>
                            {closeIcon}
                          </Button>
                        </span>
                      }

                      {/* <Button type="link" onClick={() => removeFilterItems(data)}>
                    {closeIcon}
                  </Button> */}
                    </span>
                  </div>
                )
              })
            }
          </div>
          <div className="formal-license-btn">
            <Button htmlType="submit" type="primary">
              SAVE SEARCH PRESET
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default SaveSearchModal;
