import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectionProposal from "../Components/ContentArea/SelectionProposal/SelectionProposalIndex";

import {
  getAllTradingTask,
  getFormalLicense,
  createFormalLicense,
  getSelectionAssets,
  updateFormalLicenseForm,
  deleteFormalLicenseForm,
  cancelNegotiation,
  uploadAgreementFile,
  getLogsList,
  getOrganizationProfile,
  getOrganizationImage,
  sendInvitation, updateTradingTaskStatus,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    getAllTradingTaskRes: state.requestProposalReducer.getAllTradingTaskRes,
    getFormalLicenseRes: state.selectionProposalReducer.getFormalLicenseRes,
    createFormalLicenseRes:
      state.selectionProposalReducer.createFormalLicenseRes,
    selectionAssetsListRes: state.selectionReducer.selectionAssetsListRes,
    updateFormalLicenseFormRes:
      state.selectionProposalReducer.updateFormalLicenseFormRes,
    deleteFormalLicenseFormRes:
      state.selectionProposalReducer.deleteFormalLicenseFormRes,
    cancelNegotiationRes: state.selectionProposalReducer.cancelNegotiationRes,
    uploadAgreementFileRes:
      state.selectionProposalReducer.uploadAgreementFileRes,
    getLogsListRes: state.selectionProposalReducer.getLogsListRes,
    getOrganizationProfileRes:
      state.organizationReducer.getOrganizationProfileRes,
    organizationImageRes: state.organizationReducer.organizationImageRes,
    sendInvitationRes: state.selectionProposalReducer.sendInvitationRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllTradingTask: bindActionCreators(getAllTradingTask, dispatch),
    onGetFormalLicense: bindActionCreators(getFormalLicense, dispatch),
    onCreateFormalLicense: bindActionCreators(createFormalLicense, dispatch),
    onGetSelectionAssets: bindActionCreators(getSelectionAssets, dispatch),
    onUpdateFormalLicenseForm: bindActionCreators(
      updateFormalLicenseForm,
      dispatch
    ),
    onDeleteFormalLicenseForm: bindActionCreators(
      deleteFormalLicenseForm,
      dispatch
    ),
    onCancelNegotiation: bindActionCreators(cancelNegotiation, dispatch),
    onUploadAgreementFile: bindActionCreators(uploadAgreementFile, dispatch),
    onGetLogsList: bindActionCreators(getLogsList, dispatch),
    onGetOrganizationProfile: bindActionCreators(
      getOrganizationProfile,
      dispatch
    ),
    onGetOrganizationImage: bindActionCreators(getOrganizationImage, dispatch),
    onsendInvitation: bindActionCreators(sendInvitation, dispatch),
    onUpdateTradingTaskStatus: bindActionCreators(updateTradingTaskStatus, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionProposal);
