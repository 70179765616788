import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DepartmentsManagement from "../Components/ContentArea/Administration/DepartmentsManagement";

import {
  createDepartment,
  getDepartmentList,
  getSpecificDepartmentDetail,
  updateDepartment,
  departmentDelete,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    createDepartmentRes: state.departmentReducer.createDepartmentRes,
    departmentList: state.departmentReducer.departmentList,
    getSpecificDepartmentDetail:
      state.departmentReducer.getSpecificDepartmentDetail,
    updateDepartmentRes: state.departmentReducer.updateDepartmentRes,
    deleteDepartmentRes: state.departmentReducer.deleteDepartmentRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateDepartment: bindActionCreators(createDepartment, dispatch),
    onGetDepartmentList: bindActionCreators(getDepartmentList, dispatch),
    onGetSpecificDepartment: bindActionCreators(
      getSpecificDepartmentDetail,
      dispatch
    ),
    onUpdateDepartment: bindActionCreators(updateDepartment, dispatch),
    onDeleteDepartment: bindActionCreators(departmentDelete, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentsManagement);
