import React from 'react';
import TopTitleSection from "./TopTitleSection";

const HeaderFilter = ({ setSearchResult, index }) => {

  const onFinish = e => {
    setSearchResult(e.target.value)
  };

  return (
    <div className="catalogue-filter">
      <TopTitleSection onFinish={onFinish} index={index} />
    </div>
  )
}

export default HeaderFilter;