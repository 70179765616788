import React from 'react';
import { Switch } from 'antd';

const InventorySwitchFilter = ({
  selectSales,
  setSelectPurchase,
  setSelectionTypeChange,
  setSelectSales,
  selectPurchase,
}) => {

  // useEffect(() => {
  //   if (selectSales === false) {
  //     setSelectPurchase(true);
  //   }

  //   if (selectPurchase === true) {
  //     setSelectSales(false);
  //     setSelectionTypeChange(false)
  //   }

  // }, [setSelectPurchase, selectSales, setSelectSales, selectPurchase])

  // const onChangeSales = (checked) => {
  //   setSelectionTypeChange(checked)
  // }

  const onChangeSales = (selectSales) => {
    setSelectSales(selectSales)
  }

  const onChangePurchase = (selectPurchase) => {
    setSelectPurchase(selectPurchase)
  }

  return (
    <div>
      <div className="inventry-switch-btns">
        <span>
          <Switch
            size="small"
            onChange={() => onChangeSales(!selectSales)}
            defaultChecked
          /> Sales
        </span>
        <span>
          <Switch
            defaultChecked
            size="small"
            onChange={() => onChangePurchase(!selectPurchase)}
          /> Purchase
        </span>
      </div>
    </div>
  )
}

export default InventorySwitchFilter;