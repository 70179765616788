import React, { useState, useEffect } from "react";
import { Form, Input, Button, message } from "antd";

import { getUrlLastId, getEmail } from "./../Common/reUseFunctions";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const Register = ({
  history,
  location,
  onSignIn,
  setUserPasswordRes,
  onUpdateUserPassword,
}) => {
  const [loading, setLoading] = useState(false);
  const [urlToken, setUrlToken] = useState("");
  const [getUrlEmail, setGetUrlEmail] = useState("");

  useEffect(() => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);
    setUrlToken(getUrlPath);
    // getEmail()
    setGetUrlEmail(getEmail());
  }, []);

  const onFinish = (values) => {
    const data = {
      password: values.password,
    };

    onUpdateUserPassword(urlToken, data)
      .then((res) => {
        message.success("Password successfully set.");
        history.push("/login");
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="auth-page license-edit-model">
      <div className="main-wrap">
        <div className="authentic-heading">
          <h1>Content.Agent</h1>
        </div>
        <Form
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h3 className="form-title">Welcome Aboard!</h3>
          <div className="slection-asset-view">
            <div className="formal-license-view">
              <div className="formal-license-row">
                <div className="formal-license-name text-uppercase">
                  <span>Email Address</span>
                </div>
                <div className="formal-license-data">
                  <Input placeholder={getUrlEmail} disabled={true} />
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name text-uppercase">
                  <span>Password</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password placeholder="*********" />
                  </Form.Item>
                </div>
              </div>

              <div className="formal-license-row">
                <div className="formal-license-name text-uppercase">
                  <span>Confirm Password</span>
                </div>
                <div className="formal-license-data">
                  <Form.Item
                    name="confirm-password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="*********" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="full-width mt30 p10 h-auto text-uppercase"
            >
              Complete Activation
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Register;
