import React, { useState } from "react";
import { Radio, Carousel, Button } from "antd";
import { getLength } from "./../../../Common/reUseFunctions";
import LanguagesModal from "./../../../Common/Components/LanguagesModal";

const AssetModal = ({
  assetDetail,
  showTableEditor,
  getInventorySeachDetailRes,
  onGetInventorySeachDetail,
  assetId,
}) => {
  const [visibleLanguages, setVisibleLanguages] = useState(false);
  const [selectedDataLang, setSelectedDataLang] = useState();

  const onChangeLanguage = (e) => {
    console.log("language", e.target.value);
  };

  const showAssetLanguages = (type, data) => {
    setVisibleLanguages(true);
    setSelectedDataLang(data);
  };

  const cancelAssetLanguages = () => {
    setVisibleLanguages(false);
  };

  return (
    <div>
      <div className="asset-detail-model">
        <div className="asset-model-video">
          {
            // assetDetail && assetDetail.trailers[0] && assetDetail.trailers[0].url ?
            // <ReactPlayer url={assetDetail && assetDetail.trailers[0] && assetDetail.trailers[0].url} className="react-video" controls={true}  playing={true} light={true} /> :
            <Carousel>
              {assetDetail &&
                assetDetail.preview_image &&
                assetDetail.preview_image.length &&
                assetDetail.preview_image.map((data, index) => {
                  return (
                    <div className="preview-image" key={`asset-image-${index}`}>
                      <img
                        src={data}
                        style={{ width: "100%" }}
                        alt={"carousel"}
                      />
                    </div>
                  );
                })}
            </Carousel>
          }
          {/* {playIcon} */}
        </div>
        <div className="asset-model-info">
          <div style={{ width: "42px" }} className="radio-btn-style lang-btn">
            <Radio.Group
              onChange={(e) => onChangeLanguage(e.target.value)}
              buttonStyle="solid"
            >
              {assetDetail &&
                assetDetail.audio_tracks &&
                assetDetail.audio_tracks.length > 0 &&
                assetDetail.audio_tracks.map((data, index) => (
                  <Radio.Button key={`language-${index}`}>
                    {data.language}
                  </Radio.Button>
                ))}
              {assetDetail &&
                assetDetail.audioTrack &&
                assetDetail.audioTrack.length > 4 && (
                  <Button
                    className="specific-role-btn specific-lang-btn"
                    type="link"
                    onClick={() => showAssetLanguages("languages")}
                  >
                    <u>
                      +
                      {assetDetail &&
                        assetDetail.audioTrack &&
                        assetDetail.audioTrack.length - 4}
                    </u>
                  </Button>
                )}
            </Radio.Group>
          </div>
          <div className="d-flex">
            <div className="asset-model-text">
              <h3>
                {assetDetail && assetDetail.title && assetDetail.title.value}
              </h3>
              <p className="duration">
                <span>{assetDetail && assetDetail.production_date}</span>
                {assetDetail &&
                  assetDetail.versions &&
                  assetDetail.versions.length &&
                  getLength(assetDetail.versions[0].duration)}
              </p>
              <h4>Summary</h4>
              {assetDetail &&
              assetDetail.content_summaries_short &&
              assetDetail.content_summaries_short.length
                ? assetDetail.content_summaries_short.map((data, index) => {
                    return data.value;
                  })
                : ""}
            </div>
            <div className="asset-model-func">
              <ul>
                <li>
                  <span>Production Company:</span>
                  {assetDetail && assetDetail.production_company
                    ? assetDetail.production_company.value
                    : ""}
                </li>
                <li>
                  <span>Co-Production Company:</span>
                  {assetDetail &&
                  assetDetail.co_production_companies &&
                  assetDetail.co_production_companies.length
                    ? assetDetail.co_production_companies.map((data, index) => {
                        return data.value;
                      })
                    : ""}
                </li>
                <li>
                  <span>Film Director:</span>
                    <div className="f-artist-com">
                      {assetDetail &&
                      assetDetail.directors &&
                      assetDetail.directors.length
                        ? assetDetail.directors.map((data, index) => {
                            return (
                              <div className="inlineProperty">
                                {data}
                                <span>,</span>{" "}
                              </div>
                            );
                          })
                        : ""}
                      </div>
                </li>
                <li>
                  <span>Film Genre:</span>
                  {assetDetail && assetDetail.film_genres
                    ? assetDetail.film_genres.join(", ")
                    : ""}
                </li>
                <li>
                  <span>Featured Artist:</span>
                  <div className="f-artist-com overflowDots">
                    {assetDetail &&
                    assetDetail.contributors &&
                    assetDetail.contributors.length
                      ? assetDetail.contributors.map((data, index) => {
                          return (
                            <div className="inlineProperty">
                              {data.artist.value}
                              <span>,</span>{" "}
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="asset-model-btn">
          <Link to={`/inventory-search/${assetDetail && assetDetail._id}`}><Button type="default">Details</Button></Link>
          <Link onClick={() => showTableEditor("add_to_selection")}><Button type="primary">Add to Selection</Button></Link>
        </div> */}
      </div>
      <LanguagesModal
        visible={visibleLanguages}
        selectedData={selectedDataLang}
        handleCancel={cancelAssetLanguages}
      />
    </div>
  );
};

export default AssetModal;
