import React from "react";
import { Button } from "antd";

const BlockUserModal = ({
  text,
  loading,
  textUnblock,
  selectedData,
  onBlockSpecificUser,
}) => {
  return (
    <div className="update-asset-modal">
      <h2>{selectedData.blocked_at ? "Unblock User" : "Block User"}</h2>
      <p>{selectedData.blocked_at ? textUnblock : text}</p>
      <Button loading={loading} onClick={onBlockSpecificUser} type="primary">
        I UNDERSTAND & CONTINUE
      </Button>
    </div>
  );
};

export default BlockUserModal;
