import { apiNew } from '../../service/newApi';
import * as types from '../Constants';

export const uploadCSV = (file, config = null) => {
  const options = {
    url: `convert-csv-to-xml`
  };

  options.types = [
    types.UPLOAD_CSV_FILE_SUCCESS,
    types.UPLOAD_CSV_FILE_FAILURE
  ];

  return apiNew.uploadMedia(options, file, config);
}

export const uploadXML = (file, config = null) => {
  const options = {
    url: `import-xml`
  };

  options.types = [
    types.UPLOAD_CSV_FILE_SUCCESS,
    types.UPLOAD_CSV_FILE_FAILURE
  ];

  return apiNew.uploadMedia(options, file, config);
}