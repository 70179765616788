import React from "react";
import { Button } from "antd";

const SignNowModal = ({ handleCancel }) => {
  return (
    <div className="sign-now-modal">
      <h2>Please Enter Your Key to Sign</h2>
      <div className="sign-key">
        <span>sadfi86dsgfdsaf86dsaf</span>
      </div>
      <p>
        <strong>Note:</strong> Once you sign a contract with your key - you
        can't cancel or change it afterwards.
      </p>
      <Button type="primary" onClick={handleCancel}>
        I UNDERSTAND AND CONTINUE
      </Button>
    </div>
  );
};

export default SignNowModal;
