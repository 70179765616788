import React, { Fragment } from 'react';
import { Button, Radio, Modal, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import Selections from '../../../Containers/Selections'

const AssetTeaser = ({ data, showModal, visible, handleCancel, selectionType, selectionData, onClickSelection }) => {

  const onChange = (e) => {
  }

  return (
    <Fragment>
      <div style={{ width: "810px", padding: "20px" }}>
        <div className="d-flex">
          <div className="asset-t-video">
            <div>
              <iframe title="asset video" width="200" height="150" src="https://www.youtube.com/embed/RSUq4VfWfjE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div className="asset-t-lang">
              <Radio.Group onChange={onChange} defaultValue="a">
                <Radio.Button value="a">EN</Radio.Button>
                <Radio.Button value="b">DE</Radio.Button>
                <Radio.Button value="c">FR</Radio.Button>
                <Radio.Button value="d">SK</Radio.Button>
              </Radio.Group>
            </div>
          </div>

          <div className="asset-t-text">
            <div className="asset-text-title">
              <h4>ENGLISH TITLE (YEAR)</h4>
              <h4>107’, 52’</h4>
              <h5>PRODUCER, CO-PRODUCER</h5>
              <h6>Featured Contributor 1, Featured Contributor 2</h6>
            </div>
            <div className="asset-text-sum">
              <h5>Short Summary</h5>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
            </div>
            <div className="asset-text-cont">
              <h5>CONTRIBUTORS</h5>
              <div className="d-flex">
                <ul>
                  <li>John Doe (Musician)</li>
                  <li>Jane Doe (Dancer)</li>
                </ul>
                <ul>
                  <li>Somebody Else (Role of “Max”)</li>
                  <li>Another Person (Director of Photography)</li>
                </ul>
              </div>
            </div>
            <div className="add-read-btn">
              {
                (selectionData && selectionData.name) ?
                  <div>
                    <strong className="mr10">{selectionData.name}</strong>
                    <Tooltip title="Delete">
                      <Button type="danger" onClick={() => onClickSelection(null)}><DeleteOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <Button type="primary" onClick={() => showModal('add_to_selection')}><EditOutlined /></Button>
                    </Tooltip>
                  </div>
                  :
                  <Button type="primary" onClick={() => showModal('add_to_selection')}>Add to Selection</Button>
              }

              <Link to={`/marketplace/${data.id}`}><Button>Read more …</Button></Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Add to selection"
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        style={{ top: 20 }}
      >
        <Selections selectionType={selectionType} onClickSelection={onClickSelection} />

      </Modal>
    </Fragment>
  )
}

export default AssetTeaser;