import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectionsComp from '../Components/ContentArea/Administration/RolesManagement';

import {
  createRoles,
  getRolesList,
  getSpecificRole,
  updateRole,
  deleteRole,
  getAvailablePermissoins,
  getSpecificUserDetail,
  mianUserDetail
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    createRoles: state.rolesReducer.createRoles,
    rolesList: state.rolesReducer.rolesList,
    specificRole: state.rolesReducer.specificRole,
    updateRoleRes: state.rolesReducer.updateRoleRes,
    deleteRole: state.rolesReducer.deleteRole,
    permissionsRes: state.rolesReducer.permissionsRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    mainUserRes: state.userReducer.mainUserRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCreateRoles: bindActionCreators(createRoles, dispatch),  
    onGetRolesList: bindActionCreators(getRolesList, dispatch),
    onGetSpecificRole: bindActionCreators(getSpecificRole, dispatch),
    onUpdateRole: bindActionCreators(updateRole, dispatch),
    onDeleteRole: bindActionCreators(deleteRole, dispatch),
    onGetPermissions: bindActionCreators(getAvailablePermissoins, dispatch),
    onGetSpecificUserDetail: bindActionCreators(getSpecificUserDetail, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectionsComp);

