import React from "react";
import { Input } from "antd";

import { searchIcon } from "../../../../../../../Common/Icons";

const InventorySearchDropdown = ({ onSearch }) => {
  return (
    <div className="d-flex">
      <div className="inventory-search-bar">
        <Input placeholder="Search" prefix={searchIcon} onChange={onSearch} />
      </div>
    </div>
  );
};

export default InventorySearchDropdown;
