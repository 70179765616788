import React from "react";
import { Switch } from "antd";

const InventorySwitchFilter = () => {
  return (
    <div>
      <div className="inventry-switch-btns">
        <span>
          <Switch
            defaultChecked={true}
            size="small"
          />
          Allowed
        </span>
        <span>
          <Switch
            defaultChecked={true}
            size="small"
          />
          Blocked
        </span>
      </div>
    </div>
  );
};

export default InventorySwitchFilter;
