import React from "react";

import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor,
  currentTab,
  onHidePopover,
  setVisiblePopover,
  visiblePopover,
  onSaveSearch,
  setSearch,
  setCurrentTab,
  onChangeTabs,
}) => {
  return (
    <div>
      <TopTitleSection
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        onChangeTabs={onChangeTabs}
      />

      {/* <InventoryFilters
        showTableEditor={showTableEditor}
        currentTab={currentTab}

        onHidePopover={onHidePopover}
        setVisiblePopover={setVisiblePopover}
        visiblePopover={visiblePopover}

        onSaveSearch={onSaveSearch}
        setSearch={setSearch}
      /> */}
    </div>
  );
};

export default HeaderFilter;
