import React from 'react';
import { Button } from 'antd';

const TopTitleSection = ({
  showModal,
  sessionRes,

  organizationRes,
}) => {
  return (
    <div className="my-market-inventory">
      <div><h3>Columns Mapping</h3></div>
      <div className="header-btn-groups import-asset-menu">
        <Button className="mr20" type="link">Uploading File</Button>
        <Button className="mr20 active" type="link">Columns Mapping</Button>
        <Button className="mr20" type="link">Import Assets</Button>
      </div>
    </div>
  )
}

export default TopTitleSection;