import React from "react";
import { Input, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Filter = ({ onSearch, search }) => {
  return (
    <Row gutter={16} className="mb15">
      <Col span={24}>
        <Input
          value={search}
          onChange={onSearch}
          prefix={<SearchOutlined />}
          placeholder="Search Organization by name"
          className="width-300"
        />
      </Col>
    </Row>
  );
};

export default Filter;
