import React from "react";
import { Button } from "antd";
import { pluseFilledIcon } from "./../../../../Common/Icons";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const TopTitleSection = ({ showModal, sessionRes, mainUserRes }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>Departments Management</h3>
      </div>
      <div>
        {getRolesPermissions(mainUserRes, "department.create") === false ? (
          <p className="add-selection-btn add-new-selection">
            <Button
              type="default"
              className="p0 border-0"
              onClick={() => showModal("add-user")}
            >
              {pluseFilledIcon}
              Add New Department
            </Button>
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TopTitleSection;
