import React from "react";
import TopTitleSection from "./TopTitleSection";

const HeaderFilter = ({
  showModal,
  sessionRes,
  organizationRes,
  mainUserRes,
}) => {
  return (
    <div>
      <TopTitleSection
        showModal={showModal}
        sessionRes={sessionRes}
        organizationRes={organizationRes}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default HeaderFilter;
