import { combineReducers } from "redux";
import { userReducer } from "./User";
import { walletReducer } from "./Wallet";
import { assetReducer } from "./Assets";
import { selectionReducer } from "./Selections";
import { messages } from "./messages";
import { assetMetadataReducer } from "./AssetsMetadata";
import { inventorySearchReducer } from "./InventorySearch";
import { rolesReducer } from "./Roles";
import { userManagementReducer } from "./UserManagement";
import { catalogueReducer } from "./Catalogue";
import { specialCatalogueReducer } from "./SpecialCatalogue";
import { organizationReducer } from "./Organization";
import { departmentReducer } from "./DepartmentManagement";
import { tradingTaskReducer } from "./TradingTasks";
import { requestProposalReducer } from "./RequestProposal";
import { selectionProposalReducer } from "./SelectionProposal";
import { sendMessageReducer } from "./AddMessage";
import { taskAssigmentReducer } from "./TaskAssignment";
import { closingRoomReducer } from "./ClosingRoom";
import { uploadCSVReducer } from "./UploadCSV";
import { timezonesReducer } from "./Timezones";
import { notificationsReducer } from "./Notifications";

const reducersObj = {
  userReducer,
  walletReducer,
  assetReducer,
  selectionReducer,
  messages,
  assetMetadataReducer,
  inventorySearchReducer,
  rolesReducer,
  userManagementReducer,
  catalogueReducer,
  specialCatalogueReducer,
  organizationReducer,
  departmentReducer,
  tradingTaskReducer,
  requestProposalReducer,
  selectionProposalReducer,
  sendMessageReducer,
  taskAssigmentReducer,
  closingRoomReducer,
  uploadCSVReducer,
  timezonesReducer,
  notificationsReducer,
};

export default combineReducers(reducersObj);
