import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InventoryModelComp from '../Components/ContentArea/LicenseTrading/InventorySearch/InventoryModal';

import {
  getSelectionsList,
  getSelectionDetail,
  createSelection,
  updateSelection,
  deleteSelection
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    selectionsList: state.selectionReducer.selectionsList,
    selectionDetail: state.selectionReducer.selectionDetail,
    createSelection: state.selectionReducer.createSelection,
    updateSelection: state.selectionReducer.updateSelection,
    deleteSelection: state.selectionReducer.deleteSelection,
    sessionRes: state.userReducer.sessionRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetSelectionsList: bindActionCreators(getSelectionsList, dispatch),
    onGetSelectionDetail: bindActionCreators(getSelectionDetail, dispatch),
    onCreateSelection: bindActionCreators(createSelection, dispatch),
    onUpdateSelection: bindActionCreators(updateSelection, dispatch),
    onDeleteSelection: bindActionCreators(deleteSelection, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModelComp);