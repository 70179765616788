export const userRole = [
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Sales Agent",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Sales Agent",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Blocked",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Private",
    role: "Administrator",
    block: "Unblock"
  },
  {
    status: "Blocked",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Private",
    role: "Administrator",
    block: "Unblock"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
  {
    status: "Active",
    name: "Jasmine Griffin",
    userName: "jasmine_griffin",
    emailAddress: "carolyn.ramirez@mail.com",
    createdDate: "13-09-2020 20:30",
    profileStatus: "Public",
    role: "Administrator",
    block: "Block"
  },
]