import { Skeleton } from "antd";
import React from "react";

const TopTitleSection = ({ catalogueName, catalogueImage }) => {
  return (
    <div className="my-market-inventory">
      <div>
        <h3>{catalogueName}</h3>
      </div>
      <div>
        {
          <img
            height={"50"}
            src={
              catalogueImage ? (
                `${process.env.REACT_APP_API_BASE_URL}organizations/${catalogueImage}/image`
              ) : (
                <Skeleton.Image />
              )
            }
            alt={catalogueName}
          />
        }
      </div>
    </div>
  );
};

export default TopTitleSection;
