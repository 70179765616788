import React from "react";
import { Comment } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { TimeFormate } from "../../Common/reUseFunctions";

const Message = ({ message, author, getAllTradingTaskRes }) => {
  const date = message.created_at;
  const assignId =
    getAllTradingTaskRes &&
    getAllTradingTaskRes.data &&
    getAllTradingTaskRes.data.assigned_to_object &&
    getAllTradingTaskRes.data.assigned_to_object._id;
  return (
    <div
      className={
        assignId === message.from_contact_id
          ? "my-message-item"
          : "my-message-item from-message-item"
      }
    >
      <div>
        <div className="my-username">
          {/* {author}, {date} */}
          {moment(date).format("YYYY/MM/DD, kk:mm")}
        </div>
        <Comment className="message-box" content={<p>{message.message}</p>} />
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default Message;
