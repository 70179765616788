import React from "react";
import { MessagesList, AddMessage } from "../../../Containers";

import ChatUserInfo from "../SelectionProposal/Chat/ChatUserInfo";
import ChatVideoCall from "../SelectionProposal/Chat/ChatVideoCall";

const ChatApp = ({
  showModal,
  videoCall,
  dropVideoCall,
  userId,
  showChat,
  location,
  history,
  onCancelNegotiation,
  cancelNegotiationRes,
  tradingTaskId,
  getAllTradingTaskRes,
  mainUserRes,
  rightOrganizationData,
}) => {
  return (
    <div className="chat-style">
      <ChatUserInfo
        videoCall={videoCall}
        showModal={showModal}
        userId={userId}
        onCancelNegotiation={onCancelNegotiation}
        cancelNegotiationRes={cancelNegotiationRes}
        location={location}
        history={history}
        getAllTradingTaskRes={getAllTradingTaskRes}
        mainUserRes={mainUserRes}
        rightOrganizationData={rightOrganizationData}
      />
      <ChatVideoCall
        videoCall={videoCall}
        dropVideoCall={dropVideoCall}
        userId={userId}
        showModal={showModal}
      />
      {/* <Sidebar /> */}
      <MessagesList
        showModal={showModal}
        showChat={showChat}
        location={location}
      />

      <AddMessage tradingTaskId={tradingTaskId} />
    </div>
  );
};

export default ChatApp;
