import React, { Fragment, useState } from "react";
import { Form, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import CommnetsForm from "./CommentsForm";

const CommnetsFormIndex = ({ helperText, requiredField }) => {
  const [tabsChange, setTabsChange] = useState(true);

  return (
    <Row
      gutter={16}
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >
      <Col className="pl0_important" span={24}>
        <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Comments</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>
              Provide any additional comment that you would like to add to this
              asset.
            </p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="comments">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <Fragment key={field.key}>
                      <Row gutter={16} className="mb20">
                        <CommnetsForm field={field} />
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Comment
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default CommnetsFormIndex;
