const initialData = {
    tasks: {
        'task-1': {
            id: 'task-1',
            title: 'Title',
        },
        'task-2': {
            id: 'task-2',
            title: 'Length',
        },
        'task-3': {
            id: 'task-3',
            title: 'Director',
        },
        'task-4': {
            id: 'task-4',
            title: 'Languages',
        },
        'task-5': {
            id: 'task-5',
            title: 'Production Company',
        },
        'task-6': {
            id: 'task-6',
            title: 'production year',
        },


        'task-7': {
            id: 'task-7',
            title: 'genres',
        },
        'task-8': {
            id: 'task-8',
            title: 'Subtitle tracks',
        },
        'task-9': {
            id: 'task-9',
            title: 'PG Ratings',
        },
        'task-10': {
            id: 'task-10',
            title: 'Video tracks',
        },
        'task-11': {
            id: 'task-11',
            title: 'Audio tracks',
        },
        'task-12': {
            id: 'task-12',
            title: 'Music Genres',
        }
    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'Selected Columns',
            taskIds: ['task-1', 'task-2', 'task-3', 'task-4', 'task-5', 'task-6']
        },
        'column-2': {
            id: 'column-2',
            title: 'Available Columns',
            taskIds: ['task-7', 'task-8', 'task-9', 'task-10', 'task-11', 'task-12']
        }
    },
    columnOrder: ['column-1', 'column-2']
}

export default initialData;
