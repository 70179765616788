import React from "react";
import { Collapse, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import ColImg from "../../../../statics/images/col-img.png";

const { Panel } = Collapse;

const RequestProposalTab = ({ selectionAssets, selectionId, showModal }) => {
  const genExtra = () => (
    <div className="i-selecion-btn s-col-actions">
      <Button type="link">Remove</Button>
    </div>
  );

  return (
    <div className="License-assets-collapse c-col-main trading-task-detail-tabs">
      <Collapse
      //  defaultActiveKey={['1']}
      >
        <Panel header="The Lion King" key="1" extra={genExtra()}>
          <div className="catalogues-p-grid c-col-panel">
            <div className="c-col-img">
              <img src={ColImg} alt="Catalogues" />
            </div>
            <div className="catalogues-p-info">
              <div className="c-d-title">
                <h4>The Lion King</h4>
                <span>
                  <LeftOutlined />
                  Director's Cut
                  <RightOutlined />
                </span>
              </div>
              <p className="duration">
                <span>2020</span> 1 hr 52 min
              </p>
              <p className="m-cata">
                <span>PRODUCTION COMPANY</span> Walt Disney Pictures
              </p>
              <p className="m-cata">
                <span>CO-PRODUCTION</span> Fairview Entertainment, British Film
                Commission
              </p>
              <p className="m-cata">
                <span>FILM DIRECTOR</span> Jon Favreau
              </p>
              <p className="m-cata">
                <span>FEATURED ARTISTS</span> Ella Elephant (Musician,
                Trombone), Elton John (Composer)
              </p>
            </div>
          </div>
        </Panel>
        <Panel header="The Lion King" key="2" extra={genExtra()}>
          <div className="catalogues-p-grid c-col-panel">
            <div className="c-col-img">
              <img src={ColImg} alt="Catalogues" />
            </div>
            <div className="catalogues-p-info">
              <h4>The Lion King</h4>
              <p className="duration">
                <span>2020</span> 1 hr 52 min
              </p>
              <p className="m-cata">
                <span>PRODUCTION COMPANY</span> Walt Disney Pictures
              </p>
              <p className="m-cata">
                <span>CO-PRODUCTION</span> Fairview Entertainment, British Film
                Commission
              </p>
              <p className="m-cata">
                <span>FILM DIRECTOR</span> Jon Favreau
              </p>
              <p className="m-cata">
                <span>FEATURED ARTISTS</span> Ella Elephant (Musician,
                Trombone), Elton John (Composer)
              </p>
            </div>
          </div>
        </Panel>
        <Panel header="The Lion King" key="3" extra={genExtra()}>
          <div className="catalogues-p-grid c-col-panel">
            <div className="c-col-img">
              <img src={ColImg} alt="Catalogues" />
            </div>
            <div className="catalogues-p-info">
              <h4>The Lion King</h4>
              <p className="duration">
                <span>2020</span> 1 hr 52 min
              </p>
              <p className="m-cata">
                <span>PRODUCTION COMPANY</span> Walt Disney Pictures
              </p>
              <p className="m-cata">
                <span>CO-PRODUCTION</span> Fairview Entertainment, British Film
                Commission
              </p>
              <p className="m-cata">
                <span>FILM DIRECTOR</span> Jon Favreau
              </p>
              <p className="m-cata">
                <span>FEATURED ARTISTS</span> Ella Elephant (Musician,
                Trombone), Elton John (Composer)
              </p>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default RequestProposalTab;
