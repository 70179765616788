import React from "react";
import { Button } from "antd";
import { closeIcon } from "./../../../../../../Common/Icons";
import { getOnlyYear } from "./../../../../../../Common/reUseFunctions";

const InventorySearchResult = ({ searchResult, setSearchResult }) => {
  const removeFilterItems = (index, item) => {
    let array = [...searchResult];
    if (item) {
      if (array[index].film_genre) {
        let innerIndex = array[index].film_genre.indexOf(item);

        array[index].film_genre.splice(innerIndex, 1);
        setSearchResult(array);
      }

      if (array[index].Music_genre) {
        let innerIndex = array[index].Music_genre.indexOf(item);

        array[index].Music_genre.splice(innerIndex, 1);
        setSearchResult(array);
      }
    }
    if (!item) {
      array.splice(index, 1);
      setSearchResult(array);
    }
  };

  return (
    <div className="inventory-search-result">
      <div>
        <h3>Search results for “Cosi fan tutte”</h3>
      </div>
      <div>
        {searchResult.map((data, index) => {
          return (
            <div className="inventory-search-tabs" index={index}>
              <span>{data.asset_select_filter}</span>
              <span className="inventory-result">
                {data &&
                  data.film_genre &&
                  data.film_genre.length > 0 &&
                  data.film_genre.map((item) => {
                    return (
                      <span>
                        {item}
                        <Button
                          type="link"
                          onClick={() => removeFilterItems(index, item)}
                        >
                          {closeIcon}
                        </Button>
                      </span>
                    );
                  })}

                {data &&
                  data.Music_genre &&
                  data.Music_genre.length > 0 &&
                  data.Music_genre.map((item) => {
                    return (
                      <span>
                        {item}
                        <Button
                          type="link"
                          onClick={() => removeFilterItems(index, item)}
                        >
                          {closeIcon}
                        </Button>
                      </span>
                    );
                  })}

                {data && data.video_resolution && (
                  <span>
                    {data.video_resolution}
                    <Button
                      type="link"
                      onClick={() => removeFilterItems(index)}
                    >
                      {closeIcon}
                    </Button>
                  </span>
                )}

                {data && data.production_year && (
                  <span>
                    {getOnlyYear(data.production_year[0])} -{" "}
                    {getOnlyYear(data.production_year[1])}
                    <Button
                      type="link"
                      onClick={() => removeFilterItems(index)}
                    >
                      {closeIcon}
                    </Button>
                  </span>
                )}

                {/* <Button type="link" onClick={() => removeFilterItems(data)}>
                    {closeIcon}
                  </Button> */}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InventorySearchResult;
