import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const rolesReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_ROLES_SUCCESS:
      return { ...state, createRoles: action.payload };
    case types.CREATE_ROLES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, createRoles: action.payload };
    case types.GET_ROLES_LIST_SUCCESS:
      return { ...state, rolesList: action.payload };
    case types.GET_ROLES_LIST_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, rolesList: action.payload };
    case types.GET_SPECIFIC_ROLES_SUCCESS:
      return { ...state, specificRole: action.payload };
    case types.GET_SPECIFIC_ROLES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, specificRole: action.payload };
    case types.UPDATE_ROLES_SUCCESS:
      return { ...state, updateRoleRes: action.payload };
    case types.UPDATE_ROLES_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, updateRoleRes: action.payload };
    case types.DELETE_ROLES_SUCCESS:
      return { ...state, deleteRole: action.payload };
    case types.DELETE_ROLES_FAILURE:
      if (
        action.payload &&
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.success === true
      ) {
        openNotificationWithIcon("error", "Error", "Please Try Again");
      }
      return { ...state, deleteRole: action.payload };
    case types.GET_AVAILABLE_PERMISSIONS_SUCCESS:
      return { ...state, permissionsRes: action.payload };
    case types.GET_AVAILABLE_PERMISSIONS_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, permissionsRes: action.payload };
    default:
      return state;
  }
};
