import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Row, Col, Select } from "antd";
import { multiLanguages } from "./../../../statics/data/multiLanguages";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const DataMaintenanceForm = ({
  search,
  currentPage,
  assetDetail,
  onUpdateAsset,
  modalType,
  selectedData,
  onCreateAsset,
  onGetAssetDetail,
  getAssestsList,
  history,
  onCloseAsset,
  handleCancel,
}) => {
  const [selectedLang, setSelectedLang] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalType === "edit") {
      form.setFieldsValue({
        title: assetDetail && assetDetail.asset_title,
      });
    }
  }, [assetDetail]);

  useEffect(() => {
    if (modalType === "edit") {
      onGetAssetDetail(selectedData && selectedData._id);
    }
  }, []);

  const onChangeLanguage = (value) => {
    setSelectedLang(value);
  };

  const onFinish = (values) => {
    if (modalType === "edit") {
      onUpdateAsset(assetDetail && assetDetail._id, values).then(() =>
        getAssestsList()
      );
      message.success("Successfully Updated");

      handleCancel();
    } else {
      const data = {
        title: {
          value: values.title,
          language: values.language,
        },
      };
      onCreateAsset(data).then((res) => {
        history.push(
          `asset-management/${
            res && res.payload && res.payload.asset && res.payload.asset._id
          }`
        );
      });
      message.success("Successfully Created");
      handleCancel();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        {...layout}
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your Inventory title!",
                },
              ]}
            >
              <Input placeholder="Inventory Title" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="language"
              className="full-width mr20 mb0"
              rules={[
                {
                  required: true,
                  message: "Please select the language",
                },
                // {
                //   required: true,
                //   pattern: new RegExp("[a-z]{2}(-[A-Z]{2})?"),
                // },
              ]}
            >
              <Select
                placeholder="Select language"
                value={selectedLang}
                onChange={onChangeLanguage}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                {multiLanguages.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item className={modalType === "edit" ? "mb0" : ""}>
          <div className="text-right asset-action-btn">
            {modalType !== "edit" && (
              <Button type="default" onClick={() => onCloseAsset(false)}>
                Go Back
              </Button>
            )}
            {modalType === "edit" && (
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
            )}
            <Button type="primary" htmlType="submit" className="ml10">
              {modalType === "edit" ? "Update" : "Create"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DataMaintenanceForm;
