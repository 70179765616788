import React, { Fragment, useState } from 'react';
import { Row, Col, Checkbox, InputNumber, Button, Tag, Modal, Upload, message } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

import Invitations from './Invitations';
import FormalLicenseForm from './FormalLicenseForm';
import { countryList } from './../../../../statics/data/countryList'
import { licenseFlags } from './../../../../statics/data/LicenseFlags'
import { toDateString } from '../../../Common/reUseFunctions'

const AuctionDetail = ({
  history,
}) => {

  const [visible, setVisible] = useState(false);
  const [licenseData, setLicenseData] = useState([]);

  const showModal = () => {
    setVisible(true);
  };

  const onGroupFirst = (checkedValues) => {
  }

  const handleCancel = e => {
    setVisible(false);
  };

  const fileUploadProps = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Fragment>
      <div className="license-contract">
        <h2 className="mb20">AUCTION TITLE 1</h2>
        <div className="auction-box">
          <div className="mb20" style={{ maxWidth: "400px" }}>
            <Upload {...fileUploadProps}>
              <Button>
                <UploadOutlined /> Upload the file...
                </Button>
            </Upload>
          </div>
          <h2>FORMAL LICENSE DEFINITION</h2>

          <div className="d-flex align-item-center">
            <Row gutter={16}>
              <Col span={12} style={{ marginBottom: "20px" }}>
                <div className="checkout-box-group mb20">
                  <Checkbox.Group style={{ width: '100%' }} onChange={onGroupFirst}>
                    <Row gutter={16}>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <span style={{ marginRight: "15px" }}>Resale Allowed:</span> <span>Yes</span>
                      </Col>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <span style={{ marginRight: "15px" }}>Exclusive License:</span> <span>No</span>
                      </Col>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <span style={{ marginRight: "15px" }}>Countries:</span>
                        <Tag style={{ marginBottom: "10px" }} color="magenta">Australia</Tag>
                        <Tag style={{ marginBottom: "10px" }} color="magenta">Bangladesh</Tag>
                        <Tag style={{ marginBottom: "10px" }} color="magenta">Belgium</Tag>
                        <Tag style={{ marginBottom: "10px" }} color="magenta">Brazil</Tag>
                      </Col>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <span style={{ marginRight: "15px" }}>License Flags:</span>
                        <Tag style={{ marginBottom: "10px" }} color="blue">Audio only</Tag>
                        <Tag style={{ marginBottom: "10px" }} color="blue">DVD</Tag>
                        <Tag style={{ marginBottom: "10px" }} color="blue">Pay TV</Tag>
                      </Col>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <span style={{ marginRight: "15px" }}>Start Date:</span>
                        <span style={{ marginBottom: "10px" }} color="blue">Fri Jul 31 2020</span>
                      </Col>
                      <Col span={24} style={{ marginBottom: "20px" }}>
                        <span style={{ marginRight: "15px" }}>End Date:</span>
                        <span style={{ marginBottom: "10px" }} color="blue">Wed Aug 26 2020</span>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </div>
              </Col>

              {
                licenseData ?
                  licenseData.map((data, index) => {
                    return (
                      <Col span={12} style={{ marginBottom: "20px" }}>
                        <div key={index} className="checkout-box-group mb20">
                          <Row gutter={16}>
                            <Col span={24} style={{ marginBottom: "20px" }}>
                              <span style={{ marginRight: "15px" }}>Resale Allowed:</span> <span>{data.resale}</span>
                            </Col>
                            <Col span={24} style={{ marginBottom: "20px" }}>
                              <span style={{ marginRight: "15px" }}>Exclusive License:</span> <span>{data.exlusive}</span>
                            </Col>
                            <Col span={24} style={{ marginBottom: "20px" }}>
                              <span style={{ marginRight: "15px" }}>Countries:</span>
                              {
                                countryList.map(data1 => {
                                  return data.select_countries.map((data, index) => {
                                    return (
                                      data1.code === data &&
                                      <Tag style={{ marginBottom: "10px" }} key={index} color="magenta">{data1.name}</Tag>
                                    )
                                  })
                                })
                              }
                            </Col>
                            <Col span={24} style={{ marginBottom: "20px" }}>
                              <span style={{ marginRight: "15px" }}>License Flags:</span>
                              {
                                licenseFlags.map(data1 => {
                                  return data.select_license.map((data, index) => {
                                    return (
                                      data1.value === data &&
                                      <Tag style={{ marginBottom: "10px" }} key={index} color="magenta">{data1.name}</Tag>
                                    )
                                  })
                                })
                              }
                            </Col>
                            <Col span={24} style={{ marginBottom: "20px" }}>
                              <span style={{ marginRight: "15px" }}>Start Date:</span>
                              <span style={{ marginBottom: "10px" }} key={index} color="blue">{toDateString(data.dateTime[0])}</span>

                            </Col>

                            <Col span={24} style={{ marginBottom: "20px" }}>
                              <span style={{ marginRight: "15px" }}>End Date:</span>
                              <span style={{ marginBottom: "10px" }} key={index} color="blue">{toDateString(data.dateTime[1])}</span>

                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )
                  }) :
                  null
              }
              <Button type="primary" icon={<PlusOutlined />} onClick={showModal} />

            </Row>
          </div>
        </div>

        <div className="auction-box">
          <h2>SETTINGS</h2>
          <div className="checkout-box-group mb20 w50-per-imp">
            <Checkbox.Group style={{ width: '100%' }} onChange={onGroupFirst}>
              <Row gutter={16}>
                <Col span={24}>
                  <Checkbox value="Austria">Closed auction</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="DVD">Absolute price</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="Belgium">relative price</Checkbox>
                </Col>
              </Row>
              <Row gutter={16} className="align-item-center mb30">
                <Col span={8}><strong>minimum price:</strong></Col>

                <Col span={16} className="text-right">€  <InputNumber min={1} defaultValue={16} /></Col>
              </Row>
            </Checkbox.Group>
          </div>
        </div>

        <div className="auction-box">
          <h2>INVITATIONS</h2>
          <Invitations />
        </div>

      </div>
      {
        visible ?
          <Modal
            title="FORMAL LICENSE DEFINITION"
            visible={visible}
            onCancel={handleCancel}
            footer={false}
          >
            <FormalLicenseForm
              handleCancel={handleCancel}
              licenseData={licenseData}
              setVisible={setVisible}
              setLicenseData={setLicenseData}
            />
          </Modal> :
          null
      }
    </Fragment>
  )
}

export default AuctionDetail;