import React, { useState } from "react";

import InventoryFilterBar from "./InventoryFiltersBar";
import InventorySearchResult from "./../../../../Common/SearchFilterDropdown/InventorySearchResult";

const MyMarketInventory = ({
  onChangeLayout,
  changeLayout,
  showTableEditor,
  setSearch,

  onHidePopover,
  setVisiblePopover,
  visiblePopover,
  onSaveSearch,

  setMarketReady,
  marketReady,
  setNotMarketReady,
  notMarketReady,
  setPublishedAssets,
  publishedAssets,
  search,
  mainUserRes,
  assetsList,
}) => {
  const [searchResult, setSearchResult] = useState([]);

  const onFinish = (values) => {
    if (values) {
      setVisiblePopover(false);
    }
    setSearchResult(values.inventory_search_filter);
  };

  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
        setSearch={setSearch}
        search={search}
        onHidePopover={onHidePopover}
        setVisiblePopover={setVisiblePopover}
        visiblePopover={visiblePopover}
        setMarketReady={setMarketReady}
        marketReady={marketReady}
        setNotMarketReady={setNotMarketReady}
        notMarketReady={notMarketReady}
        setPublishedAssets={setPublishedAssets}
        publishedAssets={publishedAssets}
        mainUserRes={mainUserRes}
        assetsList={assetsList}
      />
      {searchResult && searchResult.length > 0 && (
        <InventorySearchResult
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          onSaveSearch={onSaveSearch}
        />
      )}
    </div>
  );
};

export default MyMarketInventory;
