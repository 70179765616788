import React, { useEffect } from "react";
import { Row, Col, Spin, Skeleton } from "antd";
import AddCataloguesAssets from "./AddCataloguesAssets";
import {
  GET_INVENTORY_LIST_SUCCESS,
  GET_SPECIAL_CATALOGUES_SUCCESS,
} from "../../../../store/Constants";
import Placeholder from "../../../../statics/images/placeholder.jpg";
import { getRolesPermissions } from "../../../Common/reUseFunctions";

const ProfileSection = ({
  organizationRes,
  loading,
  setLoading,

  setHeroImage,
  heroImage,
  setLogoImage,
  logoImage,

  modalType,
  showModal,

  onGetCatalogue,
  onGetAssetsList,
  items,
  setItems,
  getOrganization,
  highlights,
  searchResult,
  mainUserRes,
}) => {
  useEffect(() => {
    if (
      getRolesPermissions(mainUserRes, "organization-profile.show") === false
    ) {
      setLoading(true);
      getOrganization();
    }
  }, [mainUserRes]);

  useEffect(() => {
    (modalType === "special_catalogues" || modalType === "assets") &&
      getItems();
  }, [modalType, searchResult]);

  const getItems = () => {
    const apis = {
      special_catalogues: {
        url: onGetCatalogue,
        params: [0, 9000, searchResult],
        key: null,
      },
      assets: {
        url: onGetAssetsList,
        params: [
          9000,
          0,
          `%5B%22title.value%22,%22_id%22,%22preview_image%22%5D`,
          "local",
          searchResult,
        ],
        key: "assets",
      },
    };

    try {
      apis[modalType]["url"](...apis[modalType]["params"]).then((res) => {
        if (
          res.type === GET_SPECIAL_CATALOGUES_SUCCESS ||
          res.type === GET_INVENTORY_LIST_SUCCESS
        ) {
          const data = apis[modalType].key
            ? res.payload[modalType][apis[modalType].key]
            : res.payload[modalType];
          setItems({ data, type: modalType });
        }
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Spin spinning={loading} size="small">
      {getRolesPermissions(mainUserRes, "organization-profile.show") ===
      false ? (
        <Row gutter={24}>
          <Col className="mb40" span={13}>
            <div className="organization-profile-detail">
              <div className="member-profile-text">
                {logoImage === undefined ? (
                  <Skeleton.Image />
                ) : (
                  <img src={logoImage} alt="Logo" />
                )}
                <h2>{organizationRes && organizationRes.shortname}</h2>
                <p>{organizationRes && organizationRes.about}</p>
                <div className="total-assets">
                  <h3>
                    {organizationRes &&
                      organizationRes.highlights &&
                      parseInt(organizationRes.highlights.assets.length) +
                        parseInt(
                          organizationRes.highlights.special_catalogues.length
                        )}
                  </h3>
                  <p>Total assets</p>
                </div>
              </div>
            </div>
          </Col>
          <Col className="mb40" span={11}>
            <div className="member-profile-img">
              {heroImage ? (
                <img src={heroImage} alt="Hero logo" />
              ) : (
                <Skeleton.Image />
              )}
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {getRolesPermissions(mainUserRes, "organization-profile.show") ===
      false ? (
        <div className="p-cata-grid">
          <h2 className="highlight-mb">Highlights</h2>
          <div className="highlight-title-detail">
            Select up to three assets or catalogues
          </div>
          <Row className="mb5" gutter={16}>
            <Col className="mb25 mt10" span={6}>
              <AddCataloguesAssets showModal={showModal} />
            </Col>

            {highlights &&
              highlights.special_catalogues &&
              highlights.special_catalogues.map(
                (item) =>
                  item && (
                    <Col className="mb25 mt10" span={6} key={item._id}>
                      <div className="new-items-grid add-asset-sec">
                        {/* {item.special_catalogue_data.banner ?  <img src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.special_catalogue_data.banner}/image`} alt={item.special_catalogue_data.name}  width={'100%'} /> : <Skeleton.Image />} */}
                        {item.special_catalogue_data.banner ? (
                          // <img src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.special_catalogue_data.banner}/image`} alt={item.special_catalogue_data.name}  width={'100%'} />
                          <div
                            className={"catalogue-bg"}
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.special_catalogue_data.banner}/image)`,
                            }}
                          >
                            <img
                              src={Placeholder}
                              alt={item.special_catalogue_data.name}
                              width={"100%"}
                            />
                          </div>
                        ) : (
                          <Skeleton.Image />
                        )}

                        <div className="new-items-info">
                          <h3>{item.special_catalogue_data.name}</h3>
                          {/* <h4>Production Year: <span>{special_catalogue_data.production_date}</span></h4> */}
                          {/* <div className="catalouge-btns">
                    <Link to={`/special_catalogue_details/${special_catalogue_data._id}`} > <Button type="link" className="p0 border-0"> View Assets</Button></Link>
                  </div> */}
                        </div>
                      </div>
                    </Col>
                  )
              )}

            {highlights &&
              highlights.assets &&
              highlights.assets.map(
                (item) =>
                  item && (
                    <Col className="mb25 mt10" span={6} key={item._id}>
                      <div className="new-items-grid add-asset-sec">
                        {item.asset_data.preview_image[0] ? (
                          // {item.asset_data.preview_image[0] ? <img src={item.asset_data.preview_image[0]} alt={item.asset_data.title.value} width={'100%'} /> : <Skeleton.Image />}
                          <div
                            className={"catalogue-bg"}
                            style={{
                              backgroundImage: `url(${item.asset_data.preview_image[0]})`,
                            }}
                          >
                            <img
                              src={Placeholder}
                              alt={item.asset_data.title.value}
                              width={"100%"}
                            />
                          </div>
                        ) : (
                          <Skeleton.Image />
                        )}
                        <div className="new-items-info">
                          <h3>{item.asset_data.title.value}</h3>
                          {/* <h4>Production Year: <span>{asset_data.production_date}</span></h4> */}
                          {/* <div className="catalouge-btns">
                    <Link to={`/asset_details/${asset_data._id}`} > <Button type="link" className="p0 border-0"> Details</Button></Link>
                    <Button type="link" className="p0 border-0" onClick={() => {}}> Add to Selection</Button>
                  </div> */}
                        </div>
                      </div>
                    </Col>
                  )
              )}
          </Row>
        </div>
      ) : (
        ""
      )}
    </Spin>
  );
};

export default ProfileSection;
