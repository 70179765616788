import React from 'react';
import { Radio } from 'antd'
import { ListViewIcon, listGridIcon } from '../../../../../Common/Icons';

const InventorySwitchFilter = ({
  onChangeLayout,
  changeLayout,
}) => {
  return (
    <div className="radio-btn-style">
      <Radio.Group onChange={onChangeLayout} defaultValue={changeLayout} buttonStyle="solid">
        <Radio.Button value="LIST">{ListViewIcon}</Radio.Button>
        <Radio.Button value="GRID">{listGridIcon}</Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default InventorySwitchFilter;
