import { apiNew } from '../../service/newApi'
import * as types from '../Constants'

export const getAssignedTasks = (skip, limit, name = '') => {
  const options = {
    url: `automated-task-assignments?skip=${skip}&limit=${limit}&name=${name}`,
  }

  options.types = [types.GET_ASSIGNED_TASKS_SUCCESS, types.GET_ASSIGNED_TASKS_FAILURE]

  return apiNew.get(options)
}

export const createAssignedTask = ({payload}) => {
  const options = {
    url: `automated-task-assignments`,
  }

  options.types = [types.CREATE_ASSIGNED_TASK_SUCCESS, types.CREATE_ASSIGNED_TASK_FAILURE]

  return apiNew.post(options, payload)
}

export const updateAssignedTask = ({payload, id}) => {
  const options = {
    url: `automated-task-assignments/${id}`,
  }

  options.types = [types.UPDATE_ASSIGNED_TASK_SUCCESS, types.UPDATE_ASSIGNED_TASK_FAILURE]

  return apiNew.put(options, payload)
}

export const deleteAssignedTask = id => {
  const options = {
    url: `automated-task-assignments/${id}`,
  }

  options.types = [types.DELETE_ASSIGNED_TASK_SUCCESS, types.DELETE_ASSIGNED_TASK_FAILURE]

  return apiNew.delete(options)
}

export const getAllUsers = () => {
  const options = {
    url: `users/all`,
  }

  options.types = [types.GET_ALL_USERS_SUCCESS, types.GET_ALL_USERS_FAILURE]

  return apiNew.get(options)
}

export const getAllOrganizations = () => {
  const options = {
    url: `organizations/all `,
  }

  options.types = [types.GET_ALL_ORGANIZATIONS_SUCCESS, types.GET_ALL_ORGANIZATIONS_FAILURE]

  return apiNew.get(options)
}
