import * as types from '../Constants'
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions'

export const userManagementReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_NEW_USER_INVITE_SUCCESS:
      return { ...state, createNewUserRes: action.payload }
    case types.CREATE_NEW_USER_INVITE_FAILURE:
      console.log(action.payload)
      openNotificationWithIcon(
        'error',
        'Error',
        action.payload.response.data.errors
          ? action.payload.response.data.errors[0]
          : action.payload.response.data.message
      )
      return { ...state, createNewUserRes: action.payload }

    case types.GET_USERS_LIST_SUCCESS:
      return { ...state, userManagementList: action.payload }
    case types.GET_USERS_LIST_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, userManagementList: action.payload }

    case types.GET_SPECIFIC_USER_DETAIL_SUCCESS:
      return { ...state, getSpecificUserDetail: action.payload }
    case types.GET_SPECIFIC_USER_DETAIL_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, getSpecificUserDetail: action.payload }

    case types.GET_USER_PICTURE_SUCCESS:
      return { ...state, specificUserImage: action.payload }
    case types.GET_USER_PICTURE_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload && action.payload.response && action.payload.response.statusText
      // );
      return { ...state, specificUserImage: action.payload }

    case types.UPDATE_NEW_PASSWORD_SUCCESS:
      return { ...state, changePasswordRes: action.payload }
    case types.UPDATE_NEW_PASSWORD_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, changePasswordRes: action.payload }

    case types.UPDATE_USERPRIFILE_AND_USERNAME_SUCCESS:
      return { ...state, updateUserprofileAndUsername: action.payload }
    case types.UPDATE_USERPRIFILE_AND_USERNAME_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, updateUserprofileAndUsername: action.payload }

    case types.GET_BLOCK_SPECIFIC_USER_SUCCESS:
      return { ...state, blockSpecificUser: action.payload }
    case types.GET_BLOCK_SPECIFIC_USER_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, blockSpecificUser: action.payload }

    case types.GET_UNBLOCK_SPECIFIC_USER_SUCCESS:
      return { ...state, unBlockSpecificUser: action.payload }
    case types.GET_UNBLOCK_SPECIFIC_USER_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, unBlockSpecificUser: action.payload }

    case types.GET_PROFILE_STATUS_USER_SUCCESS:
      return { ...state, profileStatusSpecificUser: action.payload }
    case types.GET_PROFILE_STATUS_USER_FAILURE:
      openNotificationWithIcon('error', 'Error', action.payload.response.data.message)
      return { ...state, profileStatusSpecificUser: action.payload }

    case types.UPDATE_USER_INFORMATION_SUCCESS:
      return { ...state, updateUserInfoRes: action.payload }
    case types.UPDATE_USER_INFORMATION_FAILURE:
      openNotificationWithIcon('error', 'Error', action.payload.response.data.message)
      return { ...state, updateUserInfoRes: action.payload }

    case types.USER_DELETE_SUCCESS:
      return { ...state, userDelete: action.payload }
    case types.USER_DELETE_FAILURE:
      openNotificationWithIcon('error', 'Error', 'Please Try Again')
      return { ...state, userDelete: action.payload }
    default:
      return state
  }
}
