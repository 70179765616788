import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Modal } from "antd";

import AssetDetailVideo from "./AssetVideos";
import AssetDetailText from "./AssetDetailText";
import HeaderFilter from "./HeaderFilter/TopTitleSection";
import LanguagesModal from "../../Common/Components/LanguagesModal";

import { SelectionModelComp } from "../../../Containers";

const AssetDetails = ({
  assetDetail,
  InventoryDetail,
  getInventorySeachDetailRes,
  onGetInventorySeachDetail,
  type,
}) => {
  const [visible, setVisible] = useState(false);
  const [assetID, setAssetID] = useState("");
  const [visibleLanguages, setVisibleLanguages] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (type !== "from-metadata-form") {
      setAssetID(id);
      onGetInventorySeachDetail(id);
    }
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const showAssetLanguages = (type, data) => {
    setVisibleLanguages(true);
  };

  const cancelAssetLanguages = () => {
    setVisibleLanguages(false);
  };

  return (
    <Fragment>
      <Row gutter={16}>
        <Col span={24}>
          <HeaderFilter
            showModal={showModal}
            InventoryDetail={InventoryDetail}
            type={type}
            assetDetail={assetDetail}
            getInventorySeachDetailRes={
              type === "from-metadata-form"
                ? assetDetail
                : getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.assets
            }
          />
        </Col>
        <Col span={12}>
          <AssetDetailVideo
            getInventorySeachDetailRes={
              type === "from-metadata-form"
                ? assetDetail
                : getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.assets
            }
          />
        </Col>
        <Col span={12}>
          <AssetDetailText
            getInventorySeachDetailRes={
              type === "from-metadata-form"
                ? assetDetail
                : getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.assets
            }
            showAssetLanguages={showAssetLanguages}
          />
        </Col>
      </Row>
      {visible && (
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={450}
        >
          <SelectionModelComp
            assetID={assetID}
            handleCloseSelection={handleCancel}
          />
        </Modal>
      )}
      <LanguagesModal
        visible={visibleLanguages}
        // selectedData={selectedData}
        handleCancel={cancelAssetLanguages}
        assetDetail={assetDetail}
      />
    </Fragment>
  );
};

export default AssetDetails;
