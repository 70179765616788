import React, { useState } from "react";
import { Modal, message } from "antd";
import Filter from "./HeaderFilter";
import UserManagementBox from "./List";
import CascadingSort from "../../../Common/CascadingSort";
import TableEditor from "../../../Common/TableEditor";

import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";

const UserManagement = ({
  userManagementList,
  onClickSelection,
  sessionRes,
}) => {
  const [modalType, setModalType] = useState("");
  const [visibleTE, setVisibleTE] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [delConfirm, setDelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [blockedFilter, setBlockedFilter] = useState(true);
  const [unBlockedFilter, setUnBlockedFilter] = useState(true);
  const [statusFilter, setStatusFilter] = useState(true);
  const [pendingFilter, setPendingFilter] = useState(true);

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const showTableEditor = (modalType, selectedSpecificData) => {
    setVisibleTE(true);
    setModalType(modalType);
    setSelectedData(selectedSpecificData);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const onChangeSelectionType = (e) => {
    setLoading(false);
  };

  const onDeletingItem = (modalType) => {
    message.success("Successfully Deleted!");
    setDelConfirm(false);
  };

  const delConfirmModal = (data) => {
    setSelectedData(data);
    setDelConfirm(true);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}
        onChangeSelectionType={onChangeSelectionType}
        sessionRes={sessionRes}
        showTableEditor={showTableEditor}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setBlockedFilter={setBlockedFilter}
        blockedFilter={blockedFilter}
        setUnBlockedFilter={setUnBlockedFilter}
        unBlockedFilter={unBlockedFilter}
        setPendingFilter={setPendingFilter}
        pendingFilter={pendingFilter}
      />

      <UserManagementBox
        loading={loading}
        onClickSelection={onClickSelection}
        delConfirmModal={delConfirmModal}
        showTableEditor={showTableEditor}
        userManagementList={userManagementList}
        onChangePagination={onChangePagination}
        currentPage={currentPage}
      />

      {visibleTE && (
        <Modal
          visible={visibleTE}
          onCancel={cancelTableEditor}
          footer={false}
          className="modal-style"
          width={
            (modalType === "table_editor" && 740) ||
            (modalType === "cascading_sort" && 500)
          }
        >
          {modalType === "table_editor" && <TableEditor />}

          {modalType === "cascading_sort" && <CascadingSort />}
        </Modal>
      )}

      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onDeletingItem}
        selectedData={selectedData}
      />
    </div>
  );
};

export default UserManagement;
