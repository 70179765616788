import React from "react";
import { Table, Tag, Card, Avatar } from "antd";
import { Link } from "react-router-dom";

const { Meta } = Card;

const IntentoryList = ({ data }) => {
  const columns = [
    {
      title: "Organization Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Link to={`/organizations/${record.id}`}>
            <Meta
              className="meta-avatar-align meta-link-avatar"
              avatar={
                <Avatar src="https://cdn.domestika.org/c_limit,dpr_auto,f_auto,q_auto,w_820/v1458003213/content-items/001/570/122/artetacna2-01-original.jpg?1458003213" />
              }
              title={text}
            />
          </Link>
        );
      },
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text) => <Tag color="cyan">{text}</Tag>,
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      render: (text) => <span>{text}</span>,
    },
  ];

  return <Table columns={columns} dataSource={data} />;
};

export default IntentoryList;
