import React, { useState, useEffect, Fragment } from "react";
import {
  Form,
  Col,
  Select,
  // Checkbox,
} from "antd";

import { countryList } from "./../../../../../../statics/data/countryList";
import { countryListTerritories } from "./../../../../../../statics/data/countryListTerritories";

const { Option } = Select;

const Index = ({ field, rightOffered, rightOfferedWorldWide }) => {

  const [selectTerritory, setSelectTerritory] = useState([]);

  useEffect(() => {
    if (rightOffered && rightOffered.type === "all_but") {
      setSelectTerritory("worldwide");
    }
  }, [rightOffered]);

  const onSelectTerritories = (value, multipleOpt) => {
    setSelectTerritory(value);
    if (value === "worldwide" && multipleOpt === "multipleOpt") {
      setTimeout(() => {
        const getthings = document.getElementsByClassName("worldwideclass")[0];
        if (getthings) {
          getthings.click();
        }
      }, 500);
    }
  };

  return (
    <Fragment>
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name">
            <h3 className="required">
              <span>Territories</span>
            </h3>
          </div>
          <div className="metadata-filed">
            <Form.Item
              {...field}
              name={[field.fieldKey, "territory"]}
              fieldKey={[field.fieldKey, "territory"]}
            >
              {selectTerritory === "worldwide" ? (
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  placeholder="Select Territories"
                  onSelect={onSelectTerritories}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {countryListTerritories.map((item, index) => (
                    <Option
                      className={
                        item.name === "WorldWide" ? "worldwideclass" : ""
                      }
                      key={index}
                      value={item.code}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Territories"
                  onSelect={(value) =>
                    onSelectTerritories(value, "multipleOpt")
                  }
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {countryListTerritories.map((item, index) => (
                    <Option key={index} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name">
            <h3 className="required">
              <span>Excluding</span>
            </h3>
          </div>
          <div className="metadata-filed">
            {selectTerritory === "worldwide" && (
              <Form.Item
                {...field}
                name={[field.fieldKey, "excluding"]}
                fieldKey={[field.fieldKey, "excluding"]}
                rules={[{ required: false }]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Excluding Territories"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {countryList.map((item, index) => (
                    <Option key={index} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default Index;
