import React, { Fragment, useEffect } from "react";
import { Tag, Button } from "antd";
import { CloseOutlined, StarOutlined } from "@ant-design/icons";
import { pluseFilledIcon } from "./../../../Common/Icons";
import {
  getUrlLastId,
  toDateString,
  getRolesPermissions,
} from "./../../../Common/reUseFunctions";

const SelectionAssetListView = ({
  location,
  showModal,
  showModalLicensePreset,

  getFormalLicenseRes,
  onGetFormalLicenseList,
  selectedAssetId,

  onDeleteFormalLicense,
  mainUserRes,
  licenseId,
  hasLicense,
  onGetSelectionDetail,
}) => {
  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "selection-license.list") === false) {
      const locationUrl = location.pathname;
      const getUrlPath = getUrlLastId(locationUrl);
      if (getUrlPath && selectedAssetId) {
        onGetFormalLicenseList(getUrlPath, selectedAssetId);
      }
    }
  }, [selectedAssetId]);

  const delFormalLicense = (lisenseId) => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);
    if (getUrlPath && selectedAssetId) {
      onDeleteFormalLicense(getUrlPath, selectedAssetId, lisenseId._id).then(
        () => {
          onGetFormalLicenseList(getUrlPath, selectedAssetId);
          const locationUrl = location.pathname;
          const selectionId = getUrlLastId(locationUrl);
          onGetSelectionDetail(selectionId);
        }
      );
    }
  };

  return (
    <Fragment>
      {hasLicense === true &&
      selectedAssetId &&
      selectedAssetId.length === 24 &&
      getFormalLicenseRes &&
      getFormalLicenseRes.formal_license_definitions &&
      getFormalLicenseRes.formal_license_definitions.length > 0
        ? getFormalLicenseRes.formal_license_definitions.map((data, index) => {
            return (
              <div key={index} className="slection-asset-view">
                <h3>FORMAL LICENSE DEFINITION</h3>
                <div key={index} className="formal-license-view">
                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>TERRITORIES</span>
                    </div>
                    <div className="formal-license-data">
                      {data &&
                      data.territories &&
                      data.territories.length > 0 ? (
                        data.territories.map((data, index) => {
                          return <Tag key={index}>{data}</Tag>;
                        })
                      ) : (
                        <Tag key={index}>WorldWide</Tag>
                      )}
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>EXCLUDING</span>
                    </div>
                    <div className="formal-license-data">
                      {data.excluding.map((data, index) => {
                        return <Tag key={index}>{data}</Tag>;
                      })}
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>CHANNELS</span>
                    </div>
                    <div className="formal-license-data">
                      {data.channels.map((data, index) => {
                        return <Tag key={index}>{data}</Tag>;
                      })}
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>RESALE ALLOWED</span>
                    </div>
                    <div className="formal-license-data">
                      <span>
                        {(data.resale_allowed === true && "Yes") ||
                          (data.resale_allowed === false && "No")}
                      </span>
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>EXCLUSIVE LICENSE</span>
                    </div>
                    <div className="formal-license-data">
                      <span>
                        {(data.exclusive_license === true && "Yes") ||
                          (data.exclusive_license === false && "No")}
                      </span>
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>START DATE</span>
                    </div>
                    <div className="formal-license-data">
                      <span>{toDateString(data.start_date)}</span>
                    </div>
                  </div>

                  <div className="formal-license-row">
                    <div className="formal-license-name">
                      <span>END DATE</span>
                    </div>
                    <div className="formal-license-data">
                      <span>{toDateString(data.end_date)}</span>
                    </div>
                  </div>
                  <div className="d-flex align-item-center justify-between">
                    <div className="formal-license-btn">
                      {getRolesPermissions(
                        mainUserRes,
                        "selection-license.edit"
                      ) === false ? (
                        <Button
                          type="link"
                          onClick={() => {
                            showModal("edit", data);
                          }}
                        >
                          <u>Edit License</u>
                        </Button>
                      ) : (
                        ""
                      )}
                      {getRolesPermissions(
                        mainUserRes,
                        "selection-license.create"
                      ) === false ? (
                        <p className="add-selection-btn">
                          <Button
                            type="link"
                            className="p0 border-0"
                            onClick={() => showModal("add")}
                          >
                            {pluseFilledIcon} Add New Definition
                          </Button>
                        </p>
                      ) : (
                        ""
                      )}
                      {getRolesPermissions(
                        mainUserRes,
                        "selection-license.delete"
                      ) === false ? (
                        <Button
                          onClick={() => delFormalLicense(data)}
                          type="link"
                        >
                          <CloseOutlined /> Delete
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                    <Button
                      type="link"
                      className="save-preset-btn"
                      onClick={() => showModalLicensePreset()}
                    >
                      <StarOutlined />
                      Save License Preset
                    </Button>
                  </div>
                </div>
              </div>
            );
          })
        : selectedAssetId &&
          selectedAssetId.length === 24 &&
          getFormalLicenseRes &&
          getFormalLicenseRes.formal_license_definitions &&
          getFormalLicenseRes.formal_license_definitions.length === 0 &&
          (getRolesPermissions(mainUserRes, "selection-license.create") ===
          false ? (
            <div className="formal-license-btn">
              <p className="add-selection-btn">
                <Button
                  type="link"
                  className="p0 border-0"
                  onClick={() => showModal("add")}
                >
                  {pluseFilledIcon} Add New Definition
                </Button>
              </p>
            </div>
          ) : (
            ""
          ))}
    </Fragment>
  );
};

export default SelectionAssetListView;
