import React from "react";
import TopTitleSection from "./TopTitleSection";

const HeaderFilter = ({ onShowModal, catalogue, mainUserRes }) => {
  return (
    <div>
      <TopTitleSection
        mainUserRes={mainUserRes}
        onShowModal={onShowModal}
        catalogue={catalogue}
      />
    </div>
  );
};

export default HeaderFilter;
