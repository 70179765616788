import React, { useState } from "react";
import InventoryFilterBar from "./InventoryFiltersBar";
import InventorySearchResult from "./InventoryFiltersBar/InventorySearchResult";

const MyMarketInventory = ({
  onChangeLayout,
  changeLayout,
  setSearch,
  onSearch,
  mainUserRes,
}) => {
  const [searchResult, setSearchResult] = useState([]);

  const onFinish = (values) => {
    setSearchResult(values.inventory_search_filter);
  };

  return (
    <div>
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        setSearch={setSearch}
        onSearch={onSearch}
        mainUserRes={mainUserRes}
      />
      {searchResult.length > 0 && (
        <InventorySearchResult
          searchResult={searchResult}
          setSearchResult={setSearchResult}
        />
      )}
    </div>
  );
};

export default MyMarketInventory;
