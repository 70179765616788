import React from "react";
import InventoryFilterBar from "./InventoryFiltersBar";

const MyMarketInventory = ({ onSearch, mainUserRes }) => {
  return (
    <div>
      <InventoryFilterBar onSearch={onSearch} mainUserRes={mainUserRes} />
    </div>
  );
};

export default MyMarketInventory;
