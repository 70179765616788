import React, { Fragment } from 'react';
import { Button } from 'antd';

import PresetsListView from './PresetsListView';

const SearchPresetList = ({
  searchPresetList,

  handleCloseSelection,
}) => {

  return (
    <Fragment>
      <ul className="selection-radio column-tasks-area">

        {
          (searchPresetList && searchPresetList.length > 0) &&
          searchPresetList.map((list, index) => {
            return (
              <li key={index}>
                <PresetsListView
                  list={list}
                  index={index}
                />
              </li>

            )
          })
        }

      </ul>
      <div className="add-selections-style">
        <Button type="primary" onClick={handleCloseSelection}>SAVE CHANGES</Button>
      </div>
    </Fragment>
  )
}

export default SearchPresetList;
