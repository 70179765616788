import React, { Fragment } from "react";
import HeaderFilter from './HeaderFilter';

import ProgressScale from './ProgressScales';
import AssetsDetailOptions from './AssetsDetailOptions';

const ColumnsMappingValuesCollectoinModal = ({
  saveValuesCorrection
}) => {
  
  return (
    <Fragment>
      <HeaderFilter />
      <div className="asset-detail-style mt0-imp">
        <div className="form-section">
          {
            <AssetsDetailOptions
            // onIsccCodeUpdating={onIsccCodeUpdating}
            // isccUpdating={isccUpdating}
            // onGetAssetDetail={onGetAssetDetail}
            // assetDetail={assetDetail}
            // onUpdateAsset={onUpdateAsset}

            // onIsccCodeGeneration={onIsccCodeGeneration}
            // isccGeneration={isccGeneration}

            // onIsccGenerationProcess={onIsccGenerationProcess}
            // isccStatus={isccStatus}
            // assetID={assetID}
            />
          }
        </div>
        <div className="right-side-section">
            <ProgressScale 
            saveValuesCorrection={saveValuesCorrection}
            />
        </div>
      </div>
    </Fragment>
  );
};

export default ColumnsMappingValuesCollectoinModal;
