import React, { Fragment, useState, useEffect } from "react";
import { Table, Space, Button, Modal } from "antd";
import { Link } from "react-router-dom";
import {
  getOnlyYear,
  getOnlyMinutes,
  getRolesPermissions,
} from "./../../../../Common/reUseFunctions";
import FormalLicenseEdit from "../FormalLicenseDefinition/FormalLicenseEditModal";
import LicensePresetModal from "../../../../Common/ColumnsMappingPreset";
import { CaretUpOutlined, CaretDownFilled } from "@ant-design/icons";

const InventoryTable = ({
  mainUserRes,
  setorderDisorder,
  orderDisorder,
  onGetInventoryHeader,
  getInventoryHeaderRes,
  showTableEditor,
  inventoryListRes,
  showPreviewModal,
}) => {
  const [visible, setVisible] = useState(false);
  const [modelType, setModelType] = useState(false);
  const [visiblePreset, setVisiblePreset] = useState(false);

  useEffect(() => {
    // onGetInventoryHeader();
    // onUpdateInventoryHeader();
    // if (
    //   getInventoryHeaderRes &&
    //   getInventoryHeaderRes.headers &&
    //   getInventoryHeaderRes.headers.length > 0
    // ) {
    //   getInventoryHeaderRes.headers.map((data, index) => {
    //     columns[index].title = tableTitle(data.replace("_", " "));
    //     columns[index].key =
    //       columns[index].key === "languages" ? "title" : data;
    //     columns[index].dataIndex =
    //       columns[index].dataIndex === "languages" ? "title" : data;
    //   });
    //   setInventoryHeader(columns);
    // }
  }, [getInventoryHeaderRes]);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "inventory.list") === false) {
      onGetInventoryHeader();
    }
  }, [mainUserRes]);

  const showModalPreset = (e) => {
    setVisiblePreset(true);
    setVisible(false);
  };

  const handleCancelPreset = (e) => {
    setVisiblePreset(false);
  };

  const showModal = (e) => {
    setVisible(true);
    setModelType(e);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const tableTitle = (value) => {
    return (
      value && (
        <div className="title-icon-flex">
          {value}
          <strong className="inventory-title-icon">
            <CaretUpOutlined
              style={{ opacity: orderDisorder === true ? 1 : 0.4 }}
              onClick={() => setorderDisorder(true)}
            />
            <span className="mt-disasendingorder">
              <CaretDownFilled
                style={{ opacity: orderDisorder === true ? 0.4 : 1 }}
                onClick={() => setorderDisorder(false)}
              />
            </span>
          </strong>
        </div>
      )
    );
  };

  const columns = [
    // {
    //   title: 'OWNERSHIP',
    //   dataIndex: 'owner',
    //   key: 'owner',
    //   render: text => <Link className="underline-ownership" onClick={() => { showModal("edit") }}>{text}</Link>,
    // },
    {
      title: tableTitle("Title"),
      dataIndex: "title",
      key: "title",
      render: (text, record) =>
        text && (
          <Link to={`/inventory-search/${record._id}`}>{text.value}</Link>
        ),
    },
    {
      title: tableTitle("LENGTH"),
      dataIndex: "versions",
      key: "versions",
      render: (text, record) => {
        return getOnlyMinutes(text[0].duration);
      },
    },
    // {
    //   title: tableTitle('DIRECTOR'),
    //   dataIndex: 'directors',
    //   key: 'directors',
    //   render: (text) => (
    //     <>
    //       {text.join(", ")}

    //     </>
    //   ),
    // },
    // {
    //   title: tableTitle('LANGUAGES'),
    //   key: 'title',
    //   dataIndex: 'title',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <Tag className="text-uppercase">{text.language}</Tag>
    //       </>
    //     //   <>
    //     //   {
    //     //     text && text[0] &&
    //     //     <Tag className="text-uppercase">
    //     //       {text.lang}
    //     //     </Tag>
    //     //   }

    //     //   {
    //     //     text && text[1] &&
    //     //     <Tag className="text-uppercase">
    //     //       {text[1]}
    //     //     </Tag>
    //     //   }
    //     //   {
    //     //     text && text.length > 2 &&
    //     //     <Button className="specific-role-btn" type="link" onClick={() => showAssetLanguages("languages", record)}><u>+{text.length - 2}</u></Button>
    //     //   }

    //     // </>
    //     )
    //   }

    // },
    {
      title: tableTitle("PRODUCTION COMPANY"),
      dataIndex: "production_company",
      key: "production_company",
      render: (text) => text && text.value,
    },
    {
      title: tableTitle("PRODUCTION YEAR"),
      dataIndex: "production_date",
      key: "production_date",
      render: (text) => getOnlyYear(text),
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="table-action">
          <Button onClick={() => showPreviewModal(record._id)} type="link">
            Preview
          </Button>
          {getRolesPermissions(mainUserRes, "inventory.show") === false ? (
            <Link to={`/inventory-search/${record._id}`}>
              <Button type="link">Detail</Button>
            </Link>
          ) : (
            ""
          )}
          {getRolesPermissions(mainUserRes, "selection-item.create") ===
          false ? (
            <Button
              onClick={() => showTableEditor("add_to_selection", record)}
              type="link"
            >
              Add to Selection
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        pagination={false}
        className="table-style"
        columns={columns}
        dataSource={
          inventoryListRes &&
          inventoryListRes.assets &&
          inventoryListRes.assets.assets
        }
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        rowKey={(record) => record._id}
      />
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className="modal-style"
        width={610}
      >
        <FormalLicenseEdit
          handleCancel={handleCancel}
          modelType={modelType}
          showModal={showModalPreset}
        />
      </Modal>

      <Modal
        visible={visiblePreset}
        onCancel={handleCancelPreset}
        footer={false}
        className="modal-style"
        width={450}
      >
        <LicensePresetModal
          handleCancel={handleCancelPreset}
          modelType={modelType}
          showModal={showModal}
          ViewSearchPreset="search_preset"
        />
      </Modal>
    </Fragment>
  );
};

export default InventoryTable;
