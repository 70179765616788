import React from "react";
import MyMarketInventorySec from "./MyMarketInventory";
import InventoryFilterBar from "./InventoryFiltersBar";
import InventorySearchResult from "./../../../../Common/SearchFilterDropdown/InventorySearchResult";

const MyMarketInventory = ({
  setSearch,
  onSaveSearch,
  onHidePopover,
  setVisiblePopover,
  visiblePopover,
  changeLayout,
  showTableEditor,
  setChangeInventory,
  changeInventory,
  onChangeLayout,
  setSearchResult,
  searchResult,
  mainUserRes,
}) => {
  const onFinish = (values) => {
    if (values) {
      setVisiblePopover(false);
    }
    setSearchResult(values.inventory_search_filter);
  };

  return (
    <div>
      <MyMarketInventorySec onChangeInventory={setChangeInventory} />
      <InventoryFilterBar
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        changeInventory={changeInventory}
        onFinish={onFinish}
        onHidePopover={onHidePopover}
        visiblePopover={visiblePopover}
        showTableEditor={showTableEditor}
        setSearch={setSearch}
        mainUserRes={mainUserRes}
      />
      {searchResult && searchResult.length > 0 && (
        <InventorySearchResult
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          onSaveSearch={onSaveSearch}
        />
      )}
    </div>
  );
};

export default MyMarketInventory;
