import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssetDeatilCom from "../Components/ContentArea/AssetDetails";

import {
  getAssetsList,
  getAssetDetail,
  getMarketReady,
  getSession,
  getInventorySeachDetail,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    assetsList: state.assetReducer.assetsList,
    assetDetail: state.assetReducer.assetDetail,
    marketList: state.inventorySearchReducer.marketList,
    sessionRes: state.userReducer.sessionRes,
    getInventorySeachDetailRes:
      state.inventorySearchReducer.getInventorySeachDetailRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAssetsList: bindActionCreators(getAssetsList, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onGetMarketReady: bindActionCreators(getMarketReady, dispatch),
    onGetSession: bindActionCreators(getSession, dispatch),
    onGetInventorySeachDetail: bindActionCreators(
      getInventorySeachDetail,
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetDeatilCom);
