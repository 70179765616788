import React, { Fragment, useEffect } from "react";
import { FileOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "../../Common/reUseFunctions";

const FileHistory = ({
  getLogsListRes,
  onGetLogsList,
  tradingTaskId,
  mainUserRes,
}) => {
  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "trading-task.logs") === false) {
      onGetLogsList(tradingTaskId);
    }
  }, []);

  return (
    <div className="file-history">
      <h2>Negotiation Logs</h2>
      {getLogsListRes &&
        getLogsListRes.data &&
        getLogsListRes.data.length > 0 &&
        getLogsListRes.data.map((data, index) => (
          <Fragment key={index}>
            <ul>
              <li key={index}>
                <div className="file-history-name">
                  <FileOutlined />
                  <span>{data && data.note}</span>
                </div>
                {/* <h3>{toDateString(data.created_at)}</h3> */}
                {/* <div className="file-history-download">
                  <Button type="link"><DownloadOutlined /></Button>
                </div> */}
              </li>
            </ul>
          </Fragment>
        ))}
    </div>
  );
};

export default FileHistory;
