import React, { useEffect, useState } from "react";
import { Collapse, Button, Tag } from "antd";
import { CloseOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { pluseFilledIcon } from "./../../Common/Icons";
import { Link } from "react-router-dom";
import {
  getRolesPermissions,
  getUrlSecondLastId,
  toDateString,
} from "../../Common/reUseFunctions";

const { Panel } = Collapse;

const LicenseAssetCollapse = ({
  location,
  mainUserRes,
  showModal,
  getAllTradingTaskRes,
  setAssetId,
  onDeleteFormalLicenseForm,
  assetId,
  onGetAllTradingTask,
}) => {

  const [tradingTaskId, setTradingTaskId] = useState("");
  const [assetLicenseStatus, setAssetLicenseStatus] = useState("");
  useEffect(() => {
    getStatusForAssetLicense(getAllTradingTaskRes);
  }, []);

  useEffect(() => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlSecondLastId(locationUrl);
    setTradingTaskId(getUrlPath);
    getStatusForAssetLicense(getAllTradingTaskRes);
  }, [getAllTradingTaskRes]);

  const onPanel = (data) => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlSecondLastId(locationUrl);
    if (getRolesPermissions(mainUserRes, "trading-task-asset.list") === false) {
      onGetAllTradingTask(getUrlPath);
    }

    setAssetId(data);
  };

  const getStatusForAssetLicense = (getAllTradingTaskRes) => {
    if( getAllTradingTaskRes && getAllTradingTaskRes.data && getAllTradingTaskRes.data.assets &&
      getAllTradingTaskRes.data.assets.length > 0)
    {
      for(let ind = 0 ; ind < getAllTradingTaskRes.data.assets.length ; ind++)
      {
        if(getAllTradingTaskRes.data.assets[ind].formal_license_definitions.length > 0)
        {
          setAssetLicenseStatus("found");
        }
        else
        {
          setAssetLicenseStatus("");
          break;
          //do nothing
        }
      }
    }
    else
    {
      //do nothing
    }
    // getAllTradingTaskRes &&
    // getAllTradingTaskRes.data &&
    // getAllTradingTaskRes.data.assets.map((data, ind) => {
    //   if(data.formal_license_definitions &&
    //       data.formal_license_definitions.length > 0)
    //   {
    //     setAssetLicenseStatus("found")
    //   }
    //   else
    //   {
    //     setAssetLicenseStatus("")
    //   }
    // })
  };

  const delFormalLicense = (data) => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlSecondLastId(locationUrl);
    if (
      getRolesPermissions(mainUserRes, "trading-task-asset.delete") === false
    ) {
      if (getUrlPath) {
        onDeleteFormalLicenseForm(getUrlPath, assetId.id, data._id).then(() => {
          onGetAllTradingTask(getUrlPath);
        });
      }
    }
  };

  return (
    <div className="License-assets-collapse">
      {getAllTradingTaskRes &&
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.assets && getAllTradingTaskRes.data.assets.map((data, index) => {
          return (
            <Collapse key={index} accordion onChange={() => onPanel(data)}>
              <Panel header={data.title} key="1">
                {data &&
                data.formal_license_definitions &&
                data &&
                data.formal_license_definitions &&
                data.formal_license_definitions.length > 0 ? (
                  data.formal_license_definitions.map((items, index) => {
                    return (
                      <div key={index} className="slection-asset-view">
                        <div key={index} className="formal-license-view">
                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>TERRITORIES</span>
                            </div>
                            <div className="formal-license-data">
                              {items &&
                              items.territories &&
                              items.territories.length > 0 ? (
                                  items.territories.map((data, index) => {
                                  return <Tag key={index}>{data}</Tag>;
                                })
                              ) : (
                                <Tag key={index}>WorldWide</Tag>
                              )}
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>EXCLUDING</span>
                            </div>
                            <div className="formal-license-data">
                              {items.excluding.map((data, index) => {
                                return <Tag key={index}>{data}</Tag>;
                              })}
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>CHANNELS</span>
                            </div>
                            <div className="formal-license-data">
                              {items.channels.map((data, index) => {
                                return <Tag key={index}>{data}</Tag>;
                              })}
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>RESALE ALLOWED</span>
                            </div>
                            <div className="formal-license-data">
                              <span>
                                {(items.resale_allowed === true && "Yes") ||
                                  (items.resale_allowed === false && "No")}
                              </span>
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>EXCLUSIVE LICENSE</span>
                            </div>
                            <div className="formal-license-data">
                              <span>
                                {(items.exclusive_license === true && "Yes") ||
                                  (items.exclusive_license === false && "No")}
                              </span>
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>START DATE</span>
                            </div>
                            <div className="formal-license-data">
                              <span>{toDateString(items.start_date)}</span>
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>END DATE</span>
                            </div>
                            <div className="formal-license-data">
                              <span>{toDateString(items.end_date)}</span>
                            </div>
                          </div>
                          <div className="formal-license-btn">
                            {getRolesPermissions(
                              mainUserRes,
                              "trading-task-asset.edit"
                            ) === false ? (
                              <Button
                                onClick={() =>
                                  showModal("license_edit", data.id, items)
                                }
                                type="link"
                              >
                                <u>Edit License</u>
                              </Button>
                            ) : (
                              ""
                            )}
                            {getRolesPermissions(
                              mainUserRes,
                              "trading-task-asset.create"
                            ) === false ? (
                              <Button
                                onClick={() =>
                                  showModal("add_definition", data.id)
                                }
                                type="link"
                                className="add-definition-btn"
                              >
                                {pluseFilledIcon} Add New Definition
                              </Button>
                            ) : (
                              ""
                            )}
                            {getRolesPermissions(
                              mainUserRes,
                              "trading-task-asset.delete"
                            ) === false ? (
                              <Button
                                type="link"
                                onClick={() => delFormalLicense(items)}
                                className="dell-definition-btn"
                              >
                                <CloseOutlined /> Delete
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="formal-license-btn">
                    <p className="add-selection-btn">
                      <Button
                        type="link"
                        className="p0 border-0"
                        onClick={() => {
                          showModal("add_definition");
                        }}
                      >
                        {pluseFilledIcon} Add New Definition
                      </Button>
                    </p>
                  </div>
                )}
              </Panel>
            </Collapse>
          );
        })}
      {(getAllTradingTaskRes &&
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.agreement_file === null ||  assetLicenseStatus !== "found" )? (
        <Button disabled={true} type="secondary" className="mt30 proceed-btn">
          <Link to="">
            PROCEED <ArrowRightOutlined />
          </Link>
        </Button>
      ) : (
        <Button type="secondary" className="mt30 proceed-btn">
          <Link to={`/selections/${tradingTaskId}/proposal-closing`}>
            PROCEED <ArrowRightOutlined />
          </Link>
        </Button>
      )}
    </div>
  );
};

export default LicenseAssetCollapse;
