import React, { useEffect } from 'react';
import { Switch, Button } from 'antd';
import {selectPreset} from './../../../../Common/Icons';

const MetadataSwitchFilter = ({
  setHelperText,
  helperText,

  setRequiredField,
  requiredField,

  setAllMetadata,
  allMetadata,

  showModal,
}) => {

  useEffect(() => {
    
    if (allMetadata === true) {
      setRequiredField(false);
    }


  }, [requiredField, allMetadata, setRequiredField, setAllMetadata])

  const onChangeRequiredSwitch = (checked) => {
    setRequiredField(checked);
    setAllMetadata(false);
  }
  const onChangeHelperSwitch = (checked) => {
    setHelperText(checked);
  }

  return (
    <div className="s-mapping-preset-btn">
      <div className="inventry-switch-btns pl2px">
        <span><Switch size="small" checked={true} onChange={() => onChangeRequiredSwitch(!requiredField)} /> Minimum Required Metadata</span>
        <span><Switch size="small" checked={true} onChange={() => onChangeHelperSwitch(!helperText)} /> Helper Text</span>
      </div>
      <div className="trading-task-detail">
      <Button type="link" className="back-button preset-btn"  onClick={() => showModal('select-preset')}>
      {selectPreset} Select a Preset
            </Button>
      </div>
    </div>
  )
}

export default MetadataSwitchFilter;