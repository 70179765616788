import React from 'react';
import { Input} from 'antd'

import { searchIcon } from './../../../../../../../Common/Icons';

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onFinish,
  showTableEditor
}) => {

  return (
    <div className="d-flex">
      <div className="inventory-search-bar">
      <Input placeholder="Search" prefix={searchIcon} />
      </div>
      <div className="layout-btn">
      </div>
    </div>
  )
}

export default InventorySearchDropdown;