import React from "react";

import TopTitleSection from "./TopTitleSection";

const HeaderFilter = ({ sessionRes, setStep, mainUserRes }) => {
  return (
    <div>
      <TopTitleSection
        sessionRes={sessionRes}
        setStep={setStep}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default HeaderFilter;
