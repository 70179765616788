import React, { Fragment, useState } from 'react';
import { Button, Checkbox, message } from 'antd';

import AddSelectionList from './SelectionList';

const selectionsList = [
  { name: "Invite User 1", role: "Sales Agent" },
  { name: "Invite User 2", role: "Sales Agent"  },
  { name: "Invite User 3", role: "Sales Agent"  },
  { name: "Invite User 4", role: "Sales Agent"  },
  { name: "Invite User 5", role: "Sales Agent"  },
  { name: "Invite User 6", role: "Sales Agent"  },
  { name: "Invite User 7", role: "Sales Agent"  },
  { name: "Invite User 8", role: "Sales Agent"  },
  { name: "Invite User 9", role: "Sales Agent"  },
]

const SelectionList = ({
  addSelectionsList,
  // selectionsList,
  onCreateSelection,
  onGetSelectionsList,
  onUpdateSelection,

  handleCancel
}) => {

  const [selectedSelection, setSelectedSelection] = useState("");

  const onChangeSelection = value => {
    setSelectedSelection(value)
  };

  const onAddSelection = () => {
    if (selectedSelection) {
      handleCancel();
    } else {
      message.info('Please select the selection first');
    }
  }

  return (
    <Fragment>
      <h5>AVAILABLE USERS</h5>
      <ul className="invite-checkbox column-tasks-area">

        {
          (selectionsList && selectionsList.length > 0) &&
          selectionsList.map((list, index) => {
            return (
              <li key={index}>
                  <Checkbox.Group onChange={onChangeSelection}>
                  <Checkbox value={list._id}>
                    <AddSelectionList
                      list={list}
                      index={index}
                      onGetSelectionsList={onGetSelectionsList}
                      onUpdateSelection={onUpdateSelection}
                    />
                  </Checkbox>
                  </Checkbox.Group>
              </li>

            )
          })
        }

      </ul>
      <div className="add-selections-style">
        <Button type="primary" onClick={onAddSelection}>SEND INVITE</Button>
      </div>
    </Fragment>
  )
}

export default SelectionList;
