import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const departmentReducer = (state = {}, action) => {
  switch (action.type) {

    case types.CREATE_DEPARTMENT_SUCCESS:
      return { ...state, createDepartmentRes: action.payload }
    case types.CREATE_DEPARTMENT_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, createDepartmentRes: action.payload }

    case types.GET_DEPARTMENT_LIST_SUCCESS:
      return { ...state, departmentList: action.payload }
    case types.GET_DEPARTMENT_LIST_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, departmentList: action.payload }

    case types.GET_SPECIFIC_DEPARTMENT_SUCCESS:
      return { ...state, getSpecificDepartmentDetail: action.payload }
    case types.GET_SPECIFIC_DEPARTMENT_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, getSpecificDepartmentDetail: action.payload }

    case types.UPDATE_DEPARTMENT_SUCCESS:
      return { ...state, updateDepartmentRes: action.payload }
    case types.UPDATE_DEPARTMENT_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, updateDepartmentRes: action.payload }

    case types.DELETE_DEPARTMENT_SUCCESS:
      return { ...state, deleteDepartmentRes: action.payload }
    case types.DELETE_DEPARTMENT_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, deleteDepartmentRes: action.payload }
    default:
      return state
  }
}