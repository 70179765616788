import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

const SpecialCatalogue = ({
  items,
  notExists,
  addToHighlights,
  removeFromHighlights,
}) => {
  return (
    <Fragment>
      <Row gutter={16}>
        {items &&
          items.map((item) => (
            <Col className="mb20" span={8} key={item._id}>
              {/* <Link to="/#!"> */}
              <div className="catalogues-banner catalogue-b-grid">
                <div
                  className={"catalogue-bg"}
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.banner}/image)`,
                  }}
                >
                  <img
                    className="catalogue-bg-img"
                    src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.banner}/image`}
                    alt="Catalogues"
                  />
                  <div className="new-items-grid-img-overlay"></div>
                  <h2 className="new-items-grid-img-text">{item.name}</h2>
                </div>

                {/* <img className="catalogue-bg-img" src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${item.banner}/image`} alt="Catalogues" />
                <div className="catalogues-b-text  new-items-grid-img-overlay">
                  <div>
                    <h2 className="new-items-grid-img-overlay-text">{item.name}</h2>
                  </div>
                </div> */}
              </div>
              {/* </Link> */}
              <div className="new-catalogues-info highlights-catalogue s-catalogues-text">
                <h3>{item.name}</h3>
                <h4>
                  <span>No of Assets:</span> {item.assets}
                </h4>
                {notExists("special_catalogues", item._id) ? (
                  <div className="catalouge-btns">
                    <Button
                      type="link"
                      className="p0 border-0"
                      onClick={() =>
                        addToHighlights(
                          "special_catalogues",
                          "special_catalogue",
                          item._id
                        )
                      }
                    >
                      {" "}
                      Add to Highlights
                    </Button>
                  </div>
                ) : (
                  <div className="catalouge-btns">
                    <Button
                      type="link"
                      className="p0 border-0"
                      onClick={() =>
                        removeFromHighlights("special_catalogues", item._id)
                      }
                    >
                      {" "}
                      Remove from Highlights
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          ))}
      </Row>
    </Fragment>
  );
};

export default SpecialCatalogue;
