import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TradingTask from '../Components/ContentArea/LicenseTrading/TradingTasks';

import {
  logout,
  getSpecificUserDetail,
  getSpecificUserImage,
  changeUserPassword,
  updateProfileAndUsername,
  mianUserDetail,
  getTradingList,
  getAllTradingTask,
  getAssetDetail,
  getInventorySeachDetail,
  getUserAllList,
  getTradingTask,
  sendInvitation,
  getOrganizationProfile,
  updateTradingTaskApproval,
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    logoutRes: state.userReducer.logoutRes,
    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    specificUserImage: state.userManagementReducer.specificUserImage,
    changePasswordRes: state.userManagementReducer.changePasswordRes,
    updateUserprofileAndUsername: state.userManagementReducer.updateUserprofileAndUsername,
    mainUserRes: state.userReducer.mainUserRes,
    getTradingListRes: state.tradingTaskReducer.getTradingListRes,
    getAllTradingTaskRes: state.requestProposalReducer.getAllTradingTaskRes,
    assetDetail: state.assetReducer.assetDetail,
    getInventorySeachDetailRes: state.inventorySearchReducer.getInventorySeachDetailRes,
    getUserListRes: state.tradingTaskReducer.getUserListRes,
    getTradingTaskRes: state.tradingTaskReducer.getTradingTaskRes,
    getOrganizationProfileRes: state.organizationReducer.getOrganizationProfileRes,
    sendInvitationRes: state.selectionProposalReducer.sendInvitationRes,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: bindActionCreators(logout, dispatch),
    onGetSpecificUserDetail: bindActionCreators(getSpecificUserDetail, dispatch),
    onGetSpecificUserImage: bindActionCreators(getSpecificUserImage, dispatch),
    onChangeUserPassword: bindActionCreators(changeUserPassword, dispatch),
    onUpdateProfileAndUsername: bindActionCreators(updateProfileAndUsername, dispatch),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
    onGetTradingList: bindActionCreators(getTradingList, dispatch),
    onGetAllTradingTask: bindActionCreators(getAllTradingTask, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onGetInventorySeachDetail: bindActionCreators(getInventorySeachDetail, dispatch),
    onGetUserList: bindActionCreators(getUserAllList, dispatch),
    onGetTradingTask: bindActionCreators(getTradingTask, dispatch),
    onGetOrganizationProfile: bindActionCreators(getOrganizationProfile, dispatch),
    onsendInvitation: bindActionCreators(sendInvitation, dispatch),
    onUpdateTradingTaskApproval: bindActionCreators(updateTradingTaskApproval, dispatch),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradingTask);
