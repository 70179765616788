import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MembersCatelogues from "../Components/ContentArea/MembersCatelogues";

import { getMemberCatalogues, getOrganizationDetail } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    cataloguesList: state.catalogueReducer.cataloguesList,
    // getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,
    mainUserRes: state.userReducer.mainUserRes,
    organizationRes: state.organizationReducer.organizationRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCataloguesList: bindActionCreators(getMemberCatalogues, dispatch),
    // onGetSpecificUserDetail: bindActionCreators(getSpecificUserDetail, dispatch),
    onGetOrganization: bindActionCreators(getOrganizationDetail, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MembersCatelogues);
