import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form } from 'antd';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

const MUSIC = [
  "Alternative",
  "Blues",
  "Children's Music",
  "Classical",
  "Comedy",
  "Commercial",
  "Country",
  "Electronic",
  "Enka",
  "Folk Music",
  "Hip-Hop/Rap",
  "Holiday",
  "Industrial",
  "Jazz",
  "Latin",
  "New Age",
  "Opera",
  "Pop",
  "R&amp;B/Soul",
  "Reggae",
  "Religious",
  "Rock",
  "Singer/Songwriter",
  "Soundtrack",
  "Vocal",
  "World",
  "other",
];

const DANCE = [
  "African and African-American",
  "Ballet",
  "Ballroom",
  "Ceremonial dance",
  "Contemporary",
  "Disco/Electronic dance",
  "Flamenco",
  "Free and improvised dance",
  "Historical dance",
  "Jazz dance",
  "Latin/Rhythm",
  "Novelty and fad dances",
  "Social dance",
  "Street dance",
  "Swing dance",
  "Tap dance",
  "Traditional Dance",
  "other",
];

const MusicDanceGenres = ({ helperText, validationField, requiredField }) => {
  const [filmItems, setFilmItems] = useState([]);
  const [danceItems, setDanceItems] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);
  const [tabsChangeTwo, setTabsChangeTwo] = useState(true);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "music_genres")) {
      setTabsChange(false)
    }
  }, [validationField])

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "dance_genres")) {
      setTabsChangeTwo(false)
    }
  }, [validationField])

  const danceHandleChange = selectedItems => {
    setDanceItems(selectedItems);
  };

  const filmHandleChange = selectedItems => {
    setFilmItems(selectedItems);
  };

  const filmFilteredOptions = MUSIC.filter(o => !filmItems.includes(o));
  const danceFilteredOptions = DANCE.filter(o => !danceItems.includes(o));
  return (
    <Row gutter={16}>
      <Col span={24}>
        <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
          <Col className="pl0_important" span={24}>
            <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
              <div
                className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
              >
                <h3>Music Genres</h3>
                <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
              </div>
            </Link>
          </Col>

          {
            helperText &&
            <Col span={24} className={tabsChange === true && "hide-helper-text"}>
              <div className="heper-text"><p>Select at least one music genre that applies to your asset. Missing a (sub-) genre? Let us know which one/s by sending us an email: support@contentagent.net</p></div>
            </Col>
          }

          <Col span={24}>
            <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
              <div className="metadata-field-box">
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="metadata-field-row">
                      <div className="metadata-field-name"><h3 className=""><span>Music Genres</span></h3></div>
                      <div className="metadata-filed">
                        <Form.Item
                          name="musicGenre"
                          rules={[{ type: 'array' }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select multiple..."
                            value={filmItems}
                            onChange={filmHandleChange}
                            style={{ width: '100%' }}
                            getPopupContainer={trigger => trigger.parentElement}
                          >
                            {filmFilteredOptions.map(item => (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>
          <Col className="pl0_important" span={24}>
            <Link to='#' onClick={() => setTabsChangeTwo(!tabsChangeTwo)}>
              <div
                className={tabsChangeTwo === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
              >
                <h3>Dance Genres</h3>
                <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
              </div>
            </Link>
          </Col>

          {
            helperText &&
            <Col span={24} className={tabsChangeTwo === true && "hide-helper-text"}>
              <div className="heper-text"><p>Select one or more dance genres that apply to your asset.</p></div>
            </Col>
          }

          <Col span={24}>
            <div className={tabsChangeTwo === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
              <div className="metadata-field-box">
                <Row gutter={16}>
                  <Col span={24}>
                    <div className="metadata-field-row">
                      <div className="metadata-field-name"><h3 className=""><span>Dance Genres</span></h3></div>
                      <div className="metadata-filed">
                        <Form.Item
                          name="danceGenre"
                          rules={[{ type: 'array' }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select multiple..."
                            value={danceItems}
                            onChange={danceHandleChange}
                            style={{ width: '100%' }}
                            getPopupContainer={trigger => trigger.parentElement}
                          >
                            {danceFilteredOptions.map(item => (
                              <Select.Option key={item} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MusicDanceGenres;