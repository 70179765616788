import React, { Component, Fragment } from "react";
import { Droppable } from "react-beautiful-dnd";
import { SwapOutlined } from "@ant-design/icons";

import Tasks from "./Tasks";

class Columns extends Component {
  render() {
    const { column, task, index } = this.props;

    return (
      <Fragment>
        <div className="table-edit-column">
          <h3 className="table-edit-column-title">{column.title}</h3>

          <Droppable droppableId={column.id} type="tasks">
            {(provided, snapshot) => (
              <div
                className={
                  snapshot.draggingOverWith
                    ? "column-tasks-area move-bg"
                    : "column-tasks-area"
                }
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {task.map((task, index) => {
                  return <Tasks key={task.id} index={index} task={task} />;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        {index === 0 && (
          <div className="swap-icon">
            <SwapOutlined />
          </div>
        )}
      </Fragment>
    );
  }
}

export default Columns;
