import React, { useState, useEffect } from "react";
import { Modal, Pagination } from "antd";

import HeaderComponent from "./../../../Header";
import HeaderFiltter from "./HeaderFilter";
import ListBoth from "./ListBoth";
import ListArchive from "./ListArchive";
import SavedSearchPresetsModal from "../../../Common/SavedSearchPresetsModal";
import SaveSearchModal from "../../../Common/Components/SaveSearchModal";
import NumberOfAssetModal from "./NumberOfAssetModal";
import PreviewModal from "./PreviewModal";
import TableEditor from "../../../Common/TableEditor";
import CascadingShort from "../../../Common/CascadingSort";
import EvaluateModal from "./EvaluateModal";
import NegotiationInvitationModal from "./../../../Common/SendInvitationModal/NegotiationInvitationModal";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const TradingTasks = ({
  location,
  mainUserRes,
  onLogout,
  onGetOrganizationProfile,
  onsendInvitation,
  getOrganizationProfileRes,
  sendInvitationRes,
  onUpdateTradingTaskApproval,
  getSpecificUserDetail,
  onGetSpecificUserDetail,
  specificUserImage,
  onGetSpecificUserImage,
  onGetMainUserDetail,
  onGetTradingList,
  getTradingListRes,
  onGetAllTradingTask,
  getAllTradingTaskRes,
  onGetUserList,
  getUserListRes,
  getTradingTaskRes,
  onGetTradingTask,
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [visibleTE, setVisibleTE] = useState(false);
  const [visibleTrading, setVisibleTrading] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [saveSearch, setSaveSearch] = useState(false);
  const [saveSearchResult, setSaveSearchResult] = useState([]);
  const [currentTab, setCurrentTab] = useState("sale");
  const [modalType, setModalType] = useState("");
  const [search, setSearch] = useState("");
  const [tradingTaskId, setTradingTaskId] = useState();
  const [assetsList, setAssetsList] = useState([]);
  const [approved, setApproved] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "trading-task.list") === false) {
      if (currentTab === "sale") {
        getSalesPurchase(currentTab, 10, currentPage, search);
      }
      if (currentTab === "purchase" || approved) {
        getSalesPurchase(currentTab, 10, currentPage, search);
        setApproved(false);
      }
      if (currentTab === "both") {
        getSalesPurchase(currentTab, 10, currentPage, search);
      }
      if (currentTab === "archive") {
        getSalesPurchase("archive", 10, currentPage, search);
      }
    }
  }, [currentTab, currentPage, search, mainUserRes, approved]);

  useEffect(() => {
    onGetOrganizationProfile();
  }, []);

  useEffect(() => {
    onGetUserList().then((res) => {
    });
  }, []);


  const showTableEditor = (value, record) => {
    setVisibleTE(true);
    setModalType(value);
    setVisiblePopover(false);
    setTradingTaskId(record._id);
    setAssetsList(record);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const showModal = (value) => {
    setVisibleTE(true);
    setModalType(value);
    setVisiblePopover(false);
  };

  const showTradingTask = (value, record) => {
    setVisibleTrading(true);
    setModalType(value);
    setVisibleTE(false);
    setTradingTaskId(record._id);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const onChangeTabs = (value) => {
    setCurrentTab(value);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const onCloseSaveSearch = () => {
    setSaveSearch(false);
  };

  const cancelTradingTask = (e) => {
    setVisibleTrading(false);
  };

  const onHidePopover = (value) => {
    setVisiblePopover(true);
  };

  const onSaveSearch = (modalType, searchRes) => {
    setSaveSearch(true);
    setModalType(modalType);
    setSaveSearchResult(searchRes);
  };

  const getSalesPurchase = (currentTab, limit, currentPage, search) => {
    setCurrentPage(currentPage);
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 10;
    onGetTradingList(currentTab, limit, skip, search).then((res) => {
      setTotal(res &&
          res.payload &&
          res.payload.data &&
          res.payload.data.pagination &&
          res.payload.data.pagination.total);
    });
  };

  const onFinish = (values) => {
    if (values) {
      setVisibleTE(false);
    }
  };

  return (
    <div>
      <HeaderFiltter
        showTableEditor={showTableEditor}
        currentTab={currentTab}
        onHidePopover={onHidePopover}
        setVisiblePopover={setVisiblePopover}
        visiblePopover={visiblePopover}
        onSaveSearch={onSaveSearch}
        onSearch={onSearch}
        search={search}
        setCurrentTab={setCurrentTab}
        onChangeTabs={onChangeTabs}
      />
      {(currentTab === "sale" ||
        currentTab === "purchase" ||
        currentTab === "both") && (
        <ListBoth
          showTableEditor={showTableEditor}
          currentTab={currentTab}
          getTradingListRes={getTradingListRes}
          showTradingTask={showTradingTask}
          getUserListRes={getUserListRes}
          getTradingTaskRes={getTradingTaskRes}
          onGetTradingTask={onGetTradingTask}
          mainUserRes={mainUserRes}
          getOrganizationProfileRes={getOrganizationProfileRes}
        />
      )}

      {currentTab && currentTab === "archive" && (
        <ListArchive
          showTableEditor={showTableEditor}
          currentTab={currentTab}
          getTradingListRes={getTradingListRes}
          getUserListRes={getUserListRes}
          onGetTradingTask={onGetTradingTask}
        />
      )}

      <div className="mt20 center">
        <Pagination
          current={currentPage}
          total={total}
          defaultPageSize={10}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      </div>
      {visibleTE && (
        <Modal
          visible={visibleTE}
          onCancel={cancelTableEditor}
          footer={false}
          className="modal-style"
          width={
            (modalType === "table_editor" && 740) ||
            (modalType === "asset-list" && 540) ||
            (modalType === "preview-modal" && 540) ||
            (modalType === "evaluate-modal" && 540) ||
            (modalType === "cascading_sort" && 500) ||
            (modalType === "search_preset" && 400)
          }
          centered
        >
          {modalType === "table_editor" && <TableEditor />}

          {modalType === "cascading_sort" && (
            <CascadingShort onFinish={onFinish} />
          )}

          {modalType === "asset-list" && (
            <NumberOfAssetModal
              assetsList={assetsList}
              cancelTableEditor={cancelTableEditor}
            />
          )}

          {modalType === "preview-modal" && (
            <PreviewModal
              showModal={showModal}
              onGetAllTradingTask={onGetAllTradingTask}
              getAllTradingTaskRes={getAllTradingTaskRes}
              onUpdateTradingTaskApproval={onUpdateTradingTaskApproval}
              tradingTaskId={tradingTaskId}
              setApproved={setApproved}
            />
          )}

          {modalType === "evaluate-modal" && (
            <EvaluateModal
              cancelTableEditor={cancelTableEditor}
              showTradingTask={showTradingTask}
            />
          )}

          {modalType === "search_preset" && (
            <SavedSearchPresetsModal handleCloseSelection={cancelTableEditor} />
          )}
          {modalType === "invite-preveiw" && (
            <NegotiationInvitationModal
              cancelTableEditor={cancelTableEditor}
              onsendInvitation={onsendInvitation}
              sendInvitationRes={sendInvitationRes}
              location={location}
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              tradingTaskId={tradingTaskId}
            />
          )}
        </Modal>
      )}

      {visibleTrading === true && (
        <Modal
          visible={visibleTrading}
          onCancel={cancelTradingTask}
          footer={false}
          className="modal-trading"
          width="100%"
        >
          <div className="app-header-trading-task">
            <HeaderComponent
              getSpecificUserDetail={getSpecificUserDetail}
              onGetSpecificUserDetail={onGetSpecificUserDetail}
              specificUserImage={specificUserImage}
              onGetSpecificUserImage={onGetSpecificUserImage}
              mainUserRes={mainUserRes}
              onGetMainUserDetail={onGetMainUserDetail}
              onLogout={onLogout}
            />
          </div>
        </Modal>
      )}
      <SaveSearchModal
        visible={saveSearch}
        searchResult={saveSearchResult}
        setSearchResult={setSaveSearchResult}
        handleCancel={onCloseSaveSearch}
      />
    </div>
  );
};

export default TradingTasks;
