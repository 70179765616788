import React from "react";
import { notification } from "antd";
import { data } from "jquery";
import Title from "antd/lib/skeleton/Title";

export const openNotificationWithIcon = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const getUrlLastId = (value) => {
  const array = value.split("/");
  return array[array.length - 1];
};

export const getUrlSecondLastId = (value) => {
  const array = value.split("/");
  return array[array.length - 2];
};

export function toDateString(date) {
  if (date) {
    const newDate = new Date(date);
    let dateDay = newDate.getDate();
    let month = newDate.toLocaleString("default", { month: "short" });
    let year = newDate.getFullYear();
    return `${dateDay && dateDay}-${month && month}-${year && year}`;
  }
}

export function toCreatingDateString(date) {
  const newDate = new Date(date);
  let dd = newDate.getDate();
  let mm = newDate.getMonth();
  let yyyy = newDate.getFullYear();
  let hh = newDate.getHours();
  let mmi = newDate.getMinutes();
  return `${dd < 10 ? `0${dd}` : dd}-${
    mm < 10 ? `0${mm}` : mm
  }-${yyyy} ${hh}:${mmi}`;
}

export function yyyymmddFormate(date) {
  let dateNew = new Date(date);
  let dd = dateNew.getDate();
  let mm = dateNew.getMonth() + 1;
  let yyyy = dateNew.getFullYear();
  return `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
}

export function getOnlyYear(date) {
  let dateNew = new Date(date);

  return dateNew.getFullYear().toString();
}

export function getDateByYear(date) {
  let dateNew = new Date(date);

  return dateNew;
}

export function hhmmssFormate(date) {
  if (date) {
    let dateNew = new Date(date);
    let hh = dateNew.getHours();
    let mm = dateNew.getMinutes();
    let ss = dateNew.getSeconds();
    let ms = dateNew.getMilliseconds();
    return `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm}:${
      ss < 10 ? `0${ss}` : ss
    }.${
      ms
        ? (ms.toString().length === 1 && `00${ms}`) ||
          (ms.toString().length === 2 && `0${ms}`) ||
          (ms.toString().length === 3 && `${ms}`) ||
          (ms.toString().length === 0 && `000`)
        : "000"
    }`;
  } else {
    return "";
  }
}

export function getLength(date) {
  const length = date && date.split(":");

  return `${length ? length[0] : "00"} hr ${length ? length[1] : "00"} mins`;
}

export function getOnlyMinutes(date) {
  const length = date && date.split(":");
  let hours = length && length[0] ? 60 * parseInt(length[0]) : "";
  let minutes = length && length[1] ? parseInt(length[1]) : "";

  return `${hours + minutes} ${hours + minutes && "min."}`;
}

export function getOnlyHoursMinutes(date) {
  const length = date && date.split(":");
  let hours = length && length[0] ? 60 * parseInt(length[0]) : "";
  let minutes = length && length[1] ? parseInt(length[1]) : "";

  return `${minutes && minutes.length > 59 ? hours : minutes} ${
    minutes && minutes.length > 59 ? "hours ago" : "minutes ago"
  }`;
}

export function getOnlyHoursMints(time) {
  var date = new Date(parseInt(time));
  var localeSpecificTime = date.toLocaleTimeString();
  return localeSpecificTime;
}

export function newYearDate(date) {
  const arrYear = date && date.split("-");
  let dateNew = new Date();

  dateNew.setFullYear(parseInt(arrYear && arrYear[0]));

  return dateNew;
}

export function setTiming(time) {
  if (time) {
    const arrTime = time && time.split(":");
    let dateNew = new Date();

    dateNew.setHours(arrTime && arrTime[0]);
    dateNew.setMinutes(arrTime && arrTime[1]);
    dateNew.setSeconds(arrTime && arrTime[2]);
    return dateNew;
  } else {
    return "";
  }
}

export function mergeDateAndTime(data) {
  let date = new Date(data && data.date);
  let time = new Date(data && data.time);

  const hh = time.getHours();
  const mm = time.getMinutes();
  const ss = time.getSeconds();

  date.setHours(hh);
  date.setMinutes(mm);
  date.setSeconds(ss);

  return date;
}

export function checkPermissions(array, string) {
  const arrPermissionKey = array;

  var permissionKey = arrPermissionKey && arrPermissionKey.includes(string);
  return permissionKey;
}

export function addKeyInArray(arr) {
  let newArr = [];

  if (arr && arr.length > 0) {
    arr.map(function (data) {
      data.key = data._id;
      return newArr.push(data);
    });
  }

  return newArr;
}

export function addAccessToken(token) {
  const tokenEncode = btoa(token);
  const accessToken = window.localStorage.setItem("AK", tokenEncode);

  return accessToken;
}

export function getAccessToken() {
  const encodeToken = window.localStorage.getItem("AK");
  const tokenDecode = atob(encodeToken);

  return `Bearer ${tokenDecode}`;
}

export function convertoCapitalize(value) {
  if (value) {
    // const text = value && value.toLowerCase();
    return <span className="text-capitalize">{value}</span>;
  }
}

export function addSpecificUserId(id) {
  const userIdEncode = btoa(id);
  const specificUserId = window.localStorage.setItem("UI", userIdEncode);

  return specificUserId;
}

export function getSpecificUserId() {
  const getSpecificUi = window.localStorage.getItem("UI");
  const SpecificUi = atob(getSpecificUi);

  return SpecificUi;
}

export function yyyymmddAndTimeFormate(date) {
  let dateNew = new Date(date);
  let dd = dateNew.getDate();
  let mm = dateNew.getMonth() + 1;
  let yyyy = dateNew.getFullYear();

  let hours = dateNew.getHours();
  let minutes = dateNew.getMinutes();
  return `${dd < 10 ? `0${dd}` : dd}-${mm < 10 ? `0${mm}` : mm}-${yyyy} ${
    hours < 10 ? `0${hours}` : hours
  }:${minutes < 10 ? `0${minutes}` : minutes}`;
}

export function TimeFormate(date) {
  let dateNew = new Date(date);
  let hours = dateNew.getHours();
  let minutes = dateNew.getMinutes();
  let seconds = dateNew.getSeconds();
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
}

export function timeFormateAmPm(date, countryCode, countryTimeZone) {
  let dateNew = new Date(date);
  let zone = new Date(date).toLocaleString(`en-${countryCode}`, {
    timeZone: countryTimeZone,
  });
  let completeTime = "";
  if (zone) {
    const splitDate = zone.split(",");
    completeTime = splitDate[1];
  }
  return completeTime;
}

export function dateFormateAmPm(date, countryCode, countryTimeZone) {
  let zone = new Date(date).toLocaleString(`en-${countryCode}`, {
    timeZone: countryTimeZone,
  });
  let completeDate = "";
  if (zone) {
    const splitDate = zone.split(",");
    completeDate = splitDate[0];
  }
  return completeDate;
}

export function onlyMinutesByDate(date) {
  let dateNew = new Date(date);
  let minutes = dateNew.getMinutes();
  return minutes;
}

export function onlyHoursByDate(date) {
  let dateNew = new Date(date);
  let hours = dateNew.getHours();
  return hours;
}

export function calculateSearchFilter(arrFilter) {
  let queryString = "";
  if (arrFilter && arrFilter.length > 0) {
    arrFilter.map((data, index) => {
      if (data && data.production_year) {
        queryString += `&production_year_start=${getOnlyYear(
          data.production_year[0]
        )}`;
        queryString += `&production_year_end=${getOnlyYear(
          data.production_year[1]
        )}`;
      }
      if (data && data.film_genre) {
        queryString += `&film_genres=${data.film_genre}`;
      }
      if (data && data.Music_genre) {
        queryString += `&music_genres=${data.Music_genre}`;
      }
      if (data && data.video_resolution) {
        queryString += `&resolution=${data.video_resolution}`;
      }
      if (data && data.director) {
        queryString += `&director=${data.director}`;
      }
      if (data && data.duration_hour) {
        queryString += `&duration_start_hour=${
          data && onlyHoursByDate(data.duration_hour[0])
        }`;
        queryString += `&duration_end_hour=${
          data && onlyHoursByDate(data.duration_hour[1])
        }`;
      }
      if (data && data.duration_minute) {
        queryString += `&duration_start_minute=${
          data && onlyMinutesByDate(data.duration_minute[0])
        }`;
        queryString += `&duration_end_minute=${
          data && onlyMinutesByDate(data.duration_minute[1])
        }`;
      }
      if (data && data.production_company) {
        queryString += `&production_company=${data.production_company}`;
      }
    });
  }
  return queryString;
}

export function valueInString(value) {
  if (value === "title.value") {
    return "Title";
  }
  if (value === "duration") {
    return "Duration";
  }
  if (value === "directors") {
    return "Directors";
  }
  if (value === "language") {
    return "Language";
  }
  if (value === "production_company") {
    return "Production Company";
  }
  if (value === "production_date") {
    return "Production Year";
  }
}

export function getEmail() {
  var url_string = window.location.href;
  var url = new URL(url_string);
  var c = url.searchParams.get("email");
  return c;
}

export function onTokenExpire(error) {
  if (error && error.status === 401) {
    localStorage.clear();
    window.location.assign("/login");
  }
}

// export function getFromApprovels (approvalsArr) {
//   debugger
//   let listPush = "";
//   approvalsArr.map(data =>(
//     data.approved === false && listPush.push("1")
//   ))
//   debugger
//   return (listPush)
// }

export function getAssetArray(assetArr) {
  let arrRes = assetArr.some(
    (element) =>
      element.formal_license_definitions &&
      element.formal_license_definitions.length === 0
  );
  return arrRes;
}

export function getRolesPermissions(mainUser, permission) {
  let allPermissions = [];

  if (mainUser && mainUser.roles && mainUser.roles.length > 0) {
    mainUser.roles.some((data, index) => {
      allPermissions = [...allPermissions, ...data.permissions];
    });
  }
  const uniqPermissoins = allPermissions.filter(function (item, pos) {
    return allPermissions.indexOf(item) == pos;
  });
  if (uniqPermissoins && uniqPermissoins.includes(permission)) {
    return false;
  } else {
    return true;
  }
}

export function deleteSuperAdmin({ users }) {
  const filteredUsers = [];

  users.forEach((user) => {
    const filteredRoles = [];

    user.roles.forEach((role) => {
      if (role.name === "Super Admin") filteredRoles.push(role.name);
    });

    if (filteredRoles.includes("Super Admin")) filteredUsers.push(user);
  });

  console.log("FU ::: ", filteredUsers.length > 1);

  return filteredUsers.length > 1;
}

export function getCountrytime(getOrganizationProfileRes) {
  if (
    (getOrganizationProfileRes && getOrganizationProfileRes.country !== "") ||
    (getOrganizationProfileRes && getOrganizationProfileRes.timezone !== "")
  ) {
    let zone = new Date().toLocaleString(
      getOrganizationProfileRes && getOrganizationProfileRes.country,
      {
        timeZone:
          getOrganizationProfileRes && getOrganizationProfileRes.timezone,
      }
    );

    let time = "";

    if (zone) {
      const splitDate = zone.split(",");
      let completeTime = splitDate[1];

      if (completeTime) {
        let timeArr = completeTime.split(":");
        var hours = timeArr[0];
        var minutes = timeArr[1];
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        var strTime = hours + ":" + minutes + " " + ampm;
        time = strTime;
      }
    }

    return time;
  }
}

export function getLocalCountrytime() {
  let zone = new Date();

  var hours = zone.getHours();
  var minutes = zone.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;

  return strTime;
}
