import React, { Fragment } from 'react';
import CascadingSelectFilter from "./CascadingSelectFilter"

const CasdadingSort = ({
  onFinish,
  onGetInventoryHeader,
  getInventoryHeaderRes,
}) => {

  return (
    <Fragment>
      <div className="cascading-modal-header">
        <h2 className="mb5">Cascading Sort</h2>
        <p>Cascading sort will be reset if you rearrange the table columns</p>
      </div>
      <div className="cascading-columns">
        <span>COLUMNS</span>
        <span>ORDER</span>
      </div>
      <CascadingSelectFilter
        onFinish={onFinish}
        onGetInventoryHeader={onGetInventoryHeader}
        getInventoryHeaderRes={getInventoryHeaderRes}
      />
    </Fragment>
  )
}

export default CasdadingSort;