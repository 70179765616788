import React from "react";
import {
  Table,
  Space,
  Tooltip,
  Button,
  Card,
  Avatar,
  Spin,
  Pagination,
} from "antd";
import { Link } from "react-router-dom";
import PermissionsComponent from "./../../../Common/PermissionsComponent";
import ProposalsListData from "../../../../statics/data/proposalsManagement.json";

const { Meta } = Card;

const UserTaskListBox = ({
  showModal,
  loading,
  delConfirmModal,
  onChangePagination,
  currentPage,
}) => {
  const columns = [
    {
      title: "VENDOR NAME",
      dataIndex: "vendorname",
      key: "vendorname",
      render: (text, record) => text,
    },
    {
      title: "PROPOSALS ALLOWED",
      dataIndex: "allowed",
      key: "allowed",
      render: (text, record) => text,
    },
    {
      title: "ASSIGNED USER",
      dataIndex: "assignedUser",
      key: "assignedUser",
      render: (text, record) => {
        return (
          <Link to="#">
            <Meta
              className="meta-avatar-align meta-link-avatar"
              disabled={record.status === "blocked" ? true : false}
              avatar={
                <Avatar
                  size={24}
                  src="https://avatars3.githubusercontent.com/u/10627086?s=40&v=4"
                />
              }
              title={text}
            />
          </Link>
        );
      },
    },
    {
      title: "ACTIONS",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table usre-management-action">
          <PermissionsComponent
            permissions={"RFP_CREATE"}
            component={() => (
              <Tooltip title="Edit">
                <Button
                  type="link"
                  onClick={() => showModal("edit-user", record)}
                >
                  <u>Edit</u>
                </Button>
              </Tooltip>
            )}
          />

          <Tooltip title="Delete">
            <Button type="link" onClick={delConfirmModal}>
              <u>Delete</u>
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      <Table
        columns={columns}
        dataSource={ProposalsListData}
        className="table-style task-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        pagination={false}
      />

      <div span={24} className="d-flex justify-center mt50">
        <Pagination
          defaultCurrent={currentPage}
          defaultPageSize={10}
          onChange={onChangePagination}
          total={20}
        />
      </div>
    </Spin>
  );
};

export default UserTaskListBox;
