import * as types from '../Constants';
// import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const walletReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_WALLET_LIST_SUCCESS:
      return { ...state, walletList: action.payload }
    case types.GET_WALLET_LIST_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload.response.data.msg
      // );
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, walletList: action.payload }
    case types.GET_WALLET_DETAIL_SUCCESS:
      return { ...state, walletDetail: action.payload }
    case types.GET_WALLET_DETAIL_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload.response.data.msg
      // );
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, walletDetail: action.payload }
    case types.CREATE_WALLET_SUCCESS:
      return { ...state, createWallet: action.payload }
    case types.CREATE_WALLET_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload.response.data.msg
      // );
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, createWallet: action.payload }
    case types.UPDATE_WALLET_SUCCESS:
      return { ...state, updateWallet: action.payload }
    case types.UPDATE_WALLET_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload.response.data.msg
      // );
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, updateWallet: action.payload }
    case types.DELETE_WALLET_SUCCESS:
      return { ...state, deleteWallet: action.payload }
    case types.DELETE_WALLET_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload.response.data.msg
      // );
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, deleteWallet: action.payload }
    case types.GET_WALLET_BALANCE_SUCCESS:
      return { ...state, walletBalance: action.payload }
    case types.GET_WALLET_BALANCE_FAILURE:
      // openNotificationWithIcon(
      //   'error',
      //   'Error',
      //   action.payload.response.data.msg
      // );
      // if (action.payload.response.statusText === "Unauthorized") {
      //   window.location.assign('/login');
      // }
      return { ...state, walletBalance: action.payload }
    default:
      return state
  }
}