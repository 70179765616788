import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, TimePicker, DatePicker, Button } from 'antd';
import { DownOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {exclamationIcon} from './../../../../Common/Icons';
import { Link } from 'react-router-dom';

const Trailer = ({
  validationField,
  helperText,
  requiredField,
  valuesCorrection
}) => {
  const [tabsChange, setTabsChange] = useState(true);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "trailer")) {
      setTabsChange(false)
    }
  }, [validationField])

  const onChangeTime = time => {
    setValue(time);
  };

  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  }

  return (
    <Row gutter={16} className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}>

      <Col className="pl0_important" span={24}>
        <Link onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title error-f-tab" : "metadata-tab-title metadata-tab-active error-f-tab"}
          >
            <h3>Trailers</h3>
            <div className="tab-error-sec">
              {
                !valuesCorrection &&
                <div className="tab-error-found">
                  <Button type="link" >
                  {exclamationIcon} Errors Found
                  </Button>
                </div>
              }
            <DownOutlined />
            </div>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Header text would go here for this section.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="trailers">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map(field => (
                    <div>
                      <Row gutter={16} className="mb20">

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Name</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                className="full-width mr20 mb0"
                                rules={[{ required: false, message: 'Please enter name!' }]}
                              >
                                <Input placeholder="Name" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Launch Date</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'launchDate']}
                                fieldKey={[field.fieldKey, 'launchDate']}
                                rules={[{ required: false, message: 'Please enter launch date!' }]}
                                className="full-width mr20 mb0"
                              >
                                <DatePicker onChange={onChangeDate} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Length</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'length']}
                                fieldKey={[field.fieldKey, 'length']}
                                className="full-width mr20 mb0"
                                rules={[{ required: false, message: 'Please enter length!' }]}
                              >
                                <TimePicker value={value} onChange={onChangeTime} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Director</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'director']}
                                fieldKey={[field.fieldKey, 'director']}
                                className="full-width mr20 mb0"
                                rules={[{ required: false, message: 'Please enter director name!' }]}
                              >
                                <Input placeholder="Director" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Url</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'url']}
                                fieldKey={[field.fieldKey, 'url']}
                                className="full-width mr20 mb0"
                                rules={[
                                  { required: false, message: 'Please enter the preview link' },
                                  {
                                    required: false,
                                    pattern: new RegExp("https?://.+"),
                                    message: "Please enter the https://!"
                                  }
                                ]}
                              >
                                <Input placeholder="Trailer Url" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '100%' }}
                      >
                        <PlusOutlined /> Add New
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  )
}

export default Trailer;