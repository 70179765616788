import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CataloguesPremiumProfile from "../Components/ContentArea/CataloguesPremiumProfile";

import { getGeneralCatalogue, getHighlights } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    cataloguesList: state.catalogueReducer.getMemberCatalogues,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetGeneralCatalogue: bindActionCreators(getGeneralCatalogue, dispatch),
    onGetHighlights: bindActionCreators(getHighlights, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CataloguesPremiumProfile);
