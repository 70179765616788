import React, { useState } from 'react';
import { Row, Col, Input, Form } from 'antd';

const OwnerForm = () => {
  const [ownerName, setOwnerName] = useState('');

  const onChangeOwner = (e) => {
    setOwnerName(e.target.value);
  }

  return (
    <Row gutter={16} className="mb5">
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name"><h3 className="required"><span>Owner</span></h3></div>
          <div className="metadata-filed">
            <Form.Item
              name="owner"
              rules={[{ whitespace: true }]}
            >
              <Input placeholder="Owner Name" value={ownerName} onChange={onChangeOwner} />
            </Form.Item>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default OwnerForm;