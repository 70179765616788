import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Filter from "./Filter";
import HeaderFilter from "./TopTitleSection";
import WalletList from "./List";
import WalletForm from "./WalletForm";
import { getRolesPermissions } from "../../Common/reUseFunctions";

const Wallet = ({
  mainUserRes,
  onGetWalletList,
  walletList,
  onGetWalletDetail,
  walletDetail,
  onCreateWallet,
  onUpdateWallet,
  onDeleteWallet,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState("");

  useEffect(() => {
    setLoading(true);

    getWalletList(currentPage, 9, search);
  }, [currentPage, search, mainUserRes]);

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const onConfirmDelete = (selectedData) => {
    setLoading(true);
    onDeleteWallet(selectedData && selectedData._id)
      .then(() => {
        const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
        onGetWalletList(skip, 9, search).then((res) => {
          setLoading(false);
        });
        message.success("Deleted Successfully");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getWalletList = (currentPage, limit, search) => {
    if (getRolesPermissions(mainUserRes, "wallet.list") === false) {
      const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
      onGetWalletList(skip, limit, search).then((res) => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const showModal = (modalType, selectedData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedData);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div>
      <HeaderFilter mainUserRes={mainUserRes} showModal={showModal} />
      <Filter
        mainUserRes={mainUserRes}
        onSearch={onSearch}
        search={search}
        showModal={showModal}
      />

      <WalletList
        data={walletList}
        showModal={showModal}
        onDeleteWallet={onDeleteWallet}
        onGetWalletList={onGetWalletList}
        onConfirmDelete={onConfirmDelete}
        onChangePagination={onChangePagination}
        currentPage={currentPage}
        loading={loading}
        mainUserRes={mainUserRes}
      />

      {visible && (
        <Modal
          title={modalType === "edit" ? "Update Wallet" : "New Wallet"}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
        >
          <WalletForm
            handleCancel={handleCancel}
            modalType={modalType}
            onGetWalletDetail={onGetWalletDetail}
            walletDetail={walletDetail}
            selectedData={selectedData}
            onCreateWallet={onCreateWallet}
            onUpdateWallet={onUpdateWallet}
            onGetWalletList={onGetWalletList}
          />
        </Modal>
      )}
    </div>
  );
};

export default Wallet;
