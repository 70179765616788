import React from "react";
import { Switch } from "antd";
import { getRolesPermissions } from "./../../../../../../Common/reUseFunctions";

const InventorySwitchFilter = ({
  setStatusFilter,
  statusFilter,
  setBlockedFilter,
  blockedFilter,
  setPendingFilter,
  pendingFilter,
  mainUserRes,
}) => {
  const onChangeStatus = (checked) => {
    setStatusFilter(checked);
  };

  const onChangeIsBlock = (checked) => {
    setBlockedFilter(checked);
  };

  const onChangePending = (checked) => {
    setPendingFilter(checked);
  };

  return (
    <div>
      {getRolesPermissions(mainUserRes, "user.list") === false ? (
        <div className="inventry-switch-btns user-m-filter">
          <span>
            <Switch
              checked={statusFilter}
              size="small"
              onChange={() => onChangeStatus(!statusFilter)}
            />
            Active
          </span>

          <span>
            <Switch
              checked={blockedFilter}
              size="small"
              onChange={() => onChangeIsBlock(!blockedFilter)}
            />
            Blocked
          </span>

          <span>
            <Switch
              checked={pendingFilter}
              size="small"
              onChange={() => onChangePending(!pendingFilter)}
            />
            Pending
          </span>

          {/* <span>
          <Switch
            checked={unBlockedFilter}
            size="small"
            onChange={() => onChangeUnBlock(!unBlockedFilter)}
          />
          Un Blocked
        </span> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySwitchFilter;
