import React from "react";
import InventorySwitchSec from "./InventorySwitchFilter";
import InventorySearchDropDown from "./InventorySearchDropdown";
import { Input } from "antd";
import { searchIcon } from "../../../../../Common/Icons";
import { getRolesPermissions } from "../../../../../Common/reUseFunctions";

const InventorySearchDropdown = ({
  onChangeLayout,
  changeLayout,
  onChangeSelectionType,
  showTableEditor,
  selectSales,
  setSelectSales,
  selectPurchase,
  setSelectPurchase,
  setSelectionTypeChange,
  onFinish,
  onSearch,
  mainUserRes,
}) => {
  return (
    <div className="inventory-bottom-filter">
      {getRolesPermissions(mainUserRes, "selection.lists") === false ? (
        <InventorySwitchSec
          onChangeSelectionType={onChangeSelectionType}
          selectSales={selectSales}
          setSelectSales={setSelectSales}
          selectPurchase={selectPurchase}
          setSelectPurchase={setSelectPurchase}
          setSelectionTypeChange={setSelectionTypeChange}
        />
      ) : (
        ""
      )}
      {/* <InventorySearchDropDown
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        onFinish={onFinish}
        showTableEditor={showTableEditor}
      /> */}
      {getRolesPermissions(mainUserRes, "selection.lists") === false ? (
        <div className="inventory-search-bar">
          <Input placeholder="Search" prefix={searchIcon} onChange={onSearch} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InventorySearchDropdown;
