import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom'
import { Row, Col, Spin } from 'antd';
import HeaderFilter from './HeaderFilter';
import SpecialCatalogue from './SpecialCatalogue';
import GeneralCatalogue from './GeneralCatalogue';

const SpecialAndGeneralCatalogues = ({
  assets,
  onGetInventory,
  onGetSpecialCatalogue,
  onAddAssetToSpecialCatalogue,
  onDeleteAssetFromSpecialCatalogue,
}) => {
  const {id} = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchResult, setSearchResult] = useState('')

  useEffect(() => {
    onGetSpecialCatalogue()
  }, [id])

  useEffect(() => {
    getGeneralAssets()
  }, [searchResult, assets])

  const getGeneralAssets = useCallback(() => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9

    setLoading(true)

    onGetInventory(
      9,
      skip,
      `%5B%22title.value%22,%22_id%22,%22preview_image%22%5D`,
      'local',
      searchResult
    )
      .then(res => {
        setLoading(false)

        const generalAssets = res.payload.assets && res.payload.assets.assets ? res.payload.assets.assets.filter(asset => {
          const specialAssets = assets.map(asset => asset._id)
          
          return !specialAssets.includes(asset._id)
        }) : []

        setData(generalAssets)
      })
      .catch(err => {
        setLoading(false)
      })
  })

  const addAsset = (asset_id) => {
    setLoading(true)
    onAddAssetToSpecialCatalogue(id, asset_id).then(response => {
      setLoading(false)
      onGetSpecialCatalogue()
    }).catch(err => {
      setLoading(false)
    })
  }

  const removeAsset = (asset_id) => {
    setLoading(true)
    onDeleteAssetFromSpecialCatalogue(id, asset_id).then(response => {
      setLoading(false)
      onGetSpecialCatalogue()
    }).catch(err => {
      setLoading(false)
    })
  }

  return (
    <Spin spinning={loading}>
    <Row gutter={24}>
      <Col className="mb20 colum-border" span={12}>
        <HeaderFilter
          setSearchResult={setSearchResult}
        />
        <GeneralCatalogue
          assets={data}
          onAddAssetToSpecialCatalogue={onAddAssetToSpecialCatalogue}
          addAsset={addAsset}
          setCurrentPage={setCurrentPage}
        />
      </Col>
      <Col className="mb20" span={12}>
        <SpecialCatalogue
          assets={assets}
          removeAsset={removeAsset}
        />
      </Col>
    </Row>
    </Spin>
  )
}

export default SpecialAndGeneralCatalogues;