import React from 'react';
import { Upload, Button, message } from 'antd';
import {fileUploadIcon} from './../../../Common/Icons';

const { Dragger } = Upload;

const UploadCsvFile = ({
  fileList,
  setFileList
}) => {

  const onChange = ({ fileList: newFileList }) => {

    setFileList(newFileList);
  };

  const uploadService = () => {

    return Promise.resolve();
  };

  const customRequest = async (options) => {

    options.onProgress({ percent: 0 });
    const url = await uploadService();
    options.onProgress({ percent: 100 });
    options.onSuccess({}, { ...options.file, url });
    console.log(options);
  };

  const beforeUpload = (file) => {

    const isJPG = file.type === 'image/jpeg';
    const isJPEG = file.type === 'image/jpeg';
    const isGIF = file.type === 'image/gif';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      message.error("The format of the uploaded image is incorrect, not JPG, JPEG, GIF, PNG");
    }
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return new Promise((resolve, reject) => {
      if (!(isJPG || isJPEG || isGIF || isPNG) || (!isLt2M)) {
        reject(file);
      } else {
        resolve(file);
      }
    });
  }

  return ( 
    <div className="promotional-banner upload-csv-file-sec">
      <Dragger 
      onChange={onChange}
      customRequest={customRequest}
      beforeUpload={beforeUpload}
      >
        {fileList.length < 1 &&
          <div className="import-file-button-sec">
            <div>
              {fileUploadIcon}
              <h3>Upload CSV File</h3>
              <p>Drag and drop the file here to import the assets in bulk.</p>
              <p>You'll map the metadata in next step.</p>
              <Button type="primary">UPLOAD FILE</Button>
            </div>
          </div>
        }
      </Dragger >
    </div>
  )
}

export default UploadCsvFile;