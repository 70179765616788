import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublishedAssetsComp from '../Components/ContentArea/PublishedAssets';

import {
  getAssetsList,
  getAssetDetail,
  createAsset,
  updateAsset,
  deleteAsset
} from '../store/Actions';

const mapStateToProps = state => {
  return {
    assetsList: state.assetReducer.assetsList,
    assetDetail: state.assetReducer.assetDetail,
    createAsset: state.assetReducer.createAsset,
    updateAsset: state.assetReducer.updateAsset,
    deleteAsset: state.assetReducer.deleteAsset,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetAssetsList: bindActionCreators(getAssetsList, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onCreateAsset: bindActionCreators(createAsset, dispatch),
    onUpdateAsset: bindActionCreators(updateAsset, dispatch),
    onDeleteAsset: bindActionCreators(deleteAsset, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishedAssetsComp);
