import React from "react";
import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  showTableEditor,
  showModal,
  onChangeSelectionType,
  sessionRes,
  selectSales,
  setSelectSales,
  selectPurchase,
  setSelectPurchase,
  setSelectionTypeChange,
  onSearch,
  mainUserRes,
}) => {
  return (
    <div>
      <TopTitleSection
        showModal={showModal}
        sessionRes={sessionRes}
        mainUserRes={mainUserRes}
      />
      <InventoryFilters
        showTableEditor={showTableEditor}
        onChangeSelectionType={onChangeSelectionType}
        selectSales={selectSales}
        setSelectSales={setSelectSales}
        selectPurchase={selectPurchase}
        setSelectPurchase={setSelectPurchase}
        setSelectionTypeChange={setSelectionTypeChange}
        onSearch={onSearch}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default HeaderFilter;
